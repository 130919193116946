

export const types = {
  SET_INITIAL_STATE :  '[SET_INITIAL_STATE] Set or Re-set the initial statefor search users ',
  LOAD_CUSTOMERS : '[LOAD_CUSTOMERS] Load data for search users customer dropdown',
  LOAD_CUSTOMERS_SUCCESS : '[LOAD_CUSTOMERS_SUCCESS] Load data for search users customer dropdown',
  LOAD_CUSTOMERS_FAIL : '[LOAD_CUSTOMERS_FAIL] Load data for search users customer dropdown',
  LOAD_ROLES : '[LOAD_ROLES] Load data for search users roles dropdown',
  LOAD_ROLES_SUCCESS : '[LOAD_ROLES_SUCCESS] Load data for search users roles dropdown',
  LOAD_ROLES_FAIL : '[LOAD_ROLES_FAIL] Load data for search users roles dropdown',
  LOAD_UNIT_MANAGERS : '[LOAD_UNIT_MANAGERS] Load data for search users unit managers dropdown',
  LOAD_UNIT_MANAGERS_SUCCESS : '[LOAD_UNIT_MANAGERS_SUCCESS] Load data for search users unit managers dropdown',
  LOAD_UNIT_MANAGERS_FAIL : '[LOAD_UNIT_MANAGERS_FAIL] Load data for search users unit managers dropdown',
  LOAD_DEPARTMENTS : '[LOAD_DEPARTMENTS] Load data for search users departments dropdown',
  LOAD_DEPARTMENTS_SUCCESS : '[LOAD_DEPARTMENTS_SUCCESS] Load data for search users departments dropdown',
  LOAD_DEPARTMENTS_FAIL : '[LOAD_DEPARTMENTS_FAIL] Load data for search users departments dropdown',
  LOAD_USERS : '[LOAD_USERS] Load data for search users grid',
  LOAD_USERS_SUCCESS : '[LOAD_USERS_SUCCESS] Load data for search users grid',
  LOAD_USERS_FAIL : '[LOAD_USERS_FAIL] Load data for search users grid',
  SET_SELECTED_UNIT_MANAGER: '[SET_SELECTED_UNIT_MANAGER] Set selected Unit Manager for search users grid',
  SET_SELECTED_DEPARTMENT: '[SET_SELECTED_DEPARTMENT] Set selected Department for search users grid',
  SET_SELECTED_CUSTOMER: '[SET_SELECTED_CUSTOMER] Set selected customer for search users grid',
  SET_EMAIL: '[SET_EMAIL] Set Email for search users grid',
  LOAD_USER_ACTIVITY : '[LOAD_USER_ACTIVITY] Load data for search users activity grid',
  LOAD_USER_ACTIVITY_SUCCESS : '[LOAD_USER_ACTIVITY_SUCCESS] Load data for search users activity grid',
  LOAD_USER_ACTIVITY_FAIL : '[LOAD_USER_ACTIVITY_FAIL] Load data for search users activity grid',
  LOAD_USER_MCO_CONTRACTS : '[LOAD_USER_MCO_CONTRACTS]  Load data for search users mco contracts',
  LOAD_USER_MCO_CONTRACTS_SUCCESS : '[LOAD_USER_MCO_CONTRACTS_SUCCESS] Load data for search users mco contracts',
  LOAD_USER_MCO_CONTRACTS_FAIL : '[LOAD_USER_MCO_CONTRACTS_FAIL] Load data for search users mco contracts',

  UPDATE_USER_STATUS_RESPONSE: '[UPDATE_USER_STATUS] response from update user status put',
  UPDATE_USER_STATUS_FAIL: '[UPDATE_USER_STATUS] update user status fail',
  UPDATE_USER_STATUS_IS_LOADING: '[UPDATE_USER_STATUS] update user status is loading',
  UPDATE_USER_STATUS_FINISH_LOADING: '[UPDATE_USER_STATUS] update user status finish loading',

  UPDATE_USER_SUCCESS: '[UPDATE_USER] update user success',
  UPDATE_USER_FAIL: '[UPDATE_USER] update user fail',
  UPDATE_USER_IS_LOADING: '[UPDATE_USER] update user is loading',
  UPDATE_USER_FINISH_LOADING: '[UPDATE_USER] update user finish loading',

  CLEAR_UPDATE_USER_NOTIFICATIONS: '[CREATE_USER] clear update user notifications',
  CLEAR_UPDATE_USER_ERROR_MSG: '[CREATE_USER] clear update user error msg',
};