
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, CircleCheck, ListPaneHeader, SelectDropdown, Input, Button, ButtonGroup } from '@uicl/ui-core';
import useLayout from '@uicl/ui-core/dist/useLayout';
import { clearCreateUserDropdowns, clearUserErrorMsg, loadDepartmentsByCustomer, loadMcoContractsByCustomer, loadRolesByType, loadUnitManagersByCustomer, saveUser } from '../modules/actions';
import { loadCustomers } from '../../../../commonResources/commonModules/actions';
import '../styles/styles.css'
import LoadingOverlay from '../../../../components/loadingOverlay/LoadingOverlay';


const CreateUser = () => {
  const {
    expandCollapseListPaneButtonConfig,
  } = useLayout();

  const dispatch = useDispatch();

  const {
    rolesByTypeData,
    departmentsData,
    mcoContractsData,
    unitManagerData,
    rolesByTypeDataIsLoading,
    unitManagerDataIsLoading,
    createUserIsLoading,
    createUserError,
    createUserIsSuccess
  } = useSelector(state => state.createUser);
  const { customers } = useSelector(state => state.common);
  const { userInformation } = useSelector(state => state.app);

  const [roleType, setRoleType] = useState([])
  const [referralRole, setReferralRole] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState([])
  const [referralDepartment, setReferralDepartment] = useState([])
  const [unitManager, setUnitManager] = useState([])
  const [mcoContract, setMcoContract] = useState([])
  const [userCustomer, setUserCustomer] = useState([])

  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [middleInitial, setMiddleInitial] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isActive, setIsActive] = useState(true)
  const [isLockedOut, setIsLockedOut] = useState(false)

  const [isUnitMangerSelected, setIsUnitMangerSelected] = useState(false);
  const [isDepartmentMCOSelected, setIsDepartmentMCOSelected] = useState(false);

  const [clickSubmit, setSubmit] = useState(false);
  const [catsKey, setCatsKey] = useState(0);
  const [roleTypes, setRoleTypes] = useState([
    {
      label: 'External Role',
      value: 1
    },
    {
      label: 'Internal Role',
      value: 2
    }
  ])

  const [inputErrors, setInputErrors] = useState('');

  useEffect(() => {
    if (customers.length === 0) {
      dispatch(loadCustomers());
    }
  }, []);

  useEffect(() => {
    if (userInformation.customerId !== 0 && userInformation.customerId !== null) {
      const result = customers.find(element => element.value === userInformation.customerId);
      setUserCustomer(result);
      setRoleTypes([{ label: 'External Role', value: 1 }]);
      fillDropDownsByCustomer(userInformation.customerId);
    }
  }, [customers]);

  useEffect(() => {
    return () => {
      dispatch(clearCreateUserDropdowns());
    }
  }, [])

  useEffect(() => {
    setInputErrors(createUserError);
  }, [createUserError])

  useEffect(() => {
    return () => {
      dispatch(clearUserErrorMsg());
    }
  }, [])

  useEffect(() => {
    if (createUserIsSuccess) {      
      resetStates();
    }  
  }, [createUserIsSuccess])
  


  const handleCustomerChange = (e) => {
    setSelectedCustomer(e ?? []);
    setReferralDepartment([]);
    setUnitManager([]);
    setMcoContract([]);
    if (e) {
      fillDropDownsByCustomer(e.value);
    }
  }

  const fillDropDownsByCustomer = (value) => {
    dispatch(loadDepartmentsByCustomer(value));
    dispatch(loadMcoContractsByCustomer(value));
    dispatch(loadUnitManagersByCustomer(value));
  }

  const handleRoleType = (e) => {
    setRoleType(e ?? []);
    setReferralRole([]);
    handleCustomerChange();
    setSelectedCustomer([]);
    if (e) {
      dispatch(loadRolesByType(e.value));
    }
  }

  const handleRoleChange = (e) => {
    handleCustomerChange();
    setSelectedCustomer([]);
    setReferralRole(e ?? [])
    if (e.role.roleAssociationId === 1) {
      setIsDepartmentMCOSelected(true);
      setIsUnitMangerSelected(false);
    } else if (e.role.roleAssociationId === 2) {
      setIsUnitMangerSelected(true);
      setIsDepartmentMCOSelected(false);
    } else {
      setIsUnitMangerSelected(false);
      setIsDepartmentMCOSelected(false);
    }
  }

  const getPhoneNumber = (phone) => {
    return phone.replace(/[^\w]/gi, '');
  }

  const handleSubmit = () => {
    setSubmit(true);

    if (isFormValid()) {
      const data = {
        email,
        firstName,
        middleInitial,
        lastName,
        phoneNumber: getPhoneNumber(phoneNumber),
        roleTypeId: roleType.value,
        roleAssociationId: referralRole.role.roleAssociationId,
        roleId: referralRole.value,
        customerId: Array.isArray(selectedCustomer) ? userCustomer.value : selectedCustomer.value,
        departmentId: referralDepartment.value,
        mcoContractId: mcoContract.value,
        unitManagerId: unitManager.value,
        isActive,
        isLockedOut,
      }
      dispatch(saveUser(data));
    }
  }

  const isFormValid = () => {
    let isValid = false;

    if (roleType.value === 1) {
      // eslint-disable-next-line
      isValid = (
        // eslint-disable-next-line
        isValidEmail(email) &&
        (!isNullOrEmpty(firstName) && isValidName(firstName)) &&
        (!isNullOrEmpty(lastName) && isValidName(lastName)) &&
        isValidName(middleInitial) &&
        !Array.isArray(roleType) &&
        !Array.isArray(referralRole) &&
        ((roleType.value === 1 && !Array.isArray(selectedCustomer)) || (roleType.value !== 1 && Array.isArray(selectedCustomer))) &&
        ((isDepartmentMCOSelected && !Array.isArray(referralDepartment)) || (!isDepartmentMCOSelected && Array.isArray(referralDepartment))) &&
        ((isDepartmentMCOSelected && !Array.isArray(mcoContract)) || (!isDepartmentMCOSelected && Array.isArray(mcoContract))) &&
        ((isUnitMangerSelected && !Array.isArray(unitManager)) || (!isUnitMangerSelected && Array.isArray(unitManager)))
      ) ? true : false;
    }
    // eslint-disable-next-line
    if (roleType.value === 1 && !Array.isArray(userCustomer)) {
      // eslint-disable-next-line
      isValid = (
        isValidEmail(email) &&
        (!isNullOrEmpty(firstName) && isValidName(firstName)) &&
        (!isNullOrEmpty(lastName) && isValidName(lastName)) &&
        isValidName(middleInitial) &&
        !Array.isArray(roleType) &&
        !Array.isArray(referralRole) &&
        ((isDepartmentMCOSelected && !Array.isArray(referralDepartment)) || (!isDepartmentMCOSelected && Array.isArray(referralDepartment))) &&
        ((isDepartmentMCOSelected && !Array.isArray(mcoContract)) || (!isDepartmentMCOSelected && Array.isArray(mcoContract))) &&
        ((isUnitMangerSelected && !Array.isArray(unitManager)) || (!isUnitMangerSelected && Array.isArray(unitManager)))
      ) ? true : false;
    }
    // eslint-disable-next-line
    if (roleType.value === 2) {
      // eslint-disable-next-line
      isValid = (
        isValidEmail(email) &&
        (!isNullOrEmpty(firstName) && isValidName(firstName)) &&
        (!isNullOrEmpty(lastName) && isValidName(lastName)) &&
        isValidMiddleName(middleInitial) &&
        !Array.isArray(roleType) &&
        !Array.isArray(referralRole)
      ) ? true : false;
    }

    return isValid
  }

  const resetStates = () => {
    setRoleType([]);
    setReferralRole([]);
    setSelectedCustomer([]);
    setReferralDepartment([]);
    setUnitManager([]);
    setMcoContract([]);
    setEmail('');
    setFirstName('');
    setLastName('');
    setMiddleInitial('');
    setPhoneNumber('');
    setIsActive(true);
    setIsLockedOut(false);
    setIsUnitMangerSelected(false);
    setIsDepartmentMCOSelected(false);
    setSubmit(false);
    setCatsKey(key => key + 1)
  }

  const handleCancelClick = () => {
    // setIsSaveSuccess(false);
    // setIsApiError(false);
    // setIsValidationError(false);
    resetStates();
  }

  const isNullOrEmpty = (value) => {
    return value === null || value === '';
  }

  function isValidEmail(inputEmail) {
    // eslint-disable-next-line
    const regex = /^[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-]?[a-zA-Z0-9]+)*(\.[a-zA-Z]{2,}){1,2}$/;  
    return regex.test(inputEmail);
  }

  const isValidName = (str) => {
    const regex = /^$|^[a-zA-Z ]+$/;
    return regex.test(str);
  }

  const isValidMiddleName = (str) => {
    const regex = /^[a-zA-Z]*$|^empty$/;
    return regex.test(str);
  }

  const headerProps = {
    title: 'Create User',
    icon: CircleCheck,
    description: 'This is short description',
    layoutControls: [expandCollapseListPaneButtonConfig],
  };

  return (
    <>
      <ListPaneHeader {...headerProps} />
      {
        (rolesByTypeDataIsLoading || unitManagerDataIsLoading || createUserIsLoading) &&
        <LoadingOverlay />
      }
      <Container>
        <div style={{ margin: 'auto', width: '65vw' }} key={catsKey}>
          <Row>
            <Col>
              <Input
                domID="userEmail"
                regex="^[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-]?[a-zA-Z0-9]+)*(\.[a-zA-Z]{2,}){1,2}$"
                dataTestId="test-userEmail"
                name="email"
                label="Email"
                value={email}
                required
                hasError={(clickSubmit && !email) || (clickSubmit && inputErrors === 'Username is already in use')}
                // eslint-disable-next-line
                errorMessage={clickSubmit && !email ? 'Please enter an email' : (clickSubmit && inputErrors === 'Username is already in use') ? 'Username is already in use' : 'Invalid Email'}
                onChange={(e) => { setEmail(e.target.value); setInputErrors('') }}
              />
            </Col>
            <Col>
              <Input
                domID="userFistName"
                regex={/^[a-zA-Z ]+$/}
                maxLength={30}
                value={firstName}
                required
                // eslint-disable-next-line
                hasError={(clickSubmit && !firstName) || (clickSubmit && inputErrors === 'A user with this name already exists')}
                // eslint-disable-next-line
                errorMessage={clickSubmit && !firstName ? 'Please enter the First Name' : (clickSubmit && inputErrors === 'A user with this name already exists') ? 'A user with this name already exists' : 'Invalid first name'}
                name="firstName"
                label="First Name"
                onChange={(e) => { setFirstName(e.target.value); setInputErrors('') }}
              />
            </Col>
            <Col>
              <Input
                domID="userMiddleInitial"
                regex={/^[a-zA-Z]*$|^empty$/}
                maxLength={1}
                hasError={(clickSubmit && inputErrors === 'A user with this name already exists')}
                errorMessage={(clickSubmit && inputErrors === 'A user with this name already exists') ? 'A user with this name already exists' : 'Invalid middle name'}
                name='middleInitial'
                value={middleInitial}
                label="Middle Initial"
                onChange={(e) => { setMiddleInitial(e.target.value); setInputErrors('') }}
              />
            </Col>
            <Col>
              <Input
                domID="userLastName"
                regex={/^[a-zA-Z ]+$/}
                maxLength={30}
                required
                // eslint-disable-next-line
                hasError={(clickSubmit && !lastName) || (clickSubmit && inputErrors === 'A user with this name already exists')}
                // eslint-disable-next-line
                errorMessage={clickSubmit && !lastName ? 'Please enter the Last Name' : (clickSubmit && inputErrors === 'A user with this name already exists') ? 'A user with this name already exists' : 'Invalid last name'}
                value={lastName}
                name="lastName"
                label="Last Name"
                onChange={(e) => { setLastName(e.target.value); setInputErrors('') }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                domID="userPhone"
                initialValue={phoneNumber}
                hasError={(clickSubmit && inputErrors === 'Invalid phone number')}
                errorMessage={(clickSubmit && inputErrors === 'Invalid phone number') ? 'Invalid phone number' : 'Invalid phone number'}
                name="phone"
                label="Phone Number"
                autoComplete='off'
                mask='(999) 999-9999'
                onChange={(e) => { setPhoneNumber(e.target.value); setInputErrors('') }}
              />
            </Col>
            <Col>
              <SelectDropdown
                size="medium"
                domID="roleType"
                dataTestId="test-roleTypeDropdown"
                name="roleType"
                label="Role Type"
                isClearable={false}
                required
                hasError={clickSubmit && roleType.length === 0}
                errorMessage={(clickSubmit && roleType.length === 0) && 'Please select a Role Type'}
                options={roleTypes}
                onChange={handleRoleType}
                placeholderText="Select Role Type"
              />
            </Col>
            <Col>
              <SelectDropdown
                size="medium"
                domID="userRole"
                dataTestId="test-userRoleDropdown"
                name="role"
                label="Role"
                isClearable={false}
                required
                hasError={clickSubmit && referralRole.length === 0}
                errorMessage={(clickSubmit && referralRole.length === 0) && 'Please select a Role'}
                options={rolesByTypeData}
                value={referralRole}
                onChange={handleRoleChange}
                placeholderText="Select the Role"
              />
            </Col>
          </Row>
          {(
            // referralRole.id !== Number(rolesId.SYSTEM_ADMIN) &&
            // referralRole.id !== Number(rolesId.ACCOUNT_MANAGER) &&
            // referralRole.id !== Number(rolesId.SYSTEM_REFERRAL_ENTRY)
            roleType.value === 1
          )
            &&
            <Row>
              {
                <>
                  {
                    (userInformation.customerId === 0 || userInformation.customerId === null)
                      ?
                      <Col>
                        <SelectDropdown
                          size="medium"
                          domID="selectedCustomer"
                          dataTestId="test-selectedCustomerDropdown"
                          name="selectedCustomer"
                          label="Customer"
                          required={roleType.value === 1}
                          hasError={clickSubmit && roleType.value === 1 && Array.isArray(selectedCustomer)}
                          errorMessage={(clickSubmit && roleType.value === 1 && Array.isArray(selectedCustomer)) ? 'Please select a Customer' : ''}
                          options={customers}
                          value={selectedCustomer}
                          onChange={handleCustomerChange}
                          placeholderText="Select the Customer"
                        />
                      </Col>
                      :
                      <Col>
                        <Input
                          domID="customer"
                          initialValue={userCustomer.label}
                          disabled
                          name="customer"
                          label="Customer"
                          autoComplete='off'
                        />
                      </Col>
                  }
                </>
              }
              {
                (!isDepartmentMCOSelected && !isUnitMangerSelected) &&
                <>
                  <Col></Col>
                  <Col></Col>
                </>
              }
              {
                isDepartmentMCOSelected &&
                <>
                  <Col>
                    <SelectDropdown
                      size="medium"
                      domID="userReferralDepartment"
                      dataTestId="test-userReferralDepartmentDropdown"
                      name="userReferralDepartment"
                      label="Department"
                      required={roleType.value === 1 && isDepartmentMCOSelected}
                      hasError={clickSubmit && isDepartmentMCOSelected && Array.isArray(referralDepartment)}
                      errorMessage={(clickSubmit && isDepartmentMCOSelected && Array.isArray(referralDepartment)) ? 'Please select a Department' : ''}
                      options={departmentsData}
                      value={referralDepartment}
                      onChange={(e) => { setReferralDepartment(e ?? []) }}
                      placeholderText="Select the Department"
                    />
                  </Col>
                  <Col>
                    <SelectDropdown
                      size="medium"
                      domID="userMcoContract"
                      dataTestId="test-mcoContractDropdown"
                      name="mcoContract"
                      label="Mco Contract"
                      required={roleType.value === 1 && isDepartmentMCOSelected}
                      hasError={clickSubmit && isDepartmentMCOSelected && Array.isArray(mcoContract)}
                      errorMessage={(clickSubmit && isDepartmentMCOSelected && Array.isArray(mcoContract)) ? 'Please select a Contract' : ''}
                      options={mcoContractsData}
                      value={mcoContract}
                      onChange={(e) => { setMcoContract(e ?? []) }}
                      placeholderText="Select Mco Contract"
                    />
                  </Col>
                </>
              }
              {
                isUnitMangerSelected &&
                <>
                  <Col>
                    <SelectDropdown
                      size="medium"
                      domID="userUnitManager"
                      dataTestId="test-unitManagerDropdown"
                      name="unitManager"
                      label="Unit Manager"
                      required={roleType.value === 1 && isUnitMangerSelected}
                      hasError={clickSubmit && isUnitMangerSelected && Array.isArray(unitManager)}
                      errorMessage={(clickSubmit && isUnitMangerSelected && Array.isArray(unitManager)) ? 'Please select an Unit Manager' : ''}
                      options={unitManagerData}
                      value={unitManager}
                      onChange={(e) => { setUnitManager(e ?? []) }}
                      placeholderText="Select Unit Manager"
                    />
                  </Col>
                </>
              }
            </Row>
          }
          {/* {(referralRole.value === Number(rolesId.UNIT_MANAGER_ASSISTANT) || referralRole.value === Number(rolesId.REFERRAL_USER)) &&
            <Row>
              <Col>
                <SelectDropdown
                  size="medium"
                  domID="userUnitManager"
                  dataTestId="test-unitManagerDropdown"
                  name="unitManager"
                  label="unitManager"
                  options={[]}
                  value={unitManager}
                  onChange={(e) => { setUnitManager(e ?? null) }}
                  placeholderText="Select the Unit Manager"
                />
                <Input
                  domID="userUnitManager"
                  initialValue={unitManagerName}
                  name="unitManager"
                  label="Unit Manager"
                />
              </Col>
            </Row>
          } */}
          {/* <Row>
            <Col>
              <Checkbox
                domID="userIsActive"
                dataTestId='userIsActive'
                name="isActive"
                label='Is Active'
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
              />
              <Checkbox
                domID="userIsLockedOut"
                dataTestId='userIsLockedOut'
                name="lockedOut"
                label="Is Locked out?"
                checked={isLockedOut}
                onChange={() => setIsLockedOut(!isLockedOut)}
              />
            </Col>
          </Row> */}
          <div className='form-button'>
            <ButtonGroup>
              <Button
                size='medium'
                name='Create User'
                onClick={handleSubmit}
              />
              <Button
                buttonType='diminished'
                size='medium'
                name='Cancel'
                onClick={handleCancelClick}
              />
            </ButtonGroup>
          </div>
        </div>

      </Container>
    </>
  )
}

export default CreateUser;
