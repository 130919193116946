import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Modal } from '@uicl/ui-core/dist/Modal';

const Container = styled.div`
  padding: 2rem;
`;

const PingError = ({ errorCode, errorDescription, push }) => {
  useEffect(() => push('/auth'), [push]);
  return (
    <Modal domID="error" isOpen showDefaultClose={false}>
      <Container>
        <h2>Ping Error.</h2>
        <h3>{errorCode}</h3>
        <p>{errorDescription}</p>
      </Container>
    </Modal >
  );
};

PingError.propTypes = {
  errorCode: PropTypes.string,
  errorDescription: PropTypes.string,
  push: PropTypes.func,
};

export default PingError;
