import { types } from "./types";

const initialState = {
  rolesData: [],
  rolesError: '',
  rolesByTypeData: [],
  rolesByTypeError: '',
  rolesByTypeDataIsLoading: false,
  departmentsData: [],
  departmentsError: '',
  mcoContractsData: [],
  mcoContractsError: '',
  unitManagerData: [],
  unitManagerDataIsLoading: false,
  unitManagerError: '',

  createUserResponse: '',
  createUserError: '',
  createUserIsLoading: false,
  createUserIsSuccess: false,
  createUserIsFail: false,
  customerUserMcoContractData: [{label: '', value:''}],
  customerUserMcoContractError: "",
}

export const createUserReducer = (state = initialState, action) => {

  switch (action.type) {

    case types.LOAD_ROLES_SUCCESS:
      return {
        ...state,
        rolesData: action.payload
      }

    case types.LOAD_ROLES_FAIL:
      return {
        ...state,
        rolesError: action.payload
      }

    case types.LOAD_ROLES_BY_TYPE_SUCCESS:{
      const rolesData = action.payload.sort((a, b) => (a.label > b.label) ? 1 : -1)
      return {
        ...state,
        rolesByTypeData: rolesData
      }
    }

    case types.LOAD_ROLES_BY_TYPE_FAIL:
      return {
        ...state,
        rolesByTypeError: action.payload
      }

    case types.LOAD_ROLES_BY_TYPE_IS_LOADING:
      return {
        ...state,
        rolesByTypeDataIsLoading: true
      }

    case types.LOAD_ROLES_BY_TYPE_FINISH_LOADING:
      return {
        ...state,
        rolesByTypeDataIsLoading: false
      }

    case types.LOAD_DEPARTMENTS_BY_CUSTOMER_SUCCESS: {
      const departmentsDataList = action.payload.length === 0 ? action.payload : action.payload.sort((a, b) => (a.label > b.label) ? 1 : -1)
      return {
        ...state,
        departmentsData: departmentsDataList
      }
    }

    case types.LOAD_DEPARTMENTS_BY_CUSTOMER_FAIL:
      return {
        ...state,
        departmentsError: action.payload
      }

    case types.LOAD_MCO_CONTRACTS_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        mcoContractsData: action.payload
      }

    case types.LOAD_MCO_CONTRACTS_BY_CUSTOMER_FAIL:
      return {
        ...state,
        mcoContractsError: action.payload
      }

    case types.LOAD_UNIT_MANAGER_BY_CUSTOMER_SUCCESS: {
      const unitManagerDataList = action.payload.length === 0 ? action.payload : action.payload.sort((a, b) => (a.label > b.label) ? 1 : -1)
      return {
        ...state,
        unitManagerData: unitManagerDataList
      }
    }

    case types.LOAD_UNIT_MANAGER_BY_CUSTOMER_FAIL:
      return {
        ...state,
        unitManagerError: action.payload
      }

    case types.LOAD_UNIT_MANAGER_BY_CUSTOMER_IS_LOADING:
      return {
        ...state,
        unitManagerDataIsLoading: true
      }

    case types.LOAD_UNIT_MANAGER_BY_CUSTOMER_FINISH_LOADING:
      return {
        ...state,
        unitManagerDataIsLoading: false
      }

    case types.CLEAR_CREATE_USER_DROPDOWNS:
      return {
        ...state,
        rolesData: [],
        rolesByTypeData: [],
        departmentsData: [],
        mcoContractsData: [],
        unitManagerData: [],
      }

    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserResponse: action.payload,
        createUserIsSuccess: true,
        createUserError: ''
      }

    case types.CREATE_USER_FAIL:
      return {
        ...state,
        createUserError: action.payload,
        createUserIsFail: true,
      }

    case types.CREATE_USER_IS_LOADING:
      return {
        ...state,
        createUserIsLoading: true
      }

    case types.CREATE_USER_FINISH_LOADING:
      return {
        ...state,
        createUserIsLoading: false
      }

    case types.CLEAR_USER_NOTIFICATIONS:
      return {
        ...state,
        createUserIsSuccess: false,
        createUserIsFail: false,
      }

    case types.CLEAR_USER_ERROR_MSG:
      return {
        ...state,
        createUserError: ''
      }

    case types.LOAD_MCO_CONTRACT_BY_USER_SUCCESS:
      return {
        ...state,
        customerUserMcoContractData: state.mcoContractsData.filter(items => items.value === action.payload)
      }

    case types.LOAD_MCO_CONTRACT_BY_USER_FAIL:
      return {
        ...state,
        customerUserMcoContractError: action.payload
      }

    case types.CLEAR_MCO_CONTRACT_BY_USER:
      return {
        ...state,
        customerUserMcoContractData: [{label: '', value:''}]
      }

    default:
      return state;
  }

}
