export const types = {
  LOAD_BAR_CHART_INTERNAL_SUCCESS: '[DASHBOARD_INTERNAL] Load data for bar chart',
  LOAD_BAR_CHART_INTERNAL_FAIL: '[DASHBOARD_INTERNAL] Failed to load data for bar chart',
  LOAD_BAR_CHART_INTERNAL_IS_LOADING: '[DASHBOARD_INTERNAL] data for bar chart is loading',
  LOAD_BAR_CHART_INTERNAL_FINISH_LOADING: '[DASHBOARD_INTERNAL] data for bar chart finish loading',

  LOAD_PIE_CHART_INTERNAL_SUCCESS: '[DASHBOARD_INTERNAL] Load data for pie chart grid',
  LOAD_PIE_CHART_INTERNAL_FAIL: '[DASHBOARD_INTERNAL] Failed to load for pie chart',
  LOAD_PIE_CHART_INTERNAL_IS_LOADING: '[DASHBOARD_INTERNAL] data for pie chart is loading',
  LOAD_PIE_CHART_INTERNAL_FINISH_LOADING: '[DASHBOARD_INTERNAL] data for pie chart finish loading',

  LOAD_BAR_CHART_EXTERNAL_SUCCESS: '[DASHBOARD_EXTERNAL] Load data for bar chart external',
  LOAD_BAR_CHART_EXTERNAL_FAIL: '[DASHBOARD_EXTERNAL] Failed to load data for bar chart external',
  LOAD_BAR_CHART_EXTERNAL_IS_LOADING: '[DASHBOARD_EXTERNAL] data for bar chart is loading external',
  LOAD_BAR_CHART_EXTERNAL_FINISH_LOADING: '[DASHBOARD_EXTERNAL] data for bar chart finish loading external',

  LOAD_BAR_CHART_EXTERNAL_OWN_SUCCESS: '[DASHBOARD_EXTERNAL_OWN] Load data for bar chart external own',
  LOAD_BAR_CHART_EXTERNAL_OWN_FAIL: '[DASHBOARD_EXTERNAL_OWN] Failed to load data for bar chart external own',
  LOAD_BAR_CHART_EXTERNAL_OWN_IS_LOADING: '[DASHBOARD_EXTERNAL_OWN] data for bar chart is loading external own',
  LOAD_BAR_CHART_EXTERNAL_OWN_FINISH_LOADING: '[DASHBOARD_EXTERNAL_OWN] data for bar chart finish loading external own',
}