import { errorKeys, loadingKeys } from './constants';
import { types } from './types';
const initgialState = {
  products: [],
  customers: [],
  departments: [],
  errors: [{key: errorKeys.product, message: ''}, 
    {key: errorKeys.customer, message: ''},
    {key: errorKeys.department, message: ''},
    {key: errorKeys.search, message: ''},
    {key: errorKeys.export, message: ''},
  ],
  loading: [{key: loadingKeys.product, isLoading: false}, 
    {key: loadingKeys.customer, isLoading: false},
    {key: loadingKeys.department, isLoading: false},
    {key: loadingKeys.search, isLoading: false},
    {key: loadingKeys.export, isLoading: false},
  ],
  pages: [],
  totalPages: 0,
  totalRecords: 0,
  searchResultPage: [],
  tryToAttachedCustomer: false,
};

export const referralByProductReducer = (state = initgialState, {type, payload}) => {
  switch (type)
  {
    case types.REF_BY_PRODUCT_EXPORT_ACTIVITY_REPORT: {

      const refreshedLoading = state.loading.map(loadObject => loadObject.key === loadingKeys.export ? {...loadObject, isLoading: true } : loadObject);

      return {
        ...state,
        loading: refreshedLoading,
      }
    }

    case types.REF_BY_PRODUCT_EXPORT_ACTIVITY_REPORT_SUCCESS: {
  
      const refreshedErrors = state.errors.map(error => error.key === errorKeys.export ? {...error, message: '' } : error);
  
      return {
        ...state,
        errors: refreshedErrors,
      }
    }

    case types.REF_BY_PRODUCT_EXPORT_ACTIVITY_REPORT_FAIL: {

      const refreshedErrors = state.errors.map(error => error.key === errorKeys.export ? {...error, message: payload } : error);

      return {
        ...state,
        exportError : payload,
        errors: refreshedErrors,
      }
    }

    case types.REF_BY_PRODUCT_EXPORT_ACTIVITY_REPORT_FINISHED: {

      const refreshedLoading = state.loading.map(loadObject => loadObject.key === loadingKeys.export ? {...loadObject, isLoading: false } : loadObject);
      
      return {...state, 
        loading: refreshedLoading }
    }



    case types.REF_BY_PRODUCT_LOAD_PRODUCTS_LOADING: {

      const refreshedLoading = state.loading.map(loadObject => loadObject.key === loadingKeys.product ? {...loadObject, isLoading: true } : loadObject);
      
      return {...state, 
        loading: refreshedLoading }
    }

    case types.REF_BY_PRODUCT_LOAD_PRODUCTS_SUCCESS: {

      const filteredProducts = payload.filter((product) => product.id === 1 || product.id === 2 || product.id === 3);

      const refreshedErrors = state.errors.map(error => error.key === errorKeys.product ? {...error, message: '' } : error);

      return {...state, 
        products: filteredProducts, 
        errors: refreshedErrors,}
    }

    case types.REF_BY_PRODUCT_LOAD_PRODUCTS_FAIL: {

      const refreshedErrors = state.errors.map(error => error.key === errorKeys.product ? {...error, message: payload } : error);

      return {...state, 
        productError: payload, 
        errors: refreshedErrors,
        products: [], }
    }

    case types.REF_BY_PRODUCT_LOAD_PRODUCTS_FINISHED: {

      const refreshedLoading = state.loading.map(loadObject => loadObject.key === loadingKeys.product ? {...loadObject, isLoading: false } : loadObject);

      return {...state, 
        loading: refreshedLoading }
    }


    case types.REF_BY_PRODUCT_LOAD_CUSTOMERS_LOADING: {

      const refreshedLoading = state.loading.map(loadObject => loadObject.key === loadingKeys.customer ? {...loadObject, isLoading: true } : loadObject);

      return {...state, 
        loading: refreshedLoading,
        tryToAttachedCustomer: false, }
    }

    case types.REF_BY_PRODUCT_LOAD_CUSTOMERS_SUCCESS: {

      const refreshedErrors = state.errors.map(error => error.key === errorKeys.customer ? {...error, message: '' } : error);

      return {...state, 
        customers: payload, 
        errors: refreshedErrors, }
    }

    case types.REF_BY_PRODUCT_LOAD_CUSTOMERS_FAIL: {

      const refreshedErrors = state.errors.map(error => error.key === errorKeys.customer ? {...error, message: payload } : error);

      return {...state, 
        errors: refreshedErrors,
        customers: [], 
        departments: [] }
    }

    case types.REF_BY_PRODUCT_LOAD_CUSTOMERS_FINISHED: {

      const refreshedLoading = state.loading.map(loadObject => loadObject.key === loadingKeys.customer ? {...loadObject, isLoading: false } : loadObject);

      return {...state, 
        loading: refreshedLoading,
        tryToAttachedCustomer: true,}
    }


    case types.REF_BY_PRODUCT_LOAD_DEPARTMENT_LOADING: {

      const refreshedLoading = state.loading.map(loadObject => loadObject.key === loadingKeys.department ? {...loadObject, isLoading: true } : loadObject);

      return {...state, 
        loading: refreshedLoading }
    }

    case types.REF_BY_PRODUCT_LOAD_DEPARTMENT_SUCCESS: {

      const refreshedErrors = state.errors.map(error => error.key === errorKeys.department ? {...error, message: '' } : error);

      return {...state, 
        departments: payload, 
        errors: refreshedErrors,}
    }

    case types.REF_BY_PRODUCT_LOAD_DEPARTMENT_FAIL: {

      const refreshedErrors = state.errors.map(error => error.key === errorKeys.department ? {...error, message: payload } : error);

      return {...state, 
        errors: refreshedErrors,
        departments: [] }
    }

    case types.REF_BY_PRODUCT_LOAD_DEPARTMENT_FINISHED: {

      const refreshedLoading = state.loading.map(loadObject => loadObject.key === loadingKeys.department ? {...loadObject, isLoading: false } : loadObject);

      return {...state, 
        loading: refreshedLoading }
    }

    case types.REF_BY_PRODUCT_CLEAR_DEPARTMENTS: {
      return {...state, 
        departments: [] }
    }

    
    case types.REF_BY_PRODUCT_SEARCH_RESULTS_LOADING: {

      const refreshedErrors = state.errors.map(error => error.key === errorKeys.search || error.key === errorKeys.export 
        ? {...error, message: '' } : error);

      const refreshedLoading = state.loading.map(loadObject => loadObject.key === loadingKeys.search ? {...loadObject, isLoading: true } : loadObject);

      return {...state,
        errors: refreshedErrors,
        loading: refreshedLoading }
    }
  
    case types.REF_BY_PRODUCT_SEARCH_RESULTS_LOADING_SUCCESS: {

      const pages = [] 
     
      for(let i = 1; i <= payload.totalPages; i += 1){
        pages.push(i);
      }
    
      const refreshedErrors = state.errors.map(error => error.key === errorKeys.search ? {...error, message: '' } : error);

      return {...state, 
        pages,
        totalPages: payload.totalPages, 
        totalRecords: payload.totalRecords, 
        searchResultPage: payload.data,
        errors: refreshedErrors,}
    }
  
    case types.REF_BY_PRODUCT_SEARCH_RESULTS_LOADING_FAIL: {

      const refreshedErrors = state.errors.map(error => error.key === errorKeys.search ? {...error, message: payload } : error);

      return {...state, 
        errors: refreshedErrors,
        pages: [], 
        totalPages: 0, 
        totalRecords: 0, 
        searchResultPage: [],}
    }
  
    case types.REF_BY_PRODUCT_SEARCH_RESULTS_FINISHED: {

      const refreshedLoading = state.loading.map(loadObject => loadObject.key === loadingKeys.search ? {...loadObject, isLoading: false } : loadObject);

      return {...state, 
        loading: refreshedLoading }
    }



    case types.REF_BY_PRODUCT_CLEAR_LEAVE_PAGE: {
      return {...state, 
        pages: [], 
        totalPages: 0, 
        totalRecords: 0, 
        searchResultPage: [],
        errors: [{key: errorKeys.product, message: ''}, 
          {key: errorKeys.customer, message: ''},
          {key: errorKeys.department, message: ''},
          {key: errorKeys.search, message: ''},
          {key: errorKeys.export, message: ''},
        ],
        loading: [{key: loadingKeys.product, isLoading: false}, 
          {key: loadingKeys.customer, isLoading: false},
          {key: loadingKeys.department, isLoading: false},
          {key: loadingKeys.search, isLoading: false},
          {key: loadingKeys.export, isLoading: false},
        ],
        tryToAttachedCustomer: false,}
    }

    default:
      return state;         
  }
};


