import React,  { useEffect, useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import Input from '@uicl/ui-core/dist/Input';
import Button from '@uicl/ui-core/dist/Button';
import SelectDropdown from '@uicl/ui-core/dist/SelectDropdown';
import { Container, Col, Row } from '@uicl/ui-core/dist/ContentGrid';

import { loadRoles, loadCustomers, loadCustomerDependencies, setSelectedUnitManager, setSelectedDepartment, setEmail } from '../modules/actions';
import { filterTypes} from '../modules/constants';
import '../styles/styles.css';

const FilterUsers = ({onSubmit = () => {} }) => {

  const { 
    roles: rolesOptions, 
    customers: customersOptions,
    departments: departmentsOptions,
    unitManagers: unitManagerOptions,
    selectedRole, 
    selectedFilterType, 
    selectedCustomer, 
    selectedDepartment,
    selectedUnitManager,
    email,
    errorMessage,
    updateUserIsSuccess
  } = useSelector(
    (state) => state.searchUsers,
  );

  const { userInformation } = useSelector(state => state.app);

  const dispatch = useDispatch();

  const [roles, setRoles] = useState([]);
  
  useEffect(() => {
    dispatch(loadRoles());
    onSubmit(selectedRole.value, selectedCustomer.value, selectedDepartment.value, selectedUnitManager.value, email);
  }, []);

  useEffect(() => {
    if (updateUserIsSuccess) {
      onSubmit(selectedRole.value, selectedCustomer.value, selectedDepartment.value, selectedUnitManager.value, email );
    }
  }, [updateUserIsSuccess])

  useEffect(() => {
    handleRolesOptions();
  }, [rolesOptions])
  
  const handleRolesOptions = () => {
    if (userInformation.roleTypeId === 1) {
      setRoles(rolesOptions.filter(f => f.roleTypeId  === 1 || f.label === 'All'));
    } else {
      setRoles(rolesOptions);
    }
  }

  useEffect(() => {
    if (userInformation.roleTypeId === 1) {
      onSubmit(selectedRole.value, userInformation.customerId, selectedDepartment.value, selectedUnitManager.value, email );
    } else {    
      onSubmit(selectedRole.value, selectedCustomer.value, selectedDepartment.value, selectedUnitManager.value, email );
    }
  }, [])
  
  const handleSubmit = () => {
    if (userInformation.roleTypeId === 1) {    
      onSubmit(selectedRole.value, userInformation.customerId, selectedDepartment.value, selectedUnitManager.value, email );
    } else {    
      onSubmit(selectedRole.value, selectedCustomer.value, selectedDepartment.value, selectedUnitManager.value, email );
    }
  }
  
  const onChangeEmail = (e) => { dispatch(setEmail(e)); };
  const onSelectedRole  = ( role ,userInformationCustomerId) => { dispatch(loadCustomers(role,userInformationCustomerId));};
  const onSelectedCustomer = (customer) => { dispatch(loadCustomerDependencies(customer,selectedFilterType)) ;};
  const onSelectUnitManager = (unitManager) => { dispatch(setSelectedUnitManager(unitManager)); };
  const onSelectDepartment = (department) => { dispatch(setSelectedDepartment(department)); };
  
  return (
    <Container>
      <Row> 
        <Col>
          <Row>
            <Col>
              <SelectDropdown
                size = "medium"
                domID="roleDropdown"
                dataTestId="test-roles"
                label="Role" 
                options={roles}
                value = {selectedRole}
                onChange={(e) => {  onSelectedRole(e ?? { label: 'All',value: '0' }, userInformation.customerId); }}
                placeholderText="Select the role"
              />
            </Col>
            <Col>
              <Input
                domID="UserEmail"
                dataTestId="test-userEmail"
                label="User Email"
                name="UserEmail"
                size = "medium"
                onChange={(e) => { onChangeEmail(e.target.value); }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      { ( selectedFilterType !== filterTypes.BY_ROLE && selectedFilterType !== filterTypes.ALL_ROLES ) && 
      <Row>
        <Col> 
          <Row>
            <Col>
              <SelectDropdown
                size = "medium"
                domID="customerDropdown"
                dataTestId="test-customers"
                label="Customer"
                options={(userInformation.customerId === 0)?customersOptions : customersOptions.filter(f => f.value === userInformation.customerId.toString())}
                value = {selectedCustomer}
                onChange={
                  (e) => { 
                    if(e === null){
                      onSelectedCustomer({ label: 'All', value: '0' });
                    }else{
                      onSelectedCustomer(e ?? userInformation.customerId === 0 ? e : customersOptions.filter(f => f.value === userInformation.customerId.toString())[0]); 
                    }
                  }
                }
                placeholderText="Select the customer"
              />
            </Col>
            { selectedFilterType === filterTypes.BY_ROLE_CUSTOMER && 
              <Col></Col>
            }

            { selectedFilterType === filterTypes.BY_ROLE_CUSTOMER_UNIT_MANAGER &&
            <Col> 
              <SelectDropdown
                size = "medium"
                domID="unitManagerDropdown"
                dataTestId="test-unitManagers"
                label="Unit Manager"
                placeholderText="Select the Unit Manager"
                options={unitManagerOptions}
                value={selectedUnitManager}
                onChange={(e) => {   onSelectUnitManager(e ?? { label: 'All', value: '0' }); }}
              />
            </Col>
            }
            { selectedFilterType === filterTypes.BY_ROLE_CUSTOMER_DEPARTMENT &&
            <Col>
              <SelectDropdown
                size = "medium"
                domID="departmentDropdown"
                dataTestId="test-departments"
                label="Department"
                placeholderText="Select the Department"
                options={departmentsOptions}
                value = {selectedDepartment}
                onChange={(e) => { console.log('Department',e); onSelectDepartment(e ?? { label: 'All', value: '0' }); }}
              />
            </Col>
            }
          </Row>
        </Col>
      </Row>
      }
      <Row>
        <div className='column-button'>
          <Button className='search-button'
            size="medium"
            onClick={ () => { handleSubmit(); } }
            name="Search"
            dataTestId="test-formButton"
          />
        </div>    
      </Row>
      { errorMessage && 
        <Row>
          <div className='column-button'>
            {errorMessage}
          </div> 
        </Row>
      }

    </Container>
  );

}


export default FilterUsers;

FilterUsers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  onSubmit: PropTypes.func,
  
};