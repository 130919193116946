export default {
  columns: new Set([
    {
      dataName: 'userName',
      text: 'Email',
      sortable: false,
      isSorted: 0,
    },
    {
      dataName: 'firstName',
      text: 'First Name',
      sortable: false,
      isSorted: 0,
    },
    {
      dataName: 'lastName',
      text: 'Last Name',
      sortable: false,
      isSorted: 0,
    },
    {
      dataName: 'middleInitial',
      text: 'Middle Initial',
      sortable: false,
      isSorted: 0,
    },
    {
      dataName: 'roleName',
      text: 'Role',
      sortable: false,
      isSorted: 0,
    },
    {
      dataName: 'phone',
      text: 'Phone',
      sortable: false,
      isSorted: 0,
    },
    {
      dataName: 'isActiveForGrid',
      text: 'Is Active',
      sortable: false,
      isSorted: 0,
    },
    {
      dataName: 'activeDate',
      text: 'Active Date',
      sortable: false,
      isSorted: 0,
    },
  ]),
  selectedItemIds: new Set([]),
  selectionKey: 'id',
  domId: 'detail-pane',
  onSortTableColumn: () => {},
  onSelectAll: () => {},
  onLinkClick: () => {},
  onSettingsCancel: () => {},
  isConfigurable: true,
};
    