import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NotificationBanner } from '@uicl/ui-core';
import PageWrapper from '../../PageWrapper';
import Notification from '../../../components/notifications/Notification';
import NavigationPane from '../../NavigationPane/containers/NavigationPaneContainer';
import Customers from './components/Customers';
import CustomersDetail from './components/CustomersDetail';
import { setNotificationsToFalse } from './modules/actions';

const Administration = () => {
  const { updateCustomerIsSuccess, updateCustomerIsFail, updateCustomerLogoError } = useSelector((state) => state.customers);
  const dispatch = useDispatch();

  return (
    <>
      {updateCustomerIsFail && (
        <NotificationBanner
          autoDismiss
          onDismiss={() => dispatch(setNotificationsToFalse())}
          text={updateCustomerLogoError}
          type="negative"
        />
      )}
      {updateCustomerIsSuccess && (
        <Notification
          id={Math.floor((Math.random()) * 0x10000).toString(16)}
          autoDismiss
          onDismiss={() => dispatch(setNotificationsToFalse())}
          title="Success"
          text="Customer Logo Updated Successfully"
          type="success"
        />
      )}
      <PageWrapper
        navigationPaneContent={<NavigationPane componentName="Administration" />}
        listPaneContent={<Customers />}
        detailPaneContent={<CustomersDetail dataTestId='detail-pain' />}
      />
    </>
  );
};

export default Administration;