import { types } from "./types";

const initialState = {
  departmentData: [],
  departmentError: '',
  departmentIsLoading: false,

  mcoContractData: [],
  mcoContractError: '',
  mcoContractIsLoading: false,

  userData: [],
  userError: '',
  userIsLoading: false,

  zipcodeData: [],
  zipcodeError: '',
  zipcodeIsLoading: false,

  zipcodeDataAlt: [],
  zipcodeErrorAlt: '',
  zipcodeIsLoadingAlt: false,

  preferredLanguageData: [],
  otherLanguageData: [],
  languageError: '',
  languageIsLoading: false,

  relationshipData: [],
  relationshipError: '',
  relationshipIsLoading: false,

  outreachChannelData: [],
  outreachChannelError: '',
  outreachChannelIsLoading: false,

  referralResponseIsSuccessStatusCode: false,
  referralResponseStatusCode: 0,
  referralError: '',
  referralIsSaving: false,
  triggerPost: false
}

export const createReferralReducer = (state = initialState, action) => {

  switch (action.type) {
    case types.CREATE_REFERRAL_CLEAR_CUSTOMER_DEPENDENCIES:
      return {
        ...state,
        departmentData: [],
        mcoContractData: [],
        userData: [],
      }

    case types.CREATE_REFERRAL_CLEAR_DEPARTMENT_DEPENDENCIES:
      return {
        ...state,
        userData: [],
      }

    case types.CREATE_REFERRAL_CLEAR_MCOCONTRACT_DEPENDENCIES:
      return {
        ...state,
        userData: [],
      }

    case types.CREATE_REFERRAL_CLEAR_ZIPCODE_MEMBER_DATA:
      return {
        ...state,
        zipcodeData: [],
      }

    case types.CREATE_REFERRAL_CLEAR_ZIPCODE_ALT_DATA:
      return {
        ...state,
        zipcodeDataAlt: [],
      }

    case types.CREATE_REFERRAL_RESET:
      return {
        ...state,
        departmentData: [],
        mcoContractData: [],
        userData: [],
        zipcodeData: [],
        zipcodeDataAlt: [],
        referralResponseIsSuccessStatusCode: false,
        referralResponseStatusCode: 0,
      }

    case types.CREATE_REFERRAL_LOAD_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departmentData: action.payload
      }

    case types.CREATE_REFERRAL_LOAD_DEPARTMENTS_IS_LOADING:
      return {
        ...state,
        departmentIsLoading: true
      }

    case types.CREATE_REFERRAL_LOAD_DEPARTMENTS_FINISH_LOADING:
      return {
        ...state,
        departmentIsLoading: false
      }

    case types.CREATE_REFERRAL_LOAD_DEPARTMENTS_FAIL:
      return {
        ...state,
        departmentError: action.payload
      }

    case types.CREATE_REFERRAL_LOAD_MCO_CONTRACTS_SUCCESS:
      return {
        ...state,
        mcoContractData: action.payload
      }

    case types.CREATE_REFERRAL_LOAD_MCO_CONTRACTS_IS_LOADING:
      return {
        ...state,
        mcoContractIsLoading: true
      }

    case types.CREATE_REFERRAL_LOAD_MCO_CONTRACTS_FINISH_LOADING:
      return {
        ...state,
        mcoContractIsLoading: false
      }

    case types.CREATE_REFERRAL_LOAD_MCO_CONTRACTS_FAIL:
      return {
        ...state,
        mcoContractError: action.payload
      }

    case types.CREATE_REFERRAL_LOAD_USER_SUCCESS:
      return {
        ...state,
        userData: action.payload
      }

    case types.CREATE_REFERRAL_LOAD_USER_IS_LOADING:
      return {
        ...state,
        userIsLoading: true
      }

    case types.CREATE_REFERRAL_LOAD_USER_FINISH_LOADING:
      return {
        ...state,
        userIsLoading: false
      }

    case types.CREATE_REFERRAL_LOAD_USER_FAIL:
      return {
        ...state,
        userError: action.payload
      }

    case types.CREATE_REFERRAL_LOAD_ZIPCODE_SUCCESS:
      return {
        ...state,
        zipcodeData: action.payload
      }

    case types.CREATE_REFERRAL_LOAD_ZIPCODE_IS_LOADING:
      return {
        ...state,
        zipcodeIsLoading: true
      }

    case types.CREATE_REFERRAL_LOAD_ZIPCODE_FINISH_LOADING:
      return {
        ...state,
        zipcodeIsLoading: false
      }

    case types.CREATE_REFERRAL_LOAD_ZIPCODE_FAIL:
      return {
        ...state,
        zipcodeError: action.payload
      }

    case types.CREATE_REFERRAL_LOAD_ZIPCODE_ALT_SUCCESS:
      return {
        ...state,
        zipcodeDataAlt: action.payload
      }

    case types.CREATE_REFERRAL_LOAD_ZIPCODE_ALT_IS_LOADING:
      return {
        ...state,
        zipcodeIsLoadingAlt: true
      }

    case types.CREATE_REFERRAL_LOAD_ZIPCODE_ALT_FINISH_LOADING:
      return {
        ...state,
        zipcodeIsLoadingAlt: false
      }

    case types.CREATE_REFERRAL_LOAD_ZIPCODE_ALT_FAIL:
      return {
        ...state,
        zipcodeErrorAlt: action.payload
      }

    case types.CREATE_REFERRAL_LOAD_LANGUAGE_SUCCESS: {

      return {
        ...state,
        preferredLanguageData: action.preferredLanguages,
        otherLanguageData: action.otherLanguages,
      }
    }
    case types.CREATE_REFERRAL_LOAD_LANGUAGE_IS_LOADING:
      return {
        ...state,
        languageIsLoading: true
      }

    case types.CREATE_REFERRAL_LOAD_LANGUAGE_FINISH_LOADING:
      return {
        ...state,
        languageIsLoading: false
      }

    case types.CREATE_REFERRAL_LOAD_LANGUAGE_FAIL:
      return {
        ...state,
        languageError: action.payload
      }


    case types.CREATE_REFERRAL_LOAD_RELATIONSHIP_SUCCESS:
      return {
        ...state,
        relationshipData: action.payload
      }

    case types.CREATE_REFERRAL_LOAD_RELATIONSHIP_IS_LOADING:
      return {
        ...state,
        relationshipIsLoading: true
      }

    case types.CREATE_REFERRAL_LOAD_RELATIONSHIP_FINISH_LOADING:
      return {
        ...state,
        relationshipIsLoading: false
      }

    case types.CREATE_REFERRAL_LOAD_RELATIONSHIP_FAIL:
      return {
        ...state,
        relationshipError: action.payload
      }

    case types.CREATE_REFERRAL_LOAD_OUTREACHCHANNEL_SUCCESS:
      return {
        ...state,
        outreachChannelData: action.payload
      }

    case types.CREATE_REFERRAL_LOAD_OUTREACHCHANNEL_IS_LOADING:
      return {
        ...state,
        outreachChannelIsLoading: true
      }

    case types.CREATE_REFERRAL_LOAD_OUTREACHCHANNEL_FINISH_LOADING:
      return {
        ...state,
        outreachChannelIsLoading: false
      }

    case types.CREATE_REFERRAL_LOAD_OUTREACHCHANNEL_FAIL:
      return {
        ...state,
        outreachChannelError: action.payload
      }

    case types.CREATE_REFERRAL_SAVE_REFERRAL_SUCCESS: {

      const { response } = action;
      const { message, statusCode, isSuccessStatusCode } = response;

      return {
        ...state,
        referralResponseStatusCode: statusCode,
        referralResponseIsSuccessStatusCode: isSuccessStatusCode,
        referralError: message,
        triggerPost: !state.triggerPost

      }
    }

    case types.CREATE_REFERRAL_SAVE_REFERRAL_IS_SAVING:
      return {
        ...state,
        referralIsSaving: true,
        referralResponseStatusCode: 0,
        referralResponseIsSuccessStatusCode: false,
        referralError: '',
      }

    case types.CREATE_REFERRAL_SAVE_REFERRAL_FINISH_SAVING:
      return {
        ...state,
        referralIsSaving: false
      }

    case types.CREATE_REFERRAL_SAVE_REFERRAL_FAIL: {
      return {
        ...state,
        referralError: action.payload,
        referralResponseStatusCode: 500,
        referralResponseIsSuccessStatusCode: false,
      }
    }

    default:
      return state;
  }
}