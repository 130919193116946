import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Input from '@uicl/ui-core/dist/Input';
import SelectDropdown from '@uicl/ui-core/dist/SelectDropdown';
import { Button, DatePicker, RadioGroup, Row } from '@uicl/ui-core';
import useNotificationBanner from '@uicl/ui-core/dist/useNotificationBanner';
import { useForm } from '../../../../commonResources/hooks/useForm';
import { useIsMount } from '../../../../commonResources/hooks/useIsMount';
import { clearSearchReferralNotifications, referralSearchStartLoadingDataforExcel, referralSearchStartLoadingGrid } from '../modules/actions';
import { loadCustomers, loadStates } from '../../../../commonResources/commonModules/actions';
import { isInRole } from '../../../../commonResources/helpers/ruleValidation';
import { ruleConstants } from '../../../../commonResources/constants/ruleConstants';
import '../styles/styles.css'
import { seachType } from '../modules/constants';

export const FilterReferrals = ({ page = 1, setActivePage, setInitPage, setEndPage }) => {

  const { searchGridIsLoading, searchReferralPaginationData, searchExcelDataIsLoading, searchReferralGridData, searchReferralError, searchReferralErrorFlag, isUpdateReferralSuccess } = useSelector(state => state.searchReferral);
  const { customers, states } = useSelector(state => state.common);
  const { userInformation } = useSelector(state => state.app);
  const dispatch = useDispatch();
  const { notify } = useNotificationBanner();

  const [errors, setErros] = useState({
    customerError: false,
    customerErrorMsg: 'Required Field',
    dateFromError: false,
    dateFromErrorMsg: 'Required Field',
    dateThroughError: false,
    dateThroughErrorMsg: ''
  })
  const isMount = useIsMount();
  const [customer, setCustomer] = useState('');
  const [state, setState] = useState('')
  const [dateFrom, setDateFrom] = useState('')
  const [dateThrough, setDateThrough] = useState('')
  const [radioButtons, setRadioButtons] = useState(1);
  const [formValues, handleInputChange] = useForm({
    mbi: '',
    healthPlanId: '',
    firstName: '',
    lastName: '',
  });

  const { mbi, healthPlanId, firstName, lastName } = formValues;
  const { totalRecords } = searchReferralPaginationData;

  const { customerError, customerErrorMsg, dateFromError, dateFromErrorMsg, dateThroughError, dateThroughErrorMsg } = errors
  const handleSubmit = (actionType) => {

    let customerUserId = 0;
    if (isInRole(ruleConstants.ViewOthersReferrals, userInformation) === false && isInRole(ruleConstants.ViewAllReferrals, userInformation) === false) {
      customerUserId = userInformation.customerUserId === 0 ? -1 : userInformation.customerUserId;
    } else if (radioButtons === 1) {
      customerUserId = userInformation.customerUserId === 0 ? -1 : userInformation.customerUserId;
    } else if (radioButtons === 2) {
      customerUserId = 0;
    }

    if (actionType === seachType.SUBMIT_SEARCH && isFormValid()) {
      const params = {
        pageNumber: 1,
        pageSize: 10,
        customerId: customer,
        customerUserId,
        userName: '',
        firstName,
        lastName,
        healthPlanId,
        mbi,
        stateId: state,
        dateFrom,
        dateThrough,
      }
      setActivePage(1);
      setInitPage(0);
      setEndPage(10);
      dispatch(referralSearchStartLoadingGrid(params))
    }
    if (actionType === seachType.SUBMIT_PAGINATION && isFormValid()) {
      const params = {
        pageNumber: page,
        pageSize: 10,
        customerId: customer,
        customerUserId,
        userName: '',
        firstName,
        lastName,
        healthPlanId,
        mbi,
        stateId: state,
        dateFrom,
        dateThrough,
      }
      dispatch(referralSearchStartLoadingGrid(params))
    }
    if (actionType === seachType.EXPORT_EXCEL && isFormValid()) {
      const params = {
        pageNumber: 1,
        pageSize: totalRecords,
        customerId: customer,
        customerUserId,
        userName: '',
        firstName,
        lastName,
        healthPlanId,
        mbi,
        stateId: state,
        dateFrom,
        dateThrough,
      }
      dispatch(referralSearchStartLoadingDataforExcel(params))
    }
  }

  useEffect(() => {
    if (userInformation.customerId > 0) {
      setCustomer(userInformation.customerId);
    }
  }, [])

  useEffect(() => {
    if (customers.length === 0) {
      dispatch(loadCustomers())
    }
    if (states.length === 0) {
      dispatch(loadStates())
    }
  }, []);

  useEffect(() => {
    if (isMount) {
      handleSubmit(seachType.SUBMIT_PAGINATION);
    }

  }, [page])

  useEffect(() => {
    if (searchReferralErrorFlag) {
      notify(searchReferralError, 'negative');
      dispatch(clearSearchReferralNotifications());
    }
  }, [searchReferralErrorFlag])

  useEffect(() => {
    if (isUpdateReferralSuccess) {
      handleSubmit(seachType.SUBMIT_SEARCH)
    }
  }, [isUpdateReferralSuccess])


  const handleRadioChange = (e) => {
    setRadioButtons(e.id);
  }

  const isFormValid = () => {
    if (customer === '') {
      setErros({ ...errors, customerError: true });
      return false;
    }
    if (dateFrom === '') {
      setErros({ ...errors, dateFromError: true });
      return false;
    }
    if (!isValidDate(dateFrom)) {
      setErros({ ...errors, dateFromError: true, dateFromErrorMsg: 'Invalid Date' });
      return false;
    }
    if (dateThrough !== '' && !isValidDate(dateThrough)) {
      setErros({ ...errors, dateThroughError: true, dateThroughErrorMsg: 'Invalid Date' });
      return false;
    }
    setErros({ ...errors, customerError: false, dateFromError: false, dateFromErrorMsg: '', dateThroughError: false, dateThroughErrorMsg: '' });
    return true;
  }

  const isValidDate = (dateString) => {
    const regEx = /^\d{1,2}\/\d{1,2}\/\d{4}$/;

    if (!dateString.match(regEx)) {
      return false;
    }

    const dateArray = dateString.split("/");
    // eslint-disable-next-line 
    const month = parseInt(dateArray[0]);
    // eslint-disable-next-line 
    const day = parseInt(dateArray[1]);
    // eslint-disable-next-line 
    const year = parseInt(dateArray[2]);

    if (month < 1 || month > 12) {
      return false;
    }

    const d = new Date(year, month - 1, 1);
    const lastDayOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
    if (day < 1 || day > lastDayOfMonth) {
      return false;
    }

    if (year <= 0) {
      return false;
    }
    return true;
  }

  return (
    <>
      <div className='referralFiltersContainer'>
        <Row>
          <div className='column-dropdowns'>
            {
              ((isInRole(ruleConstants.ViewAllReferrals, userInformation) &&
                !isInRole(ruleConstants.ViewOthersReferrals, userInformation)))
                ?
                <SelectDropdown
                  placeholderText="[Select Customer]"
                  name="customer"
                  domID="customer"
                  label="Customer"
                  hasError={customerError}
                  errorMessage={customerErrorMsg}
                  onChange={(e) => {
                    setCustomer(e ? e.value : '');
                    setErros({ ...errors, customerError: false });
                  }}
                  options={customers}
                />
                :
                <SelectDropdown
                  placeholderText="[This user is not associated with a customer]"
                  name="customer"
                  domID="customer"
                  label="Customer"
                  value={customers.find(c => c.value === userInformation.customerId)}
                  disabled
                />
            }
          </div>
          <div className='column-dropdowns'>
            <Input
              label="MBI"
              domID="mbi"
              name="mbi"
              value={mbi}
              onChange={handleInputChange}
            />
          </div>
          <div className='column-dropdowns'>
            <Input
              label="Health Plan ID"
              domID="healthPlanId"
              name="healthPlanId"
              value={healthPlanId}
              onChange={handleInputChange}
            />
          </div>
        </Row>
        <Row>
          <div className='column'>
            <Input
              label="First Name"
              domID="firstName"
              name="firstName"
              value={firstName}
              onChange={handleInputChange}
            />
          </div>
          <div className='column'>
            <Input
              label="Last Name"
              domID="lastName"
              name="lastName"
              value={lastName}
              onChange={handleInputChange}
            />
          </div>
        </Row>
        <Row>
          <div className='column-dropdowns'>
            <SelectDropdown
              placeholderText="[Select State]"
              domID="state"
              name="state"
              label="State"
              onChange={(e) => setState(e ? e.value : '')}
              options={states}
            />
          </div>
          <div className='column-dropdowns'>
            <DatePicker
              label="Date From"
              domID="dateFrom"
              hasError={dateFromError}
              errorMessage={dateFromErrorMsg}
              onInputChange={(e) => {
                setDateFrom(e);
                setErros({ ...errors, dateFromError: false, dateFromErrorMsg: '' });
              }}
            />
          </div>
          <div className='column-dropdowns'>
            <DatePicker
              label="Date Through"
              domID="dateThrough"
              hasError={dateThroughError}
              errorMessage={dateThroughErrorMsg}
              onInputChange={(e) => {
                setDateThrough(e);
                setErros({ ...errors, dateThroughError: false, dateThroughErrorMsg: '' });
              }}
            />
          </div>
        </Row>
        <Row>
          <div className='column'>
            {
              (
                (isInRole(ruleConstants.ViewOthersReferrals, userInformation) ||
                  isInRole(ruleConstants.ViewAllReferrals, userInformation)))
                ?
                <RadioGroup
                  size="medium"
                  onChange={handleRadioChange}
                  checkedItem={{ id: 1 }}
                  items={[
                    { id: 1, label: 'My Referral' },
                    { id: 2, label: 'Other Referrals' }
                  ]}
                >
                </RadioGroup>
                :
                <RadioGroup
                  size="medium"
                  disabled
                  checkedItem={{ id: 1 }}
                  items={[
                    { id: 1, label: 'My Referral' },
                    { id: 2, label: 'Other Referrals' }
                  ]}
                >
                </RadioGroup>
            }
          </div>
        </Row>
        <Row>
          <div className='column-button'>
            <Button className='referralFiltersSubmitButtonContainer' onClick={() => handleSubmit(seachType.SUBMIT_SEARCH)} disabled={searchGridIsLoading}>
              Search Referral
            </Button>
            {
              searchReferralGridData.length !== 0 &&
              <Button className='referralFiltersExportExcelButton' onClick={() => handleSubmit(seachType.EXPORT_EXCEL)} disabled={searchGridIsLoading || searchExcelDataIsLoading}>
                Export Result to Excel
              </Button>
            }
          </div>
        </Row>
      </div>
    </>
  )
}

FilterReferrals.propTypes = {
  page: PropTypes.number,
  setActivePage: PropTypes.func,
  setInitPage: PropTypes.func, 
  setEndPage: PropTypes.func
};