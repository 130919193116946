export const headingExcelReferralExceptionReport = [
  [        
    'Customer Name',
    'Mco Contract Name',
    'Department Name',
    'Helath Plan ID',
    'Exception Description',
    'Submitted Last Name',
    'Matched Last Name',
    'Submitted HICN',
    'Matched HICN',
    'Submitted MBI',
    'Matched MBI',
    'Submitted DOB',
    'Matched DOB',
    'Submitted Gender',
    'Matched Gender', 
    'Submitted Phone Number',
    'Submitted Comments',
    'Source',
    'Created On Behalf Of User Name',
    'Created Date',
    'Comments',
  ],
];

export const headersExcelReferralExceptionReport = [
  'customerName',
  'mcoContractName',
  'departmentName',
  'customerMemberId',
  'exceptionDescription',
  'submittedLastName',
  'matchedLastName',
  'submittedHICN',
  'matchedHICN',
  'submittedMBI',
  'matchedMBI',
  'submittedDOB',
  'matchedDOB',
  'submittedGender',
  'matchedGender', 
  'submittedPhoneNumber',
  'submittedComments',
  'source',
  'createdOnBehalfOfUserName',
  'createdDate',
  'comments',
];
    
export const excelNameReferralExceptionReport = 'Referral Exception Report';