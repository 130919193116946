export default {

  columns: new Set([
    {
      dataName: 'customerName',
      text: 'Customer Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'mcoContractName',
      text: 'Mco Contract Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'departmentName',
      text: 'Department Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'customerMemberId',
      text: 'Helath Plan ID',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'exceptionDescription',
      text: 'Exception Description',
      sortable: false,
      isSorted: 0,
      headerWidth: 140,
      isHeaderTruncated: true,
    },
    {
      dataName: 'submittedLastName',
      text: 'Submitted Last Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 140,
      isHeaderTruncated: true,
    },
    {
      dataName: 'matchedLastName',
      text: 'Matched Last Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 140,
      isHeaderTruncated: true,
    },
    {
      dataName: 'submittedHICN',
      text: 'Submitted HICN',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'matchedHICN',
      text: 'Matched HICN',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'submittedMBI',
      text: 'Submitted MBI',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'matchedMBI',
      text: 'Matched MBI',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'submittedDOB',
      text: 'Submitted DOB',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'matchedDOB',
      text: 'Matched DOB',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'submittedGender',
      text: 'Submitted Gender',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'matchedGender',
      text: 'Matched Gender',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'submittedPhoneNumber',
      text: 'Submitted Phone Number',
      sortable: false,
      isSorted: 0,
      headerWidth: 140,
      isHeaderTruncated: true,
    },
    {
      dataName: 'submittedComments',
      text: 'Submitted Comments',
      sortable: false,
      isSorted: 0,
      headerWidth: 160,
      isHeaderTruncated: true,
    },
    {
      dataName: 'source',
      text: 'source',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'createdOnBehalfOfUserName',
      text: 'Created On Behalf Of UserName',
      sortable: false,
      isSorted: 0,
      headerWidth: 200,
      isHeaderTruncated: true,
    },
    {
      dataName: 'createdDate',
      text: 'Created Date',
      sortable: false,
      isSorted: 0,
      headerWidth: 140,
      isHeaderTruncated: true,
    },
    {
      dataName: 'comments',
      text: 'Comments',
      sortable: false,
      isSorted: 0,
      headerWidth: 200,
      isHeaderTruncated: true,
    },
   
  ]),
  selectedItemIds: new Set([]),
  selectionKey: 'Id',
  domId: 'detail-pane',
  onSortTableColumn: () => {},
  onSelectAll: () => {},
  onLinkClick: () => {},
  onSettingsCancel: () => {},
  isConfigurable: true,
};
    