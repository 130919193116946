export const types = {
  EXPORT_CASE_REPORT : '[EXPORT_CASE_REPORT] eXPORT data for Referral Report Case Page',
  EXPORT_CASE_REPORT_SUCCESS : '[EXPORT_CASE_REPORT_SUCCESS] Export data for Referral Report Case Page',
  EXPORT_CASE_REPORT_FAIL : '[EXPORT_CASE_REPORT_FAIL] Export data for Referral Report Case Page',
  LOAD_CASE_REPORT : '[LOAD_CASE_REPORT] Load data for Referral Report Case Page',
  LOAD_CASE_REPORT_SUCCESS : '[LOAD_CASE_REPORT_SUCCESS] Load data for Referral Report Case Page',
  LOAD_CASE_REPORT_FAIL : '[LOAD_CASE_REPORT_FAIL]Load data for Referral Report Case Page',
  LOAD_CUSTOMERS : '[LOAD_CUSTOMERS] Load data for customer dropdown in Report Case Page',
  LOAD_CUSTOMERS_SUCCESS : '[LOAD_CUSTOMERS_SUCCESS] Load data for customer dropdown in Report Case Page',
  LOAD_CUSTOMERS_FAIL : '[LOAD_CUSTOMERS_FAIL] Load data for customer dropdown in Report Case Page',
  LOAD_DEPARTMENTS : '[LOAD_DEPARTMENTS] Load data for departments dropdown in Report Case Page',
  LOAD_DEPARTMENTS_SUCCESS : '[LOAD_DEPARTMENTS_SUCCESS] Load data for departments dropdown in Report Case Page',
  LOAD_DEPARTMENTS_FAIL : '[LOAD_DEPARTMENTS_FAIL] Load data for departments dropdown in Report Case Page',
  LOAD_MCOCONTRACTS : '[LOAD_MCOCONTRACTS] Load data for mco contracts dropdown in Report Case Page',
  LOAD_MCOCONTRACTS_SUCCESS : '[LOAD_MCOCONTRACTS_SUCCESS] Load data for mco contracts dropdown in Report Case Page',
  LOAD_MCOCONTRACTS_FAIL : '[LOAD_MCOCONTRACTS_FAIL] Load data for mco contracts dropdown in Report Case Page',
  RESET_DEPARTMENTS : '[RESET_DEPARTMENTS] Reset departments dropdown in Report Case Page',
  RESET_MCOCONTRACTS : '[RESET_MCOCONTRACTS] Reset Mco Contracts dropdown in Report Case Page',
};