import { fetchWithToken } from '../../../../commonResources/helpers/fetch'
import { convertMmDdYyyyToYyyyMmDd } from '../../../../commonResources/helpers/date';

// verified

export const getCustomersCatalog = async () => (
  fetchWithToken('customers/items').then(res => res.json()).then(json => json.data)
)

export const getDepartmentsCatalog = async (customerId) => (
  fetchWithToken(`referraldepartments/customer/${customerId}/items`).then(res => res.json()).then(json => json.data)
)

export const searchForReferralBulk = (async (filters) => {
  const { pageSize, pageNumber, customer, department, mbi, healthPlanId, fromDate, toDate } = filters;
  const dateFrom = convertMmDdYyyyToYyyyMmDd(fromDate);
  const dateThrough = convertMmDdYyyyToYyyyMmDd(toDate);
 
  return fetchWithToken(`referralreport/bulk?pageSize=${pageSize}&pageNumber=${pageNumber}&CustomerId=${customer}&DepartmentId=${department}&mbi=${mbi}&healthPlanId=${healthPlanId}&DateFrom=${dateFrom}&DateThrough=${dateThrough}`).then(res => res.json())
});

export const searchForReferralBulkForExport = (async (filters) => {
  const { pageSize, pageNumber, customer, department, mbi, healthPlanId, fromDate, toDate } = filters;
  const dateFrom = convertMmDdYyyyToYyyyMmDd(fromDate);
  const dateThrough = convertMmDdYyyyToYyyyMmDd(toDate);

  return fetchWithToken(`referralreport/bulk?pageSize=${pageSize}&pageNumber=${pageNumber}&CustomerId=${customer}&DepartmentId=${department}&mbi=${mbi}&healthPlanId=${healthPlanId}&DateFrom=${dateFrom}&DateThrough=${dateThrough}`).then(res => res.json()).then(json => json.data)
});
