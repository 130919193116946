import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'connected-react-router';
import injectReducer from '../../../utils/injectReducer';
import NavigationPane from '../components/NavigationPane';
import reducer from '../modules/reducer';

const mapDispatchToProps = (dispatch) => ({
  openComponent: (url) => dispatch(push(url))
});

const withConnect = connect(null, mapDispatchToProps);
const withReducer = injectReducer({ key: 'manageNav', reducer });

export default compose(withReducer, withConnect)(NavigationPane);