import React from 'react'
import NavigationPane from '../../NavigationPane/containers/NavigationPaneContainer';
import PageWrapper from '../../PageWrapper';
import Roles from './components/Roles';
import RolesDetail from './components/RolesDetail';




const Administration = () => {
  return (
    <PageWrapper
      navigationPaneContent={<NavigationPane componentName = "Administration"/>}
      listPaneContent={<Roles />}
      detailPaneContent={<RolesDetail dataTestId='detail-pain' />}
    />
  );
};

export default Administration;