
// verified

export const filterTypes = {
  byCustomer: '1',
  byMember: '2',
};
      
export const validationDefault = {
  fromDate: '', 
  toDate: '',
  mbi: '',
  healthPlanId: '',
  pageSize: { name: 'pageSize', label: '5 records per page', value: 5 },
  customer: { name: 'customer', label: 'All Customers', value: 0 },
  department: { name: 'department', label: 'All Departments', value: 0 },
};
  
export const defaultOptions = {
  pageSize: { name: 'pageSize', label: '5 records per page', value: 5 },
  customers: { name: 'customer', value: 0, label: 'All Customers' },
  departments: { name: 'department', label: 'All Departments', value: 0 },
  attachedCustomer: { isAttached: false, name: 'customer', value: 0, label: '' },
};

export const pageSizeOptions = [
  { name: 'pageSize', label: '5 records per page', value: 5 }, 
  { name: 'pageSize', label: '10 records per page', value: 10 }, 
  { name: 'pageSize', label: '15 records per page', value: 15 }, 
  { name: 'pageSize', label: '30 records per page', value: 30 }, 
  { name: 'pageSize', label: '50 records per page', value: 50 }, 
  { name: 'pageSize', label: '75 records per page', value: 75 }, 
  { name: 'pageSize', label: '100 records per page', value: 100 }
];
  
export const validationKeys = {
  customer: "customer",
  department: "department",
  fromDate: "fromDate",
  toDate: "toDate",
  mbi: "mbi",
  healthPlanId: "healthPlanId",
  pageSize: "pageSize",
  activePage: "activePage",
  initPage: "initPage",
  endPage: "endPage",
  pageNumber: "pageNumber",
  exportToExcel: "exportToExcel",
  filterBy: "filterBy",
};

export const validationErrorMessages = {
  customerRequired: "Customer is required", 
  mbi: "MBI is required", 
  fromDateRequired: "From Date is required", 
  dateRangeTo: "Date Through must not be less than From Date",
  dateRangeToday: "From Date must be less than or equal to Today",
  dateFormat: "Invalid Date",
};

export const errorKeys = {
  customer: "customer",
  department: "department",
  export: "export",
  search: "search",
};

export const loadingKeys = {
  customer: "customer",
  department: "department",
  export: "export",
  search: "search",
};
  
  