import React from 'react'
import { useSelector } from 'react-redux';
import Grid from '@uicl/ui-core/dist/Grid';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import LoadingPage from '../../../../components/LoadingPage';
import Pagination from '../../../../components/Pagination';
import { loadingKeys } from '../modules/constants';

// verified

const ReferralBulkReportGridWrapper = styled.div``;

const SearchResults = (props) => {

  const { searchFilterState, 
    paginationState,
    onGridPageClicked,
    movePagesForward,
    movePagesBackward,
    SearchResultsGridConfig,
  } = props;
  
  const [searchResultPage,
    pages, 
    totalPages, 
    totalRecords,
    loading] = useSelector((state) => [
    state.referralBulk.searchResultPage,
    state.referralBulk.pages,
    state.referralBulk.totalPages,
    state.referralBulk.totalRecords,
    state.referralBulk.loading,
  ]);

  const isLoading = loading.filter((loadObject) => (loadObject.key === loadingKeys.customer ||
      loadObject.key === loadingKeys.search ||
      loadObject.key === loadingKeys.export)
  ).some(loadObject => loadObject.isLoading === true);

  return (
    <>
      <ReferralBulkReportGridWrapper>
        <>
          { isLoading === true
            ? <LoadingPage /> 
            : <>
              <Grid
                {...SearchResultsGridConfig}
                className="grid"
                dataTestId="test-formGrid"
                records={searchResultPage}
              />
              <>
                <Pagination  
                  totalRecords = {totalRecords}
                  totalPages = {totalPages} 
                  pages = {pages}      
                  activePage={paginationState.activePage} 
                  initPage={paginationState.initPage}
                  endPage={paginationState.endPage}
                  pageNumber = {searchFilterState.pageNumber}
                  pageSize = {searchFilterState.pageSize}     
                  onGridPag={onGridPageClicked}  
                  movePagesForward ={movePagesForward} 
                  movePagesBackward={movePagesBackward} 
                /> 
              </>
            </> 
          }
        </>
      </ReferralBulkReportGridWrapper>
    </>
  );
}

export default SearchResults;

SearchResults.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  searchFilterState: PropTypes.object, 
  paginationState: PropTypes.object,
  onGridPageClicked: PropTypes.func,
  movePagesForward: PropTypes.func,
  movePagesBackward: PropTypes.func,
  SearchResultsGridConfig: PropTypes.object,
}
