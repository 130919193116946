import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@uicl/ui-core/dist/Grid';
import styled from 'styled-components/macro';
import CircleCheck from '@uicl/ui-core/dist/CircleCheck';
import ListPaneHeader from '@uicl/ui-core/dist/ListPaneHeader';
import useLayout from '@uicl/ui-core/dist/useLayout';
import CaseReportFilters from './CaseReportFilters';
import caseReportGridConfig from './caseReportGridConfig';
import LoadingPage from '../../../../components/LoadingPage';
import Pagination from '../../../../components/Pagination';

import { searchType } from '../modules/constants';
import { loadReferralCaseReport, exportReferralCaseReport} from '../modules/actions';



const filterRequest = {
  filterOption: '',
  customer: '0',
  department: '0',
  mcoContract: '0',
  email: '',
  mbi: '',
  healthPlanId: '',
  dateFrom: '',
  dateThrough: '',
  pageNumber : 1,
  pageSize: 5, 
  loadGrid: false,
};

const pageIndexes = 5;

const ReferralCaseReportGridWrapper = styled.div``;

const ReferralCase = () => {

  const dispatch = useDispatch();
  const {
    detailProps: selectedRecord,
    expandCollapseListPaneButtonConfig,
  } = useLayout();

  const { ids: itemsIds, itemsByIds, totalRecords, pages = [], totalPages, isLoading } = useSelector(
    (state) => state.referralCase,
  );


  const items = itemsIds.map((x) => itemsByIds[x]);
  const [filterReport, setFilterReport] = useState(filterRequest);
  const [initPage, setInitPage] = useState(0);
  const [endPage, setEndPage] = useState(pageIndexes);
  const [activePage, setActivePage] = useState(1);

  const movePagesForward = () => {
    setInitPage(initPage + pageIndexes);
    setEndPage(endPage + pageIndexes);
  };
  const movePagesBackward = () => {
    setInitPage(initPage - pageIndexes);
    setEndPage(endPage - pageIndexes);
  };

  const onGridPag = (pageNumber) => {
    setActivePage(pageNumber);
    setFilterReport({...filterReport, pageNumber });
  };

  useEffect(() => {
    dispatch(loadReferralCaseReport(filterReport));
  }, [filterReport]);


  const onFilterSubmit = (action,filter,customerId, departmentId, mcoContractId, email, mbi, healthPlanId, dateFrom, dateThrough, pageSize) => {
    
    if(action === searchType.SUBMIT_SEARCH) {
      setInitPage(0);
      setEndPage(pageIndexes);
      setActivePage(1);
      setFilterReport({ filter,customerId, departmentId, mcoContractId, email, mbi, healthPlanId, dateFrom, dateThrough, pageSize , pageNumber: 1, loadGrid: true });
    }

    if(action === searchType.EXPORT_EXCEL) {
      dispatch(exportReferralCaseReport({filter,customerId, departmentId, mcoContractId, email, mbi, healthPlanId, dateFrom, dateThrough, pageSize: totalRecords , pageNumber: 1 }))
    }
    
  };

  const headerProps = {
    title: 'Referral Case Report',
    icon: CircleCheck,
    description: 'Referral Case Report',
    layoutControls: [expandCollapseListPaneButtonConfig],
    children: (<CaseReportFilters onSubmit = {(action,filter, customerId, departmentId, mcoContractId, email, mbi, healthPlanId, dateFrom, dateThrough, pageSize) => 
      onFilterSubmit(action,filter, customerId, departmentId, mcoContractId, email, mbi, healthPlanId, dateFrom, dateThrough, pageSize)} />),
  };  


  return (
    <>
      <ListPaneHeader {...headerProps} />

      <ReferralCaseReportGridWrapper>
        <>
          {isLoading === false? (<Grid
            {...caseReportGridConfig}
            className="grid"
            dataTestId="test-formGrid"
            focusedItem={selectedRecord}
            records={items}
          />) : <LoadingPage /> }
        </>

        <Pagination  
          totalRecords = {totalRecords}
          pageNumber = {filterReport.pageNumber}
          pageSize = {filterReport.pageSize}
          pages = {pages}
          totalPages = {totalPages} 
          activePage={activePage} 
          initPage={initPage}
          endPage={endPage}
          onGridPag={onGridPag}  
          movePagesForward ={movePagesForward} 
          movePagesBackward={movePagesBackward} />

      </ReferralCaseReportGridWrapper>
    
    </>
  )
}

export default ReferralCase