import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@uicl/ui-core/dist/Grid';
import CircleCheck from '@uicl/ui-core/dist/CircleCheck';
import ListPaneHeader from '@uicl/ui-core/dist/ListPaneHeader';
import useLayout from '@uicl/ui-core/dist/useLayout';
import LoadingPage from '../../../../components/LoadingPage';
import Pagination from '../../../../components/Pagination';
import CustomersLoadGridWrapper from './styled/CustomersLoadGridWrapper';
import { loadCustomers } from '../modules/actions';

const filterCustomerRequest = {
  pageNumber: 1,
  pageSize: 10,
  loadGrid: true,
  sortingName : '',
  sortingOrder : '',
};

const pageIndexes = 10;
// eslint-disable-next-line
const Customers = () => {

  const dispatch = useDispatch();
  
  const {
    openDetailPane,
    detailProps: selectedRecord,
    expandCollapseListPaneButtonConfig,
  } = useLayout();

  const { ids: customerIds, itemsByIds: customerById, totalRecords, pages = [], totalPages, isLoading} = useSelector(
    (state) => state.customers,
  );

  const customerGridConfig = {
    columns: new Set([
      {
        dataName: 'id',
        text: 'ID',
        sortable: true,
        isSorted: 0,
      },
      {
        dataName: 'name',
        text: ' Customer Name',
        sortable: true,
        isSorted: 0,
      }
    ]),
    selectedItemIds: new Set([]),
    selectionKey: 'id',
    domId: 'detail-pane',
    onSortGridColumn :  (e,r) => { 

      const sortingKey = r.sortingKey;
      const sortingName = sortingKey.split('|')[0];
      let sortingOrder = '';
      if( selectedSortingName !== sortingName){
        sortingOrder = 'SORT_ASCENDING';
      }
      else if ( selectedSortingName === sortingName && selectedSortingOrder === 'SORT_DESCENDING'){
        sortingOrder = 'SORT_ASCENDING';
      }else if ( selectedSortingName === sortingName && selectedSortingOrder === 'SORT_ASCENDING'){
        sortingOrder = 'SORT_DESCENDING';
      }
    
      setFilterCustomer({...filterCustomer,pageNumber: activePage, sortingName, sortingOrder, loadGrid: true})
      setSelectedSortingName(sortingName);
      setSelectedSortingOrder(sortingOrder);

    },
    onSelectAll: () => {},
    onLinkClick: () => {},
    onSettingsCancel: () => {},
    isConfigurable: true,
  };
      

  const [filterCustomer, setFilterCustomer] = useState(filterCustomerRequest);
  const [initPage, setInitPage] = useState(0);
  const [endPage, setEndPage] = useState(pageIndexes);
  const [activePage, setActivePage] = useState(1);
  const [selectedSortingName, setSelectedSortingName] = useState('');
  const [selectedSortingOrder,setSelectedSortingOrder] = useState('');
  const customers = customerIds.map((x) => customerById[x]);

  const onClickRecord = (e, record) => {
    // console.log(e,record);
    
    openDetailPane(record, e);
  };

  const movePagesForward = () => {
    setInitPage(initPage + pageIndexes);
    setEndPage(endPage + pageIndexes);
  };
  const movePagesBackward = () => {
    setInitPage(initPage - pageIndexes);
    setEndPage(endPage - pageIndexes);
  };

  const onGridPag = (pageNumber) => {
    setActivePage(pageNumber);
    setFilterCustomer({ ...filterCustomer, pageNumber });
  };

  useEffect(() => {
    dispatch(loadCustomers(filterCustomer));
  }, [filterCustomer]);

  const headerProps = {
    title: 'Customers',
    icon: CircleCheck,
    description: 'This is short description',
    layoutControls: [expandCollapseListPaneButtonConfig]
  };

  return (
    <>
      <ListPaneHeader {...headerProps} />
      <CustomersLoadGridWrapper>
        <>
          {isLoading === false ? (<Grid
          
            {...customerGridConfig}

            className="grid"
            dataTestId="test-formGrid"
            onClickThrough={(e, record) => { onClickRecord(e, record); }}
            focusedItem={selectedRecord}
            records={customers}
          />) : <LoadingPage />}
        </>

        <Pagination
          totalRecords={totalRecords}
          pageNumber={filterCustomer.pageNumber}
          pageSize={filterCustomer.pageSize}
          pages={pages}
          totalPages={totalPages}
          activePage={activePage}
          initPage={initPage}
          endPage={endPage}
          onGridPag={onGridPag}
          movePagesForward={movePagesForward}
          movePagesBackward={movePagesBackward} />
      </CustomersLoadGridWrapper>
    </>
  )
}

export default Customers