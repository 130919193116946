/**
 * Higher order component that injects an instance of WebStorageManager into
 * the wrapped component.
 */

import React from 'react';
import webStorageManager from '../utils/webstorage-manager';
import { WEB_STORAGE_CONFIG } from '../utils/constants';

const withWebStorage = Component =>
  class WithWebStorage extends React.Component {
    store = webStorageManager.configure(WEB_STORAGE_CONFIG);

    render() {
      // eslint-disable-next-line react/prop-types
      const { children, ...rest } = this.props;
      return (
        <Component store={this.store} {...rest}>
          {children}
        </Component>
      );
    }
  };

export default withWebStorage;
