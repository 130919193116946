export const filters = {
  BY_CUSTOMER : 'BY_CUSTOMER',
  BY_MEMBER : 'BY_MEMBER',
};

export const defaultOptions = {
  customers: {label: 'All', value: '0'},
  departments: {label: 'All', value: '0'},
  mcoContracts:  {label: 'All', value: ''},
};

export const searchType = {
  SUBMIT_SEARCH: 1,
  EXPORT_EXCEL: 2,
}