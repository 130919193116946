import { createSelector } from 'reselect';
import idx from 'idx.macro';
import { advanceSearchRecords } from '../stubData/stubData';
import { navigationPaneGroupTypes } from './constants';

const selectSaveSearch = (state) => idx(state, (_) => _.saveSearch);

// TODO: -- update with record filtering
export const makeSelectSaveSearch = () =>
  createSelector(selectSaveSearch, (saveSearch) => {
    if (!saveSearch) return {};
    const {
      mostRecentSearchType,
      advancedSearchFormState = {},
      navigationPaneItems,
      selectedSingleSelectItem,
    } = saveSearch;

    const saveSearchGroupIndex = navigationPaneItems.findIndex(
      (x) => x.type === navigationPaneGroupTypes.SAVED_SEARCH,
    );

    const [selectedGroup, selectdItem] = selectedSingleSelectItem.split('_');
    const savedSearchActiveIndex =
      selectedGroup === `${saveSearchGroupIndex}` ? selectdItem : '';
    const savedSearches =
      navigationPaneItems.filter(
        (x) => x.type === navigationPaneGroupTypes.SAVED_SEARCH,
      )[0].items || [];
    const index = savedSearchActiveIndex
      ? savedSearchActiveIndex.split('|')[1]
      : null;
    const activeSearch = index !== null ? savedSearches[index] : null;
    const records = mostRecentSearchType ? advanceSearchRecords : [];

    // advanced search
    const {
      jobId = '',
      serviceDate = '',
      patientName = '',
      provider = '',
      location = '',
    } = advancedSearchFormState;

    const filteredRecords =
      Object.keys(advancedSearchFormState).length > 0
        ? records.filter(
          (record) => {
            if (jobId && `${record.jobId}`.includes(jobId)) return true;
            if (serviceDate && `${record.serviceDate}`.includes(serviceDate)) {
              return true;
            }
            if (patientName && `${record.patientName}`.includes(patientName))
              return true;
            if (provider && `${record.provider}`.includes(provider))
              return true;
            if (location && `${record.location}`.includes(location))
              return true;
            return false;
          })
        : records;

    return {
      ...saveSearch,
      savedSearchActiveIndex,
      savedSearches,
      activeSearch,
      records: filteredRecords,
    };
  });
