export const isValidString = value =>
  typeof value === "string" && value.trim() !== "";

export const isValidStorage = type => {
  const test = {
    key: "webStorageManager.test",
    value: "This is a a test of webStorageManager"
  };

  try {
    type.setItem(test.key, test.value);
    type.getItem(test.key);
    type.removeItem(test.key);
    return true;
  } catch (err) {
    return false;
  }
};

// converts value to JSON for storage
export const serialize = value => {
  if (value) {
    try {
      return JSON.stringify(value);
    } catch (err) {}
  }
  return value;
};

// deserializes JSON
export const deserialize = value => {
  if (value) {
    try {
      return JSON.parse(value);
    } catch (err) {}
  }
  return value;
};
