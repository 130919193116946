import { push } from "connected-react-router";
import { getCustomers, getStates } from "./api";
import { types } from "./types";
import { UNAUTHORIZED } from "../constants/statusCode";

export const loadCustomers = () => async dispatch => {
  dispatch({ type: types.LOAD_CUSTOMERS_IS_LOADING })

  try {
    getCustomers().then(customers => {
      if(customers.statusCode === UNAUTHORIZED){
        dispatch(push("/login"));
        return;
      }
      dispatch({
        type: types.LOAD_CUSTOMERS_SUCCESS,
        payload: customers.data.map(obj => ({
          label: obj.name,
          value: obj.id
        }))
      })
    }).catch(() => {
      dispatch({
        type: types.LOAD_CUSTOMERS_FAIL,
        payload: "Something went wrong while creating request data."
      })
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_CUSTOMERS_FAIL,
      payload: "Something went wrong while creating request data."
    })
  } finally {
    dispatch({ type: types.LOAD_CUSTOMERS_FINISH_LOADING })
  }
}

export const loadStates = () => async dispatch => {
  dispatch({ type: types.LOAD_STATES_IS_LOADING })

  try {
    getStates().then(states => {
      if(states.statusCode === UNAUTHORIZED){
        dispatch(push("/login"));
        return;
      }
      dispatch({
        type: types.LOAD_STATES_SUCCESS,
        payload: states.data.map(obj => ({
          label: obj.name,
          value: obj.id
        }))
      })
    }).catch(() => {
      dispatch({
        type: types.LOAD_STATES_FAIL,
        payload: "Something went wrong while creating request data."
      })
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_STATES_FAIL,
      payload: "Something went wrong while creating request data."
    })
  } finally {
    dispatch({ type: types.LOAD_STATES_FINISH_LOADING })
  }
}