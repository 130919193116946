export const headingExcelReferralOutreachReport = [
  [     
    'Memership',
    'Customer Name',
    'Mco Contract Name',
    'DepartmentName',
    'FirstName',
    'MiddleInitial',
    'LastName',
    'HICN',
    'MBI',
    'Helath Plan ID',
    'Source',
    'Was Placed In Queue',
    'Days Since Referral',
    'Created On Behalf Of User Name',
    'Created Date',
  ],
];

export const headersExcelReferralOutreachReport = [
  'membershipID',
  'customerName',
  'mcoContractName',
  'departmentName',
  'firstName',
  'middleInitial',
  'lastName',
  'hicn',
  'mbi',
  'customerMemberId',
  'source',
  'wasPlacedInQueue',
  'daysSinceReferral',
  'createdOnBehalfOfUserName',
  'createdDate',
];

export const excelNameReferralOutreachReport = 'Referral Outreach Report';