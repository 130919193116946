/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import idx from 'idx.macro';
// import { isAuthenticated } from 'auth-management';
import { isAuthenticated } from '../../../commonResources/services/auth-management';
import { makeSelectSaveSearch } from '../../Paradigms/SaveSearch/modules/selectors';

const selectApp = state => idx(state, _ => _.app);
const selectRoute = state => idx(state, _ => _.route);
// const selectCommon = state => idx(state, _ => _.common);

const makeSelectLocation = () =>
  createSelector(selectRoute, routeState => idx(routeState, _ => _.location));



const makeSelectMasthead = () =>
  createSelector(selectApp, appState => {
    let productMenuItems = idx(appState, _ => _.user.apps) || [];
    const aud = idx(appState, _ => _.user.aud);
    if (!Array.isArray(productMenuItems)) {
      productMenuItems = [productMenuItems];
    }
    const currentProduct = productMenuItems.find(app => app.uri === aud);
    const mappedProductMenuItems = [
      productMenuItems.filter(app => app !== currentProduct).map(app => ({
        id: app.id,
        label: app.name,
        path: app.uri,
      })),
    ];
    const authenticated = isAuthenticated();
    
    return {
      navMenuItems: idx(appState, _ => _.navMenuItems),
      productMenuItems: mappedProductMenuItems,
      currentProductName: idx(currentProduct, _ => _.name),
      userName: `${idx(appState, _ => _.user.given_name)} ${idx(
        appState,
        _ => _.user.family_name,
      )}`,
      avatarImage: idx(appState, _ => _.user.photo),
      // probably a more elegant way to do this... in reducer? in index?
      supportMenuItems: authenticated
        ? idx(appState, _ => _.supportMenuItems)
        : [],
      avatarMenuItems: authenticated
        ? idx(appState, _ => _.avatarMenuItems)
        : [],
      logoRedirect: idx(appState, _ => _.logoRedirect),
    };
  });

const makeSelectLocationState = () => {
  let prevRoutingState;
  let prevRoutingStateJS;

  return state => {
    const routingState = idx(state, _ => _.route); // or state.route

    if (!routingState.equals(prevRoutingState)) {
      prevRoutingState = routingState;
      prevRoutingStateJS = routingState.toJS();
    }

    return prevRoutingStateJS;
  };
};

const makeSelectUser = () =>
  createSelector(selectApp, appState => appState.user);

const selectAuthModalOpen = state => idx(state, _ => _.app.authModalOpen);

const makeSelectNotifications = () =>
  createSelector(selectApp, appState => appState.notifications);

export {
  selectApp,
  makeSelectMasthead,
  makeSelectLocationState,
  makeSelectLocation,
  selectRoute,
  makeSelectUser,
  selectAuthModalOpen,
  makeSelectNotifications,
  makeSelectSaveSearch,
};
