import { types } from "./types";

const initialState = {
  roles: [],
  rolesPaginationData: {},
  rolesError: '',
  rolesIsLoading: false,
  rules: [],
  rulesError: '',
  rulesIsLoading: false,
  roleTypes: [],
  roleTypesError: '',
  roleAssociation: [],
  roleAssociationError: '',
  statusCode:0,
  message: '',
  triggerPost: false,
  rolesSaveIsLoading: false,
  statusCodeUpdate:0,
  messageUpdate: '',
  triggerPut: false,
  roleUpdateIsLoading: false,
};

export const rolesReducer = (state = initialState, action) => {

  switch (action.type) {

    case types.LOAD_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      }

    case types.LOAD_ROLES_FAIL:
      return {
        ...state,
        rolesError: action.payload
      }

    case types.LOAD_ROLES_IS_LOADING:
      return {
        ...state,
        rolesIsLoading: true
      }

    case types.LOAD_ROLES_FINISH_LOADING:
      return {
        ...state,
        rolesIsLoading: false
      }

    case types.LOAD_ROLES_PAGINATION_DATA_SUCCESS:
      return {
        ...state,
        rolesPaginationData: action.payload,
      }

    case types.LOAD_RULES_SUCCESS:
      return {
        ...state,
        rules: action.payload,
      }

    case types.LOAD_RULES_FAIL:
      return {
        ...state,
        rulesError: action.payload
      }

    case types.LOAD_RULES_IS_LOADING:
      return {
        ...state,
        rulesIsLoading: true
      }

    case types.LOAD_RULES_FINISH_LOADING:
      return {
        ...state,
        rulesIsLoading: false
      }
    
    case types.LOAD_ROLES_TYPES_SUCCESS:
      return {
        ...state,
        roleTypes: action.payload,
      }

    case types.LOAD_ROLES_TYPES_FAIL:
      return {
        ...state,
        roleTypesError: action.payload,
      }

    case types.LOAD_ROLES_ASSOCIATION_SUCCESS:
      return {
        ...state,
        roleAssociation: action.payload,
      }

    case types.LOAD_ROLES_ASSOCIATION_FAIL:
      return {
        ...state,
        roleAssociationError: action.payload,
      }

    case types.CREATE_ROLE_RESPONSE:
      return {
        ...state,
        statusCode: action.payload.statusCode || action.payload.StatusCode,
        message: action.payload.message || action.payload.Message,
        triggerPost: !state.triggerPost
      }

    case types.SAVE_ROLE_IS_LOADING:
      return {
        ...state,
        rolesSaveIsLoading: true
      }
      
    case types.SAVE_ROLE_FINISH_LOADING:
      return {
        ...state,
        rolesSaveIsLoading: false
      }

    case types.UPDATE_ROLE_RESPONSE:
      return {
        ...state,
        statusCodeUpdate: action.payload.statusCode || action.payload.StatusCode,
        messageUpdate: action.payload.message || action.payload.Message,
        triggerPut: !state.triggerPut
      }

    case types.UPDATE_ROLE_IS_LOADING:
      return {
        ...state,
        roleUpdateIsLoading: true
      }
      
    case types.UPDATE_ROLE_FINISH_LOADING:
      return {
        ...state,
        roleUpdateIsLoading: false
      }

    case types.CLEAR_ROLES_REDUCER:
      return {
        ...initialState
      }

    default:
      return state;
  }

}