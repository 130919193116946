/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 */
import { getUser } from '../../../commonResources/services/auth-management';
import integrations from '../../../integrations';

import {
  NAV_MENU_ITEMS,
  SUPPORT_MENU_ITEMS,
  AVATAR_MENU_ITEMS,
  DEFAULT_LOGO_REDIRECT_PATH,
  DEMO_USER,
  ON_PRODUCT_MENU_SELECT,
  ON_AVATAR_MENU_SELECT,
  ON_NAV_MENU_SELECT,
  ON_MULTI_GROUP_OPEN,
  ON_MULTI_GROUP_CHECK,
  ON_SINGLE_GROUP_OPEN,
  ON_SINGLE_GROUP_SELECT,
  USER_SET,
  TOGGLE_AUTH_MODAL_OPEN,
  ON_INITIAL_NOTIFICATIONS,
  ON_UPDATE_NOTIFICATIONS,
  ON_UNPROCESSED_NOTIFICATIONS,
  MARK_NOTIFICATIONS_READ,
  LOAD_USER_INFORMATION_SUCCESS,
  LOAD_MENU_ITEMS,
  LOAD_USER_INFORMATION_FAIL,
  LOAD_USER_INFORMATION_IS_LOADING,
  LOAD_USER_INFORMATION_FINISH_LOADING,
} from './constants';

// The initial state of the App
const initialState = {
  // navigation
  navMenuItems: NAV_MENU_ITEMS,
  currentNavMenuIndex: 0,
  supportMenuItems: SUPPORT_MENU_ITEMS,
  avatarMenuItems: AVATAR_MENU_ITEMS,
  logoRedirect: DEFAULT_LOGO_REDIRECT_PATH,
  user: integrations.ciam ? getUser() || {} : DEMO_USER,
  authModalOpen: false,
  notifications: [],
  testSagaTimestamp: 0,
  userInformation: [],
  userInformationError: '',
  userInformationErrorFlag: false,
  userInformationIsLoading: false,
  // navMenuItems: []
};

function AppReducer(state = initialState, action) {
  switch (action.type) {
    case ON_PRODUCT_MENU_SELECT:
      return {
        ...state,
      };
    case ON_AVATAR_MENU_SELECT:
      return {
        ...state,
      };
    case ON_NAV_MENU_SELECT:
      return {
        ...state,
        currentNavMenuIndex: action.selectionIndex,
      };
    case ON_MULTI_GROUP_OPEN:
      return {
        ...state,
        multiSelectGroupOpenIDs: new Set([...action.state.openGroupIDs]),
      };
    case ON_MULTI_GROUP_CHECK:
      return {
        ...state,
        multiSelectGroupCheckedIDs: new Set([...action.state.selectedIDs]),
      };
    case ON_SINGLE_GROUP_OPEN:
      return {
        ...state,
        openSingleGroupIndices: new Set([...action.state.openGroupIndices]),
      };
    case ON_SINGLE_GROUP_SELECT:
      return {
        ...state,
        singleSelectIndex: action.state.selectedIndex,
      };
    case USER_SET:
      return {
        ...state,
        user: action.state,
      };

    case LOAD_USER_INFORMATION_SUCCESS:
      return {
        ...state,
        userInformation: action.payload
      }

    case LOAD_USER_INFORMATION_IS_LOADING:
      return {
        ...state,
        userInformationIsLoading: true
      }

    case LOAD_USER_INFORMATION_FINISH_LOADING:
      return {
        ...state,
        userInformationIsLoading: false
      }

    case LOAD_USER_INFORMATION_FAIL:
      return {
        ...state,
        userInformationError: action.payload,
        userInformationErrorFlag: true,
      }

    case LOAD_MENU_ITEMS:
      return {
        ...state,
        navMenuItems: [action.payload]
      }

    case TOGGLE_AUTH_MODAL_OPEN:
      return {
        ...state,
        authModalOpen: action.state,
      };
    case ON_INITIAL_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.state,
      };
    case ON_UPDATE_NOTIFICATIONS:
      return {
        ...state,
        notification: {
          ...state.notifications,
          ...action.state,
        },
      };
    // TODO: This is the same as mark notifications read right now
    // it could handle errors in the future, or we could merge the two then if it doesn't
    case ON_UNPROCESSED_NOTIFICATIONS:
      // eslint-disable-next-line no-case-declarations
      const processedIds = action.state.map(
        (notification) => notification.notificationId,
      );
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          const processedIndex = processedIds.indexOf(
            notification.notificationId,
          );
          if (processedIndex !== -1) {
            return action.state[processedIndex];
          }
          return notification;
        }),
      };
    // TODO: Can this use a more efficient or concise lodash function?
    case MARK_NOTIFICATIONS_READ:
      // eslint-disable-next-line no-case-declarations
      const markedIds = action.state.map(
        (notification) => notification.notificationId,
      );
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          const markedIndex = markedIds.indexOf(notification.notificationId);
          if (markedIndex !== -1) {
            return action.state[markedIndex];
          }
          return notification;
        }),
      };
    default:
      return state;
  }
}

export default AppReducer;
