/**
This code contains a series of functions with the objective of making requests to an API. 
These functions receive different values as parameters, which are used to make the requests.

First, two elements are imported from other files: getRoles, getRolesByRoleType, getDepartmentsByCustomer, 
getMcoContractsByCustomer, getUnitManagersByCustomer and postUser from the "api" file and types from the "types" file.

Below are 8 functions that make different requests to the API. Each of them receives different values as parameters and 
executes different actions depending on the result obtained. For example, the loadRoles() function makes an API request 
to get the available roles. If this request is successful, then the corresponding dispatch will be executed to update the 
state of the application with the data obtained; otherwise, another dispatch will be executed to display an error message.

The other functions have a behavior similar to that described above. Finally, there are two functions that do not make 
any API requests but simply update the state of the application: setUserNotificationsToFalse() and clearUserErrorMsg().
 */

import { getRoles, getRolesByRoleType, getDepartmentsByCustomer, getMcoContractsByCustomer, getUnitManagersByCustomer, postUser, getMcoContractByUser } from "./api";
import { types } from "./types";

export const loadRoles = () => async dispatch => {

  getRoles().then(response => {
    if (response.statusCode !== 200) {
      dispatch({ type: types.LOAD_ROLES_FAIL, payload: 'Something went wrong while creating request data. Load Roles' });
      return;
    }
    dispatch({
      type: types.LOAD_ROLES_SUCCESS,
      payload: response.data.map(obj => ({
        label: obj.roleName,
        value: obj.id,
        role: obj,
      }))
    })
  }).catch(() => {
    dispatch({
      type: types.LOAD_ROLES_FAIL,
      payload: 'Something went wrong while creating request data. Load Roles'
    })
  });
}

export const loadRolesByType = (roleTypeId) => async dispatch => {
  dispatch({ type: types.LOAD_ROLES_BY_TYPE_IS_LOADING });
  getRolesByRoleType(roleTypeId).then(response => {
    if (response.statusCode !== 200) {
      dispatch({ type: types.LOAD_ROLES_BY_TYPE_FAIL, payload: 'Something went wrong while creating request data. Load Roles by type' });
      dispatch({ type: types.LOAD_ROLES_BY_TYPE_FINISH_LOADING });
      return;
    }
    dispatch({
      type: types.LOAD_ROLES_BY_TYPE_SUCCESS,
      payload: response.data.map(obj => ({
        label: obj.roleName,
        value: obj.id,
        role: obj,
      }))
    })
  }).catch(() => {
    dispatch({
      type: types.LOAD_ROLES_BY_TYPE_FAIL,
      payload: 'Something went wrong while creating request data. Load Roles by type'
    })
  }).finally(() => {
    dispatch({ type: types.LOAD_ROLES_BY_TYPE_FINISH_LOADING });
  });
}

export const loadDepartmentsByCustomer = (customerId) => async dispatch => {

  getDepartmentsByCustomer(customerId).then(response => {
    if (response.statusCode !== 200) {
      dispatch({ type: types.LOAD_DEPARTMENTS_BY_CUSTOMER_FAIL, payload: 'Something went wrong while creating request data. Load Departments' });
      return;
    }
    dispatch({
      type: types.LOAD_DEPARTMENTS_BY_CUSTOMER_SUCCESS,
      payload: response.data.map(obj => ({
        label: obj.name,
        value: obj.id,
        deparment: obj,
      }))
    })
  }).catch(() => {
    dispatch({
      type: types.LOAD_DEPARTMENTS_BY_CUSTOMER_FAIL,
      payload: 'Something went wrong while creating request data. Load Departments'
    })
  });
}

export const loadMcoContractsByCustomer = (customerId) => async dispatch => {

  getMcoContractsByCustomer(customerId).then(response => {
    if (response.statusCode !== 200) {
      dispatch({ type: types.LOAD_MCO_CONTRACTS_BY_CUSTOMER_FAIL, payload: 'Something went wrong while creating request data. Load Mco Contracts' });
      return;
    }
    dispatch({
      type: types.LOAD_MCO_CONTRACTS_BY_CUSTOMER_SUCCESS,
      payload: response.data.map(obj => ({
        label: obj.description,
        value: obj.id,
        mcoContract: obj,
      }))
    })
  }).catch(() => {
    dispatch({
      type: types.LOAD_MCO_CONTRACTS_BY_CUSTOMER_FAIL,
      payload: 'Something went wrong while creating request data. Load Mco Contracts'
    })
  });
}

export const loadUnitManagersByCustomer = (customerId) => async dispatch => {
  dispatch({ type: types.LOAD_UNIT_MANAGER_BY_CUSTOMER_IS_LOADING });
  getUnitManagersByCustomer(customerId).then(response => {
    if (response.statusCode !== 200) {
      dispatch({ type: types.LOAD_UNIT_MANAGER_BY_CUSTOMER_FAIL, payload: 'Something went wrong while creating request data. Load Unit Manager' });
      dispatch({ type: types.LOAD_UNIT_MANAGER_BY_CUSTOMER_FINISH_LOADING });
      return;
    }
    dispatch({
      type: types.LOAD_UNIT_MANAGER_BY_CUSTOMER_SUCCESS,
      payload: response.data.map(obj => ({
        label: `${obj.firstName} ${obj.middleInitial} ${obj.lastName} - ${obj.userName}`,
        value: obj.id,
        mcoContract: obj,
      }))
    })
  }).catch(() => {
    dispatch({
      type: types.LOAD_UNIT_MANAGER_BY_CUSTOMER_FAIL,
      payload: 'Something went wrong while creating request data. Load Unit Manager'
    })
  }).finally(() => {
    dispatch({ type: types.LOAD_UNIT_MANAGER_BY_CUSTOMER_FINISH_LOADING });
  });
}

export const clearCreateUserDropdowns = () => async dispatch => {
  dispatch({
    type: types.CLEAR_CREATE_USER_DROPDOWNS
  })
}

export const saveUser = (data) => async dispatch => {
  dispatch({ type: types.CREATE_USER_IS_LOADING })
  dispatch({ type: types.CLEAR_USER_ERROR_MSG })
  postUser(data).then(resp => {
    if (resp.statusCode !== 200) {
      dispatch({ type: types.CREATE_USER_FAIL, payload: resp.message })
      dispatch({ type: types.CREATE_USER_FINISH_LOADING })
      return
    }
    dispatch({
      type: types.CREATE_USER_SUCCESS,
      payload: resp
    })
  }).catch(() => {
    dispatch({ type: types.CREATE_USER_FAIL, payload: 'Something went wrong while fetching data. Create User' })
  }).finally(() => {
    dispatch({ type: types.CREATE_USER_FINISH_LOADING })
  });
}

export const loadMcoContractByUser = (customerUserId) => async dispatch => {
  dispatch({type: types.CLEAR_MCO_CONTRACT_BY_USER})
  getMcoContractByUser(customerUserId).then(response => {
    if (response.statusCode !== 200) {
      dispatch({ type: types.LOAD_MCO_CONTRACT_BY_USER_FAIL, payload: 'Something went wrong while creating request data. Load Mco Contract by User' });
      return;
    }
    dispatch({
      type: types.LOAD_MCO_CONTRACT_BY_USER_SUCCESS,
      payload: response.data.mcoContractId
    })
  }).catch(() => {
    dispatch({
      type: types.LOAD_MCO_CONTRACT_BY_USER_FAIL,
      payload: 'Something went wrong while creating request data. Load Mco Contract by User'
    })
  });
}

export const setUserNotificationsToFalse = () => dispatch => {
  dispatch({ type: types.CLEAR_USER_NOTIFICATIONS })
}

export const clearUserErrorMsg = () => dispatch => {
  dispatch({ type: types.CLEAR_USER_ERROR_MSG })
}