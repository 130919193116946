import React from 'react'
import PropTypes from 'prop-types';
import useLayout from '@uicl/ui-core/dist/useLayout';
import DetailPaneHeader from '@uicl/ui-core/dist/DetailPaneHeader';
import CustomersDetailComponent from './CustomersDetailComponent';


const CustomersDetail = ({ dataTestId }) => {

  const {
    detailProps = {},
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    closeDetailPane
  } = useLayout();

  return (detailProps ?
    <>
      <DetailPaneHeader
        domID="customerDetailPane"
        title='Customer Details'
        dataTestId={`${dataTestId}-header`}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig,
        ]}
      />
      {
        detailProps ?
          <CustomersDetailComponent detailProps={detailProps} closeDetailPane={closeDetailPane} />
          : null
      }
    </> : null
  )
}

export default CustomersDetail

CustomersDetail.propTypes = {
  dataTestId: PropTypes.string,
};