import { fetchWithToken } from '../../../../commonResources/helpers/fetch'

export const getCustomers = async (pageNumber, pageSize,sortingName,sortingOrder) => (
  fetchWithToken(`Customers/searchSorted?pageNumber=${pageNumber}&pageSize=${pageSize}&SortingName=${sortingName}&SortingOrder=${sortingOrder}`).then(res => res.json())
)

export const getMcoContractsByCustomer = async (params) => (
  fetchWithToken(`McoContracts/getAllMcoContractsByCustomerPaginated?${new URLSearchParams(params)}`).then(res => res.json())
)

export const getCustomerImage = async (customerId) => (
  fetchWithToken(`ReferralCustomerLogo/getCustomerImage/${customerId}`).then(res => res.json())
)

export const getAllCustomerImages = async (customerId) => (
  fetchWithToken(`ReferralCustomerLogo/getAllLogosByCustomer/${customerId}`).then(res => res.json())
)

export const putCustomerLogo = async (data) => (
  fetchWithToken(`ReferralCustomerLogo/updateLogo`, data, 'PUT').then(res => res.json()).then(json => json)
)