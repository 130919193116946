/*eslint-disable */
export const types = {

    CREATE_REFERRAL_LOAD_DEPARTMENTS_SUCCESS: '[CREATE_REFERRAL] Load data for Departments dropdown',
    CREATE_REFERRAL_LOAD_DEPARTMENTS_FAIL: '[CREATE_REFERRAL] Failed to load data for Departments dropdown',
    CREATE_REFERRAL_LOAD_DEPARTMENTS_IS_LOADING: '[CREATE_REFERRAL] In progress loading data for Departments dropdown',
    CREATE_REFERRAL_LOAD_DEPARTMENTS_FINISH_LOADING: '[CREATE_REFERRAL] Finish loading data for Departments dropdown',

    CREATE_REFERRAL_LOAD_MCO_CONTRACTS_SUCCESS: '[CREATE_REFERRAL] Load data for Mco Contracts dropdown',
    CREATE_REFERRAL_LOAD_MCO_CONTRACTS_FAIL: '[CREATE_REFERRAL] Failed to load data for Mco Contracts dropdown',
    CREATE_REFERRAL_LOAD_MCO_CONTRACTS_IS_LOADING: '[CREATE_REFERRAL] In progress loading data for Mco Contracts dropdown',
    CREATE_REFERRAL_LOAD_MCO_CONTRACTS_FINISH_LOADING: '[CREATE_REFERRAL] Finish loading data for Mco Contracts dropdown',

    CREATE_REFERRAL_LOAD_USER_SUCCESS: '[CREATE_REFERRAL] Load data for Referred by dropdown',
    CREATE_REFERRAL_LOAD_USER_FAIL: '[CREATE_REFERRAL] Failed to load data for Referred by dropdown',
    CREATE_REFERRAL_LOAD_USER_IS_LOADING: '[CREATE_REFERRAL] In progress loading data for Referred by dropdown',
    CREATE_REFERRAL_LOAD_USER_FINISH_LOADING: '[CREATE_REFERRAL] Finish loading data for Referred by dropdown',

    CREATE_REFERRAL_LOAD_ZIPCODE_SUCCESS: '[CREATE_REFERRAL] Load data for Zipcode validator',
    CREATE_REFERRAL_LOAD_ZIPCODE_FAIL: '[CREATE_REFERRAL] Failed to load data for Zipcode validator',
    CREATE_REFERRAL_LOAD_ZIPCODE_IS_LOADING: '[CREATE_REFERRAL] In progress loading data for Zipcode validator',
    CREATE_REFERRAL_LOAD_ZIPCODE_FINISH_LOADING: '[CREATE_REFERRAL] Finish loading data for Zipcode validator',

    CREATE_REFERRAL_LOAD_ZIPCODE_ALT_SUCCESS: '[CREATE_REFERRAL] Load data for Alt Zipcode validator',
    CREATE_REFERRAL_LOAD_ZIPCODE_ALT_FAIL: '[CREATE_REFERRAL] Failed to load data for Alt Zipcode validator',
    CREATE_REFERRAL_LOAD_ZIPCODE_ALT_IS_LOADING: '[CREATE_REFERRAL] In progress loading data for Alt Zipcode validator',
    CREATE_REFERRAL_LOAD_ZIPCODE_ALT_FINISH_LOADING: '[CREATE_REFERRAL] Finish loading data for Alt Zipcode validator',

    CREATE_REFERRAL_LOAD_LANGUAGE_SUCCESS: '[CREATE_REFERRAL] Load data for Preferred Language dropdown',
    CREATE_REFERRAL_LOAD_LANGUAGE_FAIL: '[CREATE_REFERRAL] Failed to load data for Preferred Language dropdown',
    CREATE_REFERRAL_LOAD_LANGUAGE_IS_LOADING: '[CREATE_REFERRAL] In progress loading data for Preferred Language dropdown',
    CREATE_REFERRAL_LOAD_LANGUAGE_FINISH_LOADING: '[CREATE_REFERRAL] Finish loading data for Preferred Language dropdown',

    CREATE_REFERRAL_LOAD_RELATIONSHIP_SUCCESS: '[CREATE_REFERRAL] Load data for Relationship dropdown',
    CREATE_REFERRAL_LOAD_RELATIONSHIP_FAIL: '[CREATE_REFERRAL] Failed to load data for Relationship dropdown',
    CREATE_REFERRAL_LOAD_RELATIONSHIP_IS_LOADING: '[CREATE_REFERRAL] In progress loading data for Relationship dropdown',
    CREATE_REFERRAL_LOAD_RELATIONSHIP_FINISH_LOADING: '[CREATE_REFERRAL] Finish loading data for Relationship dropdown',

    CREATE_REFERRAL_LOAD_OUTREACHCHANNEL_SUCCESS: '[CREATE_REFERRAL] Load data for Outreach dropdown',
    CREATE_REFERRAL_LOAD_OUTREACHCHANNEL_FAIL: '[CREATE_REFERRAL] Failed to load data for Outreach dropdown',
    CREATE_REFERRAL_LOAD_OUTREACHCHANNEL_IS_LOADING: '[CREATE_REFERRAL] In progress loading data for Outreach dropdown',
    CREATE_REFERRAL_LOAD_OUTREACHCHANNEL_FINISH_LOADING: '[CREATE_REFERRAL] Finish loading data for Outreach dropdown',

    CREATE_REFERRAL_CLEAR_CUSTOMER_DEPENDENCIES: '[CREATE_REFERRAL] Clear customer depencies, set value to null',
    CREATE_REFERRAL_CLEAR_DEPARTMENT_DEPENDENCIES: '[CREATE_REFERRAL] Clear department depencies, set value to null',
    CREATE_REFERRAL_CLEAR_MCOCONTRACT_DEPENDENCIES: '[CREATE_REFERRAL] Clear mcocontract depencies, set value to null',
    CREATE_REFERRAL_CLEAR_ZIPCODE_MEMBER_DATA: '[CREATE_REFERRAL] Clear member zipcode data, set value to null',
    CREATE_REFERRAL_CLEAR_ZIPCODE_ALT_DATA: '[CREATE_REFERRAL] Clear alt zipcode data, set value to null',

    CREATE_REFERRAL_SAVE_REFERRAL_SUCCESS: '[CREATE_REFERRAL] Save referral data',
    CREATE_REFERRAL_SAVE_REFERRAL_FAIL: '[CREATE_REFERRAL] Failed saving referral data',
    CREATE_REFERRAL_SAVE_REFERRAL_IS_SAVING: '[CREATE_REFERRAL] In progress saving referral data',
    CREATE_REFERRAL_SAVE_REFERRAL_FINISH_SAVING: '[CREATE_REFERRAL] Finish saving referral data',

    CREATE_REFERRAL_RESET: '[CREATE_REFERRAL] Reset states to initial values',
}


