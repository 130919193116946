
// verified

export default {
  
  columns: new Set([
    {
      dataName: 'membershipID',
      text: 'MembershipID',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'customerName',
      text: 'Customer Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'mcoContractName',
      text: 'Mco Contract Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'departmentName',
      text: 'Department Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'bulkLoaderFirstName',
      text: 'Bulk Loader First Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 130,
      isHeaderTruncated: true,
    },
    {
      dataName: 'bulkLoaderMiddleInitial',
      text: 'Bulk Loader Middle Initial',
      sortable: false,
      isSorted: 0,
      headerWidth: 140,
      isHeaderTruncated: true,
    },
    {
      dataName: 'bulkLoaderLastName',
      text: 'Bulk Loader Last Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 130,
      isHeaderTruncated: true,
    },
    {
      dataName: 'bulkLoaderHICN',
      text: 'Bulk Loader HICN',
      sortable: false,
      isSorted: 0,
      headerWidth: 100,
      isHeaderTruncated: true,
    },
    {
      dataName: 'bulkLoaderMBI',
      text: 'Bulk Loader MBI',
      sortable: false,
      isSorted: 0,
      headerWidth: 100,
      isHeaderTruncated: true,
    },
    {
      dataName: 'customerMemberId',
      text: 'Helath Plan ID',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'bulkLoaderComments',
      text: 'Bulk Loader Comments',
      sortable: false,
      isSorted: 0,
      headerWidth: 160,
      isHeaderTruncated: true,
    },
    {
      dataName: 'matchedFirstName',
      text: 'Matched First Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 130,
      isHeaderTruncated: true,
    },
    {
      dataName: 'matchedMiddleInitial',
      text: 'Matched Middle Initial',
      sortable: false,
      isSorted: 0,
      headerWidth: 140,
      isHeaderTruncated: true,
    },
    {
      dataName: 'matchedLastName',
      text: 'Matched Last Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 130,
      isHeaderTruncated: true,
    },
    {
      dataName: 'matchedHICN',
      text: 'Matched HICN',
      sortable: false,
      isSorted: 0,
      headerWidth: 100,
      isHeaderTruncated: true,
    },
    {
      dataName: 'matchedMBI',
      text: 'Matched MBI',
      sortable: false,
      isSorted: 0,
      headerWidth: 100,
      isHeaderTruncated: true,
    },
    {
      dataName: 'matchedComments',
      text: 'Matched Comments',
      sortable: false,
      isSorted: 0,
      headerWidth: 160,
      isHeaderTruncated: true,
    },
    {
      dataName: 'bulkLoaderStatus',
      text: 'Bulk Loader Status',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'createdOnBehalfOfUserName',
      text: 'Created On Behalf Of UserName',
      sortable: false,
      isSorted: 0,
      headerWidth: 200,
      isHeaderTruncated: true,
    },
    {
      dataName: 'createdDate',
      text: 'CreatedDate',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },

  ]),
  selectedItemIds: new Set([]),
  selectionKey: 'Id',
  domId: 'detail-pane',
  onSortTableColumn: () => {},
  onSelectAll: () => {},
  onLinkClick: () => {},
  onSettingsCancel: () => {},
  isConfigurable: true,
};