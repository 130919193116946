export const headingExcelReferralCaseReport = [
  [
    'MembershipID',
    'CustomerName',
    'McoContractName',
    'DepartmentName',
    'FirstName',
    'MiddleInitial',
    'LastName',
    'HICN',
    'MBI',
    'Helath Plan ID',
    'Source',
    'CratedOnBehalfOfUserName',
    'CreatedDate',
    'FirstOutreachAttemptDate',
    'LastOutreachAttemptDate',
    'CallDispositionCode',
    'MSPStatus',
    'MSPType',
    'MSPStatusDate',
    'CLStatus',
    'CLStatusDate',
    'LISStatus',
    'LISStatusDate',
    'IsLoaded',
    'ProspectType',
    'CallAttemptsCount',
    'InMSPQueue',
    'InLISQueue',
    'InCLQueue',
    'InMedicaidQueue',
    'MedicaidIndicator',
    'PartDLowIncomeIndicator',
    'MigrationDate',
    'Comments',
    'CLProgram1Status',
    'ClProgram1Name',
    'ClProgram1Sponsor',
    'ClProgram1Date',
    'CLProgram2Status',
    'ClProgram2Name',
    'ClProgram2Sponsor',
    'ClProgram2Date',
    'CLProgram3Status',
    'ClProgram3Name',
    'ClProgram3Sponsor',
    'ClProgram3Date',
    'CLProgram4Status',
    'ClProgram4Name',
    'ClProgram4Sponsor',
    'ClProgram4Date',
    'CLProgram5Status',
    'ClProgram5Name',
    'ClProgram5Sponsor',
    'ClProgram5Date',
    'CLProgram6Status',
    'ClProgram6Name',
    'ClProgram6Sponsor',
    'ClProgram6Date',
  ],
];
export const headersExcelReferralCaseReport = [
  'membershipID',
  'customerName',
  'mcoContractName',
  'departmentName',
  'firstName',
  'middleInitial',
  'lastName',
  'hicn',
  'mbi',
  'customerMemberId',
  'source',
  'createdOnBehalfOfUserName',
  'createdDate',
  'firstOutreachAttemptDate',
  'lastOutreachAttemptDate',
  'callDispositionCode',
  'mspStatus',
  'mspType',
  'mspStatusDate',
  'clStatus',
  'clStatusDate',
  'lisStatus',
  'lisStatusDate',
  'isLoaded',
  'prospectType',
  'callAttemptsCount',
  'inMSPQueue',
  'inLISQueue',
  'inCLQueue',
  'inMedicaidQueue',
  'medicaidIndicator',
  'partDLowIncomeIndicator',
  'migrationDate',
  'comments',
  'gtProgram1Status',
  'gtProgram1Name',
  'gtProgram1Sponsor',
  'gtProgram1Date',
  'gtProgram2Status',
  'gtProgram2Name',
  'gtProgram2Sponsor',
  'gtProgram2Date',
  'gtProgram3Status',
  'gtProgram3Name',
  'gtProgram3Sponsor',
  'gtProgram3Date',
  'gtProgram4Status',
  'gtProgram4Name',
  'gtProgram4Sponsor',
  'gtProgram4Date',
  'gtProgram5Status',
  'gtProgram5Name',
  'gtProgram5Sponsor',
  'gtProgram5Date',
  'gtProgram6Status',
  'gtProgram6Name',
  'gtProgram6Sponsor',
  'gtProgram6Date',
];


export const excelNameReferralCaseReport = 'Referral Case Report';
