import { types } from './types';
import { getReferralCaseReportByCustomerForExcel,getReferralCaseReportByMemberForExcel, getCustomersCatalog, getDepartmentsCatalog, getMcoContractsCatalog, getReferralCaseReportByCustomer, getReferralCaseReportByMember} from "./api";
import { filters } from './constants';
import { convertMmDdYyyyToYyyyMmDd } from '../../../../commonResources/helpers/date';
import { excelExport } from "../../../../commonResources/helpers/exportExcel";
import { excelNameReferralCaseReport, headersExcelReferralCaseReport, headingExcelReferralCaseReport } from "../components/exportDataConfig";

export const resetDepartments = () => async dispatch => {
  dispatch({type: types.RESET_DEPARTMENTS});
}

export const resetMcoContracts = () => async dispatch => {
  dispatch({type: types.RESET_MCOCONTRACTS});
}

export const loadCustomers = (userInformationCustomerId) => async dispatch => {
  dispatch({type: types.LOAD_CUSTOMERS});
  (    
    getCustomersCatalog().then(customers => {
      dispatch({type: types.LOAD_CUSTOMERS_SUCCESS, customers ,userInformationCustomerId})
    }).catch( () => {
      dispatch({type: types.LOAD_CUSTOMERS_FAIL, errorMessage: 'Something went wrong while fetching Customers Data.'})
    })
  )
};

export const loadDepartments = (customerId) => async dispatch => {
  dispatch({type: types.LOAD_DEPARTMENTS});
  (    
    getDepartmentsCatalog(customerId).then(departments => {
      dispatch({type: types.LOAD_DEPARTMENTS_SUCCESS, departments })
    }).catch( () => {
      dispatch({type: types.LOAD_DEPARTMENTS_FAIL, errorMessage: 'Something went wrong while fetchint departments data.'})
    })
  )
};

export const loadMcoContracts = (customerId) => async dispatch => {
  dispatch({type: types.LOAD_MCOCONTRACTS});
  (    
    getMcoContractsCatalog(customerId).then(mcoContracts => {
      dispatch({type: types.LOAD_MCOCONTRACTS_SUCCESS, mcoContracts })
    }).catch( () => {
      dispatch({type: types.LOAD_MCOCONTRACTS_FAIL, errorMessage: 'Something went wrong while fetching mco contracts data.'})
    })
  )
};

export const loadReferralCaseReport = (filterRequest)=> async dispatch => {

  if(!filterRequest.loadGrid)
    return ;

  const { pageSize, pageNumber, dateFrom, dateThrough} = filterRequest;

  dispatch({type: types.LOAD_CASE_REPORT});

  if(filterRequest.filter === filters.BY_CUSTOMER){

    const {customerId, departmentId, mcoContractId, email} = filterRequest;
    
    (
      getReferralCaseReportByCustomer(pageSize,pageNumber, customerId, departmentId, mcoContractId, email, convertMmDdYyyyToYyyyMmDd(dateFrom), convertMmDdYyyyToYyyyMmDd(dateThrough)).then(paginatedData => {
        dispatch({type: types.LOAD_CASE_REPORT_SUCCESS, paginatedData })
      }).catch( () => {
        dispatch({type: types.LOAD_CASE_REPORT_FAIL, errorMessage: 'Something went wrong while fetching data.'})
      })
    )
  }

  if(filterRequest.filter === filters.BY_MEMBER){
  
    const { mbi, healthPlanId } = filterRequest;

    (
      getReferralCaseReportByMember(pageSize, pageNumber, mbi, healthPlanId,convertMmDdYyyyToYyyyMmDd(dateFrom), convertMmDdYyyyToYyyyMmDd(dateThrough)).then(paginatedData => {
        dispatch({type: types.LOAD_CASE_REPORT_SUCCESS, paginatedData })
      }).catch( () => {
        dispatch({type: types.LOAD_CASE_REPORT_FAIL, errorMessage: 'Something went wrong while fetching data.'})
      })
    )
  }
    
}

/* eslint-disable */
export const exportReferralCaseReport = (filterRequest)=> async dispatch => {
 
  const { pageSize, pageNumber, dateFrom, dateThrough} = filterRequest;

  dispatch({ type: types.EXPORT_CASE_REPORT })

  if(filterRequest.filter === filters.BY_CUSTOMER){

    const {customerId, departmentId, mcoContractId, email} = filterRequest;
    
    getReferralCaseReportByCustomerForExcel(pageSize,pageNumber, customerId, departmentId, mcoContractId, email, convertMmDdYyyyToYyyyMmDd(dateFrom), convertMmDdYyyyToYyyyMmDd(dateThrough)).then(data => {
      dispatch({type: types.EXPORT_CASE_REPORT_SUCCESS, data })
      
      // Remove extra columns
      data.forEach(object => {
        delete object['gtStatus'];
        delete object['gtStatusDate'];
        delete object['inGTQueue'];
        delete object['customerUserId'];
        delete object['departmentId'];
      });

      excelExport(headingExcelReferralCaseReport, headersExcelReferralCaseReport, data, excelNameReferralCaseReport);
    }).catch( () => {
      dispatch({type: types.EXPORT_CASE_REPORT_FAIL, errorMessage: 'Something went wrong while fetching data to export to excel.'})
    })
    
  }

  if(filterRequest.filter === filters.BY_MEMBER){
  
    const { mbi, healthPlanId } = filterRequest;

    getReferralCaseReportByMemberForExcel(pageSize, pageNumber, mbi, healthPlanId,convertMmDdYyyyToYyyyMmDd(dateFrom), convertMmDdYyyyToYyyyMmDd(dateThrough)).then(data => {
      dispatch({type: types.EXPORT_CASE_REPORT_SUCCESS, data });

       // Remove extra columns
      data.forEach(object => {
        delete object['gtStatus'];
        delete object['gtStatusDate'];
        delete object['inGTQueue'];
        delete object['customerUserId'];
        delete object['departmentId'];
      });

      excelExport(headingExcelReferralCaseReport, headersExcelReferralCaseReport, data, excelNameReferralCaseReport);
    }).catch( () => {
      dispatch({type: types.EXPORT_CASE_REPORT_FAIL, errorMessage: 'Something went wrong while fetching data to export to excel.'})
    })
    
  }
}

