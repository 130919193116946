import React from 'react'
import NavigationPane from '../../NavigationPane/containers/NavigationPaneContainer';
import PageWrapper from '../../PageWrapper';
import ReferralException from './components/ReferralException';

/*eslint-disable */


const Reports = () => {
  return (
    <PageWrapper
      navigationPaneContent={<NavigationPane componentName = "Reports"/>}
      listPaneContent={<ReferralException />}
    />
  );
};

export default Reports;