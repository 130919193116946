import { fetchWithToken } from '../../../../commonResources/helpers/fetch'

export const getDepartments = async (customerId, pageNumber, pageSize, sortingName, sortingOrder) => (
  fetchWithToken(`referraldepartments/search?pageNumber=${pageNumber}&pageSize=${pageSize}&CustomerId=${customerId}&SortingName=${sortingName}&SortingOrder=${sortingOrder}`).then(res => res.json())
)

export const saveDepartment = async (department) => (
  fetchWithToken('referraldepartments', department, 'POST').then(resp => resp.json()))

export const updateDepartment = async (department) => (
  fetchWithToken('referraldepartments', department, 'PUT').then(resp => resp.json()))
