export const headingExcelSearchReferral = [
  [
    'FirstName',
    'LastName',
    'MiddleInitial',
    'HealthPlanID',
    'Department',
    'McoContract',
    'ReferredBy',
    'ReferredDate',
    'ReferralID',
    'CustomerID',
    'CustomerName',
    'MembershipID',
    'McoContractID',
    'DepartmentID',
    'CustomerUserID',
    'CustomerUserName',
    'CreatedBy',
    'CreatedDate',
    'LastUpdateBy',
    'SourceID',
    'Prospect',
    'Mhcs',
    'Address1',
    'Address2',
    'City',
    'State',
    'Zip',
    'ZipExtension',
    'HomePhone',
    'AlternatePhone',
    'Hicn',
    'Mbi',
    'Ssn',
    'Dob',
    'Gender',
    'AltRelationship',
    'AltFirstName',
    'AltLastName',
    'AltMiddleInitial',
    'AltAddress1',
    'AltAddress2',
    'AltCity',
    'AltState',
    'AltZip',
    'AltZipExtension',
    'AltPhone',
    'Language',
    'Comments',
    'EnterprisePersonID',
    'ReferralDataID',
    'BulkReferralOppId',
    'PreferredLanguage',
    'OtherPreferredLanguage',
    'OutreachPreferredMethod',
    'ReasonForReferral',
    'PreferredContact',
    'LastContactDate',
    'Amail',
    'altPreferredLanguage',
    'altPreferredContact',
    'altOutreachPreferredMethod',
    'altEmail',
  ],
];
export const headersExcelSearchReferral = [
  'firstName',
  'lastName',
  'middleInitial',
  'healthPlanId',
  'departmentName',
  'mcoContractName',
  'referredBy',
  'lastUpdateDate',
  'referralID',
  'customerID',
  'customerName',
  'membershipID',
  'mcoContractID',
  'departmentID',
  'customerUserID',
  'customerUserName',
  'createdBy',
  'createdDate',
  'lastUpdateBy',
  'sourceID',
  'prospect',
  'mhcs',
  'address1',
  'address2',
  'city',
  'state',
  'zip',
  'zipExtension',
  'homePhone',
  'alternatePhone',
  'hicn',
  'mbi',
  'ssn',
  'dob',
  'gender',
  'altRelationship',
  'altFirstName',
  'altLastName',
  'altMiddleInitial',
  'altAddress1',
  'altAddress2',
  'altCity',
  'altState',
  'altZip',
  'altZipExtension',
  'altPhone',
  'language',
  'comments',
  'enterprisePersonID',
  'referralDataID',
  'bulkReferralOppId',
  'preferredLanguage',
  'otherPreferredLanguage',
  'outreachPreferredMethod',
  'reasonForReferral',
  'preferredContact',
  'lastContactDate',
  'email',
  'altPreferredLanguage',
  'altPreferredContact',
  'altOutreachPreferredMethod',
  'altEmail',
];


export const ExcelNameSearchReferral = 'Referral Search Result';