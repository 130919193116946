/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { initializeConfig, gotoPingLogin } from '../../commonResources/services/auth-management';
import integrations from '../../integrations';
import withStorage from '../../components/withWebStorage';

// Redirect to login page
export const NotAuthorized = ({ location, store }) => {
  useEffect(() => {
    if (location.state && location.state.from && store) {
      store.add('targetLocation', location.state.from);
    }
    // redirect to login
    initializeConfig(process.env.NODE_ENV, integrations.ciam);
    gotoPingLogin();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <div />;
};

export default withStorage(NotAuthorized);
