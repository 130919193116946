import { getDepartmentsByCustomer, getMcoContractsByCustomer, getUsersByMcoContractandDepartment, getZipcodesByState, getLanguages, getRelationshipsRelatives, getOutreachChannels, saveReferralTempData } from "./api";
import { types } from "./types";

// LOAD ACTIONS
export const loadDepartmentsByCustomer = (customer) => async dispatch => {
  dispatch({ type: types.CREATE_REFERRAL_LOAD_DEPARTMENTS_IS_LOADING })

  try {
    getDepartmentsByCustomer(customer).then(departments => {
      dispatch({
        type: types.CREATE_REFERRAL_LOAD_DEPARTMENTS_SUCCESS,
        payload: departments.map(obj => ({
          label: obj.name,
          value: obj.id
        }))

      })
    }).catch(() => {
      dispatch({
        type: types.CREATE_REFERRAL_LOAD_DEPARTMENTS_FAIL,
        payload: "Something went wrong while creating request data."
      })
    });
  } catch (error) {
    dispatch({
      type: types.CREATE_REFERRAL_LOAD_DEPARTMENTS_FAIL,
      payload: "Something went wrong while creating request data."
    })
  } finally {
    dispatch({ type: types.CREATE_REFERRAL_LOAD_DEPARTMENTS_FINISH_LOADING })
  }
}

export const loadMcoContractsByCustomer = (customer) => async dispatch => {
  dispatch({ type: types.CREATE_REFERRAL_LOAD_MCO_CONTRACTS_IS_LOADING })

  try {
    getMcoContractsByCustomer(customer).then(mcoContracts => {
      dispatch({
        type: types.CREATE_REFERRAL_LOAD_MCO_CONTRACTS_SUCCESS,
        payload: mcoContracts.map(obj => ({
          label: obj.name,
          value: obj.id
        }))
      })
    }).catch(() => {
      dispatch({
        type: types.CREATE_REFERRAL_LOAD_MCO_CONTRACTS_FAIL,
        payload: "Something went wrong while creating request data."
      })
    });
  } catch (error) {
    dispatch({
      type: types.CREATE_REFERRAL_LOAD_MCO_CONTRACTS_FAIL,
      payload: "Something went wrong while creating request data."
    })
  } finally {
    dispatch({ type: types.CREATE_REFERRAL_LOAD_MCO_CONTRACTS_FINISH_LOADING })
  }
}

export const loadUsersByMcoContractAndDepartment = (department, mcoContract) => async dispatch => {
  dispatch({ type: types.CREATE_REFERRAL_LOAD_USER_IS_LOADING })

  try {
    getUsersByMcoContractandDepartment(department, mcoContract).then(users => {
      dispatch({
        type: types.CREATE_REFERRAL_LOAD_USER_SUCCESS,
        payload: users.map(obj => ({
          label: `${obj.firstName} ${obj.middleInitial} ${obj.lastName}`,
          value: obj.customerUserId
        }))
      })
    }).catch(() => {
      dispatch({
        type: types.CREATE_REFERRAL_LOAD_USER_FAIL,
        payload: "Something went wrong while creating request data."
      })
    });
  } catch (error) {
    dispatch({
      type: types.CREATE_REFERRAL_LOAD_USER_FAIL,
      payload: "Something went wrong while creating request data."
    })
  } finally {
    dispatch({ type: types.CREATE_REFERRAL_LOAD_USER_FINISH_LOADING })
  }
}

export const loadZipcodeByStateMember = (state) => async dispatch => {
  dispatch({ type: types.CREATE_REFERRAL_LOAD_ZIPCODE_IS_LOADING })

  try {
    getZipcodesByState(state).then(zipcodes => {
      dispatch({
        type: types.CREATE_REFERRAL_LOAD_ZIPCODE_SUCCESS,
        payload: zipcodes.map(obj => ({
          label: obj.state,
          value: obj.zip
        }))
      })
    }).catch(() => {
      dispatch({
        type: types.CREATE_REFERRAL_LOAD_ZIPCODE_FAIL,
        payload: "Something went wrong while creating request data."
      })
    });
  } catch (error) {
    dispatch({
      type: types.CREATE_REFERRAL_LOAD_ZIPCODE_FAIL,
      payload: "Something went wrong while creating request data."
    })
  } finally {
    dispatch({ type: types.CREATE_REFERRAL_LOAD_ZIPCODE_FINISH_LOADING })
  }
}

export const loadZipcodeByStateAlt = (state) => async dispatch => {
  dispatch({ type: types.CREATE_REFERRAL_LOAD_ZIPCODE_ALT_IS_LOADING })

  try {
    getZipcodesByState(state).then(zipcodes => {
      dispatch({
        type: types.CREATE_REFERRAL_LOAD_ZIPCODE_ALT_SUCCESS,
        payload: zipcodes.map(obj => ({
          label: obj.state,
          value: obj.zip
        }))
      })
    }).catch(() => {
      dispatch({
        type: types.CREATE_REFERRAL_LOAD_ZIPCODE_ALT_FAIL,
        payload: "Something went wrong while creating request data."
      })
    });
  } catch (error) {
    dispatch({
      type: types.CREATE_REFERRAL_LOAD_ZIPCODE_ALT_FAIL,
      payload: "Something went wrong while creating request data."
    })
  } finally {
    dispatch({ type: types.CREATE_REFERRAL_LOAD_ZIPCODE_ALT_FINISH_LOADING })
  }
}

export const loadLanguages = () => async dispatch => {
  dispatch({ type: types.CREATE_REFERRAL_LOAD_LANGUAGE_IS_LOADING })

  try {
    getLanguages().then(languages => {
      const preferred = languages.filter(lang => {
        return (lang.id === 1 || lang.id === 2)
      });
      preferred.push({
        name: 'Other',
        id: 0
      })
      const other = languages.filter(lang => {
        return (lang.id > 2)
      });


      dispatch({
        type: types.CREATE_REFERRAL_LOAD_LANGUAGE_SUCCESS,
        payload: '',
        preferredLanguages: preferred.map(obj => ({
          label: obj.name,
          value: obj.id
        })),
        otherLanguages: other.map(obj => ({
          label: obj.name,
          value: obj.id
        }))
      })
    }).catch(() => {
      dispatch({
        type: types.CREATE_REFERRAL_LOAD_LANGUAGE_FAIL,
        payload: "Something went wrong while creating request data."
      })
    });
  } catch (error) {
    dispatch({
      type: types.CREATE_REFERRAL_LOAD_LANGUAGE_FAIL,
      payload: "Something went wrong while creating request data."
    })
  } finally {
    dispatch({ type: types.CREATE_REFERRAL_LOAD_LANGUAGE_FINISH_LOADING })
  }
}

export const loadRelationships = () => async dispatch => {
  dispatch({ type: types.CREATE_REFERRAL_LOAD_RELATIONSHIP_IS_LOADING })

  try {
    getRelationshipsRelatives().then(relationships => {
      dispatch({
        type: types.CREATE_REFERRAL_LOAD_RELATIONSHIP_SUCCESS,
        payload: relationships.map(obj => ({
          label: obj.name,
          value: obj.id
        }))
      })
    }).catch(() => {
      dispatch({
        type: types.CREATE_REFERRAL_LOAD_RELATIONSHIP_FAIL,
        payload: "Something went wrong while creating request data."
      })
    });
  } catch (error) {
    dispatch({
      type: types.CREATE_REFERRAL_LOAD_RELATIONSHIP_FAIL,
      payload: "Something went wrong while creating request data."
    })
  } finally {
    dispatch({ type: types.CREATE_REFERRAL_LOAD_RELATIONSHIP_FINISH_LOADING })
  }
}

export const loadOutreachChannels = () => async dispatch => {
  dispatch({ type: types.CREATE_REFERRAL_LOAD_OUTREACHCHANNEL_IS_LOADING })

  try {
    getOutreachChannels().then(outreachchannels => {
      dispatch({
        type: types.CREATE_REFERRAL_LOAD_OUTREACHCHANNEL_SUCCESS,
        payload: outreachchannels.map(obj => ({
          label: obj.name,
          value: obj.id
        }))
      })
    }).catch(() => {
      dispatch({
        type: types.CREATE_REFERRAL_LOAD_OUTREACHCHANNEL_FAIL,
        payload: "Something went wrong while creating request data."
      })
    });
  } catch (error) {
    dispatch({
      type: types.CREATE_REFERRAL_LOAD_OUTREACHCHANNEL_FAIL,
      payload: "Something went wrong while creating request data."
    })
  } finally {
    dispatch({ type: types.CREATE_REFERRAL_LOAD_OUTREACHCHANNEL_FINISH_LOADING })
  }
}

// CLEAR ACTIONS
export const clearCustomerDependencies = () => async dispatch => {
  dispatch({
    type: types.CREATE_REFERRAL_CLEAR_CUSTOMER_DEPENDENCIES,
    payload: null
  })
}

export const clearDepartmentDependencies = () => async dispatch => {
  dispatch({
    type: types.CREATE_REFERRAL_CLEAR_DEPARTMENT_DEPENDENCIES,
    payload: null
  })
}

export const clearMcoContractDependencies = () => async dispatch => {
  dispatch({
    type: types.CREATE_REFERRAL_CLEAR_MCOCONTRACT_DEPENDENCIES,
    payload: null
  })
}

export const clearZipcodeMemberData = () => async dispatch => {
  dispatch({
    type: types.CREATE_REFERRAL_CLEAR_ZIPCODE_MEMBER_DATA,
    payload: ''
  })
}


export const clearZipcodeAltData = () => async dispatch => {
  dispatch({
    type: types.CREATE_REFERRAL_CLEAR_ZIPCODE_ALT_DATA,
    payload: ''
  })
}

export const reset = () => async dispatch => {
  dispatch({
    type: types.CREATE_REFERRAL_RESET,
    payload: ''
  })
}

// UPDATE ACTIONS
export const createReferral = (referral) => async dispatch => {
  dispatch({ type: types.CREATE_REFERRAL_SAVE_REFERRAL_IS_SAVING })
  saveReferralTempData(referral).then(response => {
    dispatch({
      type: types.CREATE_REFERRAL_SAVE_REFERRAL_SUCCESS,
      response
    })
  }).catch(() => {
    dispatch({
      type: types.CREATE_REFERRAL_SAVE_REFERRAL_FAIL,
      payload: `Something went wrong while saving data.`
    })
  }).finally(() => {
    dispatch({ type: types.CREATE_REFERRAL_SAVE_REFERRAL_FINISH_SAVING })
  });
}