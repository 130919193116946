import { getUsers, saveUser as mockSave } from "./api"

export const SAVE_USER = "cra-boilerplate/users/SAVE_USER"
export const SAVE_USER_FAIL = "cra-boilerplate/users/SAVE_USER_FAIL"
export const SAVE_USER_SUCCESS = "cra-boilerplate/users/SAVE_USER_SUCCESS"
export const RESET_SAVE_USER_SUCCESS = "cra-boilerplate/users/RESET_SAVE_USER_SUCCESS"


export const LOAD_USERS = "cra-boilerplate/users/LOAD_USERS"
export const LOAD_USERS_SUCCESS = "cra-boilerplate/users/LOAD_USERS_SUCCESS"
export const LOAD_USERS_FAIL = "cra-boilerplate/users/LOAD_USERS_FAIL"


export const saveUser =  (user) => ({
  type: SAVE_USER,
  user
});

export const saveUserSuccess = (user) => ({
  type: SAVE_USER_SUCCESS,
  user
});

export const saveUserError = (errorMessage) => ({
  type: SAVE_USER_FAIL,
  errorMessage
});

export const loadUsers = () => async dispatch => {
  dispatch({type: LOAD_USERS})
  try {
    const users = await getUsers()
    dispatch({type: LOAD_USERS_SUCCESS, users})
  } catch {
    dispatch({type: LOAD_USERS_FAIL, errorMessage: 'Something went wrong while fetching data.'})
  }
}

export const callApiToSave = (user) =>  async dispatch => {
  dispatch(saveUser(user))
  try {
    const savedUser = await mockSave(user)
    dispatch(saveUserSuccess(savedUser))
  } catch (error) {
    dispatch(saveUserError('Could not save at this time.'))
  }
}

export const resetSaveUserSuccess = () => ({
  type: RESET_SAVE_USER_SUCCESS
})