import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@uicl/ui-core'
import Button from '@uicl/ui-core/dist/Button';
import SelectDropdown from '@uicl/ui-core/dist/SelectDropdown';
import Input from '@uicl/ui-core/dist/Input';
import { loadRoleAssociation, loadRoleTypes, loadRules, saveRole } from '../modules/actions';
import RulesGridConfig from './RulesGridConfig';
import { useForm } from '../../../../commonResources/hooks/useForm';
import Notification from '../../../../components/notifications/Notification';
import { useIsMount } from '../../../../commonResources/hooks/useIsMount';
import LoadingPage from '../../../../components/LoadingPage';

// eslint-disable-next-line
const specialChar = /^[0-9a-zA-Z _]+$/;
const CreateRole = ({ setCreateRole }) => {

  const dispatch = useDispatch();
  const isMount = useIsMount();

  const { rules, roleTypes, roleAssociation, statusCode, message, triggerPost, rolesSaveIsLoading } = useSelector(state => state.roles);
  const { userInformation } = useSelector(state => state.app);

  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);
  const [isApiError, setIsApiError] = useState(false);
  const [catsKey, setCatsKey] = useState(0);

  const [formValues, handleInputChange, reset] = useForm({
    roleName: ''
  });
  const { roleName } = formValues;

  const [roleType, setRoleType] = useState(null)
  const [roleSubType, setRoleSubType] = useState(null)
  const [selectedRules, setSelectedRules] = useState([])
  const [gridError, setGridError] = useState(false)
  const [errors, setErros] = useState({
    roleTypeError: false,
    roleTypeErrorMsg: 'Required Field',
    roleNameError: false,
    roleNameErrorMsg: 'Invalid Role Name',
    roleAssociationError: false,
    roleAssociationErrorMsg: 'Required Field',
  })
  const { roleTypeError, roleTypeErrorMsg, roleNameError, roleNameErrorMsg } = errors

  useEffect(() => {
    if (rules.length === 0) {
      dispatch(loadRules());
    }
  }, [])

  useEffect(() => {
    if (roleTypes.length === 0) {
      dispatch(loadRoleTypes());
    }
  }, [])

  useEffect(() => {
    if (roleAssociation.length === 0) {
      dispatch(loadRoleAssociation());
    }
  }, [])

  useEffect(() => {
    if (isMount) {
      if (statusCode !== 200) {
        setIsApiError(true);
        return
      }
      setCatsKey(key => key + 1)
      clearSelections()
      setIsSaveSuccess(true);
    }
  }, [triggerPost])


  const handleSubmit = () => {
    if (isFormValid()) {
      const data = {
        roleName: roleName.trim(),
        roleTypeId: roleType,
        roleAssociationId: roleSubType ?? 3,
        rules: selectedRules,
        userName: userInformation.userName,
      }
      dispatch(saveRole(data));
      return
    }
    setIsValidationError(true);
  }

  const isFormValid = () => {
    if (roleType === null || roleType === '') {
      setErros({ ...errors, roleTypeError: true });
      return false;
    }
    if (roleName === '') {
      setErros({ ...errors, roleNameError: true, roleNameErrorMsg: 'Required Field' });
      return false;
    }
    // if (roleType === 1 && (roleSubType === null || roleSubType === '')) {
    //   setErros({ ...errors, roleAssociationError: true, roleAssociationErrorMsg: 'Required Field' });
    //   return false;
    // }
    if (selectedRules.length < 1) {
      setGridError(true)
      return false;
    }
    setErros({ ...errors, roleTypeError: false, roleNameError: false, roleAssociationError: false, roleNameErrorMsg: 'Invalid Role Name' });
    setGridError(false)
    return true;
  }

  const clearSelections = () => {
    reset();
    setRoleType(null)
    setRoleSubType(null)
    setSelectedRules([])
  }

  return (
    <>
      {
        rolesSaveIsLoading
          ?
          <LoadingPage />
          :
          <div>
            {isSaveSuccess && (
              <Notification
                title="Success"
                text="Role Saved Successfully"
                type="success"
                timer={3500}
                onDismiss={() => setIsSaveSuccess(false)}
              />
            )}
            {isValidationError && (
              <Notification
                title="Error"
                text="Please complete the required fields (*)"
                type="error"
                onDismiss={() => setIsValidationError(false)}
              />
            )}
            {isApiError && (
              <Notification
                title="Error"
                text={message}
                type="error"
                onDismiss={() => setIsApiError(false)}
              />
            )}
            <div key={catsKey}>
              <div className='form-items'>
                <SelectDropdown
                  className='form-items-margin'
                  placeholderText="[Select Type]"
                  domID="roleType"
                  name="roleType"
                  label="Role Type*"
                  required
                  isClearable={false}
                  hasError={roleTypeError}
                  errorMessage={roleTypeErrorMsg}
                  onChange={(e) => {
                    setRoleType(e ? e.value : '')
                    setErros({ ...errors, roleTypeError: false });
                    setRoleSubType(null)
                  }}
                  options={roleTypes}
                />
                <Input
                  maxLength="50"
                  className='form-items-margin'
                  label="Role Name*"
                  domID="roleName"
                  name="roleName"
                  required
                  regex={specialChar}
                  autoComplete='off'
                  hasError={roleNameError}
                  errorMessage={roleNameErrorMsg}
                  value={roleName}
                  onChange={handleInputChange}
                  onKeyPress={() => setErros({ ...errors, roleNameError: false, roleNameErrorMsg: 'Invalid Role Name' })}
                />
                {
                  roleType === 1 &&
                  <SelectDropdown
                    placeholderText="[Select Sub Type]"
                    className='form-items-margin'
                    domID="roleSubType"
                    name="roleSubType"
                    label="Role Sub Type"
                    isClearable
                    // hasError={roleAssociationError}
                    // errorMessage={roleAssociationErrorMsg}
                    onChange={(e) => {
                      setRoleSubType(e ? e.value : '')
                      setErros({ ...errors, roleAssociationError: false });
                    }}
                    options={roleAssociation}
                  />
                }
              </div>
              <div>
                <div className='dataGrid-rules form-items-margin'>
                  <p>Select 1 or more Rule(s) to Role *:</p>
                  {
                    gridError &&
                    <p style={{ color: 'red' }}>You must select at least 1 rule from the list</p>
                  }
                </div>
                <Grid
                  {...RulesGridConfig}
                  records={rules}
                  className='dataGrid-rules form-items-margin'
                  supportSelection
                  dataTestId="test-formGrid"
                  onRowSelect={(e, gridState) => {
                    // const selectedItems = rules.filter(item => gridState.selectedItemIds.includes(item.id));
                    const selectedItems = rules.filter(item => gridState.selectedItemIds.has(item.id));
                    const rulesToSubmit = selectedItems.map(({ ruleName }) => ({ ruleName }));
                    if (rulesToSubmit.length < 1) {
                      setGridError(true);
                    } else {
                      setGridError(false);
                    }
                    setSelectedRules(rulesToSubmit);
                  }}
                  onSelectAll={(e, gridState) => {
                    const selectedItems = rules.filter(item => gridState.selectedItemIds.has(item.id));
                    const rulesToSubmit = selectedItems.map(({ ruleName }) => ({ ruleName }));
                    if (rulesToSubmit.length < 1) {
                      setGridError(true);
                    } else {
                      setGridError(false);
                    }
                    setSelectedRules(rulesToSubmit);
                  }}
                />
              </div>
            </div>
            <div className='form-buttons form-items-margin'>
              <div className='column-button-rules'>
                <Button className='submit-button' disable={rolesSaveIsLoading} onClick={handleSubmit}>
                  Create
                </Button>
                <Button className='cancel-button' buttonType="diminished" onClick={() => setCreateRole(false)}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default CreateRole