import { types } from './types';
import { convertToMMSDateFormat } from '../../../../commonResources/helpers/date';

const initialState = {
  itemsByIds: {},
  ids: [],
  pages: [],
  totalPages: 0,
  totalRecords: 0,
  errorMessage: '',
  isLoading: false,

  departmentResponseIsSuccessStatusCode: false,
  departmentResponseStatusCode: 0,
  departmentError: '',
  departmentIsSaving: false,
  triggerPost: false,

  selectedCustomer: { label: 'All Customers', value: 0 },

  departmentEditResponseIsSuccessStatusCode: false,
  departmentEditResponseStatusCode: 0,
  departmentEditError: '',
  departmentEditIsSaving: false,
  triggerPut: false,
};

export const departmentsReducer = (state = initialState, action = {}) => {

  switch (action.type) {

    case types.LOAD_DEPARTMENTS_IS_LOADING: {
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
      }
    }

    case types.LOAD_DEPARTMENTS_SUCCESS: {

      const { paginatedData } = action;
      const { totalPages, totalRecords, data } = paginatedData;
      const ids = data.map(x => x.id);
      const pages = [];

      for (let i = 1; i <= totalPages; i += 1) {
        pages.push(i);
      }

      const itemsByIds = data.reduce((prev, current) => (
        {
          ...prev,
          [current.id]: {
            ...current,
            id: current.id,
            name: current.name,
            description: current.description,
            customerName: current.customerName,
            isDeleted: current.isDeleted,
            isActive: (current.isDeleted === false) ? 'Yes' : 'No',  // used isdeleted to display Is Active 
            createdDate: convertToMMSDateFormat (current.createdDate),
            lastUpdateDate: convertToMMSDateFormat(current.lastUpdateDate),
            lastUpdateBy: current.lastUpdateBy,
          }
        }
      ), {});

      return {
        ...state,
        ids,
        itemsByIds,
        totalPages,
        totalRecords,
        pages
      }
    }

    case types.LOAD_DEPARTMENTS_FAIL: {
      return {
        ...state,
        isLoading: false,
        ids: [],
        itemsByIds: {},
        errorMessage: action.payload,
      }
    }

    case types.LOAD_DEPARTMENTS_FINISH_LOADING: {
      return {
        ...state,
        isLoading: false,
      }
    }

    case types.CREATE_DEPARTMENT_SAVE_DEPARTMENT_SUCCESS: {

      const { response } = action;
      const { message, statusCode, isSuccessStatusCode } = response;

      return {
        ...state,
        departmentResponseStatusCode: statusCode,
        departmentResponseIsSuccessStatusCode: isSuccessStatusCode,
        departmentError: message,
        triggerPost: !state.triggerPost
      }
    }

    case types.CREATE_DEPARTMENT_SAVE_DEPARTMENT_IS_SAVING:
      return {
        ...state,
        departmentIsSaving: true,
        departmentResponseStatusCode: 0,
        departmentResponseIsSuccessStatusCode: false,
        departmentError: '',
      }

    case types.CREATE_DEPARTMENT_SAVE_DEPARTMENT_FINISH_SAVING:
      return {
        ...state,
        departmentIsSaving: false,
      }

    case types.CREATE_DEPARTMENT_SAVE_DEPARTMENT_FAIL: {
      return {
        ...state,
        departmentError: action.payload,
        departmentResponseStatusCode: 500,
        departmentResponseIsSuccessStatusCode: false,
      }
    }

    case types.SEARCH_DEPARTMENT_SELECTED_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.payload
      }

    case types.EDIT_DEPARTMENT_UPDATE_DEPARTMENT_SUCCESS: {

      const { response } = action;
      const { message, statusCode, isSuccessStatusCode } = response;

      return {
        ...state,
        departmentEditResponseStatusCode: statusCode,
        departmentEditResponseIsSuccessStatusCode: isSuccessStatusCode,
        departmentEditError: message,
        triggerPut: !state.triggerPut
      }
    }

    case types.EDIT_DEPARTMENT_UPDATE_DEPARTMENT_IS_SAVING:
      return {
        ...state,
        departmentEditIsSaving: true,
        departmentEditResponseStatusCode: 0,
        departmentEditResponseIsSuccessStatusCode: false,
        departmentEditError: '',
      }

    case types.EDIT_DEPARTMENT_UPDATE_DEPARTMENT_FINISH_SAVING:
      return {
        ...state,
        departmentEditIsSaving: false,
      }

    case types.EDIT_DEPARTMENT_UPDATE_DEPARTMENT_FAIL: {
      return {
        ...state,
        departmentEditError: action.payload,
        departmentEditResponseStatusCode: 500,
        departmentEditResponseIsSuccessStatusCode: false,
      }
    }

    default:
      return state

  }

};



export default departmentsReducer;