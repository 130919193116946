import jwtdecode from "jwt-decode";

const LOGIN_URL = "LOGIN_URL";
const LOGIN_REDIRECT_URL = "LOGIN_REDIRECT_URL";
const LOGIN_SCOPE = "LOGIN_SCOPE";
const LOGIN_CLIENT_ID = "LOGIN_CLIENT_ID";
const LOGIN_ACCESS_TYPE = "LOGIN_ACCESS_TYPE";
const LOGIN_RESPONSE_TYPE = "LOGIN_RESPONSE_TYPE";
const ACCESS_TOKEN = 'ACCESS_TOKEN';
const ACCESS_TOKEN_TYPE = 'ACCESS_TOKEN_TYPE';
const ACCESS_TOKEN_EXPIRES_IN = 'ACCESS_TOKEN_EXP_IN';
const RULES = 'RULES';

export const initializeConfig = (env, ciamId) => {
  window.sessionStorage.setItem(LOGIN_URL, ciamId.LOGIN_URL);
  window.sessionStorage.setItem(LOGIN_REDIRECT_URL, ciamId.REDIRECT_URL);
  window.sessionStorage.setItem(LOGIN_SCOPE, ciamId.SCOPE);
  window.sessionStorage.setItem(LOGIN_CLIENT_ID, ciamId.CLIENT_ID);
  window.sessionStorage.setItem(LOGIN_ACCESS_TYPE, ciamId.ACCESS_TYPE);
  window.sessionStorage.setItem(LOGIN_RESPONSE_TYPE, ciamId.RESPONSE_TYPE);
};


export const gotoPingLogin = () => {

  const loginUrl = window.sessionStorage.getItem(LOGIN_URL);
  const redirectUrl = window.sessionStorage.getItem(LOGIN_REDIRECT_URL);
  const scope = window.sessionStorage.getItem(LOGIN_SCOPE);
  const clientId = window.sessionStorage.getItem(LOGIN_CLIENT_ID);
  const accessType = window.sessionStorage.getItem(LOGIN_ACCESS_TYPE);
  const responseType = window.sessionStorage.getItem(LOGIN_RESPONSE_TYPE);

  const url = `${loginUrl}?redirect_uri=${redirectUrl}&scope=${scope}&client_id=${clientId}&access_type=${accessType}&response_type=${responseType}`;
  window.location = url;

};

export const setRules = (rules) => {
  window.sessionStorage.setItem(RULES, JSON.stringify(rules));
};

// eslint-disable-next-line
export const updateTokenValues = (hash, onError, onSucess, onRedirectLogin) => {
  try {
    const paramString = hash.split('#')[1];
    const params = new URLSearchParams(paramString);
    const accessToken = params.get('access_token');
    const tokenType = params.get('token_type');
    const expiresIn = params.get('expires_in');
    const isActiveUser = params.get('isActiveUser');
    const idToken = params.get('id_token')
    const authError = params.get('auth_error');
    const userRole = params.get('userRole');
    const firstName = params.get('firstName');
    const lastName = params.get('lastName');

    if (isActiveUser === 'false') {
      onError('Authorization Error', 'The supplied user is inactive.');
      return;
    }

    if(authError !== undefined && authError !== null && authError !== ''){
      onError('', authError);
      return;
    }

    if (accessToken === undefined || accessToken === null || accessToken === '') {
      onError('JWTToken', 'Jwt Token is undefined or empty. Please try to login again.');
      return;
    }

    if (tokenType === undefined || tokenType === null || tokenType === '') {
      onError('TokenType', 'Token Type is undefined or empty. Please try to login again.');
      return;
    }

    if (expiresIn === undefined || expiresIn === null || expiresIn === '') {
      onError('ExpiresIn', 'Expires In is undefined or empty. Please try to login again.');
      return;
    }    

    window.sessionStorage.setItem(ACCESS_TOKEN, accessToken);
    window.sessionStorage.setItem(ACCESS_TOKEN_TYPE, tokenType);
    window.sessionStorage.setItem(ACCESS_TOKEN_EXPIRES_IN, expiresIn);
    window.sessionStorage.setItem('ID_TOKEN', idToken);
    window.sessionStorage.setItem('USER_ROLE', userRole);
    window.sessionStorage.setItem('FIRST_NAME', firstName);
    window.sessionStorage.setItem('LAST_NAME', lastName);

    onSucess();

  } catch (e) {
    onError('Unexpected error ', 'Unexpected error when authorizing. Please try to login again.');
  }

};

export const isAuthenticated = () => {

  const accessToken = window.sessionStorage.getItem(ACCESS_TOKEN);

  if (accessToken !== undefined && accessToken !== null)
    return true;

  return false;
}


export const getTokenValuesForHeaders = () => {
  const accessToken = window.sessionStorage.getItem(ACCESS_TOKEN);
  const accessTokenType = window.sessionStorage.getItem(ACCESS_TOKEN_TYPE);
  return { accessTokenType, accessToken };
}
// eslint-disable-next-line
export const updateTokenSilently = (onRefreshing, onTokenRefreshed, onTokenRefreshFails) => {

  
  onRefreshing();

  // Refresh Token

  // When Token is refreshed
  onTokenRefreshed();

  // When Token refresh fails 
  // onTokenRefreshFails();
  // console.log(onRefreshing, onTokenRefreshed, onTokenRefreshFails);
}

export const logout = () => {
  localStorage.clear()
  sessionStorage.clear()
  // sessionStorage.removeItem(ACCESS_TOKEN);
  // sessionStorage.removeItem(ACCESS_TOKEN_TYPE);
  // sessionStorage.removeItem(ACCESS_TOKEN_EXPIRES_IN);
  // sessionStorage.removeItem(RULES);
}


class TimeOuts {
  static instance;

  static openAuthModelTimer;

  static callLogoutTimer;

  static getInstance() {
    if (TimeOuts.instance) {
      return TimeOuts.instance;
    }
    TimeOuts.instance = new TimeOuts();
    return TimeOuts.instance;
  }

  setAuthTimeouts = function (openAuthModal, callLogout, timerAuthModel, timerLogout) {

    // console.log(openAuthModal, callLogout);
    const millisecondsMinute = 60000;
    // const millisecondsMinute = 1000;

    // console.log(' setAuthTimeouts timer', timerAuthModel * millisecondsMinute);

    clearTimeout(TimeOuts.openAuthModalTimer);
    clearTimeout(TimeOuts.callLogoutTimer);

    TimeOuts.openAuthModalTimer = setTimeout(() => { openAuthModal() }, timerAuthModel * millisecondsMinute)
    TimeOuts.callLogoutTimer = setTimeout(() => callLogout(), timerLogout * millisecondsMinute)

  }

  clearAuthTimeouts = function () {

    clearTimeout(TimeOuts.openAuthModalTimer);
    clearTimeout(TimeOuts.callLogoutTimer);
  }
}

export const timeouts = Object.freeze(new TimeOuts());

export const getUser = () => {

  const accessToken = window.sessionStorage.getItem(ACCESS_TOKEN);
  // const idToken = window.sessionStorage.getItem('ID_TOKEN');
  const userRole = window.sessionStorage.getItem('USER_ROLE');
  const firstName = window.sessionStorage.getItem('FIRST_NAME');
  const lastName = window.sessionStorage.getItem('LAST_NAME');
  
  if (accessToken !== null && accessToken !== undefined && accessToken !== '') {
    const user = jwtdecode(accessToken);
    // console.log(user)
    user.given_name = firstName;
    user.family_name = lastName;
    user.roles = userRole;
    
    return user;
  }

  return undefined;
};

// const isEmpty = (val) => {
//   return (val === undefined || val == null || val.length === '');
// }