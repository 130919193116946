export default {
  columns: new Set([
    {
      dataName: 'id',
      text: 'ID',
      sortable: true,
      isSorted: 0,
    },
    {
      dataName: 'description',
      text: 'Description',
      sortable: true,
      isSorted: 0,
    },
    {
      dataName: 'isDemoActive',
      text: 'Is Active',
      sortable: true,
      isSorted: 0,
      supportRadioSelection: true
    },
  ]),
  selectedItemIds: new Set([]),
  selectionKey: 'id',
  domId: 'detail-pane',
  onSortTableColumn: () => {},
  onSelectAll: () => {},
  onLinkClick: () => {},
  onSettingsCancel: () => {},
  isConfigurable: true, 
  isFixedHeader: true,
};
  