import { types } from './types';
import { getCustomersCatalog, getDepartmentsCatalog, searchForReferralException, searchForReferralExceptionForExport } from './api';
import { excelExport } from "../../../../commonResources/helpers/exportExcel";
import { excelNameReferralExceptionReport, headersExcelReferralExceptionReport, headingExcelReferralExceptionReport } from "../components/SearchResultsExportDataConfig";

export const loadCustomers = () => async dispatch => {
  dispatch({type: types.REF_EXCEPTION_LOAD_CUSTOMERS_LOADING})

  try {
    await getCustomersCatalog().then(customers => {
      if (customers === null || customers === undefined) {
        dispatch({type: types.REF_EXCEPTION_LOAD_CUSTOMERS_FAIL, 
          payload: 'Something went wrong while fetching Customers Data.'})
      } else {
        dispatch({ type: types.REF_EXCEPTION_LOAD_CUSTOMERS_SUCCESS,
          payload: customers })
      }
    }).catch(() => {
      dispatch({type: types.REF_EXCEPTION_LOAD_CUSTOMERS_FAIL, 
        payload: 'Something went wrong while fetching Customer Data.'})
    });
  } catch (error) {
    dispatch({type: types.REF_EXCEPTION_LOAD_CUSTOMERS_FAIL, 
      payload: 'Something went wrong while fetching Customer Data.'})
  } finally {
    dispatch({ type: types.REF_EXCEPTION_LOAD_CUSTOMERS_FINISHED })
  }
}

export const loadDepartments = (customerId) => async dispatch => {
  dispatch({type: types.REF_EXCEPTION_LOAD_DEPARTMENT_LOADING})

  try {
    await getDepartmentsCatalog(customerId).then(departments => {
      if (departments === null || departments === undefined) {
        dispatch({type: types.REF_EXCEPTION_LOAD_DEPARTMENT_FAIL, 
          payload: 'Something went wrong while fetching Department Data.'})
      } else {
        dispatch({ type: types.REF_EXCEPTION_LOAD_DEPARTMENT_SUCCESS,
          payload: departments })
      }
    }).catch(() => {
      dispatch({type: types.REF_EXCEPTION_LOAD_DEPARTMENT_FAIL, 
        payload: 'Something went wrong while fetching Department Data.'})
    });
  } catch (error) {
    dispatch({type: types.REF_EXCEPTION_LOAD_DEPARTMENT_FAIL, 
      payload: 'Something went wrong while fetching Department Data.'})
  } finally {
    dispatch({ type: types.REF_EXCEPTION_LOAD_DEPARTMENT_FINISHED })
  }
}

export const clearDepartments = () => async dispatch => {
  dispatch({ type: types.REF_EXCEPTION_CLEAR_DEPARTMENTS });
};

export const clearPageLeave = () => async dispatch => {
  dispatch({ type: types.REF_EXCEPTION_CLEAR_LEAVE_PAGE });
  dispatch({ type: types.REF_EXCEPTION_CLEAR_DEPARTMENTS });
};


export const getReferralException = (filters) => async dispatch => {
  dispatch({type: types.REF_EXCEPTION_SEARCH_RESULTS_LOADING})

  try {
    await searchForReferralException(filters).then(paginatedData => {
      if (paginatedData === null || paginatedData.data === null ||
          paginatedData === undefined || paginatedData.data === undefined) {
        dispatch({type: types.REF_EXCEPTION_SEARCH_RESULTS_LOADING_FAIL, 
          payload: 'Something went wrong while fetching Report Data.'})
      } else {
        dispatch({ type: types.REF_EXCEPTION_SEARCH_RESULTS_LOADING_SUCCESS,
          payload: paginatedData })
      }
    }).catch(() => {
      dispatch({type: types.REF_EXCEPTION_SEARCH_RESULTS_LOADING_FAIL, 
        payload: 'Something went wrong while fetching Report Data.'})
    });
  } catch (error) {
    dispatch({type: types.REF_EXCEPTION_SEARCH_RESULTS_LOADING_FAIL, 
      payload: 'Something went wrong while fetching Report Data.'})
  } finally {
    dispatch({ type: types.REF_EXCEPTION_SEARCH_RESULTS_FINISHED })
  }
}

export const exportReferralExceptionReport = (filters)=> async dispatch => {

  dispatch({ type: types.REF_EXCEPTION_EXPORT_ACTIVITY_REPORT });

  try {
    await searchForReferralExceptionForExport(filters).then(data => {
      if (data === null || data === undefined) {
        dispatch({type: types.REF_EXCEPTION_EXPORT_ACTIVITY_REPORT_FAIL, 
          payload: 'Something went wrong while fetching report data to export'})
      } else {
        dispatch({type: types.REF_EXCEPTION_EXPORT_ACTIVITY_REPORT_SUCCESS })
        
        const exportData = data.map((item) => {
          const { departmentID, customerUserID, ...excluded } = item;
          return excluded;
        });
         
        excelExport(headingExcelReferralExceptionReport, headersExcelReferralExceptionReport, exportData, excelNameReferralExceptionReport);
      }
    }).catch(() => {
      dispatch({type: types.REF_EXCEPTION_EXPORT_ACTIVITY_REPORT_FAIL, 
        payload: 'Something went wrong while fetching report data to export.'})
    });
  } catch (error) {
    dispatch({type: types.REF_EXCEPTION_EXPORT_ACTIVITY_REPORT_FAIL, 
      payload: 'Something went wrong while fetching report data to export.'})
  } finally {
    dispatch({ type: types.REF_EXCEPTION_EXPORT_ACTIVITY_REPORT_FINISHED })
  }
}

