export const types = {
  LOAD_ROLES_SUCCESS: '[LOAD_ROLES] load roles success',
  LOAD_ROLES_FAIL: '[LOAD_ROLES] load roles fail',

  LOAD_ROLES_BY_TYPE_SUCCESS: '[LOAD_ROLES_BY_TYPE] load roles by type success',
  LOAD_ROLES_BY_TYPE_FAIL: '[LOAD_ROLES_BY_TYPE] load roles by type fail',
  LOAD_ROLES_BY_TYPE_IS_LOADING: '[LOAD_ROLES_BY_TYPE] load roles by type is loading',
  LOAD_ROLES_BY_TYPE_FINISH_LOADING: '[LOAD_ROLES_BY_TYPE] load roles by type finish loading',

  LOAD_DEPARTMENTS_BY_CUSTOMER_SUCCESS  : '[LOAD_DEPARTMENTS_BY_CUSTOMER] load departments by customer success',
  LOAD_DEPARTMENTS_BY_CUSTOMER_FAIL: '[LOAD_DEPARTMENTS_BY_CUSTOMER] load deparments by customer fail',

  LOAD_MCO_CONTRACTS_BY_CUSTOMER_SUCCESS: '[LOAD_MCO_CONTRACTS_BY_CUSTOMER] load mco contracts by customer success',
  LOAD_MCO_CONTRACTS_BY_CUSTOMER_FAIL: '[LOAD_MCO_CONTRACTS_BY_CUSTOMER] load mco contracts by customer fail',

  LOAD_UNIT_MANAGER_BY_CUSTOMER_SUCCESS: '[LOAD_UNIT_MANAGER_BY_CUSTOMER] load unit manager by customer success',
  LOAD_UNIT_MANAGER_BY_CUSTOMER_FAIL: '[LOAD_UNIT_MANAGER_BY_CUSTOMER] load unit manager by customer fail',
  LOAD_UNIT_MANAGER_BY_CUSTOMER_IS_LOADING: '[LOAD_UNIT_MANAGER_BY_CUSTOMER] load unit manager by customer is loading',
  LOAD_UNIT_MANAGER_BY_CUSTOMER_FINISH_LOADING: '[LOAD_UNIT_MANAGER_BY_CUSTOMER] load unit manager by customer finish loading',

  CLEAR_CREATE_USER_DROPDOWNS: '[CLEAR_CREATE_USER_DROPDOWNS] clear create user dropdowns',

  CREATE_USER_SUCCESS: '[CREATE_USER] create user success',
  CREATE_USER_FAIL: '[CREATE_USER] create user fail',
  CREATE_USER_IS_LOADING: '[CREATE_USER] create user is loading',
  CREATE_USER_FINISH_LOADING: '[CREATE_USER] create user finish loading',

  CLEAR_USER_NOTIFICATIONS: '[CREATE_USER] clear create user notifications',
  CLEAR_USER_ERROR_MSG: '[CREATE_USER] clear create user error msg',

  LOAD_MCO_CONTRACT_BY_USER_SUCCESS: '[LOAD_MCO_CONTRACT_BY_USER] load mco contract by user success',
  LOAD_MCO_CONTRACT_BY_USER_FAIL: '[LOAD_MCO_CONTRACT_BY_USER] load mco contract by user fail',
  CLEAR_MCO_CONTRACT_BY_USER: '[CLEAR_MCO_CONTRACT_BY_USER] clear mco contract by user data',
};