export default {
  columns: new Set([
    {
      dataName: 'membershipID',
      text: 'Membership',
      sortable: false,
      isSorted: 0,
      headerWidth: 70,
      isHeaderTruncated: true,
    },
    {
      dataName: 'customerName',
      text: 'Customer Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'mcoContractName',
      text: 'Mco Contract Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'departmentName',
      text: 'Department Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'firstName',
      text: 'First Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 70,
      isHeaderTruncated: true,
    },
    {
      dataName: 'middleInitial',
      text: 'Middle Initial',
      sortable: false,
      isSorted: 0,
      headerWidth: 70,
      isHeaderTruncated: true,
    },
    {
      dataName: 'lastName',
      text: 'Last Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 70,
      isHeaderTruncated: true,
    },
    {
      dataName: 'hicn',
      text: 'HICN',
      sortable: false,
      isSorted: 0,
      headerWidth: 70,
      isHeaderTruncated: true,
    },
    {
      dataName: 'mbi',
      text: 'MBI',
      sortable: false,
      isSorted: 0,
      headerWidth: 70,
      isHeaderTruncated: true,
    },
    {
      dataName: 'customerMemberId',
      text: 'Helath Plan ID',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'source',
      text: 'Source',
      sortable: false,
      isSorted: 0,
      headerWidth: 60,
      isHeaderTruncated: true,
    },
    {
      dataName: 'wasPlacedInQueue',
      text: 'Was Placed In Queue',
      sortable: false,
      isSorted: 0,
      headerWidth: 140,
      isHeaderTruncated: true,
    },
    {
      dataName: 'daysSinceReferral',
      text: 'Days Since Referral',
      sortable: false,
      isSorted: 0,
      headerWidth: 140,
      isHeaderTruncated: true,
    },
    {
      dataName: 'createdOnBehalfOfUserName',
      text: 'Created On Behalf Of User Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 200,
      isHeaderTruncated: true,
    },
    {
      dataName: 'createdDate',
      text: 'Created Date',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },

  ]),
  selectedItemIds: new Set([]),
  selectionKey: 'Id',
  domId: 'detail-pane',
  onSortTableColumn: () => {},
  onSelectAll: () => {},
  onLinkClick: () => {},
  onSettingsCancel: () => {},
  isConfigurable: true,
};