/**
 * Asynchronously loads the component for HomePage
 */
import React from 'react';
import styled from 'styled-components/macro';
import LoadingIndicator from '@uicl/ui-core/dist/LoadingIndicator';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;

const LoadingPage = () => (
  <Container>
    <LoadingIndicator />
  </Container>
);

export default LoadingPage;
