import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Input from '@uicl/ui-core/dist/Input';
import SelectDropdown from '@uicl/ui-core/dist/SelectDropdown';
import Checkbox from '@uicl/ui-core/dist/Checkbox';
import CircleCheck from '@uicl/ui-core/dist/CircleCheck';
import useLayout from '@uicl/ui-core/dist/useLayout';
import { Container, ListPaneHeader, Row, Group, TextArea, DatePicker, GroupTitle, Button, ButtonGroup } from '@uicl/ui-core';
import Notification from '../../../../components/notifications/Notification';
import { useForm } from '../../../../commonResources/hooks/useForm';
import { loadCustomers, loadStates } from '../../../../commonResources/commonModules/actions';
import '../styles/styles.css'
import { clearCustomerDependencies, clearDepartmentDependencies, clearMcoContractDependencies, clearZipcodeAltData, clearZipcodeMemberData, createReferral, loadDepartmentsByCustomer, loadLanguages, loadMcoContractsByCustomer, loadOutreachChannels, loadRelationships, loadUsersByMcoContractAndDepartment, loadZipcodeByStateAlt, loadZipcodeByStateMember, reset } from '../modules/actions';
import { isInRole } from '../../../../commonResources/helpers/ruleValidation';
import { ruleConstants } from '../../../../commonResources/constants/ruleConstants';
import { useIsMount } from '../../../../commonResources/hooks/useIsMount';


/*eslint-disable */
const CreateReferral = () => {

  const {
    expandCollapseListPaneButtonConfig,
  } = useLayout();

  const dispatch = useDispatch();
  const isMount = useIsMount();

  const {
    mcoContractData,
    departmentData,
    userData,
    userIsLoading,
    userError,

    zipcodeData,
    zipcodeDataAlt,
    preferredLanguageData,
    otherLanguageData,
    relationshipData,
    outreachChannelData,
    referralResponseIsSuccessStatusCode,
    referralResponseStatusCode,
    referralError,
    referralIsSaving,
    triggerPost
  } = useSelector(state => state.createReferral);

  const { customers, states } = useSelector(state => state.common);
  const { userInformation } = useSelector(state => state.app);

  useEffect(() => {
    if (customers.length === 0) {
      dispatch(loadCustomers());
    }
    if (states.length === 0) {
      dispatch(loadStates())
    }
    if (preferredLanguageData.length === 0 || otherLanguageData.length === 0) {
      dispatch(loadLanguages())
    }
    if (relationshipData.length === 0) {
      dispatch(loadRelationships())
    }
    if (outreachChannelData.length === 0) {
      dispatch(loadOutreachChannels())
    }

    setCatKey(0);
  }, []);

  useEffect(() => {
    if (customers.length > 0) {
      if (userInformation && !isInRole(ruleConstants.CreateReferralOthers, userInformation) && isInRole(ruleConstants.CreateReferralOwn, userInformation) && userInformation.customerId) {
        dispatch(loadDepartmentsByCustomer(userInformation.customerId));
        dispatch(loadMcoContractsByCustomer(userInformation.customerId));
        const selectedCustomer = customers.find(c => c.value === userInformation.customerId);
        setCustomer(selectedCustomer);
      }
    }
  }, [customers]);

  useEffect(() => {
    if (isMount) {
      if (!referralResponseIsSuccessStatusCode) {
        setIsApiError(true);
        return
      }
      setIsSaveSuccess(true);
      resetStates();
    }
  }, [triggerPost])

  const [formValues, handleInputChange, resetForm, handleInputChangeUpper] = useForm({
    healthPlanId: '',
    mbi: '',
    firstName: '',
    middleInitial: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    zip4: '',
    homePhoneNumber: '',
    altPhoneNumber: '',
    email: ''
  });

  const {
    healthPlanId,
    mbi,
    firstName,
    middleInitial,
    lastName,
    address1,
    address2,
    city,
    zip4,
    homePhoneNumber,
    altPhoneNumber,
    email
  } = formValues;

  const [formAltValues, handleAltInputChange, resetAltForm, handleAltInputChangeUpper] = useForm({
    altFirstName: '',
    altMiddleInitial: '',
    altLastName: '',
    altAddress1: '',
    altAddress2: '',
    altCity: '',
    altZip4: '',
    altHomePhoneNumber: '',
    altEmail: ''
  });

  const {
    altFirstName,
    altMiddleInitial,
    altLastName,
    altAddress1,
    altAddress2,
    altCity,
    altZip4,
    altHomePhoneNumber,
    altEmail
  } = formAltValues;

  const [catKey, setCatKey] = useState('');

  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState();
  const [isApiError, setIsApiError] = useState(false);

  const [customer, setCustomer] = useState('');
  const [selectedMcoContract, setSelectedMcoContract] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedUser, setSelectedUser] = useState('');


  const [enrolled, setEnrolled] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState('');

  const [gender, setGender] = useState('');

  const [state, setState] = useState('');
  const [selectedZipcodeMember, setSelectedZipcodeMember] = useState('');

  const [altState, setAltState] = useState('');
  const [selectedZipcodeAlt, setSelectedZipcodeAlt] = useState('');

  const [comment, setComment] = useState('');

  const [preferredLanguage, setLanguage] = useState('');
  const [otherLanguage, setOtherLanguage] = useState('');

  const [outreachChannel, setOutreachChannel] = useState('');

  const [isMemberPreferredContact, setIsMemberPreferredContact] = useState(true);
  const [isAltPreferredContact, setIsAltPreferredContact] = useState(false);

  const [hasAlternativeContact, setHasAlternativeContact] = useState(false);

  const [relationship, setRelationship] = useState('');

  const [clickSubmit, setSubmit] = useState(false);

  const resetAltStatesToInitialState = () => {
    setRelationship('');
    setAltState('');
    setSelectedZipcodeAlt('');
    setIsAltPreferredContact(false);
  };

  const resetStatesToInitialState = () => {
    setCustomer('');
    setSelectedMcoContract('');
    setSelectedDepartment('');
    setSelectedUser('');
    setEnrolled(false);
    setDateOfBirth('');
    setGender('');
    setState('');
    setSelectedZipcodeMember('');
    setLanguage('');
    setOtherLanguage('');
    setOutreachChannel('');
    setIsMemberPreferredContact(true);
    setComment('');
    setHasAlternativeContact(false);
  };


  const headerProps = {
    title: 'Create Referral',
    icon: CircleCheck,
    description: 'This is short description',
    layoutControls: [expandCollapseListPaneButtonConfig]
  };

  const genderOptions = [
    { label: 'Female', value: 'F' },
    { label: 'Male', value: 'M' }
  ];

  const reloadCustomerDependencies = () => {
    setSelectedMcoContract(null);
    setSelectedDepartment(null);
    setSelectedUser(null);
    dispatch(clearCustomerDependencies());
  }

  const handleCustomerChange = (e) => {
    if (e && e !== customer) {
      reloadCustomerDependencies();
      dispatch(loadDepartmentsByCustomer(e.value));
      dispatch(loadMcoContractsByCustomer(e.value));
    }
    if (!e) {
      reloadCustomerDependencies();
    }
    setCustomer(e);
  }

  const reloadDepartmentDependencies = () => {
    setSelectedUser(null);
    dispatch(clearDepartmentDependencies());
  }

  const handleDepartmentChange = (e) => {
    if (e && e !== selectedDepartment) {
      reloadDepartmentDependencies();
      if (selectedMcoContract) {
        dispatch(loadUsersByMcoContractAndDepartment(e.value, selectedMcoContract.value));
      }
    }
    if (!e) {
      reloadDepartmentDependencies();
    }
    setSelectedDepartment(e);
  }

  const reloadMcoDependencies = () => {
    setSelectedUser(null);
    dispatch(clearMcoContractDependencies());
  }

  const handleMcoContractChange = (e) => {
    if (e && e !== selectedMcoContract) {
      reloadMcoDependencies();
      if (selectedDepartment) {
        dispatch(loadUsersByMcoContractAndDepartment(selectedDepartment.value, e.value));
      }
    }
    if (!e) {
      reloadMcoDependencies();
    }
    setSelectedMcoContract(e);
  }

  const today = () => {
    const current = new Date();
    const date = current.getDate();
    const month = current.getMonth() + 1;
    const year = current.getFullYear();

    return `${month < 10 ? `0${month}` : `${month}`}/${date < 10 ? `0${date}` : `${date}`}/${year}`;
  }

  const invalidSelectedDate = (date) => {
    var todayDate = new Date(today());
    var selectedDate = new Date(date);
    return todayDate < selectedDate;
  }

  const handleStateChange = (e) => {
    if ((e && e !== state) || !e) {
      dispatch(clearZipcodeMemberData());
      setSelectedZipcodeMember('');
      if (e) {
        dispatch(loadZipcodeByStateMember(e.value))
      }
    }
    setState(e ? e : '');
  }

  const IsValidZipCode = (zipCode, zipcodes) => {
    if (zipCode && zipCode.length === 5) {
      return zipcodes.find(zip => { return zip.value === zipCode }) ? true : false;
    }
    else {
      return !zipCode || zipCode.length === 0;
    }
  }

  const handlePreferredLanguageChange = (e) => {
    if ((e && e !== preferredLanguage) || !e) {
      setOtherLanguage('');
    }
    setLanguage(e ? e : '');
  }

  const handleMemberPreferredContactChange = (e) => {
    //un-checking the check box
    if (isMemberPreferredContact && !hasAlternativeContact) {
      setHasAlternativeContact(true);
    }
    setIsMemberPreferredContact(!isMemberPreferredContact);
    setIsAltPreferredContact(isMemberPreferredContact);
  }

  const handleAltPreferredContactChange = (e) => {
    setIsAltPreferredContact(!isAltPreferredContact);
    setIsMemberPreferredContact(isAltPreferredContact);
  }

  const handleHasAlternativeContactChange = (e) => {
    setHasAlternativeContact(!hasAlternativeContact);
    //Is changing hasAlternativeContact to false
    if (hasAlternativeContact) {
      resetAltForm();
      resetAltStatesToInitialState();
      setIsMemberPreferredContact(true);
    }
  }

  const handleStateAltChange = (e) => {
    if (e && e !== altState) {
      dispatch(clearZipcodeAltData());
      dispatch(loadZipcodeByStateAlt(e.value));
      setSelectedZipcodeAlt('');
    }
    if (!e) {
      dispatch(clearZipcodeAltData());
      setSelectedZipcodeAlt('');
    }
    setAltState(e ? e : '');
  }

  const getPhoneNumber = (phone) => {
    return phone.replace(/[^\w]/gi, '');
  }

  const isValidPhoneNumber = (phone) => {
    const phLenght = getPhoneNumber(phone).length;
    return phLenght === 10 || phLenght === 0;
  }

  const resetStates = () => {
    resetForm();
    resetAltForm();
    resetStatesToInitialState();
    resetAltStatesToInitialState();
    setCatKey(catKey + 1);
    dispatch(reset());
    setSubmit(false);
  }

  const handleCancelClick = () => {
    setIsSaveSuccess(false);
    setIsApiError(false);
    setIsValidationError(false);
    resetStates();
  }

  const isValidTextData = (text) => {
    const regex = /^([A-Za-z]+[A-Za-z. ']+[ ]*)+$/;
    return regex.test(text);
  }

  const isValidZipcode = (zip) => {
    const regex = /^([0-9]){5}$/;
    return regex.test(zip);
  }

  const isValidReferralInputData = () => {

    const isvalid = (
      customer &&
      selectedMcoContract &&
      selectedDepartment &&
      selectedUser &&
      firstName &&
      lastName &&
      address1 &&
      city &&
      state &&
      selectedZipcodeMember &&
      homePhoneNumber && getPhoneNumber(homePhoneNumber).length === 10 &&
      isValidPhoneNumber(altPhoneNumber) &&
      dateOfBirth &&
      gender &&
      (
        (preferredLanguage && preferredLanguage.value != 0) ||
        (preferredLanguage.value === 0 && otherLanguage)
      ) &&
      (
        !outreachChannel ||
        outreachChannel.value != 4 ||
        (outreachChannel.value === 4 && email)
      ) &&
      (
        !enrolled || (enrolled && mbi)
      ) &&
      (
        !hasAlternativeContact ||
        hasAlternativeContact &&
        (
          relationship &&
          altFirstName &&
          altLastName &&
          altAddress1 &&
          altCity &&
          altState &&
          selectedZipcodeAlt &&
          altHomePhoneNumber && getPhoneNumber(altHomePhoneNumber).length === 10 &&
          (
            !outreachChannel ||
            outreachChannel.value != 4 ||
            (outreachChannel.value === 4 && altEmail)
          )
        )
      )
    ) ? true : false;

    if (!isvalid) {
      setValidationErrorMessage("Please complete the required fields (*)");
      return false;
    }

    const isValidInputData = (
      isValidTextData(firstName) &&
      isValidTextData(lastName) &&
      !invalidSelectedDate(dateOfBirth) &&
      IsValidZipCode(selectedZipcodeMember, zipcodeData)) &&
      (
        !hasAlternativeContact ||
        hasAlternativeContact &&
        (
          isValidTextData(altFirstName) &&
          isValidTextData(altLastName) &&
          IsValidZipCode(selectedZipcodeAlt, zipcodeDataAlt)
        )
      ) ? true : false;

    if (!isValidInputData) {
      setValidationErrorMessage("Please review invalid input data");
      return false;
    }

    setValidationErrorMessage();
    return true;
  }

  const selectPreferredLanguage = () => {
    return preferredLanguage.value === 0 ? otherLanguage.value : preferredLanguage.value;
  }

  const handleSubmit = () => {
    setSubmit(true);
    if (isValidReferralInputData()) {

      var today = new Date();
      var dob = new Date(dateOfBirth);
      const referral = {
        CustomerId: customer.value,
        McoContractId: selectedMcoContract.value,
        DepartmentId: selectedDepartment.value,
        CustomerUserId: selectedUser.value,
        SourceId: 1,
        Prospect: false,
        FirstName: firstName,
        MiddleInitial: middleInitial,
        LastName: lastName,
        Address1: address1,
        Address2: address2,
        City: city,
        State: state.value,
        Zip: selectedZipcodeMember,
        ZipExtension: zip4,
        HomePhone: getPhoneNumber(homePhoneNumber),
        AlternatePhone: getPhoneNumber(altPhoneNumber),
        AltFirstName: altFirstName,
        AltMiddleInitial: altMiddleInitial,
        AltLastName: altLastName,
        AltAddress1: altAddress1,
        AltAddress2: altAddress2,
        AltCity: altCity,
        AltState: altState.value,
        AltZip: selectedZipcodeAlt,
        AltZipExtension: altZip4,
        AltPhone: getPhoneNumber(altHomePhoneNumber),
        Dob: dob,
        Gender: gender.value,
        Comments: comment,
        CreatedBy: userInformation.userName,
        CreatedDate: today,
        MBI: mbi,
        RelationshipId: relationship.value,
        CustomerMemberID: healthPlanId,
        Email: email,
        PrimaryLanguageID: selectPreferredLanguage(),
        OutreachChannelTypeID: outreachChannel.value,
        IsMemberPreferredContact: isMemberPreferredContact,
        AltEmail: altEmail,
      };

      dispatch(createReferral(referral));
      return
    }

    setIsValidationError(true);
  }

  return (
    <>
      <ListPaneHeader {...headerProps} />

      {isSaveSuccess && (
        <Notification
          title="Success"
          text='Referral was submitted successfully'
          type="success"
          timer={3500}
          onDismiss={() => setIsSaveSuccess(false)}
        />
      )}
      {isValidationError && (
        <Notification
          title="Error"
          text={validationErrorMessage}
          type="error"
          onDismiss={() => setIsValidationError(false)}
        />
      )}
      {isApiError && (
        <Notification
          title="Error"
          text={`Create Referral failed, try again later. ${referralError}`}
          type="error"
          timer={3500}
          onDismiss={() => setIsApiError(false)}
        />
      )}

      <Container>
        <div style={{ margin: 'auto', width: '60vw' }}>
          <Group className='general-info'
            title='General Information'
          >
            <Row className='general-info'>
              <div className='column-dropdowns'>
                {
                  userInformation && isInRole(ruleConstants.CreateReferralOthers, userInformation)
                    ?
                    <SelectDropdown
                      placeholderText='[Select Customer]'
                      name='customer'
                      domID='customer'
                      required
                      hasError={clickSubmit && !customer}
                      errorMessage={clickSubmit && !customer ? 'Please select a Customer' : ''}
                      label='Customer'
                      value={customer}
                      onChange={handleCustomerChange}
                      options={customers}
                    />
                    :
                    <SelectDropdown
                      placeholderText='[Select Customer]'
                      name='customer'
                      domID='customer'
                      required
                      hasError={clickSubmit && !customer}
                      errorMessage={clickSubmit && !customer ? 'Please select a Customer' : ''}
                      label='Customer'
                      value={customers.find(c => c.value === userInformation.customerId)}
                      disabled
                    />
                }
              </div>
              <div className='column-dropdowns'>
                <SelectDropdown
                  placeholderText='[Select Mco Contract]'
                  name='mcoContract'
                  domID='mcoContract'
                  value={selectedMcoContract}
                  required
                  hasError={clickSubmit && !selectedMcoContract}
                  errorMessage={clickSubmit && !selectedMcoContract ? 'Please select an Mco Contract' : ''}
                  label='Mco Contract'
                  onLoad
                  onChange={handleMcoContractChange}
                  options={mcoContractData}
                />
              </div>
              <div className='column-dropdowns'>
                <SelectDropdown
                  placeholderText='[Select Department]'
                  name='department'
                  domID='department'
                  value={selectedDepartment}
                  required
                  hasError={clickSubmit && !selectedDepartment}
                  errorMessage={clickSubmit && !selectedDepartment ? 'Please select a Department' : ''}
                  label='Department'
                  onChange={handleDepartmentChange}
                  options={departmentData}
                />
              </div>
            </Row>
            <Row className='general-info'>
              <div className='column-dropdowns'>
                <SelectDropdown
                  placeholderText='[Select User]'
                  name='user'
                  domID='user'
                  value={selectedUser}
                  required
                  hasError={clickSubmit && !selectedUser}
                  errorMessage={clickSubmit && !selectedUser ? 'Please select a User' : ''}
                  label='Referred By'
                  onChange={(e) => setSelectedUser(e)}
                  options={userData}
                />
              </div>
            </Row>
            <Row className='general-info'>
              <div className='column-dropdowns'>
                <Checkbox
                  label='Is the member currently enrolled in plan?'
                  dataTestId='enrolled'
                  checked={enrolled}
                  size='medium'
                  value={enrolled}
                  onChange={(e) => setEnrolled(!enrolled)}
                />
              </div>
            </Row>
            <Row className='general-info'>
              <div className='column-dropdowns'>
                <Input
                  label='Health Plan ID'
                  domID='healthPlanId'
                  name='healthPlanId'
                  autoComplete='off'
                  maxLength={20}
                  value={healthPlanId}
                  onChange={handleInputChange}
                />
              </div>
              <div className='column-dropdowns'>
                <Input
                  label='MBI'
                  domID='mbi'
                  name='mbi'
                  required={enrolled}
                  hasError={clickSubmit && enrolled && !mbi}
                  errorMessage={clickSubmit && enrolled && !mbi ? 'Please enter the MBI' : ''}
                  autoComplete='off'
                  maxLength={11}
                  value={mbi}
                  onChange={handleInputChange}
                />
              </div>
            </Row>
          </Group>
          <Group className='member-info'
            title='Member Information'
            isAccordion={true}
          >
            <Row className='member-info-name'>
              <div className='column-info'>
                <Input
                  label='First Name'
                  domID='firstName'
                  required
                  hasError={clickSubmit && !firstName}
                  errorMessage={clickSubmit && !firstName ? 'Please enter the First Name' : 'Invalid First Name'}
                  name='firstName'
                  regex={/^([A-Za-z]+[A-Za-z. ']+[ ]*)+$/}
                  autoComplete='off'
                  maxLength={30}
                  value={firstName}
                  onChange={handleInputChangeUpper}
                />
              </div>
              <div className='column-small'>
                <Input
                  label='Middle Initial'
                  domID='middleInitial'
                  name='middleInitial'
                  autoComplete='off'
                  maxLength={1}
                  value={middleInitial}
                  onChange={handleInputChangeUpper}
                />
              </div>
              <div className='column-info'>
                <Input
                  label='LastName'
                  domID='lastName'
                  required
                  hasError={clickSubmit && !lastName}
                  errorMessage={clickSubmit && !lastName ? 'Please enter the Last Name' : 'Invalid Last Name'}
                  name='lastName'
                  regex={/^([A-Za-z]+[A-Za-z. ']+[ ]*)+$/}
                  autoComplete='off'
                  maxLength={30}
                  value={lastName}
                  onChange={handleInputChangeUpper}
                />
              </div>
            </Row>
            <Row className='member-info-dob-gender'>
              <div key={catKey} className='column-smalldropdown'>
                <DatePicker
                  label='Date of Birth'
                  domID='dateOfBirth'
                  required
                  hasError={invalidSelectedDate(dateOfBirth) || (clickSubmit && !dateOfBirth)}
                  errorMessage={clickSubmit && !dateOfBirth ? 'Please enter a valid Date of Birth' : 'Invalid D.O.B'}
                  value={dateOfBirth}
                  onInputChange={(e) => setDateOfBirth(e)}
                />
              </div>
              <div className='column-smalldropdown'>
                <SelectDropdown
                  placeholderText='[Select Gender]'
                  name='gender'
                  domID='gender'
                  label='Gender'
                  value={gender}
                  required
                  isSearchable={false}
                  hasError={clickSubmit && !gender}
                  errorMessage={clickSubmit && !gender ? 'Please select Gender' : ''}
                  onChange={(e) => setGender(e ? e : '')}
                  options={genderOptions}
                />
              </div>
            </Row>
            <Row className='member-info-address'>
              <div className='address'>
                <Input
                  label='Address 1'
                  domID='address1'
                  required
                  maxLength={50}
                  hasError={clickSubmit && !address1}
                  errorMessage={clickSubmit && !address1 ? 'Please enter the Address' : ''}
                  name='address1'
                  autoComplete='off'
                  value={address1}
                  onChange={handleInputChange}
                />
              </div>
              <div className='address'>
                <Input
                  label='Address 2'
                  domID='address2'
                  maxLength={30}
                  name='address2'
                  autoComplete='off'
                  value={address2}
                  onChange={handleInputChange}
                />
              </div>
            </Row>
            <Row className='member-info-address'>
              <div className='column-dropdowns'>
                <Input
                  label='City'
                  domID='city'
                  required
                  maxLength={50}
                  hasError={clickSubmit && !city}
                  errorMessage={clickSubmit && !city ? 'Please enter the City' : ''}
                  name='city'
                  autoComplete='off'
                  value={city}
                  onChange={handleInputChange}
                />
              </div>
              <div className='column-dropdowns'>
                <SelectDropdown
                  placeholderText='[Select State]'
                  domID='state'
                  required
                  hasError={clickSubmit && !state}
                  errorMessage={clickSubmit && !state ? 'Please select a State' : ''}
                  name='state'
                  value={state}
                  label='State'
                  onChange={handleStateChange}
                  options={states}
                />
              </div>
              <div className='column-small'>
                <Input
                  label='Zip Code'
                  domID='selectedZipcodeMember'
                  required
                  hasError={(clickSubmit && !selectedZipcodeMember) || !IsValidZipCode(selectedZipcodeMember, zipcodeData)}
                  errorMessage={clickSubmit && !selectedZipcodeMember ? 'Please enter the Zip Code' : !IsValidZipCode(selectedZipcodeMember, zipcodeData) ? 'Invalid zip code' : ''}
                  regex={/^([0-9]){5}$/}
                  name='selectedZipcodeMember'
                  autoComplete='off'
                  maxLength={5}
                  value={selectedZipcodeMember}
                  onChange={(e) => setSelectedZipcodeMember(e ? e.target.value : '')}
                />
              </div>
              <div className='column-small'>
                <Input
                  label='Zip 4'
                  domID='zip4'
                  name='zip4'
                  autoComplete='off'
                  maxLength={4}
                  value={zip4}
                  onChange={handleInputChange}
                />
              </div>
            </Row>
            <Row className='member-info-phone-email'>
              <div className='column-smalldropdown'>
                <Input
                  label='Home Phone Number'
                  domID='homePhoneNumber'
                  required
                  hasError={(clickSubmit && (!homePhoneNumber || getPhoneNumber(homePhoneNumber).length != 10)) || !isValidPhoneNumber(homePhoneNumber)}
                  errorMessage={clickSubmit && !homePhoneNumber ? 'Please enter the Phone Number' : !isValidPhoneNumber(homePhoneNumber) ? 'Invalid Phone Number' : ''}
                  name='homePhoneNumber'
                  autoComplete='off'
                  mask='(999) 999-9999'
                  value={homePhoneNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div className='column-smalldropdown'>
                <Input
                  label='Alternate Phone Number'
                  domID='altPhoneNumber'
                  name='altPhoneNumber'
                  hasError={!isValidPhoneNumber(altPhoneNumber)}
                  errorMessage={!isValidPhoneNumber(altPhoneNumber) ? 'Invalid Phone Number' : ''}
                  autoComplete='off'
                  mask='(999) 999-9999'
                  value={altPhoneNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div className='column'>
                <Input
                  label='Email'
                  domID='email'
                  name='email'
                  maxLength={128}
                  required={outreachChannel && outreachChannel.value === 4}
                  hasError={clickSubmit && (outreachChannel && outreachChannel.value === 4 && !email)}
                  errorMessage={clickSubmit && (outreachChannel && outreachChannel.value === 4 && !email) ? 'Please enter the email' : ''}
                  autoComplete='off'
                  value={email}
                  onChange={handleInputChange}
                />
              </div>
            </Row>
            <Row className='member-info-preferrences'>
              <GroupTitle title='Member Preferences' />
            </Row>
            <Row className='member-info-preferrences'>
              <div className='column-dropdowns'>
                <SelectDropdown
                  placeholderText='[Select Preferred Language]'
                  domID='preferredLanguage'
                  name='preferredLanguage'
                  value={preferredLanguage}
                  required
                  hasError={clickSubmit && !preferredLanguage}
                  errorMessage={clickSubmit && !preferredLanguage ? 'Please select the Preferred Language' : ''}
                  label='Preferred Language'
                  onChange={handlePreferredLanguageChange}
                  options={preferredLanguageData}
                />
              </div>
              {preferredLanguage && preferredLanguage.value === 0 &&
                <div className='column-dropdowns'>
                  <SelectDropdown
                    placeholderText='[Select Other Language]'
                    domID='otherLanguage'
                    name='otherLanguage'
                    value={otherLanguage}
                    required={preferredLanguage && preferredLanguage.value === 0}
                    hasError={clickSubmit && !otherLanguage}
                    errorMessage={clickSubmit && preferredLanguage && preferredLanguage.value === 0 && !otherLanguage ? 'Please select the Preferred Language' : ''}
                    label='Other Preferred Language'
                    onChange={(e) => setOtherLanguage(e)}
                    options={otherLanguageData}
                  />
                </div>
              }
              <div className='column-dropdowns'>
                <SelectDropdown
                  placeholderText='[Select Outreach Method]'
                  domID='outreachChannel'
                  name='outreachChannel'
                  label='Preferred Outreach Method'
                  value={outreachChannel}
                  onChange={(e) => setOutreachChannel(e)}
                  options={outreachChannelData}
                />
              </div>
            </Row>
            <Row className='member-info-preferrences'>
              <div className='column-dropdowns'>
                <Checkbox
                  label='Preferred Contact'
                  dataTestId='isMemberPreferredContact'
                  checked={isMemberPreferredContact}
                  size='medium'
                  value={isMemberPreferredContact}
                  onChange={handleMemberPreferredContactChange}
                />
              </div>
            </Row>
            <Row className='member-info-additional'>
              <GroupTitle title='Additional Information' />
            </Row>
            <Row className='member-info-additional'>
              <div className='column-large'>
                <TextArea
                  domID='comment'
                  dataTestId='comment'
                  initialValue={comment}
                  name='comment'
                  label='Comment'
                  resize='both'
                  size='large'
                  maxLength={250}
                  hasCounter={true}
                  value={comment}
                  onChange={(e) => setComment(e ? e.target.value : '')}
                />
              </div>
            </Row>
            <Row className='member-info-additional'>
              <div className='column-dropdowns'>
                <Checkbox
                  label='It has Alternative Contact'
                  dataTestId='hasAlternativeContact'
                  checked={hasAlternativeContact}
                  size='medium'
                  value={hasAlternativeContact}
                  onChange={handleHasAlternativeContactChange}
                />
              </div>
            </Row>
          </Group>
          {hasAlternativeContact &&
            <Group className='alt-info'
              title='Alternative Contact'
              isAccordion={true}
              initialIsCollapsed={false}
            >
              <Row className='alt-info-name'>
                <div className='column-info'>
                  <Input
                    label='First Name'
                    domID='altFirstName'
                    required={hasAlternativeContact}
                    hasError={hasAlternativeContact && clickSubmit && !altFirstName}
                    errorMessage={clickSubmit && !altFirstName ? 'Please enter the First Name' : 'Invalid First Name'}
                    name='altFirstName'
                    regex={/^([A-Za-z]+[A-Za-z. ']+[ ]*)+$/}
                    autoComplete='off'
                    maxLength={30}
                    value={altFirstName}
                    onChange={handleAltInputChangeUpper}
                  />
                </div>
                <div className='column-small'>
                  <Input
                    label='Middle Initial'
                    domID='altMiddleInitial'
                    name='altMiddleInitial'
                    autoComplete='off'
                    value={altMiddleInitial}
                    maxLength={1}
                    onChange={handleAltInputChangeUpper}
                  />
                </div>
                <div className='column-info'>
                  <Input
                    label='LastName'
                    domID='altLastName'
                    required={hasAlternativeContact}
                    hasError={hasAlternativeContact && clickSubmit && !altLastName}
                    errorMessage={clickSubmit && !altLastName ? 'Please enter the Last Name' : 'Invalid Last Name'}
                    name='altLastName'
                    regex={/^([A-Za-z]+[A-Za-z. ']+[ ]*)+$/}
                    autoComplete='off'
                    maxLength={30}
                    value={altLastName}
                    onChange={handleAltInputChangeUpper}
                  />
                </div>
                <div className='column-smalldropdown'>
                  <SelectDropdown
                    placeholderText='[Select Relationship]'
                    name='relationship'
                    domID='relationship'
                    label='Relationship'
                    value={relationship}
                    required={hasAlternativeContact}
                    hasError={hasAlternativeContact && clickSubmit && !relationship}
                    errorMessage={clickSubmit && !relationship ? 'Please select a Relationship' : ''}
                    onChange={(e) => setRelationship(e)}
                    options={relationshipData}
                  />
                </div>
              </Row>
              <Row className='alt-info-address'>
                <div className='address'>
                  <Input
                    label='Address 1'
                    domID='altAddress1'
                    autoComplete='off'
                    required={hasAlternativeContact}
                    maxLength={50}
                    hasError={hasAlternativeContact && clickSubmit && !altAddress1}
                    errorMessage={clickSubmit && !altAddress1 ? 'Please enter the Address' : ''}
                    name='altAddress1'
                    value={altAddress1}
                    onChange={handleAltInputChange}
                  />
                </div>
                <div className='address'>
                  <Input
                    label='Address 2'
                    domID='altAddress2'
                    name='altAddress2'
                    maxLength={30}
                    autoComplete='off'
                    value={altAddress2}
                    onChange={handleAltInputChange}
                  />
                </div>
              </Row>
              <Row className='alt-info'>
                <div className='column-dropdowns'>
                  <Input
                    label='City'
                    domID='altCity'
                    autoComplete='off'
                    maxLength={50}
                    required={hasAlternativeContact}
                    hasError={hasAlternativeContact && clickSubmit && !altCity}
                    errorMessage={clickSubmit && !altCity ? 'Please enter the City' : ''}
                    name='altCity'
                    value={altCity}
                    onChange={handleAltInputChange}
                  />
                </div>
                <div className='column-dropdowns'>
                  <SelectDropdown
                    placeholderText='[Select State]'
                    domID='altState'
                    required={hasAlternativeContact}
                    hasError={hasAlternativeContact && clickSubmit && !altState}
                    errorMessage={clickSubmit && !altState ? 'Please select a State' : ''}
                    name='altState'
                    label='State'
                    value={altState}
                    onChange={handleStateAltChange}
                    options={states}
                  />
                </div>
                <div className='column-small'>
                  <Input
                    label='Zip Code'
                    domID='altZipcode'
                    required={hasAlternativeContact}
                    hasError={(clickSubmit && !selectedZipcodeAlt) || !IsValidZipCode(selectedZipcodeAlt, zipcodeDataAlt)}
                    errorMessage={clickSubmit && !selectedZipcodeAlt ? 'Please enter the Zip Code' : !IsValidZipCode(selectedZipcodeAlt, zipcodeDataAlt) ? 'Invalid zip code' : ''}
                    regex={/^([0-9]){5}$/}
                    name='altZipcode'
                    autoComplete='off'
                    maxLength={5}
                    value={selectedZipcodeAlt}
                    onChange={(e) => setSelectedZipcodeAlt(e ? e.target.value : '')}
                  />
                </div>
                <div className='column-small'>
                  <Input
                    label='Zip 4'
                    domID='altZip4'
                    name='altZip4'
                    autoComplete='off'
                    maxLength={4}
                    value={altZip4}
                    onChange={handleAltInputChange}
                  />
                </div>
              </Row>
              <Row className='alt-info-phone-email'>
                <div className='column-smalldropdown'>
                  <Input
                    label='Home Phone Number'
                    domID='altHomePhoneNumber'
                    required={hasAlternativeContact}
                    hasError={(hasAlternativeContact && clickSubmit && (!altHomePhoneNumber || getPhoneNumber(altHomePhoneNumber).length != 10)) || !isValidPhoneNumber(altHomePhoneNumber)}
                    errorMessage={clickSubmit && !altHomePhoneNumber ? 'Please enter the Phone Number' : !isValidPhoneNumber(altHomePhoneNumber) ? 'Invalid Phone Number' : ''}
                    name='altHomePhoneNumber'
                    autoComplete='off'
                    mask='(999) 999-9999'
                    value={altHomePhoneNumber}
                    onChange={handleAltInputChange}
                  />
                </div>
                <div className='column'>
                  <Input
                    label='Email'
                    domID='altEmail'
                    name='altEmail'
                    maxLength={128}
                    required={hasAlternativeContact && outreachChannel && outreachChannel.value === 4}
                    hasError={hasAlternativeContact && clickSubmit && (outreachChannel.value === 4 && !altEmail)}
                    errorMessage={clickSubmit && (outreachChannel.value === 4 && !altEmail) ? 'Please enter the email' : ''}
                    autoComplete='off'
                    value={altEmail}
                    onChange={handleAltInputChange}
                  />
                </div>
              </Row>
              <Row className='alt-info-preferrences'>
                <div className='column-dropdowns'>
                  <Checkbox
                    label='Preferred Contact'
                    dataTestId='isAltPreferredContact'
                    checked={isAltPreferredContact}
                    size='medium'
                    value={isAltPreferredContact}
                    onChange={handleAltPreferredContactChange}
                  />
                </div>
              </Row>
            </Group>
          }
          <div className='button'>
            <ButtonGroup>
              <Button
                size='medium'
                name='Create Referral'
                onClick={handleSubmit}
              />
              <Button
                buttonType='diminished'
                size='medium'
                name='Cancel'
                onClick={handleCancelClick}
              />
            </ButtonGroup>
          </div>


        </div>
      </Container>

    </>
  )
}

export default CreateReferral
