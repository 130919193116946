export default {
  columns: new Set([
    {
      dataName: 'customerName',
      text: 'Customer Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'mcoContractName',
      text: 'Mco Contract Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'departmentName',
      text: 'Department Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'potentiallyEligible',
      text: 'Potentially Eligible',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'inProgress',
      text: 'In Progress',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'pending',
      text: 'Pending',
      sortable: false,
      isSorted: 0,
      headerWidth: 50,
      isHeaderTruncated: true,
    },
    {
      dataName: 'referred',
      text: 'Referred',
      sortable: false,
      isSorted: 0,
      headerWidth: 50,
      isHeaderTruncated: true,
    },
    {
      dataName: 'approved',
      text: 'Approved',
      sortable: false,
      isSorted: 0,
      headerWidth: 56,
      isHeaderTruncated: true,
    },
    {
      dataName: 'completed',
      text: 'Completed',
      sortable: false,
      isSorted: 0,
      headerWidth: 62,
      isHeaderTruncated: true,
    },
    {
      dataName: 'submitted',
      text: 'Submitted',
      sortable: false,
      isSorted: 0,
      headerWidth: 56,
      isHeaderTruncated: true,
    },
    {
      dataName: 'notQualifiedAssets',
      text: 'Not Qualified Assets',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'notQualifiedIncome',
      text: 'Not Qualified Income',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'total',
      text: 'Total',
      sortable: false,
      isSorted: 0,
      headerWidth: 50,
      isHeaderTruncated: true,
    },

  ]),
  selectedItemIds: new Set([]),
  selectionKey: 'Id',
  domId: 'detail-pane',
  onSortTableColumn: () => {},
  onSelectAll: () => {},
  onLinkClick: () => {},
  onSettingsCancel: () => {},
  isConfigurable: true,
};
  