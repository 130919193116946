
export const types = {
  LOAD_ROLES_SUCCESS : '[ROLES] Load data for roles grid',
  LOAD_ROLES_FAIL : '[ROLES] Load data for roles grid fail',
  LOAD_ROLES_IS_LOADING : '[ROLES] Load data for roles grid is loading',
  LOAD_ROLES_FINISH_LOADING : '[ROLES] Load data for roles finish loading',  
  LOAD_ROLES_PAGINATION_DATA_SUCCESS: '[ROLES] Load data for roles pagination',

  LOAD_RULES_SUCCESS : '[RULES] Load data for rules grid',
  LOAD_RULES_FAIL : '[RULES] Load data for rules grid fail',
  LOAD_RULES_IS_LOADING : '[RULES] Load data for rules grid is loading',
  LOAD_RULES_FINISH_LOADING : '[RULES] Load data for rules finish loading', 
  
  LOAD_ROLES_TYPES_SUCCESS : '[ROLES] Load data for rules types dropdown',
  LOAD_ROLES_TYPES_FAIL : '[ROLES] Load data for roles types dropdown fail',

  LOAD_ROLES_ASSOCIATION_SUCCESS : '[ROLES] Load data for roles association dropdown',
  LOAD_ROLES_ASSOCIATION_FAIL : '[ROLES] Load data for roles association dropdown fail',

  CREATE_ROLE_RESPONSE: '[CREATE_ROLE] Reponse from create role post',

  SAVE_ROLE_IS_LOADING: '[CREATE_ROLE] Role save is loading',
  SAVE_ROLE_FINISH_LOADING: '[CREATE_ROLE] Role save finish loading',

  UPDATE_ROLE_RESPONSE: '[UPDATE_ROLE] Reponse from update role put',
  UPDATE_ROLE_IS_LOADING: '[UPDATE_ROLE] Role update is loading',
  UPDATE_ROLE_FINISH_LOADING: '[UPDATE_ROLE] Role update finish loading',

  CLEAR_ROLES_REDUCER: '[ROLES] Clear roles deducer',
};