export const types = {
  LOAD_REFERRAL_DATA_GRID_SUCCESS: '[REFERRAL_SEARCH] Load data to search referral data grid',
  LOAD_REFERRAL_DATA_GRID_FAIL: '[REFERRAL_SEARCH] Failed to load grid data for search referral',
  LOAD_REFERRAL_PAGINATION_DATA_SUCCESS: '[REFERRAL_SEARCH] Load data for search referral pagination',
  CLEAR_REFERRAL_DATA_GRID: '[REFERRAL_SEARCH] Clear referral search grid',
  LOAD_REFERRAL_GRID_IS_LOADING: '[REFERRAL_SEARCH] referrals data for grid is loading',
  LOAD_REFERRAL_GRID_FINISH_LOADING: '[REFERRAL_SEARCH] referrals data for grid finish loading',
  CLEAR_REFERRAL_NOTIFICATIONS: '[REFERRAL_SEARCH] clear notification flag for referral search',

  LOAD_REFERRAL_DATA_FOR_EXCEL_SUCCESS: '[REFERRAL_SEARCH] Load data to search referral excel export',
  LOAD_REFERRAL_DATA_FOR_EXCEL_FAIL: '[REFERRAL_SEARCH] Failed to load data for search referral excel export',
  LOAD_REFERRAL_EXCEL_IS_LOADING: '[REFERRAL_SEARCH] referrals data for excel file is loading',
  LOAD_REFERRAL_EXCEL_FINISH_LOADING: '[REFERRAL_SEARCH] referrals data for excel file finish loading',
  CLEAR_REFERRAL_EXCEL_DATA: '[REFERRAL_SEARCH] Clear referral search excel data',

  LOAD_LANGUAGES_SUCCESS: '[REFERRAL_EDIT] load languages for edit referral',
  LOAD_LANGUAGES_FAIL: '[REFERRAL_EDIT] fail load languages for edit referral',

  LOAD_RELATIONSHIPS_SUCCESS: '[REFERRAL_EDIT] load relationships for edit referral',
  LOAD_RELATIONSHIPS_FAIL: '[REFERRAL_EDIT] fail load relationships for edit referral',

  LOAD_OUTREACHCHANNEL_SUCCESS: '[REFERRAL_EDIT] load outreach channel for edit referral',
  LOAD_OUTREACHCHANNEL_FAIL: '[REFERRAL_EDIT] fail load outreach channel for edit referral',

  UPDATE_REFERRAL_SUCCESS: '[REFERRAL_EDIT] update referral success',
  UPDATE_REFERRAL_FAIL: '[REFERRAL_EDIT] update referral fail',
  UPDATE_REFERRAL_IS_LOADING: '[REFERRAL_EDIT] update referral is loading',
  UPDATE_REFERRAL_FINISH_LOADING: '[REFERRAL_EDIT] update referral is finish loading',
  UPDATE_REFERRAL_CLEAR_NOTIFICATIONS: '[REFERRAL_EDIT] clear notifications for update referral',
  UPDATE_REFERRAL_LOAD_ZIPCODE_ALT_SUCCESS: '[REFERRAL_EDIT] load zip codes by state id for edit referral',
  UPDATE_REFERRAL_LOAD_ZIPCODE_ALT_FAIL: '[REFERRAL_EDIT] load zip codes by state id for edit referral fail',
  UPDATE_REFERRAL_CLEAR_ZIPCODE_ALT_DATA: '[REFERRAL_EDIT] clear data for zip codes by state',
}