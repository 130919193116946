export default {
  columns: new Set([
    {
      dataName: 'membershipID',
      text: 'MembershipID',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'customerName',
      text: 'CustomerName',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'mcoContractName',
      text: 'McoContractName',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'departmentName',
      text: 'DepartmentName',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'firstName',
      text: 'FirstName',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'middleInitial',
      text: 'MiddleInitial',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'lastName',
      text: 'LastName',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'hicn',
      text: 'HICN',
      sortable: false,
      isSorted: 0,
      headerWidth: 80,
      isHeaderTruncated: true,
    },
    {
      dataName: 'mbi',
      text: 'MBI',
      sortable: false,
      isSorted: 0,
      headerWidth: 80,
      isHeaderTruncated: true,
    },
    {
      dataName: 'customerMemberId',
      text: 'Helath Plan ID',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'source',
      text: 'Source',
      sortable: false,
      isSorted: 0,
      headerWidth: 60,
      isHeaderTruncated: true,
    },
    {
      dataName: 'createdOnBehalfOfUserName',
      text: 'CratedOnBehalfOfUserName',
      sortable: false,
      isSorted: 0,
      headerWidth: 200,
      isHeaderTruncated: true,
    },
    {
      dataName: 'createdDate',
      text: 'CreatedDate',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'firstOutreachAttemptDate',
      text: 'First Outreach Attempt Date',
      sortable: false,
      isSorted: 0,
      headerWidth: 160,
      isHeaderTruncated: true,
    },
    {
      dataName: 'lastOutreachAttemptDate',
      text: 'Last Outreach Attempt Date',
      sortable: false,
      isSorted: 0,
      headerWidth: 160,
      isHeaderTruncated: true,
    },
    {
      dataName: 'callDispositionCode',
      text: 'Call Disposition Code',
      sortable: false,
      isSorted: 0,
      headerWidth: 140,
      isHeaderTruncated: true,
    },
    {
      dataName: 'mspStatus',
      text: 'MSP Status',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'mspType',
      text: 'MSP Type',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'mspStatusDate',
      text: 'MSP Status Date',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'clStatus',
      text: 'CL Status',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'clStatusDate',
      text: 'CL Status Date',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'lisStatus',
      text: 'LIS Status',
      sortable: false,
      isSorted: 0,
      headerWidth: 90,
      isHeaderTruncated: true,
    },
    {
      dataName: 'lisStatusDate',
      text: 'LIS Status Date',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'isLoaded',
      text: 'Is Loaded',
      sortable: false,
      isSorted: 0,
      headerWidth: 80,
      isHeaderTruncated: true,
    },
    {
      dataName: 'prospectType',
      text: 'Prospect Type',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'callAttemptsCount',
      text: 'Call Attempts Count',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'inMSPQueue',
      text: 'In MSP Queue',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'inLISQueue',
      text: 'In LIS Queue',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'inCLQueue',
      text: 'In CL Queue',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'inMedicaidQueue',
      text: 'In Medicaid Queue',
      sortable: false,
      isSorted: 0,
      headerWidth: 130,
      isHeaderTruncated: true,
    },
    {
      dataName: 'medicaidIndicator',
      text: 'Medicaid Indicator',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'partDLowIncomeIndicator',
      text: 'Part D Low Income Indicator',
      sortable: false,
      isSorted: 0,
      headerWidth: 180,
      isHeaderTruncated: true,
    },
    {
      dataName: 'migrationDate',
      text: 'Migration Date',
      sortable: false,
      isSorted: 0,
      headerWidth: 110,
      isHeaderTruncated: true,
    },
    {
      dataName: 'comments',
      text: 'Comments',
      sortable: false,
      isSorted: 0,
      headerWidth: 180,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram1Status',
      text: 'CL Program1 Status',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram1Name',
      text: 'Cl Program1 Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram1Sponsor',
      text: 'Cl Program1 Sponsor',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram1Date',
      text: 'Cl Program1 Date',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram2Status',
      text: 'CL Program2 Status',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram2Name',
      text: 'Cl Program2 Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram2Sponsor',
      text: 'Cl Program2 Sponsor',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram2Date',
      text: 'Cl Program2 Date',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram3Status',
      text: 'CL Program3 Status',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram3Name',
      text: 'Cl Program3 Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'clProgram3Sponsor',
      text: 'Cl Program3 Sponsor',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram3Date',
      text: 'Cl Program3 Date',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram4Status',
      text: 'CL Program4 Status',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram4Name',
      text: 'Cl Program4 Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram4Sponsor',
      text: 'Cl Program4 Sponsor',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram4Date',
      text: 'Cl Program4 Date',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram5Status',
      text: 'CLProgram5Status',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram5Name',
      text: 'Cl Program5 Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram5Sponsor',
      text: 'ClProgram5 Sponsor',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram5Date',
      text: 'Cl Program5 Date',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram6Status',
      text: 'CL Program6 Status',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram6Name',
      text: 'Cl Program6 Name',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram6Sponsor',
      text: 'Cl Program6 Sponsor',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    {
      dataName: 'gtProgram6Date',
      text: 'Cl Program6 Date',
      sortable: false,
      isSorted: 0,
      headerWidth: 120,
      isHeaderTruncated: true,
    },
    
  ]),
  selectedItemIds: new Set([]),
  selectionKey: 'id',
  domId: 'detail-pane',
  onSortTableColumn: () => {},
  onSelectAll: () => {},
  onLinkClick: () => {},
  onSettingsCancel: () => {},
  isConfigurable: true,
};

/*

CLProgram1Sponsor
CLProgram1Date
CLProgram2Status
CLProgram2Name
CLProgram2Sponsor
CLProgram2Date
CLProgram3Status
CLProgram3Name
CLProgram3Sponsor
CLProgram3Date
CLProgram4Status
CLProgram4Name
CLProgram4Sponsor
CLProgram4Date
CLProgram5Status
CLProgram5Name
CLProgram5Sponsor
CLProgram5Date
CLProgram6Status
CLProgram6Name
CLProgram6Sponsor
CLProgram6Date */
    
