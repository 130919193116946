export const types = {

  REF_EXCEPTION_LOAD_CUSTOMERS_LOADING : '[REF_EXCEPTION_LOAD_CUSTOMERS_LOADING] Load customer data for referral exception page',
  REF_EXCEPTION_LOAD_CUSTOMERS_SUCCESS : '[REF_EXCEPTION_LOAD_CUSTOMERS_SUCCESS]  Load customer data for referral exception page',
  REF_EXCEPTION_LOAD_CUSTOMERS_FAIL : '[REF_EXCEPTION_LOAD_CUSTOMERS_FAIL]  Load customer data for referral exception page',
  REF_EXCEPTION_LOAD_CUSTOMERS_FINISHED : '[REF_EXCEPTION_LOAD_CUSTOMERS_FINISHED]  Load customer data for referral exception page',

  REF_EXCEPTION_LOAD_DEPARTMENT_LOADING : '[REF_EXCEPTION_LOAD_DEPARTMENT_LOADING] Load department data for referral exception page',
  REF_EXCEPTION_LOAD_DEPARTMENT_SUCCESS : '[REF_EXCEPTION_LOAD_DEPARTMENT_SUCCESS] Load department data for referral exception page',
  REF_EXCEPTION_LOAD_DEPARTMENT_FAIL : '[REF_EXCEPTION_LOAD_DEPARTMENT_FAIL] Load department data for referral exception page',
  REF_EXCEPTION_CLEAR_DEPARTMENTS : '[REF_EXCEPTION_CLEAR_DEPARTMENTS] Load department data for referral exception page',
  REF_EXCEPTION_LOAD_DEPARTMENT_FINISHED : '[REF_EXCEPTION_LOAD_DEPARTMENT_FINISHED] Load department data for referral exception page',

  
  REF_EXCEPTION_SEARCH_RESULTS_LOADING : '[REF_EXCEPTION_SEARCH_RESULTS_LOADING] Load serch results for referral exception',
  REF_EXCEPTION_SEARCH_RESULTS_LOADING_SUCCESS : '[REF_EXCEPTION_SEARCH_RESULTS_LOADING_SUCCESS] Load serch results for referral exception',
  REF_EXCEPTION_SEARCH_RESULTS_LOADING_FAIL : '[REF_EXCEPTION_SEARCH_RESULTS_LOADING_FAIL] Load serch results for referral exception',
  REF_EXCEPTION_SEARCH_RESULTS_FINISHED : '[REF_EXCEPTION_SEARCH_RESULTS_FINISHED] Load serch results for referral exception',


  REF_EXCEPTION_EXPORT_ACTIVITY_REPORT : '[REF_EXCEPTION_EXPORT_ACTIVITY_REPORT] Export exception report',
  REF_EXCEPTION_EXPORT_ACTIVITY_REPORT_SUCCESS : '[REF_EXCEPTION_EXPORT_ACTIVITY_REPORT_SUCCESS] Export exception report',
  REF_EXCEPTION_EXPORT_ACTIVITY_REPORT_FAIL : '[REF_EXCEPTION_EXPORT_ACTIVITY_REPORT_FAIL] Export exception report',
  REF_EXCEPTION_EXPORT_ACTIVITY_REPORT_FINISHED: '[REF_EXCEPTION_EXPORT_ACTIVITY_REPORT_FINISHED] Export exception report',
  

  REF_EXCEPTION_CLEAR_LEAVE_PAGE : '[REF_EXCEPTION_CLEAR_LEAVE_PAGE]  Clear data for page leave',

};

export const SearchFilterTypes = { 
  SEARCH_FILTER_SET : 'SEARCH_FILTER_SET',
  SEARCH_FILTER_PAGE_NUMBER : 'SEARCH_FILTER_PAGE_NUMBER',
};

export const PaginationTypes = {
  PAGINATION_RESET : 'PAGINATION_RESET',
  PAGINATION_PAGE_NUMBER : 'PAGINATION_PAGE_NUMBER',
  PAGINATION_MOVE : 'PAGINATION_MOVE',
};

  