import { types } from "./types";
import { getLanguages, getOutreachChannels, getRelationshipsRelatives, getSearchReferral, getSearchReferralDataForExcel, getZipcodesByState, putReferral } from "./api";
import { excelExport } from "../../../../commonResources/helpers/exportExcel";
import { ExcelNameSearchReferral, headersExcelSearchReferral, headingExcelSearchReferral } from "../components/exportDataConfig";

export const referralSearchStartLoadingGrid = (params) => async dispatch => {
  dispatch({ type: types.LOAD_REFERRAL_GRID_IS_LOADING })
  dispatch({ type: types.CLEAR_REFERRAL_DATA_GRID });
  getSearchReferral(params).then(searchReferralData => {
    dispatch({
      type: types.LOAD_REFERRAL_DATA_GRID_SUCCESS,
      payload: searchReferralData.data
    });
    dispatch({
      type: types.LOAD_REFERRAL_PAGINATION_DATA_SUCCESS,
      payload: searchReferralData
    });
  }).catch(() => {
    dispatch({
      type: types.LOAD_REFERRAL_DATA_GRID_FAIL,
      payload: "Something went wrong while creating request data. Referral Search"
    })
  }).finally(() => {
    dispatch({ type: types.LOAD_REFERRAL_GRID_FINISH_LOADING })
  });
}

export const referralSearchStartLoadingDataforExcel = (params) => async dispatch => {
  dispatch({ type: types.LOAD_REFERRAL_EXCEL_IS_LOADING })
  dispatch({
    type: types.CLEAR_REFERRAL_EXCEL_DATA
  });
  getSearchReferralDataForExcel(params).then(searchReferralData => {
    dispatch({
      type: types.LOAD_REFERRAL_DATA_FOR_EXCEL_SUCCESS,
      payload: searchReferralData
    });
    excelExport(headingExcelSearchReferral, headersExcelSearchReferral, searchReferralData, ExcelNameSearchReferral);
  }).catch(() => {
    dispatch({
      type: types.LOAD_REFERRAL_DATA_FOR_EXCEL_FAIL,
      payload: "Something went wrong while creating request data."
    })
  }).finally(() => {
    dispatch({ type: types.LOAD_REFERRAL_EXCEL_FINISH_LOADING })
  });
}

export const clearSearchReferralState = () => dispatch => {
  dispatch({ type: types.CLEAR_REFERRAL_DATA_GRID })
};

export const clearSearchReferralNotifications = () => dispatch => {
  dispatch({ type: types.CLEAR_REFERRAL_NOTIFICATIONS })
};

// //////////////// EDIT REFERRAL /////////////////
export const loadLanguages = () => async dispatch => {
  // dispatch({ type: types.CREATE_REFERRAL_LOAD_LANGUAGE_IS_LOADING })
  getLanguages().then(languages => {
    // validate status code OK
    if (languages.statusCode !== 200) {
      dispatch({
        type: types.LOAD_LANGUAGES_FAIL,
        payload: "Something went wrong while creating request data. Load languages."
      })
      return;
    }
    // get preferred langiages
    const preferred = languages.data.filter(lang => {
      return (lang.id === 1 || lang.id === 2)
    });
    preferred.push({
      name: 'Other',
      id: 0
    })

    // get other languages
    const other = languages.data.filter(lang => {
      return (lang.id > 2)
    });

    dispatch({
      type: types.LOAD_LANGUAGES_SUCCESS,
      preferredLanguages: preferred.map(obj => ({
        label: obj.name,
        value: obj.id
      })),
      otherLanguages: other.map(obj => ({
        label: obj.name,
        value: obj.id
      }))
    })
  }).catch(() => {
    dispatch({
      type: types.LOAD_LANGUAGES_FAIL,
      payload: "Something went wrong while creating request data. Load languages."
    })
  }).finally(() => {
    // dispatch({ type: types.CREATE_REFERRAL_LOAD_LANGUAGE_FINISH_LOADING })
  });
}

export const loadRelationships = () => async dispatch => {
  // dispatch({ type: types.CREATE_REFERRAL_LOAD_RELATIONSHIP_IS_LOADING })

  getRelationshipsRelatives().then(relationships => {
    if (relationships.statusCode !== 200) {
      dispatch({
        type: types.LOAD_RELATIONSHIPS_FAIL,
        payload: "Something went wrong while creating request data. Load relationships."
      })
      return;
    }
    dispatch({
      type: types.LOAD_RELATIONSHIPS_SUCCESS,
      payload: relationships.data.map(obj => ({
        label: obj.name,
        value: obj.id
      }))
    })
  }).catch(() => {
    dispatch({
      type: types.LOAD_RELATIONSHIPS_FAIL,
      payload: "Something went wrong while creating request data. Load relationships."
    })
  }).finally(() => {
    // dispatch({ type: types.CREATE_REFERRAL_LOAD_RELATIONSHIP_FINISH_LOADING })
  });
}

export const loadOutreachChannels = () => async dispatch => {
  // dispatch({ type: types.CREATE_REFERRAL_LOAD_OUTREACHCHANNEL_IS_LOADING })

  getOutreachChannels().then(outreachchannels => {
    dispatch({
      type: types.LOAD_OUTREACHCHANNEL_SUCCESS,
      payload: outreachchannels.data.map(obj => ({
        label: obj.name,
        value: obj.id
      }))
    })
  }).catch(() => {
    dispatch({
      type: types.LOAD_OUTREACHCHANNEL_FAIL,
      payload: "Something went wrong while creating request data. Load outreach channels."
    })
  }).finally(() => {
    // dispatch({ type: types.CREATE_REFERRAL_LOAD_OUTREACHCHANNEL_FINISH_LOADING })
  });
}

export const updateReferral = (data) => async dispatch => {
  dispatch({ type: types.UPDATE_REFERRAL_IS_LOADING })
  putReferral(data).then(resp => {
    if (resp.statusCode !== 200) {
      dispatch({ type: types.UPDATE_REFERRAL_FAIL, payload: 'Something went wrong while fetching data. Update Referral.', updateErrorMsg: resp.message })
    } else {
      dispatch({
        type: types.UPDATE_REFERRAL_SUCCESS,
        payload: resp
      })
    }
  }).catch(() => {
    dispatch({ type: types.UPDATE_REFERRAL_FAIL, payload: 'Something went wrong while fetching data. Update Referral.' })
  }).finally(() => {
    dispatch({ type: types.UPDATE_REFERRAL_FINISH_LOADING })
  });
};

export const loadZipcodeByStateAlt = (state) => async dispatch => {
  getZipcodesByState(state).then(zipcodes => {
    dispatch({
      type: types.UPDATE_REFERRAL_LOAD_ZIPCODE_ALT_SUCCESS,
      payload: zipcodes.map(obj => ({
        label: obj.state,
        value: obj.zip
      }))
    })
  }).catch(() => {
    dispatch({
      type: types.UPDATE_REFERRAL_LOAD_ZIPCODE_ALT_FAIL,
      payload: "Something went wrong while creating request data."
    })
  });
}

export const clearZipcodeAltData = () => async dispatch => {
  dispatch({
    type: types.UPDATE_REFERRAL_CLEAR_ZIPCODE_ALT_DATA
  })
}

export const updateReferralClearNotification = () => dispatch => {
  dispatch({
    type: types.UPDATE_REFERRAL_CLEAR_NOTIFICATIONS
  })
};