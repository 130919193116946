import { types }  from './types';
import { defaultOptions } from './constants';

const initialState = {
  itemsByIds: {},
  ids: [],
  pages: [],
  totalPages: 0,
  totalRecords: 0,
  customers: [],
  departments: [defaultOptions.departments],
  mcoContracts: [defaultOptions.mcoContracts],
  errorMessage : '',
  isLoading: false,
  selectedCustomer : { label : 'All', value : 0 },
};


export const referralCaseReducer = (state = initialState, action = {}) => {

  switch (action.type) {

    case types.EXPORT_CASE_REPORT: {
      return {
        ...state,
        isLoading: true,
        errorMessage : '',
      }
    }

    case types.EXPORT_CASE_REPORT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        errorMessage : '',
      }
    }

    case types.EXPORT_CASE_REPORT_FAIL: {
      return {
        ...state,
        isLoading: false,
        errorMessage : action.errorMessage,
      }
    }

    case types.RESET_DEPARTMENTS: {
      return {
        ...state,
        departments: [defaultOptions.departments],
      }
    }

    case types.RESET_MCOCONTRACTS: {
      return {
        ...state,
        mcoContracts: [defaultOptions.mcoContracts],
      }
    }

    case types.LOAD_CASE_REPORT: {
      return {
        ...state,
        isLoading: true,
        errorMessage : '',
      }
    }

    case types.LOAD_CASE_REPORT_SUCCESS: {

      const { paginatedData } = action;
      const {totalPages, totalRecords, data} = paginatedData;
      const ids = data.map(x => x.membershipID);
      const pages = [];
     
      for(let i = 1; i <= totalPages; i += 1){
        pages.push(i);
      }

    
      const itemsByIds = data.reduce((prev, current) => (
        {
          ...prev, 
          [current.membershipID]: {
            ...current,
          }
        }
      ), {});

      return {
        ...state,
        isLoading: false,
        ids,
        itemsByIds,
        totalPages,
        totalRecords,
        pages
      }
      
    }

    case types.LOAD_CASE_REPORT_FAIL: {
      return {
        ...state,
        isLoading: false,
        ids: [],
        itemsByIds: {},
        errorMessage : action.errorMessage,
      }
    }

    case types.LOAD_MCOCONTRACTS: {
      return {
        ...state,
        isLoading: true,
        errorMessage : '',
      }
    }

    case types.LOAD_MCOCONTRACTS_SUCCESS: {

      const mcoContracts = action.mcoContracts.map(x => ({ label: x.name, value: x.id.toString() }));
      mcoContracts.unshift({ label: 'All', value: '' });
     
      return {
        ...state,
        isLoading: false,
        mcoContracts,
      }
      
    }

    case types.LOAD_MCOCONTRACTS_FAIL: {
      return {
        ...state,
        isLoading: false,
        errorMessage : action.errorMessage,
      }
    }


    case types.LOAD_DEPARTMENTS: {
      return {
        ...state,
        isLoading: true,
        errorMessage : '',
      }
    }

    case types.LOAD_DEPARTMENTS_SUCCESS: {

      const departments = action.departments.map(x => ({ label: x.name, value: x.id.toString() }));

      departments.unshift({ label: 'All', value: 0  });

      return {
        ...state,
        isLoading: false,
        departments,
      }
    }

    case types.LOAD_DEPARTMENTS_FAIL: {
      return {
        ...state,
        isLoading: false,
        errorMessage : action.errorMessage,
      }
    }

    case types.LOAD_CUSTOMERS: {
      return {
        ...state,
        isLoading: true,
        errorMessage : '',
      }
    }

    case types.LOAD_CUSTOMERS_SUCCESS: {

      const { userInformationCustomerId } = action;

      let selectedCustomer = { label : 'All', value : 0 };
      let customers = action.customers.map(x => ({ label: x.name, value: x.id.toString() }));
      
      if(userInformationCustomerId > 0){
        customers = customers.filter(f => f.value === userInformationCustomerId.toString())
        selectedCustomer = customers[0];
      }
        
      if(userInformationCustomerId === 0)
        customers.unshift({ label: 'All', value: 0 });

      return {
        ...state,
        isLoading: false,
        selectedCustomer,
        customers,
      }
    }

    case types.LOAD_CUSTOMERS_FAIL: {
      return {
        ...state,
        isLoading: false,
        errorMessage : action.errorMessage,
      }
    }
   
    default:
      return state
  }

};

export default referralCaseReducer;