/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import appReducer from './containers/PageWrapper/modules/reducer';
import usersReducer from './containers/Paradigms/FormFlow/modules/reducers';
import { searchReferralReducer } from './containers/Referral/SearchReferral/modules/reducers';
import { createReferralReducer } from './containers/Referral/CreateReferral/modules/reducers';
import { searchUsersReducer } from './containers/Users/SearchUser/modules/reducers';
import { referralCaseReducer } from './containers/Reports/ReferralCase/modules/reducers';
import { commonReducer } from "./commonResources/commonModules/reducers";
import { rolesReducer } from './containers/Administration/Roles/modules/reducers';
import { referralByProductReducer } from './containers/Reports/ReferralByProduct/modules/reducers';
import { departmentsReducer } from './containers/Administration/Departments/modules/reducers';
import { customersReducer } from './containers/Administration/Customers/modules/reducer';
import { dashboardReducer } from './containers/HomePage/Dashboard/modules/reducers';
import { referralOutreachReducer } from './containers/Reports/ReferralOutreach/modules/reducers';
import { referralExceptionReducer } from './containers/Reports/ReferralException/modules/reducers';
import { createUserReducer } from './containers/Users/CreateUser/modules/reducers';
import { referralBulkReducer } from './containers/Reports/ReferralBulk/modules/reducers';

/**
 * Creates the main reducer with the dynamically injected ones
 */

export default function createReducer(injectedReducers = {}, history = null) {
  return combineReducers({
    router: connectRouter(history),
    app: appReducer,
    users: usersReducer,
    searchReferral: searchReferralReducer,
    createReferral: createReferralReducer,
    searchUsers: searchUsersReducer,
    createUser: createUserReducer,
    referralCase: referralCaseReducer,
    common: commonReducer,
    roles: rolesReducer,
    referralByProduct: referralByProductReducer,
    departments: departmentsReducer,
    customers: customersReducer,
    dashboard: dashboardReducer,
    referralOutreach: referralOutreachReducer,
    referralException: referralExceptionReducer,
    referralBulk: referralBulkReducer,
    ...injectedReducers,
  });
}
