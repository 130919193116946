import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Modal } from '@uicl/ui-core/dist/Modal';
import { logout } from '../../commonResources/services/auth-management';

const Container = styled.div`
  padding: 2rem;
`;

export const Logout = () => {
  useEffect(() => {
    // put other state cleanup stuff here
    setTimeout(logout, 1000);
  }, []);

  return (
    <Modal domID="no-auth" isOpen showDefaultClose={false}>
      <Container>You are being logged out</Container>
    </Modal>
  );
};

export default Logout;
