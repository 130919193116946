import { types } from "./types";
import { getRoleAssociation, getRoles, getRoleTypes, getRules, postRole, putRole } from "./api";

export const loadRoles = (params) => async dispatch => {
  dispatch({ type: types.LOAD_ROLES_IS_LOADING })
  getRoles(params).then(roles => {
    dispatch({ type: types.LOAD_ROLES_SUCCESS, payload: roles.data })
    dispatch({
      type: types.LOAD_ROLES_PAGINATION_DATA_SUCCESS,
      payload: roles
    });
  }).catch(() => {
    dispatch({ type: types.LOAD_ROLES_FAIL, errorMessage: 'Something went wrong while fetching data.' })
  }).finally(() => {
    dispatch({ type: types.LOAD_ROLES_FINISH_LOADING })
  });
};

export const loadRules = () => async dispatch => {
  dispatch({ type: types.LOAD_RULES_IS_LOADING })
  getRules().then(rules => {
    dispatch({ type: types.LOAD_RULES_SUCCESS, payload: rules })
  }).catch(() => {
    dispatch({ type: types.LOAD_RULES_FAIL, errorMessage: 'Something went wrong while fetching data.' })
  }).finally(() => {
    dispatch({ type: types.LOAD_RULES_FINISH_LOADING })
  });
};

export const loadRoleTypes = () => async dispatch => {
  getRoleTypes().then(rolesTypes => {
    if (rolesTypes.statusCode !== 200) {
      return
    }
    dispatch(
      {
        type: types.LOAD_ROLES_TYPES_SUCCESS,
        payload: rolesTypes.data.map(obj => ({
          label: obj.roleTypeName,
          value: obj.id
        }))
      })
  }).catch(() => {
    dispatch({ type: types.LOAD_ROLES_TYPES_FAIL, errorMessage: 'Something went wrong while fetching data.' })
  });
};

export const loadRoleAssociation = () => async dispatch => {
  getRoleAssociation().then(rolesAssociation => {
    if (rolesAssociation.statusCode !== 200) {
      return
    }
    const rolesAssociationFiltered = rolesAssociation.data.filter(f => f.id !== 3);
    dispatch(
      {
        type: types.LOAD_ROLES_ASSOCIATION_SUCCESS,
        payload: rolesAssociationFiltered.map(obj => ({
          label: obj.roleAssociationName,
          value: obj.id
        }))
      })
  }).catch(() => {
    dispatch({ type: types.LOAD_ROLES_ASSOCIATION_FAIL, errorMessage: 'Something went wrong while fetching data.' })
  });
};

export const saveRole = (data) => async dispatch => {
  dispatch({ type: types.SAVE_ROLE_IS_LOADING })
  postRole(data).then(resp => {
    dispatch({
      type: types.CREATE_ROLE_RESPONSE,
      payload: resp
    })
  }).catch(() => {
    dispatch({ type: types.LOAD_ROLES_ASSOCIATION_FAIL, errorMessage: 'Something went wrong while fetching data.' })
  }).finally(() => {
    dispatch({ type: types.SAVE_ROLE_FINISH_LOADING })
  });
};

export const updateRole = (data) => async dispatch => {
  dispatch({ type: types.UPDATE_ROLE_IS_LOADING })
  putRole(data).then(resp => {
    dispatch({
      type: types.UPDATE_ROLE_RESPONSE,
      payload: resp
    })
  }).catch(() => {
    dispatch({ type: types.LOAD_ROLES_ASSOCIATION_FAIL, errorMessage: 'Something went wrong while fetching data.' })
  }).finally(() => {
    dispatch({ type: types.UPDATE_ROLE_FINISH_LOADING })
  });
};

export const clearRolesReducer = () => dispatch => {
  dispatch({
    type: types.CLEAR_ROLES_REDUCER
  })
} 