import { fetchWithToken } from "../../../../commonResources/helpers/fetch";

export const getSearchReferral = async (params) => (
  fetchWithToken(`referral/search?${new URLSearchParams(params)}`).then(res => res.json()).then(json => json)
);

export const getSearchReferralDataForExcel = async (params) => (
  fetchWithToken(`referral/search?${new URLSearchParams(params)}`).then(res => res.json()).then(json => json.data)
);

export const getLanguages = async () => (
  fetchWithToken('Languages/items').then(res => res.json()).then(json => json));

export const getRelationshipsRelatives = async () => (
  fetchWithToken('Relationships/alternatives').then(res => res.json()).then(json => json));

export const getOutreachChannels = async () => (
  fetchWithToken('OutreachChannels/referrals').then(res => res.json()).then(json => json));

export const putReferral = async (params) => (
  fetchWithToken('Referral/updateReferral', params, 'PUT').then(resp => resp.json()))

export const getZipcodesByState = async (stateId) => (
  fetchWithToken(`States/zipcode/state/${stateId}/items`).then(res => res.json()).then(json => json.data))