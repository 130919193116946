export const headingExcelReferralActivityReport = [
  [
    'Customer Name',
    'Mco Contract Name',
    'Department Name',
    'Potentially Eligible',
    'In Progress',
    'Pending',
    'Referred',
    'Approved',
    'Completed',
    'Submitted',
    'Not Qualified Assets',
    'Not Qualified Income',
    'Total',
  ],
];

export const headersExcelReferralActivityReport = [
  'customerName',
  'mcoContractName',
  'departmentName',
  'potentiallyEligible',
  'inProgress',
  'pending',
  'referred',
  'approved',
  'completed',
  'submitted',
  'notQualifiedAssets',
  'notQualifiedIncome',
  'total',
];

export const excelNameReferralActivityReport = 'Referral Activity Report';