export const NAV_MENU_ITEMS = [
  // [
  //   {
  //     id: 1,
  //     label: 'Home',
  //     path: '/',
  //   },
  //   {
  //     id: 8,
  //     label: 'Account',
  //     path: '/account/change-password',
  //   },
  //   {
  //     id: 9,
  //     label: 'Referral',
  //     path: '/referral/index',
  //   },
  //   {
  //     id: 10,
  //     label: 'Users',
  //     path: '/users/create-user',
  //   },
  //   {
  //     id: 11,
  //     label: 'Reports',
  //     path: '/reports/corporate-user',
  //   },
  //   {
  //     id: 12,
  //     label: 'Administration',
  //     path: '/administration/customers',
  //   },
  // ],
];

export const DEMO_USER = {
  sub: 'test1.dffkj@org1.com',
  aud: 'https://sdfkjldfskjl.chhc.io',
  jti: 'RgGcjg6ZJonYXnR24wjoIa',
  iss: 'https://sso-test.changehealthcare.com',
  iat: 1534264941,
  exp: 1534266741,
  phone_numbers: {
    number: '',
    type: 'work',
  },
  roles: 'User',
  photo: 'https://media.forgecdn.net/avatars/124/768/636424778749237239.jpeg',
  company: {
    name: 'Florida Blue',
    id: 'be5bf4cb-ddd6-4532-8e4f-a3f2df847650',
  },
  given_name: 'Test1',
  uuid: '716a2271-12d9-4007-8054-6bcabb4c7052',
  family_name: 'User1',
  email: 'test1.dffkj@org1.com',
  apps: [
    {
      name: 'Boilerplate',
      id: '7c7-4ff9-b4a8-8e0a00354d8a',
      uri: 'https://sdfkjldfskjl.chhc.io',
    },
    {
      name: 'Other App',
      id: '7c7-4ff9-b4a8-8e0a0',
      uri: 'https://otherapp9394.chhc.io',
    },
    {
      name: 'Third App',
      id: '7c7-4ff9-b4a8-8e0a0',
      uri: 'https://thirdapp34984.chhc.io',
    },
  ],
};

export const SUPPORT_MENU_ITEMS = [
  {
    id: 0,
    label: 'MenuItem1',
    path: '/',
  },
  {
    id: 1,
    label: 'MenuItem2',
    path: '/',
  },
  {
    id: 2,
    label: 'MenuItem3',
    path: '/',
  },
];

export const AVATAR_MENU_ITEMS = [
  [
    {
      id: 0,
      label: 'My Profile',
      path: '/',
    },
    {
      id: 1,
      label: 'My Views',
      path: '/',
    },
  ],
  [
    {
      id: 2,
      label: 'Log Out',
      path: '/logout',
    },
  ],
  [
    {
      id: 3,
      label: 'Altegra Health Plan',
      path: '/',
    },
    {
      id: 4,
      label: 'Lorem Ipsum Plan',
      path: '/',
    },
  ],
];

export const SINGLE_SELECT_GROUP_ITEMS = {
  items: [
    {
      text: 'Single Select Group Label',
      items: [
        {
          text: 'Sub-Title One',
          items: [
            'item one',
            {
              text: 'item two',
              count: 4,
              tooltipText: 'Example Tooltip',
              tooltipWidth: 200,
            },
          ],
        },
      ],
    },
  ],
  onOpen: () => false,
  onSelect: () => false,
  domID: 'single-select-group',
};

export const MULTI_SELECT_GROUP_ITEMS = {
  items: [
    {
      id: 'one',
      text: 'Multi-Select Group Label',
      items: [
        {
          id: 'two',
          text: 'Multi-Select Item',
          count: 2,
        },
        {
          id: 'three',
          text: 'Multi-Select Group Label',
          items: [
            {
              id: 'four',
              text: 'Multi-Select Item',
            },
            {
              id: 'five',
              text: 'Multi-Select Item',
            },
          ],
        },
      ],
    },
  ],
  domID: 'multi-select-group',
};

export const DEFAULT_LOGO_REDIRECT_PATH = '/';

export const ON_PRODUCT_MENU_SELECT =
  'app/containers/PageWrapper/ON_PRODUCT_MENU_SELECT';
export const ON_AVATAR_MENU_SELECT =
  'app/containers/PageWrapper/ON_AVATAR_MENU_SELECT';
export const ON_NAV_MENU_SELECT =
  'app/containers/PageWrapper/ON_NAV_MENU_SELECT';
export const ON_MULTI_GROUP_OPEN =
  'app/containers/PageWrapper/ON_MULTI_GROUP_OPEN';
export const ON_MULTI_GROUP_CHECK =
  'app/containers/PageWrapper/ON_MULTI_GROUP_CHECK';
export const ON_SINGLE_GROUP_SELECT =
  'app/view/PageWrapper/ON_SINGLE_GROUP_SELECT';
export const ON_SINGLE_GROUP_OPEN = 'app/view/PageWrapper/ON_SINGLE_GROUP_OPEN';

export const USER_SET = 'app/containers/PageWrapper/USER_SET';

export const LOAD_USER_INFORMATION_SUCCESS = 'app/containers/PageWrapper/LOAD_USER_INFORMATION_SUCCESS';
export const LOAD_MENU_ITEMS = 'app/containers/PageWrapper/LOAD_MENU_ITEMS';
export const LOAD_USER_INFORMATION_FAIL = 'app/containers/PageWrapper/LOAD_USER_INFORMATION_FAIL';
export const LOAD_USER_INFORMATION_IS_LOADING = 'app/containers/PageWrapper/LOAD_USER_INFORMATION_IS_LOADING';
export const LOAD_USER_INFORMATION_FINISH_LOADING = 'app/containers/PageWrapper/LOAD_USER_INFORMATION_FINISH_LOADING';

export const TOGGLE_AUTH_MODAL_OPEN =
  'app/containers/PageWrapper/TOGGLE_AUTH_MODAL_OPEN';

export const ON_INITIAL_NOTIFICATIONS =
  'app/containers/PageWrapper/ON_INITIAL_NOTIFICATIONS';
export const ON_UPDATE_NOTIFICATIONS =
  'app/containers/PageWrapper/ON_UPDATE_NOTIFICATIONS';
export const ON_UNPROCESSED_NOTIFICATIONS =
  'app/containers/PageWrapper/ON_UNPROCESSED_NOTIFICATIONS';
export const MARK_NOTIFICATIONS_READ =
  'app/containers/PageWrapper/MARK_NOTIFICATIONS_READ';

export const APP_ID = 'TEST';
