export const types = {
  REF_BY_PRODUCT_LOAD_PRODUCTS_LOADING : '[REF_BY_PRODUCT_LOAD_PRODUCTS_LOADING] Load products data for referral by product page',
  REF_BY_PRODUCT_LOAD_PRODUCTS_SUCCESS : '[REF_BY_PRODUCT_LOAD_PRODUCTS_SUCCESS] Load products data for referral by product page',
  REF_BY_PRODUCT_LOAD_PRODUCTS_FAIL : '[REF_BY_PRODUCT_LOAD_PRODUCTS_FAIL] Load products data for referral by product page',
  REF_BY_PRODUCT_LOAD_PRODUCTS_FINISHED : '[REF_BY_PRODUCT_LOAD_PRODUCTS_FINISHED] Load products data for referral by product page',

  REF_BY_PRODUCT_LOAD_CUSTOMERS_LOADING : '[REF_BY_PRODUCT_LOAD_CUSTOMERS_LOADING] Load customer data for referral by product page',
  REF_BY_PRODUCT_LOAD_CUSTOMERS_SUCCESS : '[REF_BY_PRODUCT_LOAD_CUSTOMERS_SUCCESS] Load customer data for referral by product page',
  REF_BY_PRODUCT_LOAD_CUSTOMERS_FAIL : '[REF_BY_PRODUCT_LOAD_CUSTOMERS_FAIL] Load customer data for referral by product page',
  REF_BY_PRODUCT_LOAD_CUSTOMERS_FINISHED : '[REF_BY_PRODUCT_LOAD_CUSTOMERS_FINISHED] Load products data for referral by product page',

  REF_BY_PRODUCT_LOAD_DEPARTMENT_LOADING : '[REF_BY_PRODUCT_LOAD_DEPARTMENT_LOADING] Load department data for referral by product page',
  REF_BY_PRODUCT_LOAD_DEPARTMENT_SUCCESS : '[REF_BY_PRODUCT_LOAD_DEPARTMENT_SUCCESS] Load department data for referral by product page',
  REF_BY_PRODUCT_LOAD_DEPARTMENT_FAIL : '[REF_BY_PRODUCT_LOAD_DEPARTMENT_FAIL] Load department data for referral by product page',
  REF_BY_PRODUCT_CLEAR_DEPARTMENTS : '[REF_BY_PRODUCT_CLEAR_DEPARTMENTS] Load data for referral by product page',
  REF_BY_PRODUCT_LOAD_DEPARTMENT_FINISHED : '[REF_BY_PRODUCT_LOAD_DEPARTMENT_FINISHED] Load products data for referral by product page',

  REF_BY_PRODUCT_SEARCH_RESULTS_LOADING : '[REF_BY_PRODUCT_SEARCH_RESULTS_LOADING] Load serch results for referral by product page',
  REF_BY_PRODUCT_SEARCH_RESULTS_LOADING_SUCCESS : '[REF_BY_PRODUCT_SEARCH_RESULTS_LOADING_SUCCESS] Load serch results for referral by product page',
  REF_BY_PRODUCT_SEARCH_RESULTS_LOADING_FAIL : '[REF_BY_PRODUCT_SEARCH_RESULTS_LOADING_FAIL] Load serch results for referral by product page',
  REF_BY_PRODUCT_SEARCH_RESULTS_FINISHED : '[REF_BY_PRODUCT_SEARCH_RESULTS_FINISHED] Load products data for referral by product page',

  REF_BY_PRODUCT_CLEAR_LEAVE_PAGE : '[REF_BY_PRODUCT_CLEAR_LEAVE_PAGE] Clear state leave page',

  REF_BY_PRODUCT_EXPORT_ACTIVITY_REPORT : '[REF_BY_PRODUCT_EXPORT_ACTIVITY_REPORT] Export activity report',
  REF_BY_PRODUCT_EXPORT_ACTIVITY_REPORT_SUCCESS : '[REF_BY_PRODUCT_EXPORT_ACTIVITY_REPORT_SUCCESS] Export activity report',
  REF_BY_PRODUCT_EXPORT_ACTIVITY_REPORT_FAIL : '[REF_BY_PRODUCT_EXPORT_ACTIVITY_REPORT_FAIL] Export activity report',
  REF_BY_PRODUCT_EXPORT_ACTIVITY_REPORT_FINISHED: '[REF_BY_PRODUCT_EXPORT_ACTIVITY_REPORT_FINISHED] Export activity report',
  
};

export const SearchFilterTypes = { 
  SEARCH_FILTER_SET : 'SEARCH_FILTER_SET',
  SEARCH_FILTER_PAGE_NUMBER : 'SEARCH_FILTER_PAGE_NUMBER',
};

export const PaginationTypes = {
  PAGINATION_RESET : 'PAGINATION_RESET',
  PAGINATION_PAGE_NUMBER : 'PAGINATION_PAGE_NUMBER',
  PAGINATION_MOVE : 'PAGINATION_MOVE',
};


