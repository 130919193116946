export default {
  columns: new Set([
    {
      dataName: 'roleName',
      text: 'Role',
      sortable: true,
    },
    {
      dataName: 'roleTypeName',
      text: 'Role Type',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'roles-grid',
  onSortTableColumn: () => { },
  onSelectAll: () => { },
  onLinkClick: () => { },
  onSettingsCancel: () => { },
  isConfigurable: true,
  isFixedHeader: true,
};
