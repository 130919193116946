import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid, ListPaneHeader } from '@uicl/ui-core';
import useLayout from '@uicl/ui-core/dist/useLayout';
import CircleCheck from '@uicl/ui-core/dist/CircleCheck';
import LoadingPage from "../../../../components/LoadingPage";
import { FilterReferrals } from './FilterReferrals';
import { clearSearchReferralState } from '../modules/actions';
import SearchReferralGridConfig from './SearchReferralGridConfig';
import Pagination from '../../../../components/Pagination';
import '../styles/styles.css'

const SearchReferral = () => {

  const {
    openDetailPane,
    detailProps: selectedRecord,
    expandCollapseListPaneButtonConfig,
  } = useLayout();

  const dispatch = useDispatch();

  const { searchReferralGridData, searchReferralPaginationData, searchGridIsLoading, searchExcelDataIsLoading } = useSelector(state => state.searchReferral);

  const [initPage, setInitPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [pages, setPages] = useState([]);

  const { pageNumber, pageSize, totalPages, totalRecords } = searchReferralPaginationData;

  const movePagesForward = () => {
    setInitPage(initPage + 10);
    setEndPage(endPage + 10);
  };
  const movePagesBackward = () => {
    setInitPage(initPage - 10);
    setEndPage(endPage - 10);
  };

  const onGridPag = (page) => {
    setActivePage(page);
  };

  useEffect(() => {
    const pags = [];
    for (let i = 1; i <= totalPages; i += 1) {
      pags.push(i);
    }
    setPages(pags);
  }, [searchReferralPaginationData]);

  useEffect( () => () => dispatch(clearSearchReferralState()), [] );

  const headerProps = {
    title: 'Search Referral',
    icon: CircleCheck,
    description: 'This is short description',
    layoutControls: [expandCollapseListPaneButtonConfig]
  };

  return (
    <>
      <ListPaneHeader {...headerProps} />
      <FilterReferrals page={activePage} setActivePage={setActivePage} setInitPage={setInitPage} setEndPage={setEndPage} />
      <>
        {
          searchGridIsLoading || searchExcelDataIsLoading
            ?
            <LoadingPage />
            :
            <Grid
              {...SearchReferralGridConfig}
              records={searchReferralGridData}
              dataTestId="test-formGrid"
              onClickThrough={(e, record) => {
                openDetailPane(record, e);
              }}
              focusedItem={selectedRecord}
            />
        }
      </>
      <>
        {
          searchReferralGridData.length !== 0 &&
          <Pagination
            totalRecords={totalRecords}
            pageNumber={pageNumber}
            pageSize={pageSize}
            pages={pages}
            totalPages={totalPages}
            activePage={activePage}
            initPage={initPage}
            endPage={endPage}
            onGridPag={onGridPag}
            movePagesForward={movePagesForward}
            movePagesBackward={movePagesBackward} />
        }
      </>
    </>
  )
}

export default SearchReferral