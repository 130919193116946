import { types } from "./types";

const initialState = {
  customers: [],
  customersError: '',
  states: [],
  customerIsLoading: false,
  statesIsLoading: false,
  statesError: '',
}

export const commonReducer = (state = initialState, action) => {

  switch (action.type) {

    case types.LOAD_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
      }
    case types.LOAD_CUSTOMERS_IS_LOADING:
      return {
        ...state,
        customerIsLoading: true
      }

    case types.LOAD_CUSTOMERS_FINISH_LOADING:
      return {
        ...state,
        customerIsLoading: false
      }

    case types.LOAD_CUSTOMERS_FAIL:
      return {
        ...state,
        customersError: action.payload
      }

    case types.LOAD_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload
      }

    case types.LOAD_STATES_IS_LOADING:
      return {
        ...state,
        statesIsLoading: true
      }

    case types.LOAD_STATES_FINISH_LOADING:
      return {
        ...state,
        statesIsLoading: false
      }

    case types.LOAD_STATES_FAIL:
      return {
        ...state,
        statesError: action.payload
      }

    default:
      return state;
  }

}
