import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@uicl/ui-core/dist/Grid';
import CircleCheck from '@uicl/ui-core/dist/CircleCheck';
import ListPaneHeader from '@uicl/ui-core/dist/ListPaneHeader';
import useLayout from '@uicl/ui-core/dist/useLayout';
import FilterDepartments from './FilterDepartments';
import CreateDepartment from './CreateDepartment';
import LoadingPage from '../../../../components/LoadingPage';
import Pagination from '../../../../components/Pagination';
import { loadDepartments } from '../modules/actions';


import DepartmentsLoadGridWrapper from './styled/DepartmentsLoadGridWrapper';

const filterDepartmentRequest = {
  customerId: 0,
  pageNumber: 1,
  pageSize: 10,
  loadGrid: true,
  sortingName: 'name',
  sortingOrder: 'SORT_ASCENDING',
};

const pageIndexes = 10;
// eslint-disable-next-line
const SearchDepartments = () => {

  const dispatch = useDispatch();

  const {
    openDetailPane,
    detailProps: selectedRecord,
    expandCollapseListPaneButtonConfig,
  } = useLayout();

  const { ids: departmentIds, itemsByIds: departmentById, totalRecords, pages = [], totalPages, isLoading, departmentResponseIsSuccessStatusCode, selectedCustomer, departmentEditResponseIsSuccessStatusCode } = useSelector(state => state.departments);

  const { userInformation } = useSelector(state => state.app);

  const departmentGridConfig = {
    columns: new Set([
      {
        dataName: 'name',
        text: 'Name',
        sortable: true,
        isSorted: 0,
      },
      {
        dataName: 'customerName',
        text: ' Customer',
        sortable: true,
        isSorted: 0,
      },
      {
        dataName: 'isActive',
        text: 'Is Active',
        sortable: true,
        isSorted: 0,
      },
      {
        dataName: 'createdDate',
        text: 'Created Date',
        sortable: true,
        isSorted: 0,
      },
      {
        dataName: 'lastUpdateDate',
        text: 'Last Update Date',
        sortable: true,
        isSorted: 0,
      },
      {
        dataName: 'lastUpdateBy',
        text: 'Last Update By',
        sortable: true,
        isSorted: 0,
      },
    ]),
    selectedItemIds: new Set([]),
    selectionKey: 'id',
    domId: 'detail-pane',
    onSortGridColumn: (e, r) => {

      const sortingKey = r.sortingKey;
      const sortingName = sortingKey.split('|')[0] !== '' ? sortingKey.split('|')[0] : 'name';
      let sortingOrder = 'SORT_ASCENDING';

      if (selectedSortingName !== sortingName) {
        sortingOrder = 'SORT_ASCENDING';
      }
      else if (selectedSortingName === sortingName && selectedSortingOrder === 'SORT_DESCENDING') {
        sortingOrder = 'SORT_ASCENDING';
      } else if (selectedSortingName === sortingName && selectedSortingOrder === 'SORT_ASCENDING') {
        sortingOrder = 'SORT_DESCENDING';
      }

      setFilterDepartment({ ...filterDepartment, customerId: filteredCustomerId ?? 0, pageNumber: activePage, sortingName, sortingOrder, loadGrid: true })
      setSelectedSortingName(sortingName);
      setSelectedSortingOrder(sortingOrder);

    },
    onSelectAll: () => { },
    onLinkClick: () => { },
    onSettingsCancel: () => { },
    isConfigurable: true,
  };

  const [createDepartment, setCreateDepartment] = useState(false);
  const [filterDepartment, setFilterDepartment] = useState(filterDepartmentRequest);
  const [initPage, setInitPage] = useState(0);
  const [endPage, setEndPage] = useState(pageIndexes);
  const [activePage, setActivePage] = useState(1);
  const [filteredCustomerId, setFilteredCustomerId] = useState();
  const [customer, setCustomer] = useState();
  const [selectedSortingName, setSelectedSortingName] = useState(filterDepartmentRequest.sortingName);
  const [selectedSortingOrder, setSelectedSortingOrder] = useState(filterDepartmentRequest.sortingOrder);
  const departments = departmentIds.map((x) => departmentById[x]);

  useEffect(() => {
    dispatch(loadDepartments(filterDepartment));
  }, [filterDepartment]);

  useEffect(() => {
    if (departmentResponseIsSuccessStatusCode || departmentEditResponseIsSuccessStatusCode) {
      setFilterDepartment({ ...filterDepartment, customerId: selectedCustomer? selectedCustomer.value : 0, pageNumber: 1, pageSize: 10, loadGrid: true })
      dispatch(loadDepartments(filterDepartment));
    }

  }, [departmentResponseIsSuccessStatusCode,departmentEditResponseIsSuccessStatusCode ]);

  const onClickRecord = (e, record) => {
    openDetailPane(record, e);
  };

  const movePagesForward = () => {
    setInitPage(initPage + pageIndexes);
    setEndPage(endPage + pageIndexes);
  };
  const movePagesBackward = () => {
    setInitPage(initPage - pageIndexes);
    setEndPage(endPage - pageIndexes);
  };

  const onGridPag = (pageNumber) => {
    setActivePage(pageNumber);
    setFilterDepartment({ ...filterDepartment, pageNumber });
  };

  const onFilterSubmit = (customerId) => {
    setInitPage(0);
    setEndPage(pageIndexes);
    setActivePage(1);
    setFilteredCustomerId(customerId);
    setFilterDepartment({ customerId: customerId>0 ? customerId : 0, pageNumber: 1, pageSize: 10, loadGrid: true });
  };

  const onCreateDepartment = (e) => {
    setCustomer(e);
    setFilteredCustomerId(e.value);
    setCreateDepartment(true);
  };

  const headerProps = {
    title: 'Departments',
    icon: CircleCheck,
    description: 'This is short description',
    layoutControls: [expandCollapseListPaneButtonConfig],
    children: (<FilterDepartments
      onSubmit={(customerId) => onFilterSubmit(customerId)}
      onCreateDepartment={(departmentCustomer) => onCreateDepartment(departmentCustomer)}
    />),
  };

  return (
    <>

      {
        createDepartment && <CreateDepartment setCreateDepartment={setCreateDepartment} customer={customer} userName={userInformation.userName} />
      }
      {createDepartment === false &&
        <>
          <ListPaneHeader {...headerProps} />
          <DepartmentsLoadGridWrapper>
            <>
              {isLoading === false ? (<Grid

                {...departmentGridConfig}

                className="grid"
                dataTestId="test-formGrid"
                onClickThrough={(e, record) => { onClickRecord(e, record); }}
                focusedItem={selectedRecord}
                records={departments}
                onRowSelect={(e, gridState) => { console.log(e); console.log(gridState); }}
                onSelectAll={(e, gridState) => { console.log(e); console.log(gridState); }}
              />) : <LoadingPage />}
            </>

            <Pagination
              totalRecords={totalRecords}
              pageNumber={filterDepartment.pageNumber}
              pageSize={filterDepartment.pageSize}
              pages={pages}
              totalPages={totalPages}
              activePage={activePage}
              initPage={initPage}
              endPage={endPage}
              onGridPag={onGridPag}
              movePagesForward={movePagesForward}
              movePagesBackward={movePagesBackward} />
          </DepartmentsLoadGridWrapper>
        </>
      }
    </>
  )
}

export default SearchDepartments