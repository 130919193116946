import { types } from './types';
import { getProducts, getCustomersCatalog, getDepartmentsCatalog, searchForReferralActivity, searchForReferralActivityForExport } from './api';
import { excelExport } from "../../../../commonResources/helpers/exportExcel";
import { excelNameReferralActivityReport, headersExcelReferralActivityReport, headingExcelReferralActivityReport } from "../components/SearchResultsExportDataConfig";

export const loadProdcuts = () => async dispatch => {
  dispatch({type: types.REF_BY_PRODUCT_LOAD_PRODUCTS_LOADING})

  try {
    await getProducts().then(products => {
      if (products === null || products === undefined) {
        dispatch({type: types.REF_BY_PRODUCT_LOAD_PRODUCTS_FAIL, 
          payload: 'Something went wrong while fetching Products Data.'})
      } else {
        dispatch({ type: types.REF_BY_PRODUCT_LOAD_PRODUCTS_SUCCESS,
          payload: products })
      }
    }).catch(() => {
      dispatch({type: types.REF_BY_PRODUCT_LOAD_PRODUCTS_FAIL, 
        payload: 'Something went wrong while fetching Products Data.'})
    });
  } catch (error) {
    dispatch({type: types.REF_BY_PRODUCT_LOAD_PRODUCTS_FAIL, 
      payload: 'Something went wrong while fetching Products Data.'})
  } finally {
    dispatch({ type: types.REF_BY_PRODUCT_LOAD_PRODUCTS_FINISHED })
  }
}

export const loadCustomers = () => async dispatch => {
  dispatch({type: types.REF_BY_PRODUCT_LOAD_CUSTOMERS_LOADING})

  try {
    await getCustomersCatalog().then(customers => {
      if (customers === null || customers === undefined) {
        dispatch({type: types.REF_BY_PRODUCT_LOAD_CUSTOMERS_FAIL, 
          payload: 'Something went wrong while fetching Customers Data.'})
      } else {
        dispatch({ type: types.REF_BY_PRODUCT_LOAD_CUSTOMERS_SUCCESS,
          payload: customers })
      }
    }).catch(() => {
      dispatch({type: types.REF_BY_PRODUCT_LOAD_CUSTOMERS_FAIL, 
        payload: 'Something went wrong while fetching Customer Data.'})
    });
  } catch (error) {
    dispatch({type: types.REF_BY_PRODUCT_LOAD_CUSTOMERS_FAIL, 
      payload: 'Something went wrong while fetching Customer Data.'})
  } finally {
    dispatch({ type: types.REF_BY_PRODUCT_LOAD_CUSTOMERS_FINISHED })
  }
}

export const loadDepartments = (customerId) => async dispatch => {
  dispatch({type: types.REF_BY_PRODUCT_LOAD_DEPARTMENT_LOADING})

  try {
    await getDepartmentsCatalog(customerId).then(departments => {
      if (departments === null || departments === undefined) {
        dispatch({type: types.REF_BY_PRODUCT_LOAD_DEPARTMENT_FAIL, 
          payload: 'Something went wrong while fetching Department Data.'})
      } else {
        dispatch({ type: types.REF_BY_PRODUCT_LOAD_DEPARTMENT_SUCCESS,
          payload: departments })
      }
    }).catch(() => {
      dispatch({type: types.REF_BY_PRODUCT_LOAD_DEPARTMENT_FAIL, 
        payload: 'Something went wrong while fetching Department Data.'})
    });
  } catch (error) {
    dispatch({type: types.REF_BY_PRODUCT_LOAD_DEPARTMENT_FAIL, 
      payload: 'Something went wrong while fetching Department Data.'})
  } finally {
    dispatch({ type: types.REF_BY_PRODUCT_LOAD_DEPARTMENT_FINISHED })
  }
}

export const getReferralActivity = (filters) => async dispatch => {
  dispatch({type: types.REF_BY_PRODUCT_SEARCH_RESULTS_LOADING})

  try {
    await searchForReferralActivity(filters).then(paginatedData => {
      if (paginatedData === null || paginatedData.data === null ||
        paginatedData === undefined || paginatedData.data === undefined) {
        dispatch({type: types.REF_BY_PRODUCT_SEARCH_RESULTS_LOADING_FAIL, 
          payload: 'Something went wrong while fetching Report Data.'})
      } else {
        dispatch({ type: types.REF_BY_PRODUCT_SEARCH_RESULTS_LOADING_SUCCESS,
          payload: paginatedData })
      }
    }).catch(() => {
      dispatch({type: types.REF_BY_PRODUCT_SEARCH_RESULTS_LOADING_FAIL, 
        payload: 'Something went wrong while fetching Report Data.'})
    });
  } catch (error) {
    dispatch({type: types.REF_BY_PRODUCT_SEARCH_RESULTS_LOADING_FAIL, 
      payload: 'Something went wrong while fetching Report Data.'})
  } finally {
    dispatch({ type: types.REF_BY_PRODUCT_SEARCH_RESULTS_FINISHED })
  }
}

export const clearDepartments = () => async dispatch => {
  dispatch({ type: types.REF_BY_PRODUCT_CLEAR_DEPARTMENTS });
};

export const clearPageLeave = () => async dispatch => {
  dispatch({ type: types.REF_BY_PRODUCT_CLEAR_DEPARTMENTS });
  dispatch({ type: types.REF_BY_PRODUCT_CLEAR_LEAVE_PAGE });
};

export const exportReferralActivityReport = (filters)=> async dispatch => {

  dispatch({ type: types.REF_BY_PRODUCT_EXPORT_ACTIVITY_REPORT });

  try {
    await searchForReferralActivityForExport(filters).then(data => {
      if (data === null || data === undefined) {
        dispatch({type: types.REF_BY_PRODUCT_EXPORT_ACTIVITY_REPORT_FAIL, 
          payload: 'Something went wrong while fetching report data to export'})
      } else {
        dispatch({type: types.REF_BY_PRODUCT_EXPORT_ACTIVITY_REPORT_SUCCESS })
        
        const exportData = data.map((item) => {
          const { departmentId, ...excluded } = item;
          return excluded;
        });

        excelExport(headingExcelReferralActivityReport, headersExcelReferralActivityReport, exportData, excelNameReferralActivityReport);
      }
    }).catch(() => {
      dispatch({type: types.REF_BY_PRODUCT_EXPORT_ACTIVITY_REPORT_FAIL, 
        payload: 'Something went wrong while fetching report data to export.'})
    });
  } catch (error) {
    dispatch({type: types.REF_BY_PRODUCT_EXPORT_ACTIVITY_REPORT_FAIL, 
      payload: 'Something went wrong while fetching report data to export.'})
  } finally {
    dispatch({ type: types.REF_BY_PRODUCT_EXPORT_ACTIVITY_REPORT_FINISHED })
  }
}
