import React from 'react'
import NavigationPane from '../../NavigationPane/containers/NavigationPaneContainer';
import PageWrapper from '../../PageWrapper';
import SearchDepartments from './components/SearchDepartments';
import DepartmentDetail from './components/DepartmentDetail';
/*eslint-disable */


const Departments = () => {
  return (
    <PageWrapper
      navigationPaneContent={<NavigationPane componentName = "Administration"/>}
      listPaneContent={<SearchDepartments />}
      detailPaneContent={<DepartmentDetail />}
    />
  );
};

export default Departments;