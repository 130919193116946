import React from 'react'
import NavigationPane from '../../NavigationPane/containers/NavigationPaneContainer';
import PageWrapper from '../../PageWrapper';
import ChangePassword from './components/ChangePassword';

/*eslint-disable */


const Account = () => {
  return (
    <PageWrapper
      navigationPaneContent={<NavigationPane componentName = "Account"/>}
      listPaneContent={<ChangePassword />}
    />
  );
};

export default Account;