import { validationKeys } from './constants';
import { SearchFilterTypes, PaginationTypes } from './types';

// verified

export const initialSearchFilterState = {
  [validationKeys.customer] : 0,
  [validationKeys.department] : 0,
  [validationKeys.mbi] : "",
  [validationKeys.healthPlanId] : "",
  [validationKeys.fromDate] : "",
  [validationKeys.toDate] : "", 
  [validationKeys.pageSize] : 5,
  [validationKeys.pageNumber] : 1,  
  [validationKeys.exportToExcel] : false,  
  [validationKeys.filterBy] : false,  
};

export const searchFilterReducer = (state, {type, payload}) => {

  switch (type)
  {
    case SearchFilterTypes.SEARCH_FILTER_SET:
      return {...state, 
        [validationKeys.customer] : payload.customer,
        [validationKeys.department] : payload.department,
        [validationKeys.mbi] : payload.mbi, 
        [validationKeys.healthPlanId] : payload.healthPlanId, 
        [validationKeys.fromDate] : payload.fromDate,
        [validationKeys.toDate] : payload.toDate, 
        [validationKeys.pageSize] : payload.pageSize,
        [validationKeys.exportToExcel] : payload.exportToExcel,
        [validationKeys.filterBy] : payload.filterBy,
        [validationKeys.pageNumber] : 1 };
 
    case SearchFilterTypes.SEARCH_FILTER_PAGE_NUMBER:
      return {...state, 
        [validationKeys.pageNumber] : payload };

    default:
      return state;         
  }

};
  
export const initialPaginationState = {
  [validationKeys.activePage] : 1,
  [validationKeys.initPage] : 0,
  [validationKeys.endPage] : 5,
}; 

export const paginationReducer = (state, {type, payload}) => {

  switch (type)
  {
    case PaginationTypes.PAGINATION_RESET:
      return {...state, 
        [validationKeys.activePage] : payload.activePage,
        [validationKeys.initPage] : payload.initPage,
        [validationKeys.endPage] : payload.endPage }
   
    case PaginationTypes.PAGINATION_PAGE_NUMBER:
      return {...state, 
        [validationKeys.activePage] : payload,  };
  
    case PaginationTypes.PAGINATION_MOVE: {
      return {...state, 
        [validationKeys.initPage]: payload.initPage,
        [validationKeys.endPage]: payload.endPage };
    }

    default:
      return state;         
  }
  
};
  