import { LOAD_USERS, LOAD_USERS_FAIL, LOAD_USERS_SUCCESS, SAVE_USER, SAVE_USER_FAIL, SAVE_USER_SUCCESS, RESET_SAVE_USER_SUCCESS } from './actions'



const initialState = {
  itemsByIds: {},
  ids: []
}


const usersReducer = (state = initialState, action = {}) => {
  switch(action.type) {
    case LOAD_USERS: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case LOAD_USERS_SUCCESS: {
      const ids = action.users.map(x => x.username)
      const itemsByIds = action.users.reduce((prev, current) => (
        {
          ...prev, 
          [current.username]: current
        }), {});
      return {
        ...state,
        isLoading: false,
        ids,
        itemsByIds
      }
    }
    case LOAD_USERS_FAIL: {
      return {
        ...state,
        isLoading: false,
        ids: [],
        itemsByIds: {},
      }
    }
    case SAVE_USER: {
      return {
        ...state,
        isSaving: true,
        saveId: ''
      }
    }
    case SAVE_USER_SUCCESS:
      return {
        ...state, 
        isSaving: false,
        ids: [...new Set([...state.ids, action.user?.id])],
        itemsByIds: {
          ...state.itemsByIds,
          [action.user?.id]: action.user
        },
        saveId: Date.now()
      }
    case RESET_SAVE_USER_SUCCESS:
      return {
        ...state, 
        isSaving: false,
        saveId: ''
      }
    case SAVE_USER_FAIL: {
      return {
        ...state,
        isSaving: false,
        errorMessage: action.errorMessage,
        saveId: ''
      }
    }
    default:
      return state
  }
}

export default usersReducer