import { connect } from 'react-redux';
import { push } from 'connected-react-router';
// TODO make this independent of layout
import { setUser, setUserInformation } from '../../../PageWrapper/modules/actions';
import PingAuth from '../components/PingAuth';
import withWebStorage from '../../../../components/withWebStorage';

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setUserInformation : (userName) => dispatch(setUserInformation(userName)),
  push: (location) => dispatch(push(location)),
});

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWebStorage(PingAuth));
