import { types } from './types';
import { roleType, roleAssociation, filterTypes } from './constants';
import { getUsers, getCustomersCatalog, getRolesCatalog, getDepartmentsCatalog, getUnitManagersCatalog, getUserActivity, updateUserStatus, putUser } from "./api";


export const setInitialState = () => async dispatch => {
  dispatch({type: types.SET_INITIAL_STATE});
}

export const loadUsers = (filterRequest)=> async dispatch => {

  if(!filterRequest.loadGrid)
    return ;
    
  dispatch({type: types.LOAD_USERS});
  const {roleId,customerId,departmentId,unitManagerId,email,pageNumber,pageSize} = filterRequest;
  
  (
    getUsers(roleId,customerId,departmentId,unitManagerId,email,pageNumber,pageSize).then(paginatedData => {
      dispatch({type: types.LOAD_USERS_SUCCESS, paginatedData })
    }).catch( () => {
      dispatch({type: types.LOAD_USERS_FAIL, errorMessage: 'Something went wrong while fetching data. Load Users'})
    })
  )
}

export const loadCustomers = (selectedRole,userInformationCustomerId) => async dispatch => {
  
  let filterType = filterTypes.ALL_ROLES;
  const roleTypeId = selectedRole.roleTypeId;
  const roleAssociationId = selectedRole.roleAssociationId;
  
  if (roleTypeId === roleType.INTERNAL){
    filterType = filterTypes.BY_ROLE;
  }else if(roleTypeId === roleType.EXTERNAL && roleAssociationId === roleAssociation.NO_ASSOCIATION){
    filterType = filterTypes.BY_ROLE_CUSTOMER;
  }else if(roleTypeId === roleType.EXTERNAL && roleAssociationId === roleAssociation.UNIT_MANAGER){
    filterType = filterTypes.BY_ROLE_CUSTOMER_UNIT_MANAGER;
  }else if(roleTypeId === roleType.EXTERNAL && roleAssociationId === roleAssociation.DEPARTMENT_MCO){
    filterType = filterTypes.BY_ROLE_CUSTOMER_DEPARTMENT;
  }
 
  (    
    getCustomersCatalog().then(customers => {
      dispatch({type: types.LOAD_CUSTOMERS_SUCCESS, customers,selectedFilterType : filterType, selectedRole, userInformationCustomerId})
    }).catch( () => {
      dispatch({type: types.LOAD_CUSTOMERS_FAIL, errorMessage: 'Something went wrong while fetching data.'})
    })
  )

};


export const loadRoles = () => async dispatch => {(
  getRolesCatalog().then(roles => {
    dispatch({type: types.LOAD_ROLES_SUCCESS, roles})
  }).catch( () => {
    dispatch({type: types.LOAD_ROLES_FAIL, errorMessage: 'Something went wrong while fetching data.'})
  })
)};

export const loadCustomerDependencies = (selectedCustomer, selectedFilterType)=> async dispatch => {

  if( selectedFilterType === filterTypes.BY_ROLE_CUSTOMER_DEPARTMENT)
  {
    (
      getDepartmentsCatalog(selectedCustomer.value).then(departments => {
        dispatch({type: types.LOAD_DEPARTMENTS_SUCCESS, selectedCustomer, departments})
      }).catch( () => {
        dispatch({type: types.LOAD_DEPARTMENTS_FAIL, errorMessage: 'Something went wrong while fetching data. Departments'})
      })
    )
  }

  if( selectedFilterType === filterTypes.BY_ROLE_CUSTOMER_UNIT_MANAGER)
  {
    (
      getUnitManagersCatalog(selectedCustomer.value).then(unitManagers => {
        dispatch({type: types.LOAD_UNIT_MANAGERS_SUCCESS, selectedCustomer,unitManagers})
      }).catch( () => {
        dispatch({type: types.LOAD_UNIT_MANAGERS_FAIL, errorMessage: 'Something went wrong while fetching data. Unit Managers'})
      })
    )
  }

  if(selectedFilterType === filterTypes.BY_ROLE_CUSTOMER)
  {
    dispatch({type: types.SET_SELECTED_CUSTOMER, selectedCustomer})
  }

};

export const setSelectedUnitManager = (selectedUnitManager )=> async dispatch => {
  (
    dispatch({type: types.SET_SELECTED_UNIT_MANAGER, selectedUnitManager})
  )
};

export const setSelectedDepartment = (selectedDepartment)=> async dispatch => {
  (
    dispatch({type: types.SET_SELECTED_DEPARTMENT, selectedDepartment})
  )
};

export const setEmail = (email)=> async dispatch => {
  (
    dispatch({type: types.SET_EMAIL, email})
  )
}


export const loadUserActivity = ({userId, pageNumber, pageSize}) => async dispatch => {
  dispatch({type: types.LOAD_USER_ACTIVITY, userId});
  (
    getUserActivity(userId, pageNumber, pageSize).then(paginatedData => {
      dispatch({type: types.LOAD_USER_ACTIVITY_SUCCESS, paginatedData})
    }).catch( () => {
      dispatch({type: types.LOAD_USER_ACTIVITY_FAIL, errorMessage: 'Something went wrong while fetching data.'})
    })
  )
};


export const loadUserMcoContracts = (userId) => async dispatch => {
  (
    dispatch({type: types.LOAD_USER_MCO_CONTRACTS, userId})
  )
}

export const updateStatus = (data) => async dispatch => {
  dispatch({type: types.UPDATE_USER_STATUS_IS_LOADING})
  updateUserStatus(data).then(resp => {
    dispatch({
      type: types.UPDATE_USER_STATUS_RESPONSE,
      payload: resp
    })
  }).catch( () => {
    dispatch({type: types.UPDATE_USER_STATUS_FAIL, payload: 'Something went wrong while fetching data.'})
  }).finally(() => {
    dispatch({type: types.UPDATE_USER_STATUS_FINISH_LOADING})
  })
}

export const updateUser = (data) => async dispatch => {
  dispatch({ type: types.UPDATE_USER_IS_LOADING })
  dispatch({ type: types.CLEAR_UPDATE_USER_ERROR_MSG })
  putUser(data).then(resp => {
    if (resp.statusCode !== 200) {
      dispatch({ type: types.UPDATE_USER_FAIL, payload: resp.message })
      dispatch({ type: types.UPDATE_USER_FINISH_LOADING })
      return
    }
    dispatch({
      type: types.UPDATE_USER_SUCCESS,
      payload: resp
    })
  }).catch(() => {
    dispatch({ type: types.UPDATE_USER_FAIL, payload: 'Something went wrong while fetching data. Update User' })
  }).finally(() => {
    dispatch({ type: types.UPDATE_USER_FINISH_LOADING })
  });
}

export const setUpdateUserNotificationsToFalse = () => dispatch => {
  dispatch({ type: types.CLEAR_UPDATE_USER_NOTIFICATIONS })
}

export const clearUpdateUserErrorMsg = () => dispatch => {
  dispatch({ type: types.CLEAR_UPDATE_USER_ERROR_MSG })
}