/**
 * This is the entry file for the application.
 */

// Import all the third party stuff
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'focus-visible';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'sanitize.css/sanitize.css';
import 'intersection-observer';
import { initializeNotifications } from 'notifications';
import DragProvider from '@uicl/ui-core/dist/DragProvider';
import { UICLThemeProvider } from '@uicl/ui-core';
import { initializeConfig } from './commonResources/services/auth-management';
import integrations from './integrations';
import registerServiceWorker from './registerServiceWorker';

// Load the favicon and the .htaccess file
import './favicon.ico';
import './.htaccess'; // eslint-disable-line import/extensions

// Import views
import ErrorBoundary from './ErrorBoundary';

import configureStore from './configureStore';
import GlobalStyle from './global-styles';
import { fonts } from './commonResources/constants/fonts';

// Import all application routes
import ApplicationRoutes, { history } from './applicationRoutes';

if (integrations.ciam) {
  initializeConfig(process.env.NODE_ENV, integrations.ciam);
}

if (integrations.notifications) {
  initializeNotifications(integrations.notifications);
}

// Add promise support to the global window object if not found
if (!window.Promise) window.Promise = Promise;

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');
const loadFonts = () => {
  const head = document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  style.innerHTML = fonts;
  head.appendChild(style);
};


/**
 * The UICLThemeProvider below sets the global styles for the ui-core library. It is required to be there in any project
 * that uses UICL. See the production story book to view available themes and the latest documentation
 * https://prod.storybooks.awsprod.healthcareit.net/?path=/docs/documentation-changelog--page
 */
const render = () => {
  ReactDOM.render(
    <ErrorBoundary>
      <GlobalStyle />
      <Provider store={store}>
        <UICLThemeProvider theme='base'>
          <DragProvider>
            <ApplicationRoutes />
          </DragProvider>
        </UICLThemeProvider>
      </Provider>
    </ErrorBoundary>,
    MOUNT_NODE,
    loadFonts,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./containers/PageWrapper'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

render();

registerServiceWorker();
