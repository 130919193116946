import { fetchWithToken } from '../../../../commonResources/helpers/fetch';

export const getDepartmentsByCustomer = async (customerId) => (
  fetchWithToken(`ReferralDepartments/customer/${customerId}/items`).then(res => res.json()).then(json => json.data))

export const getMcoContractsByCustomer = async (customerId) => (
  fetchWithToken(`McoContracts/customer/${customerId}/items`).then(res => res.json()).then(json => json.data))

export const getUsersByMcoContractandDepartment = async (departmentId, mcocontractId) => (
  fetchWithToken(`ReferralUsers/mcocontract/${mcocontractId}/department/${departmentId}/items`).then(res => res.json()).then(json => json.data))

export const getZipcodesByState = async (stateId) => (
  fetchWithToken(`States/zipcode/state/${stateId}/items`).then(res => res.json()).then(json => json.data))

export const getLanguages = async () => (
  fetchWithToken('Languages/items').then(res => res.json()).then(json => json.data))

export const getRelationshipsRelatives = async () => (
  fetchWithToken('Relationships/alternatives').then(res => res.json()).then(json => json.data))

export const getOutreachChannels = async () => (
  fetchWithToken('OutreachChannels/referrals').then(res => res.json()).then(json => json.data))

export const saveReferralTempData = async (referral) => (
  fetchWithToken('Referral', referral, 'POST').then(resp => resp.json()))
