import { fetchWithToken } from '../../../../commonResources/helpers/fetch'
import { convertMmDdYyyyToYyyyMmDd } from '../../../../commonResources/helpers/date';

export const getProducts = async () => (

  fetchWithToken(`product`).then(res => res.json()).then(json => json.data)

)

export const getCustomersCatalog = async () => (

  fetchWithToken('customers/items').then(res => res.json()).then(json => json.data)

)

export const getDepartmentsCatalog = async (customerId) => (

  fetchWithToken(`referraldepartments/customer/${customerId}/items`).then(res => res.json()).then(json => json.data)

)

export const searchForReferralActivity = (async (filters) => {

  const { pageSize, pageNumber, product, customer, department, fromDate, toDate } = filters;
  const dateFrom = convertMmDdYyyyToYyyyMmDd(fromDate);
  const dateThrough = convertMmDdYyyyToYyyyMmDd(toDate);

  return fetchWithToken(`referralreport/activity?pageSize=${pageSize}&pageNumber=${pageNumber}&ProductId=${product}&CustomerId=${customer}&DepartmentId=${department}&DateFrom=${dateFrom}&DateThrough=${dateThrough}`).then(res => res.json())

});

export const searchForReferralActivityForExport = (async (filters) => {

  const { pageSize, pageNumber, product, customer, department, fromDate, toDate } = filters;
  const dateFrom = convertMmDdYyyyToYyyyMmDd(fromDate);
  const dateThrough = convertMmDdYyyyToYyyyMmDd(toDate);

  return fetchWithToken(`referralreport/activity?pageSize=${pageSize}&pageNumber=${pageNumber}&ProductId=${product}&CustomerId=${customer}&DepartmentId=${department}&DateFrom=${dateFrom}&DateThrough=${dateThrough}`).then(res => res.json()).then(json => json.data)

});
