/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React from 'react';
import ErrorPage from '@uicl/ui-core/dist/ErrorPage';

export const NotFound = () => (
  <ErrorPage
    message="We couldn't find that page. You may have an incorrect URL or the system may need to fix redirects. If this issue persists, please contact a system administrator."
    title="404: Page not found"
  />
);

export default NotFound;
