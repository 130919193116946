import React from 'react'
import NavigationPane from '../../NavigationPane/containers/NavigationPaneContainer';
import PageWrapper from '../../PageWrapper';
import ReferralCase from './components/ReferralCase';

const Reports = () => {
  return (
    <PageWrapper
      navigationPaneContent={<NavigationPane componentName = "Reports"/>}
      listPaneContent={<ReferralCase />}
    />
  );
};

export default Reports;