import users from "../stubData/users"



/**
 * This is mocked up api call. It waits for 3 seconds to emulate network time
 */
export const getUsers = async () => new Promise(resolve => setTimeout(resolve(users), 50000))


export const saveUser = async (request) => new Promise(resolve => {
  const user = {...request}
  if (!user.username) {
    user.username = Date.now()
  } 
  setTimeout(resolve(user), 3000);
})

