import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import './styles.css'

const types = {
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning'
}
const Notification = ({id, title, text, type, timer=2500, onDismiss=()=>{} }) => {
  const [show, setShow] = useState(false)

  // On componentDidMount set the timer
  useEffect(() => {
    setShow(true)
    const timeId = setTimeout(() => {
      // After 2.5 seconds set the show value to false
      setShow(false)
      onDismiss()
    }, timer)

    return () => {
      clearTimeout(timeId)
    }
  }, [id, title, text, type, timer]);

  // If show is false the component will return null and stop here
  if (!show) {
    return null;
  }
  // If show is true this will be returned
  switch (type) {
    case types.INFO:
      return (
        <div className="notification-container" id={id}>
          <div className="notification notification-info">
            <strong>{title}:</strong> {text}
          </div>
        </div>
      )

    case types.SUCCESS:
      return (
        <div className="notification-container" id={id}>
          <div className="notification notification-success">
            <strong>{title}:</strong> {text}
          </div>
        </div>
      )

    case types.WARNING:
      return (
        <div className="notification-container" id={id}>
          <div className="notification notification-warning">
            <strong>{title}:</strong> {text}
          </div>
        </div>
      )

    case types.ERROR:
      return (
        <div className="notification-container" id={id}>
          <div className="notification notification-danger">
            <strong>{title}:</strong> {text}
          </div>
        </div>
      )

    default:
      return <></>
  }
}

export default Notification

Notification.propTypes = {
  /** determines color of banner */
  type: PropTypes.oneOf(types).isRequired,

  /** id for div */
  id: PropTypes.any,

  /** text displayed after the body text */
  title: PropTypes.string,

  /** text displayed on notification banner */
  text: PropTypes.string,

  /** time in milliseconds elapsed before timer is auto-dismissed; 2500ms by default */
  timer: PropTypes.number,

  /** Runs after timeout or on manual dismiss. Takes no parameters. */
  onDismiss: PropTypes.func
};