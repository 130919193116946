import { types } from './types';
import { getDepartments, saveDepartment, updateDepartment } from "./api";


export const loadDepartments = (filterRequest) => async dispatch => {
  if (!filterRequest.loadGrid)
    return;
  dispatch({ type: types.LOAD_DEPARTMENTS_IS_LOADING });
  const { customerId, pageNumber, pageSize, sortingName, sortingOrder } = filterRequest;
  getDepartments(customerId, pageNumber, pageSize, sortingName, sortingOrder).then(paginatedData => {
    dispatch({ type: types.LOAD_DEPARTMENTS_SUCCESS, paginatedData })
  }).catch(() => {
    dispatch({ type: types.LOAD_DEPARTMENTS_FAIL, payload: 'Something went wrong while fetching data. Load Deparments' })
  }).finally(() => {
    dispatch({ type: types.LOAD_DEPARTMENTS_FINISH_LOADING })
  });
}

// UPDATE ACTIONS
export const createNewDepartment = (department) => async dispatch => {
  dispatch({ type: types.CREATE_DEPARTMENT_SAVE_DEPARTMENT_IS_SAVING })
  saveDepartment(department).then(response => {
    dispatch({
      type: types.CREATE_DEPARTMENT_SAVE_DEPARTMENT_SUCCESS,
      response
    })
  }).catch(() => {
    dispatch({
      type: types.CREATE_DEPARTMENT_SAVE_DEPARTMENT_FAIL,
      payload: `Something went wrong while saving data.`
    })
  }).finally(() => {
    dispatch({ type: types.CREATE_DEPARTMENT_SAVE_DEPARTMENT_FINISH_SAVING })
  });
}

export const updateExistingDepartment = (department) => async dispatch => {
  dispatch({ type: types.EDIT_DEPARTMENT_UPDATE_DEPARTMENT_IS_SAVING })
  updateDepartment(department).then(response => {
    dispatch({
      type: types.EDIT_DEPARTMENT_UPDATE_DEPARTMENT_SUCCESS,
      response
    })
  }).catch(() => {
    dispatch({
      type: types.EDIT_DEPARTMENT_UPDATE_DEPARTMENT_FAIL,
      payload: `Something went wrong while saving data.`
    })
  }).finally(() => {
    dispatch({ type: types.EDIT_DEPARTMENT_UPDATE_DEPARTMENT_FINISH_SAVING })
  });
}

// SET ACTIONS
export const setSelectedCustomer = (customer) => async dispatch => {
  dispatch({
    type: types.SEARCH_DEPARTMENT_SELECTED_CUSTOMER,
    payload: customer
  })
}