import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import SelectDropdown from '@uicl/ui-core/dist/SelectDropdown';
import Input from '@uicl/ui-core/dist/Input';
import { Button, DatePicker, RadioGroup,Container, Col, Row} from '@uicl/ui-core';
import PropTypes from 'prop-types';
import { filters, defaultOptions, searchType } from '../modules/constants';

import { loadCustomers, loadDepartments, loadMcoContracts, resetDepartments, resetMcoContracts} from '../modules/actions';
import { isValidDate } from '../../../../commonResources/helpers/date';


const CaseReportFilters = ({onSubmit = () => {} }) => {

  const dispatch = useDispatch();

  const { 
    ids: itemsIds,
    customers: customersOptions,
    departments: departmentsOptions,
    mcoContracts: mcoContractsOptions,
    errorMessage,
  } = useSelector(
    (state) => state.referralCase,
  );

  
  const { userInformation } = useSelector(state => state.app);
 
  useEffect(() => {
    dispatch(loadCustomers(userInformation.customerId));
  }, []);

  const handleRadioChange = (e) => { 

    if(e.id === filters.BY_CUSTOMER){
      setSelectedMBI('');
      setSelectedHealthPlanId('');
    }
    else if(e.id === filters.BY_MEMBER){
      setSelectedEmail('');
      dispatch(resetDepartments());
      dispatch(resetMcoContracts());
      setSelectedCustomer(defaultOptions.customers);
      setSelectedDepartment(defaultOptions.departments);
      setSelectedMcoContract(defaultOptions.mcoContracts);
    }

    setSelectedFilterOption(e.id); 
  }

  const onSelectedCustomer = (customer) => { 
    setSelectedDepartment(defaultOptions.departments);
    setSelectedMcoContract(defaultOptions.mcoContracts);
    setSelectedCustomer(customer);
    dispatch(loadDepartments(customer.value)); 
    dispatch(loadMcoContracts(customer.value)); 
  };

  const onSelectedDepartment = (deparment) => {
    setSelectedDepartment(deparment);
  }

  const onSelectedMcoContract = (mcoContract) => {
    setSelectedMcoContract(mcoContract);
  }
  
  const pageSizeOptions = [{label: '5', value: '5'}, {label: '10', value: '10'}, {label: '15', value: '15'}, {label: '30', value: '30'}, {label: '50', value: '50'}, {label: '75', value: '75'}, {label: '100', value: '100'}];

  const [errors, setErrors] = useState({
    dateFromError: false,
    dateFromErrorMsg: '',
    dateToError: false,
    dateToErrorMsg: '',
  })

  let defaultCustomer = defaultOptions.customers;

  if(userInformation.customerId > 0)
    defaultCustomer = customersOptions.filter(f => f.value === userInformation.customerId.toString())[0];


  const [dateFrom, setDateFrom] = useState('')
  const [dateThrough, setDateThrough] = useState('')
  const [selectedFilterOption, setSelectedFilterOption] = useState(filters.BY_CUSTOMER);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [selectedMBI, setSelectedMBI] = useState('');
  const [selectedHealthPlanId, setSelectedHealthPlanId] = useState('');
  const [selectedPageSize, setSelectedPageSize] = useState({label: '5', value: '5'});
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(defaultOptions.departments);
  const [selectedMcoContract, setSelectedMcoContract] = useState(defaultOptions.mcoContracts);

  const onChangeEmail = (e) => { setSelectedEmail(e) };
  const onChangeMBI = (e) => { setSelectedMBI(e) }
  const onChangeHealthPlanId = (e) => { setSelectedHealthPlanId(e) }
  const onSelectedPageSize  = ( pageSize ) => { setSelectedPageSize(pageSize)};

  const { dateFromError, dateFromErrorMsg, dateToError, dateToErrorMsg } = errors

  useEffect(() => {
    if(userInformation.customerId > 0){
      const userCustomer = customersOptions.filter(f => f.value === userInformation.customerId.toString())[0];
      setSelectedCustomer(userCustomer);  
    }
  }, [customersOptions])

  const isFormValid = () => {
    
    let dfe = false;
    let errorMsg = ''
    let dte = false;
    let errorToMsg = ''

    if (dateFrom === '') {
      dfe = true;
      errorMsg = 'Required Field';
    }

    if (!dfe && !isValidDate(dateFrom)) {
      dfe = true;
      errorMsg = 'Invalid Date';
    }

    const fromDate = new Date(dateFrom);
    const currentDate = new Date(Date.now());

    if (dateThrough !== "") {
      if (!isValidDate(dateThrough)) 
      {
        dte = true;
        errorToMsg = 'Invalid Date';
      }

      const toDate = new Date(dateThrough);
  
      if (isValidDate(dateThrough) && fromDate >= toDate) {
        dte = true;
        errorToMsg = 'Through Date must not be less than From Date';
      }
    }

    if (fromDate > currentDate) {
      dfe = true;
      errorMsg = 'From Date must be less than or equal to Today';
    }
    
    setErrors({ ...errors, dateFromError: dfe, dateFromErrorMsg: errorMsg, dateToError: dte, dateToErrorMsg: errorToMsg });
    return !(dfe) && !(dte);
  }

  const onFilter = (actionType) => {
    if(!isFormValid())
      return;
    onSubmit(actionType,selectedFilterOption, selectedCustomer.value, selectedDepartment.value, selectedMcoContract.value, selectedEmail, selectedMBI, selectedHealthPlanId, dateFrom, dateThrough, selectedPageSize.value);

  }

  return (
    <Container>
      <Row>
        <Col>
          <Row>
            <Col>Report Options</Col>
           
            <Col>
              <RadioGroup
                size="small"
                onChange={handleRadioChange}
                checkedItem={{ id: filters.BY_CUSTOMER }}
                items={[
                  { id: filters.BY_CUSTOMER, label: 'By Customer' },
                  { id: filters.BY_MEMBER, label: 'By Member' }
                ]}
              >
              </RadioGroup>
            </Col>

            <Col></Col>
          
            <Col>
              <SelectDropdown
                size = "medium"
                domID="pageSizeDropdown"
                dataTestId="test-pageSize"
                label="Show"
                options = {pageSizeOptions}
                value = {selectedPageSize}
                onChange={(e) => {  onSelectedPageSize(e ?? { label: '5',value: '5' }); }}
              />
            </Col>
          </Row>
        </Col>
      
      </Row>

      { (selectedFilterOption === filters.BY_CUSTOMER) && 
      <Row>
        <Col>
          <Row>
            <Col>
              <SelectDropdown
                size = "medium"
                domID="customerDropdown"
                dataTestId="test-customers"
                label="Customer"
                placeholderText="Select the customer"
                value = {(selectedCustomer=== undefined)?customersOptions.filter(f => f.value === userInformation.customerId.toString()) : selectedCustomer}
                options={(userInformation.customerId === 0)?customersOptions : customersOptions.filter(f => f.value === userInformation.customerId.toString())}
                onChange={(e) => { onSelectedCustomer(e ?? defaultCustomer); }}
              />
            </Col>
            <Col>
              <SelectDropdown
                size = "medium"
                domID="departmentDropdown"
                dataTestId="test-departments"
                label="Departments"
                placeholderText="Select the department"
                value = {selectedDepartment}
                options={departmentsOptions}
                onChange={(e) => { onSelectedDepartment(e ?? defaultOptions.departments); }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      }
      
      { (selectedFilterOption === filters.BY_CUSTOMER) && 
      <Row>
        <Col>
          <Row>
            <Col>
              <SelectDropdown
                size = "medium"
                domID="mcoContractDropdown"
                dataTestId="test-mcocontracts"
                label="Mco Contract"
                placeholderText="Select the Mco Contract"
                value={selectedMcoContract}
                options={mcoContractsOptions}
                onChange={(e) => { onSelectedMcoContract(e ?? defaultOptions.mcoContracts); }}
              />
            </Col>
            <Col>
              <Input
                domID="email"
                dataTestId="test-email"
                label="Email"
                name="email"
                size = "medium"
                onChange={(e) => { onChangeEmail(e.target.value); }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      }
      

      { (selectedFilterOption === filters.BY_MEMBER) && 
      <Row>
        <Col>
          <Row>
            <Col>
              <Input
                domID="mbi"
                dataTestId="test-mbi"
                label="MBI"
                name="mbi"
                size = "medium"
                onChange={(e) => { onChangeMBI(e.target.value); }}
              />
            </Col>
            <Col>
              <Input
                domID="healthPlanId"
                dataTestId="test-healthPlanId"
                label="Health Plan Id"
                name="healthPlanId"
                size = "medium"
                onChange={(e) => { onChangeHealthPlanId(e.target.value); }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      }

      <Row>
        <Col>
          <Row>
            <Col>
              <DatePicker
                label="Date From"
                domID="dateFrom"
                hasError={dateFromError}
                errorMessage={dateFromError && dateFromErrorMsg || 'Invalid Date'}
                onInputChange={(e) => setDateFrom(e)}
              />
            </Col>
            <Col>
              <DatePicker
                label="Date Through"
                domID="dateThrough"
                hasError={dateToError}
                errorMessage={dateToError && dateToErrorMsg || 'Invalid Date'}
                onInputChange={(e) => setDateThrough(e)}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <div className='column-button'>
          <Button className='search-button'
            size="medium"
            onClick={ () => onFilter(searchType.SUBMIT_SEARCH) }
            name="Search"
            dataTestId="test-formButton"
          />
          {
            itemsIds.length !== 0 &&
            <Button className='referralFiltersExportExcelButton' onClick={() => onFilter(searchType.EXPORT_EXCEL ) }>
              Export Result to Excel
            </Button>
          }
         
        </div>    
      </Row>
      { errorMessage && 
        <Row>
          <div className='column-button'>
            {errorMessage}
          </div> 
        </Row>
      }

    </Container>
  );

}

export default CaseReportFilters;
CaseReportFilters.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  onSubmit: PropTypes.func,
  
};
