import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Grid, GroupTitle, Input } from '@uicl/ui-core'
import styled from 'styled-components/macro';
import LoadingPage from '../../../../components/LoadingPage';
import { loadAllCustomerImages, loadCustomerImage, loadMcoContracts, updateCustomerLogo } from '../modules/actions';
import CustomersDetailGrid from './CustomersDetailGrid';
import Pagination from '../../../../components/Pagination';
import noImage from '../../../../assets/no-image.svg';
import Notification from '../../../../components/notifications/Notification';

const McoContractsGridWrapper = styled.div``;
const pageIndexes = 10;

const CustomersDetailComponent = ({ detailProps, closeDetailPane }) => {

  const {
    // mcoContractError,
    // mcoContractErrorMsg,
    mcoContractDataIsLoading,
    idsMcoContracts,
    itemsByIdsMcoContracts,
    totalRecordsMcoContracts,
    pagesMcoContracts = [],
    totalPagesMcoContracts,
    customerImage,
    allCustomerImages,
    customerImageId,
    customerImageName
  } = useSelector(
    (state) => state.customers,
  );

  const { userInformation } = useSelector(state => state.app);
  const { updateCustomerIsLoading, customerImageIsLoading } = useSelector(state => state.customers);

  const dispatch = useDispatch();

  const [dataForImagesGrid, setDataForImagesGrid] = useState([])
  const [isOpenEditOptions, setIsOpenEditOptions] = useState(false);
  const [outreachProfileId, setOutreachProfileId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [isNotChangesToSave, setisNotChangesToSave] = useState(false);
  const [actualOutreachProfileId, setActualOutreachProfileId] = useState(null);

  const [filterMcoContract, setFilterMcoContract] = useState({
    customerId: detailProps.id,
    pageNumber: 1,
    pageSize: 10,
  });
  const [initPage, setInitPage] = useState(0);
  const [endPage, setEndPage] = useState(pageIndexes);
  const [activePage, setActivePage] = useState(1);
  const [mcoContracts, setMcoContracts] = useState([])

  const movePagesForward = () => {
    setInitPage(initPage + pageIndexes);
    setEndPage(endPage + pageIndexes);
  };
  const movePagesBackward = () => {
    setInitPage(initPage - pageIndexes);
    setEndPage(endPage - pageIndexes);
  };

  const onGridPag = (pageNumber) => {
    setActivePage(pageNumber);
    setFilterMcoContract({ ...filterMcoContract, pageNumber });
  };

  useEffect(() => {
    if (idsMcoContracts.length > 0) {
      const contracts = idsMcoContracts.map((x) => itemsByIdsMcoContracts[x]);
      setMcoContracts(contracts);
    }
  }, [idsMcoContracts])

  useEffect(() => {
    if (Object.keys(detailProps).length !== 0) {
      setFilterMcoContract({
        customerId: detailProps.id,
        pageNumber: 1,
        pageSize: 10,
      })
      setIsOpenEditOptions(false);
      setCustomerId(detailProps.id);
      dispatch(loadMcoContracts(filterMcoContract));
      dispatch(loadCustomerImage(detailProps.id));
      dispatch(loadAllCustomerImages(detailProps.id));
    }
  }, [detailProps])

  useEffect(() => {
    dispatch(loadMcoContracts(filterMcoContract));
  }, [filterMcoContract]);

  useEffect(() => {
    setDataForImagesGrid([])
    if (allCustomerImages.length > 0) {
      const customColumns = allCustomerImages.map(object => {
        return {
          ...object, customerLogo1: {
            sortBy: 'Each cell in this column is',
            toRender: <img id={`logo_${object.id}`} src={`data:image/png;base64,${object.customerLogo1}`} alt={object.imageName} height={30} />
          }
        };
      });
      setDataForImagesGrid(customColumns);
    }
  }, [allCustomerImages])

  const handleSubmitLogo = () => {
    if (outreachProfileId === null || outreachProfileId === undefined || outreachProfileId === actualOutreachProfileId) {
      setisNotChangesToSave(true);
    } else {
      setisNotChangesToSave(false)
      setActualOutreachProfileId(outreachProfileId)
      const data = {
        id: outreachProfileId,
        customerId,
        userName: userInformation.userName
      }
      dispatch(updateCustomerLogo(data));      
    }
  }

  return (
    <Container>
      {
        isNotChangesToSave &&
        <Notification
          id={Math.floor((Math.random()) * 0x10000).toString(16)}
          autoDismiss
          onDismiss={() => setisNotChangesToSave(false)}
          title="Info"
          text="No changes to save"
          type="info"
        />
      }
      <Input
        domID="customer"
        label="Customer"
        value={detailProps.name}
        disabled
      />
      {
        customerImageIsLoading
          ?
          <LoadingPage />
          :
          <>
            {
              !isOpenEditOptions &&
              <div style={{ marginTop: '15px', marginBottom: '20px' }}>
                <GroupTitle
                  className=""
                  dataTestId="test-groupTitle"
                  domID="titleCurrentLogo"
                  title="Current Logo:"
                />
                {
                  customerImage === ''
                    ?
                    <img src={noImage} alt='customerLogo' height='100' />
                    :
                    <div style={{ width: '10vw' }}>
                      <img id={`logo_${customerImageId}`} style={{ maxWidth: '100%', maxHeight: '100%', textAlign: 'center' }} src={`data:image/png;base64,${customerImage}`} alt={customerImageName} />
                    </div>
                }
              </div>
            }
          </>
      }
      {
        updateCustomerIsLoading
          ?
          <LoadingPage />
          :
          <>
            <div style={{ marginTop: '15px', marginBottom: '15px' }}>
              {
                isOpenEditOptions &&
                <div style={{ marginBottom: '50px' }}>
                  <GroupTitle
                    className=""
                    dataTestId="test-groupTitle"
                    domID="titleLogos"
                    title="Select Outreach Profile:"
                  />
                  <Grid
                    columns={new Set([
                      {
                        dataName: 'profileName',
                        text: 'Profile Name',
                        headerWidth: 200,
                        sortable: false,
                        isSorted: 1,
                      },
                      {
                        dataName: 'customerLogo1',
                        text: 'Logo',
                        headerWidth: 200,
                        sortable: false,
                        isSorted: 0,
                        cellType: 'custom',
                      },
                    ])}
                    dataTestId="test-gridCustomCells"
                    domID="customerLogosGrid"
                    records={dataForImagesGrid}
                    selectionKey="id"
                    selectedItemIds={new Set([])}
                    isFixedHeader
                    supportRadioSelection
                    initialRadioSelection={dataForImagesGrid.length > 0 && dataForImagesGrid.find(({ webReferralSelected }) => webReferralSelected === true) ? dataForImagesGrid.find(({ webReferralSelected }) => webReferralSelected === true).id : 0}
                    onRadioSelect={(e, gridState) => {
                      setOutreachProfileId(gridState);
                    }}
                  />
                </div>
              }
            </div>
          </>
      }
      <McoContractsGridWrapper>
        <GroupTitle
          className=""
          dataTestId="test-groupTitle"
          domID="titleMcoGrid"
          title="Mco Contracts"
        />
        <>
          {mcoContractDataIsLoading === false ? (<Grid
            {...CustomersDetailGrid}
            domID="mcoContractGrid"
            dataTestId="test-formGrid"
            records={mcoContracts}
          />) : <LoadingPage />}
        </>
        <Pagination
          totalRecords={totalRecordsMcoContracts}
          pageNumber={filterMcoContract.pageNumber}
          pageSize={filterMcoContract.pageSize}
          pages={pagesMcoContracts}
          totalPages={totalPagesMcoContracts}
          activePage={activePage}
          initPage={initPage}
          endPage={endPage}
          onGridPag={onGridPag}
          movePagesForward={movePagesForward}
          movePagesBackward={movePagesBackward} />
      </McoContractsGridWrapper>
      {
        isOpenEditOptions
          ?
          <>
            <Button onClick={handleSubmitLogo}>
              Save
            </Button>
            <Button buttonType="diminished" onClick={() => setIsOpenEditOptions(!isOpenEditOptions)}>
              Back to View Customer
            </Button>
          </>
          :
          <>
            <Button onClick={() => setIsOpenEditOptions(!isOpenEditOptions)}>
              Edit
            </Button>
            <Button buttonType="diminished" onClick={() => closeDetailPane()}>
              Back to Customer Search
            </Button>
          </>
      }
    </Container>
  )
}

export default CustomersDetailComponent
