import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import useLayout from '@uicl/ui-core/dist/useLayout';
import { CircleCheck, Button, ButtonGroup, Container, Row, ListPaneHeader, SelectDropdown, Input } from '@uicl/ui-core';
import { useForm } from '../../../../commonResources/hooks/useForm';
import { createNewDepartment, setSelectedCustomer } from '../modules/actions';
import Notification from '../../../../components/notifications/Notification';
import { loadCustomers } from '../../../../commonResources/commonModules/actions';
import { useIsMount } from '../../../../commonResources/hooks/useIsMount';

/*eslint-disable */
const createDepartment = ({ setCreateDepartment, customer, userName }) => {

  const {
    expandCollapseListPaneButtonConfig,
  } = useLayout();

  const dispatch = useDispatch();
  const isMount = useIsMount();

  const { customers } = useSelector(state => state.common);
  const { selectedCustomer } = useSelector(state => state.departments);

  const {
    departmentResponseIsSuccessStatusCode,
    departmentError,
    triggerPost
  } = useSelector(state => state.departments);

  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState();
  const [isApiError, setIsApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState();
  const [catsKey, setCatsKey] = useState(0);


  const headerProps = {
    title: 'Create Department',
    icon: CircleCheck,
    description: 'This is short description',
    layoutControls: [expandCollapseListPaneButtonConfig]
  }

  useEffect(() => {
    if (customers.length === 0) {
      dispatch(loadCustomers())
    }
    dispatch(setSelectedCustomer(customer.value > 0 ? customer : null));
  }, []);

  useEffect(() => {
    if (isMount) {
      if (!departmentResponseIsSuccessStatusCode) {
        setApiErrorMessage(departmentError);
        setIsApiError(true);
        return
      }
      setCatsKey(key => key + 1)
      setIsSaveSuccess(true);
    }
  }, [triggerPost])

  const [clickSubmit, setSubmit] = useState(false);

  const [formValues, handleInputChange] = useForm({
    name: '',
    description: ''
  });

  const {
    name,
    description
  } = formValues;

  const isValidTextData = (text) => {
    const regex = /^([A-Za-z0-9]+[A-Za-z0-9 -.]+[ ]*)+$/;
    return regex.test(text);
  }

  const isValidDepartmentInputData = () => {
    if (!selectedCustomer || !name || !description) {
      setValidationErrorMessage("Please complete the required fields (*)");
      return false;
    }

    if (!isValidTextData(name) || !isValidTextData(description)) {
      setValidationErrorMessage("Please review invalid input data");
      return false;
    }
    
    setValidationErrorMessage();
    return true;
  };

  const handleSubmit = async () => {

    setSubmit(true);

    if (isValidDepartmentInputData()) {
      const department = {
        CustomerId: selectedCustomer.value,
        Name: name,
        Description: description,
        CreatedBy: userName ? userName : 'WebReferralUser',
        CreatedDate: new Date()
      }
      dispatch(createNewDepartment(department));
      return;
    }
    setIsValidationError(true);
  }

  const handleCancel = () => {
    dispatch(setSelectedCustomer(customer.value > 0 ? customer : { label: 'All Customers', value: 0 }));
    setCreateDepartment(false)
  }

  return (
    <>
      <ListPaneHeader {...headerProps} />

      {isSaveSuccess && (
        <Notification
          title="Success"
          text='Department was created successfully'
          type="success"
          onDismiss={() => {
            setIsSaveSuccess(false);
            setCreateDepartment(false);
          }}
        />
      )}
      {isValidationError && (
        <Notification
          title="Error"
          text={validationErrorMessage}
          type="error"
          onDismiss={() => setIsValidationError(false)}
        />
      )}
      {isApiError && (
        <Notification
          title="Error"
          text={apiErrorMessage}
          type="error"
          timer={3500}
          onDismiss={() => setIsApiError(false)}
        />
      )}

      <Container >
        <div style={{ margin: 'auto', width: '60vw' }}>
          <Row>
            <div className='column-dropdowns form-items-margin'>
              <SelectDropdown
                placeholderText='[Select Customer]'
                name='customer'
                domID='customer'
                required
                hasError={clickSubmit && !selectedCustomer}
                errorMessage={clickSubmit && !selectedCustomer ? 'Please select a Valid Customer' : ''}
                label='Customer'
                value={selectedCustomer}
                onChange={(e) => dispatch(setSelectedCustomer(e))}
                options={customers}
              />
            </div>
          </Row>
          <Row className='details'>
            <div className='column-dropdowns form-items-margin'>
              <Input
                label='Department Name'
                domID='name'
                name='name'
                required
                regex={/^([A-Za-z0-9]+[A-Za-z0-9 -.]+[ ]*)+$/}
                autoComplete='off'
                maxLength={100}
                hasError={clickSubmit && !name}
                errorMessage={clickSubmit && !name ? 'Please enter Department Name' : 'Invalid Department Name'}
                value={name}
                onChange={handleInputChange}
              />
            </div>
            <div className='column-dropdowns form-items-margin'>
              <Input
                label='Description'
                domID='description'
                name='description'
                required
                regex={/^([A-Za-z0-9]+[A-Za-z0-9 -.]+[ ]*)+$/}
                autoComplete='off'
                maxLength={100}
                hasError={clickSubmit && !description}
                errorMessage={clickSubmit && !description ? 'Please enter Department Description' : 'Invalid Department Description'}
                value={description}
                onChange={handleInputChange}
              />
            </div>
          </Row>

          <ButtonGroup >
            <div style={{ margin: 'auto', float: 'right', width: '30%' }}>
              <Button
                size='medium'
                name='Create'
                onClick={handleSubmit}
              />
              <Button
                buttonType='diminished'
                size='medium'
                name='Cancel'
                onClick={handleCancel}
              />
            </div>

          </ButtonGroup>
        </div>
      </Container >
    </>

  )
};

export default createDepartment;