import { NotificationBanner } from '@uicl/ui-core';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Notification from '../../../components/notifications/Notification';
import NavigationPane from '../../NavigationPane/containers/NavigationPaneContainer';
import PageWrapper from '../../PageWrapper';
import CreateUser from './components/CreateUser';
import { setUserNotificationsToFalse } from './modules/actions';

/*eslint-disable */


const Users = () => {
  const {
    createUserIsSuccess,
    createUserIsFail
  } = useSelector(state => state.createUser);
  const dispatch = useDispatch();

  return (
    <>
      {createUserIsSuccess && (
        <Notification
          title="Success"
          text="User account created"
          type="success"
          timer={3500}
          onDismiss={() => dispatch(setUserNotificationsToFalse())}
        />
      )}
      {createUserIsFail && (
        <NotificationBanner
          autoDismiss
          onDismiss={() => dispatch(setUserNotificationsToFalse())}
          text='Something went wrong while fetching data. Create User'
          type="negative"
        />
      )}
      <PageWrapper
        navigationPaneContent={<NavigationPane componentName="Users" />}
        listPaneContent={<CreateUser />}
      />
    </>
  );
};

export default Users;