import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@uicl/ui-core/dist/Grid';
import CircleCheck from '@uicl/ui-core/dist/CircleCheck';
import ListPaneHeader from '@uicl/ui-core/dist/ListPaneHeader';
import useLayout from '@uicl/ui-core/dist/useLayout';
import Button from '@uicl/ui-core/dist/Button';
import { Container, Modal } from '@uicl/ui-core';
import LoadingPage from '../../../../components/LoadingPage';
import Pagination from '../../../../components/Pagination';
import FilterUsers from './FilterUsers';
import { setInitialState, loadUsers, loadUserMcoContracts, updateStatus } from '../modules/actions';
import { useIsMount } from '../../../../commonResources/hooks/useIsMount';
import SearchUsersLoadsGridWrapper from './styled/SearchUsersLoadsGridWrapper';
import searchUserGridConfig from './searchUserGridConfig';
import Notification from '../../../../components/notifications/Notification';
import { ruleConstants } from '../../../../commonResources/constants/ruleConstants';
import { isInRole } from '../../../../commonResources/helpers/ruleValidation';
import '../styles/styles.css'


const filterUserRequest = {
  roleId: 0,
  customerId: 0,
  departmentId: 0,
  unitManagerId: 0,
  email: '',
  pageNumber: 1,
  pageSize: 10,
  loadGrid: false,
};

const pageIndexes = 5;

const SearchUser = () => {

  const dispatch = useDispatch();
  const isMount = useIsMount();
  const {
    openDetailPane,
    detailProps: selectedRecord,
    expandCollapseListPaneButtonConfig,
  } = useLayout();
  const { ids: userIds, itemsByIds: userById, totalRecords, pages = [], totalPages, isLoading, statusCodeUpdateStatus, triggerPutUpdateStatus, messageUpdateStatus, updateStatusIsLoading } = useSelector(
    (state) => state.searchUsers,
  );
  const { userInformation } = useSelector(state => state.app);

  const users = userIds.map((x) => userById[x]);
  const [filterUsers, setFilterUsers] = useState(filterUserRequest);
  const [initPage, setInitPage] = useState(0);
  const [endPage, setEndPage] = useState(pageIndexes);
  const [activePage, setActivePage] = useState(1);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const [isValidToChangeStatus, setIsValidToChangeStatus] = useState(false);
  const [isApiError, setIsApiError] = useState(false);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  const movePagesForward = () => {
    setInitPage(initPage + pageIndexes);
    setEndPage(endPage + pageIndexes);
  };
  const movePagesBackward = () => {
    setInitPage(initPage - pageIndexes);
    setEndPage(endPage - pageIndexes);
  };

  const onGridPag = (pageNumber) => {
    setActivePage(pageNumber);
    setFilterUsers({ ...filterUsers, pageNumber });
  };


  useEffect(() => {
    return () => {
      dispatch(setInitialState());
    }
  }, []);


  useEffect(() => {
    dispatch(loadUsers(filterUsers));
  }, [filterUsers]);


  const onFilterSubmit = (roleId, customerId, departmentId, unitManagerId, email) => {
    setInitPage(0);
    setEndPage(pageIndexes);
    setActivePage(1);
    setFilterUsers({ roleId, customerId, departmentId, unitManagerId, email, pageNumber: 1, pageSize: 10, loadGrid: true });
  };

  const onClickRecord = (e, record) => {
    dispatch(loadUserMcoContracts(record.id));
    openDetailPane(record, e);
  };

  useEffect(() => {
    if (isMount) {
      if (statusCodeUpdateStatus !== 200) {
        setIsApiError(true);
        return
      }
      setSelectedUsers([])
      setIsSaveSuccess(true);
      dispatch(loadUsers(filterUsers))
    }
  }, [triggerPutUpdateStatus])

  const handleChangeStatus = () => {
    if (selectedUsers.length !== 0) {
      const data = {
        userName: userInformation.userName,
        referralUserId: selectedUsers
      }
      setIsOpenConfirmationModal(false);
      dispatch(updateStatus(data));
      return
    }
    setIsValidToChangeStatus(true);
    setIsOpenConfirmationModal(false);
  }

  const handleValidationChangeStatus = () => {
    if (selectedUsers.length !== 0) {
      setIsOpenConfirmationModal(true);
      return
    }
    setIsValidToChangeStatus(true);
  }

  const headerProps = {
    title: 'Search Users',
    icon: CircleCheck,
    description: 'This is short description',
    layoutControls: [expandCollapseListPaneButtonConfig],
    children: (<FilterUsers onSubmit={(roleId, customerId, departmentId, unitManagerId, email) => onFilterSubmit(roleId, customerId, departmentId, unitManagerId, email)} />),
  };
  return (
    <>
      {isSaveSuccess && (
        <Notification
          title="Success"
          text="Status updated successfully"
          type="success"
          timer={3500}
          onDismiss={() => setIsSaveSuccess(false)}
        />
      )}
      {isValidToChangeStatus && (
        <Notification
          title="Error"
          text="You must select at least one user"
          type="error"
          onDismiss={() => setIsValidToChangeStatus(false)}
        />
      )}
      {isApiError && (
        <Notification
          title="Error"
          text={messageUpdateStatus}
          type="error"
          onDismiss={() => setIsApiError(false)}
        />
      )}
      <ListPaneHeader {...headerProps} />
      <SearchUsersLoadsGridWrapper>
        <>
          {isLoading === false ? (<Grid
            {...searchUserGridConfig}
            className="grid"
            dataTestId="test-formGrid"
            onClickThrough={(e, record) => { onClickRecord(e, record); }}
            focusedItem={selectedRecord}
            records={users}
            supportSelection={isInRole(ruleConstants.EditUser, userInformation)}
            onRowSelect={(e, gridState) => {
              const items = [];
              gridState.selectedItemIds.forEach(element => items.push({ id: element }));
              setSelectedUsers(items);
            }}
            onSelectAll={(e, gridState) => {
              const items = [];
              gridState.selectedItemIds.forEach(element => items.push({ id: element }));
              setSelectedUsers(items);
            }}
          />) : <LoadingPage />}
        </>

        <Pagination
          totalRecords={totalRecords}
          pageNumber={filterUsers.pageNumber}
          pageSize={filterUsers.pageSize}
          pages={pages}
          totalPages={totalPages}
          activePage={activePage}
          initPage={initPage}
          endPage={endPage}
          onGridPag={onGridPag}
          movePagesForward={movePagesForward}
          movePagesBackward={movePagesBackward} />
      </SearchUsersLoadsGridWrapper>

      {
        isInRole(ruleConstants.EditUser, userInformation) &&
        <div className='change-status-button'>
          <Button disabled={updateStatusIsLoading} onClick={handleValidationChangeStatus}>
            Change Status
          </Button>
        </div>
      }

      <Modal isOpen={isOpenConfirmationModal} onClickOut={() => setIsOpenConfirmationModal(false)}>
        <Container>
          {
            selectedUsers.length > 1
              ?
              <>
                <p>Are you sure you want to Activate/Deactivate these users?</p>
                <p>In case you want to proceed with the change of status Please contact CIAM to update the User status</p>
              </>
              :
              <>
                <p>Are you sure you want to Activate/Deactivate this user?</p>
                <p>In case you want to proceed with the change of status please contact CIAM to update the User status</p>
              </>
          }
          <div className='column-button-modal'>
            <Button className='submit-button-modal' onClick={handleChangeStatus}>Yes, I am sure</Button>
            <Button buttonType="diminished" className='cancel-button-modal' onClick={() => setIsOpenConfirmationModal(false)}>Cancel</Button>
          </div>
        </Container>
      </Modal>

    </>
  )
}

export default SearchUser