export default {
  columns: new Set([
    {
      dataName: 'firstName',
      text: 'First name',
      sortable: true,
    },
    {
      dataName: 'lastName',
      text: 'Last name',
      sortable: true,
    },
    {
      dataName: 'middleInitial',
      text: 'Middle Name',
      sortable: true,
    },
    {
      dataName: 'customerName',
      text: 'Customer',
      sortable: true,
    },
    {
      dataName: 'healthPlanId',
      text: 'Health Plan ID',
      sortable: true,
    },
    {
      dataName: 'departmentName',
      text: 'Department',
      sortable: true,
    },
    {
      dataName: 'mcoContractName',
      text: 'Mco Contracts',
      sortable: true,
    },
    {
      dataName: 'referredBy',
      text: 'Referred By',
      sortable: true,
    },
    {
      dataName: 'createdDate',
      text: 'Referred Date',
      sortable: true,
    },
    {
      dataName: 'preferredLanguage',
      text: 'Preferred Language',
      sortable: true,
    },
    {
      dataName: 'preferredContact',
      text: 'Preferred Contact ',
      sortable: true,
    },
  ]),
  selectionKey: 'referralID',
  domID: 'search-referral-grid',
  onSortTableColumn: () => { },
  onSelectAll: () => { },
  onLinkClick: () => { },
  onSettingsCancel: () => { },
  isConfigurable: false,
  isFixedHeader: true,
};
