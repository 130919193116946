/*eslint-disable */
import { useState } from 'react';


export const useForm = (initialState = {}) => {

    const [formValues, setFormValues] = useState(initialState);

    const reset = (newFormState = initialState) => {
        setFormValues(newFormState);
    }


    const handleInputChange = ({ target }) => {
        // console.log(target)
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }

    const handleInputChangeUpper = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value.toUpperCase()
        });
    }

    return [formValues, handleInputChange, reset, handleInputChangeUpper];

}