import { types }  from './types';



const initialState = {
  itemsByIds: {},
  ids: [],
  pages: [],
  totalPages: 0,
  totalRecords: 0,
  errorMessage : '',
  isLoading: false,
  itemsByIdsMcoContracts: {},
  idsMcoContracts: [],
  pagesMcoContracts: [],
  totalPagesMcoContracts: 0,
  totalRecordsMcoContracts: 0,
  mcoContractError: false,
  mcoContractErrorMsg: '',
  mcoContractDataIsLoading: false,
  customerImage: '',
  customerImageId: '',
  customerImageName: '',
  customerImageIsLoading: false,

  allCustomerImages: [],

  updateCustomerLogoResponse: [],
  updateCustomerLogoError: '',
  updateCustomerIsSuccess: false,
  updateCustomerIsFail: false,
  updateCustomerIsLoading: false,
};



export const customersReducer = (state = initialState, action = {}) => {

  switch (action.type) {

    case types.LOAD_CUSTOMERS: {
      return {
        ...state,
        isLoading: true,
        errorMessage : '',
      }
    }

    case types.LOAD_CUSTOMERS_SUCCESS: {
    
      const { paginatedData } = action;
      const {totalPages, totalRecords, data} = paginatedData;
      const ids = data.map(x => x.id);
      const pages = [];

      for(let i = 1; i <= totalPages; i += 1){
        pages.push(i);
      }
            
      const itemsByIds = data.reduce((prev, current) => (
        {
          ...prev, 
          [current.id]: {
            ...current, 
            id: current.id, 
            customerName: current.name,
          }
        }
      ), {});

      // console.log('itemsById', itemsByIds);
      return {
        ...state,
        isLoading: false,
        ids,
        itemsByIds,
        totalPages,
        totalRecords,
        pages
      }
    }

    case types.LOAD_CUSTOMERS_FAIL: {
      return {
        ...state,
        isLoading: false,
        ids: [],
        itemsByIds: {},
        errorMessage : action.errorMessage,
      }
    }

    case types.LOAD_MCOCONTRACTS_SUCCESS: {
    
      const { paginatedDataMcoContracts } = action;
      const {totalPages, totalRecords, data} = paginatedDataMcoContracts;
      const idsMcoContracts = data.map(x => x.id);
      const pages = [];

      for(let i = 1; i <= totalPages; i += 1){
        pages.push(i);
      }
            
      const itemsByIdsMcoContracts = data.reduce((prev, current) => (
        {
          ...prev, 
          [current.id]: {
            ...current, 
            id: current.id, 
            description: current.description,
            isDemoActive: current.isDemoActive
          }
        }
      ), {});

      // console.log('itemsById', itemsByIds);
      return {
        ...state,
        isLoadingMcoContracts: false,
        idsMcoContracts,
        itemsByIdsMcoContracts,
        totalPagesMcoContracts: totalPages,
        totalRecordsMcoContracts: totalRecords,
        pagesMcoContracts: pages
      }
    }

    case types.LOAD_MCOCONTRACTS_FAIL: {
      return {
        ...state,
        mcoContractError: true,
        idsMcoContracts: [],
        itemsByIdsMcoContracts: {},
        mcoContractErrorMsg: action.payload
      }
    }

    case types.LOAD_MCOCONTRACTS_IS_LOADING: {
      return {
        ...state,
        mcoContractDataIsLoading: true
      }
    }

    case types.LOAD_MCOCONTRACTS_FINISH_LOADING: {
      return {
        ...state,
        mcoContractDataIsLoading: false
      }
    }

    case types.LOAD_CUSTOMER_IMAGE_SUCCESS: {
      return {
        ...state,
        customerImage: action.payload.customerLogo1,
        customerImageId: action.payload.id,
        customerImageName: action.payload.imageName
      }
    }

    case types.LOAD_CUSTOMER_IMAGES_FOR_GRID_SUCCESS: {
      return {
        ...state,
        allCustomerImages: action.payload.sort((a, b) => {
          if (a.profileName < b.profileName) {
            return -1;
          }
          if (a.profileName > b.profileName) {
            return 1;
          }
          return 0;
        })
      }
    }

    case types.CLEAR_CUSTOMER_IMAGE: {
      return {
        ...state,
        customerImage: ''
      }
    }

    case types.LOAD_CUSTOMER_IMAGES_IS_LOADING: {
      return {
        ...state,
        customerImageIsLoading: true
      }
    }

    case types.LOAD_CUSTOMER_IMAGES_FINISH_LOADING: {
      return {
        ...state,
        customerImageIsLoading: false
      }
    }

    case types.CLEAR_CUSTOMER_IMAGES_FOR_GRID: {
      return {
        ...state,
        allCustomerImages: []
      }
    }

    case types.UPDATE_CUSTOMER_IMAGE_SUCCESS: {
      return {
        ...state,
        updateCustomerLogoResponse: action.payload,
        updateCustomerIsSuccess: true
      }
    }

    case types.UPDATE_CUSTOMER_IMAGE_FAIL: {
      return {
        ...state,
        updateCustomerLogoError: action.payload,
        updateCustomerIsFail: true
      }
    }

    case types.UPDATE_CUSTOMER_IMAGE_IS_LOADING: {
      return {
        ...state,
        updateCustomerIsLoading: true
      }
    }

    case types.UPDATE_CUSTOMER_IMAGE_FINISH_LOADING: {
      return {
        ...state,
        updateCustomerIsLoading: false
      }
    }

    case types.CLEAR_CUSTOMER_NOTIFICATIONS: {
      return {
        ...state,
        updateCustomerIsSuccess: false,
        updateCustomerIsFail: false,
      }
    }

    default:
      return state

  }
};

export default customersReducer;