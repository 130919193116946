/**
 * Asynchronously loads the component for HomePage
 */
import React from 'react';
import loadable from '@loadable/component';

import LoadingPage from '../../components/LoadingPage';

const LoadableExampleDataGridPage = loadable(() => import('./index'), {
  fallback: <LoadingPage />,
});

export default LoadableExampleDataGridPage;
