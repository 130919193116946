import React from 'react'
import NavigationPane from '../../NavigationPane/containers/NavigationPaneContainer';
import PageWrapper from '../../PageWrapper';
import ReferralByProduct from './components/ReferralByProduct';

/*eslint-disable */


const Reports = () => {
  return (
    <PageWrapper
      navigationPaneContent={<NavigationPane componentName = "Reports"/>}
      listPaneContent={<ReferralByProduct />}
    />
  );
};

export default Reports;