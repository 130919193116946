import { types } from "./types";


const initialState = {
  barChartInternalData: [],
  pieChartInternalData: [],
  barChartExternalData: [],
  barChartExternalOwnData: [],
  barChartInternalIsLoading: false,
  pieChartInternalIsLoading: false,
  barChartExternalIsLoading: false,
  barChartExternalOwnIsLoading: false,
  barChartInternalError: '',
  barChartInternalErrorFlag: false,
  barChartExternalError: '',
  barChartExternalErrorFlag: false,
  barChartExternalOwnError: '',
  barChartExternalOwnErrorFlag: false,
  pieChartInternalError: '',
  pieChartInternalErrorFlag: false,
}

export const dashboardReducer = (state = initialState, action) => {

  switch (action.type) {

    case types.LOAD_BAR_CHART_INTERNAL_SUCCESS:
      return {
        ...state,
        barChartInternalData: action.payload,
      }

    case types.LOAD_BAR_CHART_INTERNAL_FAIL:
      return {
        ...state,
        barChartInternalError: action.payload,
        barChartInternalErrorFlag: true
      }

    case types.LOAD_BAR_CHART_INTERNAL_IS_LOADING:
      return {
        ...state,
        barChartInternalIsLoading: true
      }

    case types.LOAD_BAR_CHART_INTERNAL_FINISH_LOADING:
      return {
        ...state,
        barChartInternalIsLoading: false
      }

    case types.LOAD_PIE_CHART_INTERNAL_SUCCESS:
      return {
        ...state,
        pieChartInternalData: action.payload,
      }

    case types.LOAD_PIE_CHART_INTERNAL_FAIL:
      return {
        ...state,
        pieChartInternalError: action.payload,
        pieChartInternalErrorFlag: true
      }

    case types.LOAD_PIE_CHART_INTERNAL_IS_LOADING:
      return {
        ...state,
        pieChartInternalIsLoading: true
      }

    case types.LOAD_PIE_CHART_INTERNAL_FINISH_LOADING:
      return {
        ...state,
        pieChartInternalIsLoading: false
      }



    case types.LOAD_BAR_CHART_EXTERNAL_OWN_SUCCESS:
      return {
        ...state,
        barChartExternalOwnData: action.payload,
      }

    case types.LOAD_BAR_CHART_EXTERNAL_OWN_FAIL:
      return {
        ...state,
        barChartExternalOwnError: action.payload,
        barChartExternalOwnErrorFlag: true
      }

    case types.LOAD_BAR_CHART_EXTERNAL_OWN_IS_LOADING:
      return {
        ...state,
        barChartExternalOwnIsLoading: true
      }

    case types.LOAD_BAR_CHART_EXTERNAL_OWN_FINISH_LOADING:
      return {
        ...state,
        barChartExternalOwnIsLoading: false
      }


    case types.LOAD_BAR_CHART_EXTERNAL_SUCCESS:
      return {
        ...state,
        barChartExternalData: action.payload,
      }

    case types.LOAD_BAR_CHART_EXTERNAL_FAIL:
      return {
        ...state,
        barChartExternalError: action.payload,
        barChartExternalErrorFlag: true
      }

    case types.LOAD_BAR_CHART_EXTERNAL_IS_LOADING:
      return {
        ...state,
        barChartExternalIsLoading: true
      }

    case types.LOAD_BAR_CHART_EXTERNAL_FINISH_LOADING:
      return {
        ...state,
        barChartExternalIsLoading: false
      }

    default:
      return state;
  }

}