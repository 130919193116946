import { fetchWithToken } from "../../../../commonResources/helpers/fetch";

export const getRoles = async (params) => (
  fetchWithToken(`ReferralRoles/search?${new URLSearchParams(params)}`).then(res => res.json()).then(json => json)
)
export const getRules = async () => (
  fetchWithToken(`ReferralRules`).then(res => res.json()).then(json => json.data)
)
export const getRoleAssociation = async () => (
  fetchWithToken(`ReferralRoles/roleAssociationItems`).then(res => res.json()).then(json => json)
)
export const getRoleTypes = async () => (
  fetchWithToken(`ReferralRoles/roleTypesItems`).then(res => res.json()).then(json => json)
)
export const postRole = async (data) => (
  fetchWithToken(`ReferralRoles`, data, 'POST').then(res => res.json()).then(json => json)
)
export const putRole = async (data) => (
  fetchWithToken(`ReferralRoles`, data, 'PUT').then(res => res.json()).then(json => json)
)