/**
 * Iterates over the provided store and calls the provided function
 * with each namespaced key and value pair
 * @param {webStorageManager} store
 * @param {Function} cb
 */
export const iterateStorage = (store, cb) => {
  const {deserialize} = require("./utilities");
  const { storage, namespace } = store;
  Object.keys(storage)
    .filter(key => key.startsWith(namespace))
    .forEach(key => cb(key, deserialize(storage[key])));
};
