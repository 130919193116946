import { types }  from './types';
import { filterTypes } from './constants';
import { convertToMMSDateFormat } from '../../../../commonResources/helpers/date';

const initialState = {
  itemsByIds: {},
  ids: [],
  pages: [],
  totalPages: 0,
  totalRecords: 0,
  customers: [],
  roles: [],
  departments: [],
  unitManagers: [],
  userActivities: [],
  userActivitiesTotalPages : 0,
  userActivitiesTotalRecords : 0,
  userActivitiesPages: [],
  email : '',
  errorMessage : '',
  isLoading: false,
  isLoadingActivities: false,
  selectedRole : { label : 'All', value : 0 },
  selectedCustomer : { label : 'All', value : 0 },
  selectedUnitManager : { label : 'All', value : 0 },
  selectedDepartment: { label : 'All', value : 0 },
  selectedFilterType : filterTypes.ALL_ROLES,
  statusCodeUpdateStatus:0,
  messageUpdateStatus: '',
  triggerPutUpdateStatus: false,
  updateStatusIsLoading: false,
  updateStatusErrorMessage : '',  

  updateUserResponse: '',
  updateUserIsSuccess: false,
  updateUserError: '',
  updateUserIsFail: false,
  updateUserIsLoading: false,
};



export const searchUsersReducer = (state = initialState, action = {}) => {

  switch (action.type) {

    case types.SET_INITIAL_STATE: {
      return {
        ...initialState
      }
    }

    case types.LOAD_CUSTOMERS: {
      return {
        ...state,
        isLoading: true,
        errorMessage : '',
      }
    }
    case types.LOAD_CUSTOMERS_SUCCESS: {

      const { selectedFilterType, selectedRole, userInformationCustomerId } = action;

      let selectedCustomer = { label : 'All', value : 0 };
      let customers = action.customers.map(x => ({ label: x.name, value: x.id.toString() }));
      
      if(userInformationCustomerId > 0){
        customers = customers.filter(f => f.value === userInformationCustomerId.toString())
        selectedCustomer = customers[0];
      }
        
      if(userInformationCustomerId === 0)
        customers.unshift({ label: 'All', value: 0 });

      return {
        ...state,
        isLoading: false,
        customers,
        selectedFilterType,
        selectedRole, 
        selectedCustomer,
        selectedUnitManager : { label : 'All', value : 0 },
        selectedDepartment: { label : 'All', value : 0 },
        departments: [],
        unitManagers: [],
      }
    }
    case types.LOAD_CUSTOMERS_FAIL: {
      return {
        ...state,
        isLoading: false,
        ids: [],
        itemsByIds: {},
        errorMessage : action.errorMessage,
      }
    }
    case types.LOAD_ROLES: {
      return {
        ...state,
        isLoading: true,
        errorMessage : '',
      }
    }
    case types.LOAD_ROLES_SUCCESS: {

      const roles = action.roles.map(x => ({ label: x.roleName, value: x.id.toString(), roleTypeId : x.roleTypeId  ,roleAssociationId : x.roleAssociationId }));

      roles.unshift({ label: 'All', value: 0 });
     
      return {
        ...state,
        isLoading: false,
        roles,
      }
    }
    case types.LOAD_ROLES_FAIL: {
      return {
        ...state,
        isLoading: false,
        ids: [],
        itemsByIds: {},
        errorMessage : action.errorMessage,
      }
    }
    case types.LOAD_DEPARTMENTS: {
      return {
        ...state,
        isLoading: true,
        errorMessage : '',
      }
    }
    case types.LOAD_DEPARTMENTS_SUCCESS: {

      const departments = action.departments.map(x => ({ label: x.name, value: x.id.toString() }));

      departments.unshift({ label: 'All', value: 0 });
     
      return {
        ...state,
        isLoading: false,
        selectedCustomer: action.selectedCustomer,
        selectedDepartment : { label : 'All', value : 0 },
        selectedUnitManager : { label : 'All', value : 0 },
        departments,
      }
    }
    case types.LOAD_DEPARTMENTS_FAIL: {
      return {
        ...state,
        isLoading: false,
        ids: [],
        itemsByIds: {},
        errorMessage : action.errorMessage,
      }
    }
    case types.LOAD_UNIT_MANAGERS: {
      return {
        ...state,
        isLoading: true,
        errorMessage : '',
      }
    }
    case types.LOAD_UNIT_MANAGERS_SUCCESS: {

      const unitManagers = action.unitManagers.map(x => ({ label: x.userName, value: x.id.toString() }));
     
      unitManagers.unshift({ label: 'All', value: 0 });

      return {
        ...state,
        isLoading: false,
        selectedCustomer: action.selectedCustomer,
        selectedUnitManager : { label : 'All', value : 0 },
        selectedDepartment : { label : 'All', value : 0 },
        unitManagers,
      }
    }
    case types.LOAD_UNIT_MANAGERS_FAIL: {
      return {
        ...state,
        isLoading: false,
        ids: [],
        itemsByIds: {},
        errorMessage : action.errorMessage,
      }
    }
    case types.SET_SELECTED_UNIT_MANAGER: {
      const { selectedUnitManager} = action;
      return {
        ...state,
        selectedUnitManager,
        selectedDepartment : { label : '', value : 0 },
      }
    }
    case types.SET_SELECTED_DEPARTMENT: {
      const { selectedDepartment} = action;
      return {
        ...state,
        selectedDepartment,
        selectedUnitManager : { label : '', value : 0 },
      }
    }
    case types.SET_SELECTED_CUSTOMER: {
      const { selectedCustomer} = action;
      return {
        ...state,
        selectedCustomer,
      }
    }
    case types.LOAD_USERS: {
      return {
        ...state,
        isLoading: true,
        errorMessage : '',
      }
    }
    case types.LOAD_USERS_SUCCESS: {
    
      const { paginatedData } = action;
      const {totalPages, totalRecords, data} = paginatedData;
      const ids = data.map(x => x.id);
      const pages = [];

      for(let i = 1; i <= totalPages; i += 1){
        pages.push(i);
      }
      
      const itemsByIds = data.reduce((prev, current) => (
        {
          ...prev, 
          [current.id]: {
            ...current, 
            id: current.id, 
            firstName: current.firstName,
            roleName : current.referralRole.roleName, 
            phone: current.phoneNumber,
            isActive : current.isApproved,
            isActiveForGrid : current.isApproved ? 'Yes' : 'No',
            activeDate : convertToMMSDateFormat(current.createDate),
          }
        }
      ), {});

      return {
        ...state,
        isLoading: false,
        ids,
        itemsByIds,
        totalPages,
        totalRecords,
        pages
      }
    }
    case types.LOAD_USERS_FAIL: {
      return {
        ...state,
        isLoading: false,
        ids: [],
        itemsByIds: {},
        errorMessage : action.errorMessage,
      }
    }
    case types.SET_EMAIL: {
      return {
        ...state,
        email: action.email,
      }
    }
    case types.LOAD_USER_ACTIVITY: {
      return {
        ...state,
        isLoadingActivities : true,
      }
    }

    case types.LOAD_USER_ACTIVITY_SUCCESS: {

      const pages = [];
      const activities = [];
      const { paginatedData } = action;
      const {totalPages, totalRecords, data} = paginatedData;
      
      for(let i = 1; i <= totalPages; i += 1){
        pages.push(i);
      }

      data.map((activity)=>
        activities.push({
          activityType: activity.referralActivityType.name, 
          activityDate: convertToMMSDateFormat(activity.activityDate),
        })
      );

      return {
        ...state,
        isLoadingActivities : false,
        userActivities : activities,
        userActivitiesTotalPages : totalPages,
        userActivitiesTotalRecords : totalRecords,
        userActivitiesPages : pages,
      }
    }
 
    case types.LOAD_USER_ACTIVITY_FAIL: {
      return {
        ...state,
        isLoadingActivities : false,
      }
    }

    case types.UPDATE_USER_STATUS_IS_LOADING: {
      return {
        ...state,
        updateStatusIsLoading : true,
      }
    }

    case types.UPDATE_USER_STATUS_FINISH_LOADING: {
      return {
        ...state,
        updateStatusIsLoading : false,
      }
    }

    case types.UPDATE_USER_STATUS_RESPONSE:
      return {
        ...state,
        statusCodeUpdateStatus: action.payload.statusCode || action.payload.StatusCode,
        messageUpdateStatus: action.payload.message || action.payload.Message,
        triggerPutUpdateStatus: !state.triggerPutUpdateStatus
      }

    case types.UPDATE_USER_STATUS_FAIL:
      return {
        ...state,
        updateStatusErrorMessage: action.payload
      }    

    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserResponse: action.payload,
        updateUserIsSuccess: true,
        updateUserError: ''
      }

    case types.UPDATE_USER_FAIL:
      return {
        ...state,
        updateUserError: action.payload,
        updateUserIsFail: true,
      }

    case types.UPDATE_USER_IS_LOADING:
      return {
        ...state,
        updateUserIsLoading: true
      }

    case types.UPDATE_USER_FINISH_LOADING:
      return {
        ...state,
        updateUserIsLoading: false
      }

    case types.CLEAR_UPDATE_USER_NOTIFICATIONS:
      return {
        ...state,
        updateUserIsSuccess: false,
        updateUserIsFail: false,
      }

    case types.CLEAR_UPDATE_USER_ERROR_MSG:
      return {
        ...state,
        updateUserError: ''
      }

    default:
      return state
  }

};

export default searchUsersReducer;