import { getTokenValuesForHeaders } from "../services/auth-management";

const baseUrl = process.env.REACT_APP_API_URL;
const baseTimeout = process.env.REACT_APP_SET_TIMEOUT;

const fetchWithoutToken = async (endpoint, data, method = 'GET') => {
  const url = `${baseUrl}/${endpoint}`;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), baseTimeout);

  if (method === 'GET') {
    const response = await fetch(url, { signal: controller.signal });
    clearTimeout(id);
    return response;
    // eslint-disable-next-line
  } else {
    const response = await fetch(url, {
      method,
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data),
      signal: controller.signal
    });
    clearTimeout(id);
    return response;
  }
}

const fetchWithToken = async (endpoint, data, method = 'GET') => {
  const { accessTokenType, accessToken } = getTokenValuesForHeaders();
  const url = `${baseUrl}/${endpoint}`;
  
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), baseTimeout);

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `${accessTokenType} ${accessToken}`,
  };

  if (method === 'GET') {
    const response = await fetch(url, {
      method,
      headers,
      signal: controller.signal
    });

    clearTimeout(id);
    if (response.status === 401) {
      window.location.replace('/login');
    }
    return response;
    // eslint-disable-next-line
  } else {
    const response = await fetch(url, {
      method,
      headers,
      body: JSON.stringify(data),
      signal: controller.signal
    });
    clearTimeout(id);
    if (response.status === 401) {
      window.location.replace('/login');
    }
    return response;
  }
}

const fetchWithTokenForImages = async (endpoint, data, method = 'GET') => {
  const url = `${baseUrl}/${endpoint}`;
  const token = localStorage.getItem('token') || '';

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), baseTimeout);

  if (method === 'GET') {
    const response = await fetch(url, {
      method,
      headers: {
        'Token': `${token}`,
      },
      signal: controller.signal
    });
    clearTimeout(id);
    const imageBlob = await response.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    // console.log(imageObjectURL)
    return imageObjectURL;
    // eslint-disable-next-line
  } else {
    const response = await fetch(url, {
      method,
      headers: {
        'Content-type': 'multipart/form-data',
        'Token': `${token}`,
      },
      body: JSON.stringify(data),
      signal: controller.signal
    });
    clearTimeout(id);
    return response;
  }
}


export {
  fetchWithoutToken,
  fetchWithToken,
  fetchWithTokenForImages,
}
