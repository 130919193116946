import userRoles from './userRoles';

const makeUser = (
  username,
  first,
  last,
  verification,
  address1,
  address2,
  city,
  state,
  zip,
  phone,
  phoneType,
  role,
  manager,
) => ({
  username,
  first,
  last,
  verification,
  address1,
  address2,
  city,
  state,
  zip,
  phone,
  phoneType,
  role,
  manager,
});

const users = [
  makeUser(
    'udhakal',
    'Upen',
    'Dhakal',
    '1234567890',
    '2530 Sharondale Dr',
    'A2',
    'Nashville',
    'TN',
    '37215',
    '6155693159',
    'cell',
    userRoles[0].value,
    2,
  ),
  makeUser(
    'mhendrickson',
    'Mark',
    'Hendrickson',
    '0123456789',
    '3055 Lebonan Pike',
    'RV',
    'Nashville',
    'TN',
    '37214',
    '6756789098',
    'home',
    userRoles[1].value,
    1,
  ),
];

export default users;
