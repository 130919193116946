import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from '@uicl/ui-core/dist/Tabs';
import useLayout from '@uicl/ui-core/dist/useLayout';
import Grid from '@uicl/ui-core/dist/Grid';
import DetailPaneHeader from '@uicl/ui-core/dist/DetailPaneHeader';
import StickyLayout from '@uicl/ui-core/dist/StickyLayout';
import Input from '@uicl/ui-core/dist/Input';
import FormFooter from '@uicl/ui-core/dist/FormFooter';
import Button from '@uicl/ui-core/dist/Button';
import { Checkbox, SelectDropdown } from '@uicl/ui-core';
import { Col, Container, Row } from '@uicl/ui-core/dist/ContentGrid';
import { roleType } from '../modules/constants';

import Pagination from '../../../../components/Pagination';
import GridWrapper from './styled/GridWrapper';
import UserFormWrapper from './styled/UserFormWrapper';
import { loadUserActivity, updateUser } from '../modules/actions';
import userActivityGridConfig from './userActivityGridConfig';
import { loadDepartmentsByCustomer, loadMcoContractsByCustomer, loadRolesByType, loadUnitManagersByCustomer, loadMcoContractByUser } from '../../CreateUser/modules/actions';
import { ruleConstants } from '../../../../commonResources/constants/ruleConstants';
import { isInRole } from '../../../../commonResources/helpers/ruleValidation';
import LoadingOverlay from '../../../../components/loadingOverlay/LoadingOverlay';


const pageIndexes = 5;
const UserReadOnlyForm = () => {

  const {
    rolesByTypeData,
    departmentsData,
    mcoContractsData,
    unitManagerData,
    customerUserMcoContractData
  } = useSelector(state => state.createUser);

  const dispatch = useDispatch();

  const {
    detailProps = { id: 0 },
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    closeDetailPane,
  } = useLayout();

  const {
    isLoadingActivities,
    userActivities,
    userActivitiesTotalPages,
    userActivitiesTotalRecords,
    userActivitiesPages,
    updateUserIsLoading,
    updateUserIsSuccess,
    updateUserError
  } = useSelector(
    (state) => state.searchUsers,
  );

  const { userInformation } = useSelector(state => state.app);

  const filterUserActivityRequest = {
    userId: detailProps ? detailProps.id : 0,
    pageNumber: 1,
    pageSize: 10
  };

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [filterUserActivities, setFilterUserActivities] = useState(filterUserActivityRequest);
  const [initPage, setInitPage] = useState(0);
  const [endPage, setEndPage] = useState(pageIndexes);
  const [activePage, setActivePage] = useState(1);

  const [clickSubmit, setSubmit] = useState(false);
  const [inputErrors, setInputErrors] = useState('');

  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userMiddleInitial, setUserMiddleInitial] = useState('');

  const [userDepartment, setUserDepartment] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const [userUnitManager, setUserUnitManager] = useState([]);
  const [mcoContract, setMcoContract] = useState([]);
  const [isUserActive, setIsUserActive] = useState(false);
  const [isUserLocketOut, setIsUserLocketOut] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState(false);

  const [selectedRole, setSelectedRole] = useState([]);

  const [isUnitMangerSelected, setIsUnitMangerSelected] = useState(false);
  const [isDepartmentMCOSelected, setIsDepartmentMCOSelected] = useState(false);
  // eslint-disable-next-line
  const [isOpenEditOptions, setIsOpenEditOptions] = useState((isInRole(ruleConstants.EditUser, userInformation)));

  const movePagesForward = () => {
    setInitPage(initPage + pageIndexes);
    setEndPage(endPage + pageIndexes);
  };
  const movePagesBackward = () => {
    setInitPage(initPage - pageIndexes);
    setEndPage(endPage - pageIndexes);
  };

  const onGridPag = (pageNumber) => {
    setActivePage(pageNumber);
    setFilterUserActivities({ ...filterUserActivities, pageNumber, userId: detailProps.id });
  };

  useEffect(() => {
    dispatch(loadUserActivity(filterUserActivities));
  }, [filterUserActivities]);

  useEffect(() => {
    if (detailProps !== null) {
      if (Object.keys(detailProps).length !== 0 && detailProps.id !== 0) {
        dispatch(loadUserActivity({
          userId: detailProps ? detailProps.id : 0,
          pageNumber: 1,
          pageSize: 10
        }));
      }
    }
  }, [detailProps]);

  useEffect(() => {
    setInitPage(0);
    setEndPage(pageIndexes);
    setActivePage(1);
    setFilterUserActivities({ ...filterUserActivityRequest });
  }, []);

  useEffect(() => {
    if (detailProps !== null) {
      if (Object.keys(detailProps).length !== 0 && detailProps.id !== 0) {
        resetStates();
        fillDropDownsByCustomer(customer, referralRole)
        fillRoleDropDownByRoleType(referralRole.roleTypeId)
        defaultValues(detailProps.referralDepartment, detailProps.referralRole, detailProps.unitManager)
      }
    }
  }, [detailProps])

  useEffect(() => {
    if (updateUserIsSuccess) {
      closeDetailPane();
    }
  }, [updateUserIsSuccess])

  useEffect(() => {
    dispatch(loadMcoContractByUser(detailProps.customerUserId));
  }, [mcoContractsData])

  useEffect(() => {
    setInputErrors(updateUserError);
  }, [updateUserError])

  const fillRoleDropDownByRoleType = (value) => {
    dispatch(loadRolesByType(value));
  }

  const fillDropDownsByCustomer = (customer) => {
    if (customer.id > 0) {
      dispatch(loadDepartmentsByCustomer(customer.id));
      dispatch(loadMcoContractsByCustomer(customer.id));
      dispatch(loadUnitManagersByCustomer(customer.id));
    }
  }

  const defaultValues = (department, role, unitManager) => {
    setActiveTabIndex(0);
    setUserFirstName(firstName);
    setUserLastName(lastName);
    setUserMiddleInitial(middleInitial);
    setIsUserActive(isActive);
    setIsUserLocketOut(isLockedOut);
    setUserPhoneNumber(phoneNumber);

    if (department !== null) {
      setUserDepartment({
        label: department.name,
        value: department.id,
        // eslint-disable-next-line
        referralDepartment: referralDepartment
      })
    }
    if (unitManager !== null) {
      setUserUnitManager({
        label: `${unitManager.firstName} ${unitManager.middleInitial} ${unitManager.lastName} - ${unitManager.userName}`,
        value: unitManager.id,
        // eslint-disable-next-line
        unitManager: unitManager
      })
    }
    setUserRole([{
      label: role.roleName,
      value: role.id
    }])
    setSelectedRole({
      label: role.roleName,
      value: role.id,
      // eslint-disable-next-line
      role: role
    })
    
    if (role.roleAssociationId === 1) {
      setIsDepartmentMCOSelected(true);
      setIsUnitMangerSelected(false);
    } else if (role.roleAssociationId === 2) {
      setIsUnitMangerSelected(true);
      setIsDepartmentMCOSelected(false);
    } else {
      setIsUnitMangerSelected(false);
      setIsDepartmentMCOSelected(false);
    }
  }

  const {
    firstName = '',
    lastName = '',
    middleInitial = '',
    userName = '',
    phoneNumber = '',
    referralRole = { id: 0, roleName: '' },
    isActive,
    isLockedOut
  } = detailProps || {};

  let {
    referralDepartment = { id: 0, name: '' },
    customer = { id: 0, name: '' },
    unitManager = { id: 0, firstName: '', lastName: '' },
  } = detailProps || {};

  if (referralDepartment === null)
    referralDepartment = { id: 0, name: '' };

  if (customer === null)
    customer = { id: 0, name: '' };

  if (unitManager === null)
    unitManager = { id: 0, firstName: '', lastName: '' };

  const unitManagerName = `${unitManager.firstName} ${unitManager.lastName}`;
  const userFullName = `${firstName} ${lastName}`;

  const handleRoleChange = (e) => {
    setSelectedRole(e ?? [])
    if (e.role.roleAssociationId === 1) {
      setIsDepartmentMCOSelected(true);
      setIsUnitMangerSelected(false);
    } else if (e.role.roleAssociationId === 2) {
      setIsUnitMangerSelected(true);
      setIsDepartmentMCOSelected(false);
    } else {
      setIsUnitMangerSelected(false);
      setIsDepartmentMCOSelected(false);
    }
  }

  const isFormValid = () => {
    let isValid = false;

    if (selectedRole.role.roleTypeId === 1 && isUnitMangerSelected) {
      // eslint-disable-next-line
      isValid = (
        // eslint-disable-next-line
        (!isNullOrEmpty(userFirstName) && isValidName(userFirstName)) &&
        (!isNullOrEmpty(userLastName) && isValidName(userLastName)) &&
        isValidName(userMiddleInitial) &&
        !Array.isArray(selectedRole) &&
        ((isUnitMangerSelected && !Array.isArray(userUnitManager)))
      ) ? true : false;
    }

    if (selectedRole.role.roleTypeId === 1 && isDepartmentMCOSelected) {
      // eslint-disable-next-line
      isValid = (
        // eslint-disable-next-line
        (!isNullOrEmpty(userFirstName) && isValidName(userFirstName)) &&
        (!isNullOrEmpty(userLastName) && isValidName(userLastName)) &&
        isValidName(userMiddleInitial) &&
        !Array.isArray(selectedRole) &&
        ((isDepartmentMCOSelected && !Array.isArray(userDepartment))) &&
        ((isDepartmentMCOSelected && mcoContractHasValue()))
      ) ? true : false;
    }
    // eslint-disable-next-line
    if (selectedRole.role.roleTypeId === 2) {
      // eslint-disable-next-line
      isValid = (
        (!isNullOrEmpty(userFirstName) && isValidName(userFirstName)) &&
        (!isNullOrEmpty(userLastName) && isValidName(userLastName)) &&
        isValidName(userMiddleInitial) &&
        !Array.isArray(selectedRole)
      ) ? true : false;
    }
    // eslint-disable-next-line
    if (selectedRole.role.roleAssociationId === 3) {
      // eslint-disable-next-line
      isValid = (
        (!isNullOrEmpty(userFirstName) && isValidName(userFirstName)) &&
        (!isNullOrEmpty(userLastName) && isValidName(userLastName)) &&
        isValidName(userMiddleInitial) &&
        !Array.isArray(selectedRole)
      ) ? true : false;
    }
    return isValid
  }

  const handleSubmit = () => {
    setSubmit(true);
    
    if (isFormValid()) {
      const data = {
        userId: detailProps.id,
        customerUserId: detailProps.customerUserId,
        email: userName,
        firstName: userFirstName,
        middleInitial: userMiddleInitial,
        lastName: userLastName,
        phoneNumber: getPhoneNumber(userPhoneNumber),
        roleTypeId: selectedRole.role.roleTypeId,
        roleAssociationId: selectedRole.role.roleAssociationId,
        roleId: selectedRole.value,
        customerId: customer.id,
        departmentId: departmentDataToSubmit(),
        mcoContractId: mcoContractDataToSubmit(),
        unitManagerId: unitManagerDataToSubmit(),
        isActive: isUserActive,
        isLockedOut: isUserLocketOut,
      }
      // console.log(data)
      dispatch(updateUser(data));
    }
  }

  const mcoContractDataToSubmit = () => {
    if (Array.isArray(mcoContract)) {
      if (customerUserMcoContractData.length > 0) {
        return customerUserMcoContractData[0].value;
      }
    }
    if (!Array.isArray(mcoContract)) {
      return mcoContract.value
    }
    return '';
  }

  const departmentDataToSubmit = () => {
    if (!Array.isArray(userDepartment)) {
      return userDepartment.value;
    }
    return 0;
  }

  const unitManagerDataToSubmit = () => {
    if (!Array.isArray(userUnitManager)) {
      return userUnitManager.value;
    }
    return 0;
  }

  const getPhoneNumber = (phone) => {
    return phone.replace(/[^\w]/gi, '');
  }

  const isNullOrEmpty = (value) => {
    return value === null || value === '';
  }

  const mcoContractHasValue = () => {
    if (customerUserMcoContractData === undefined) {
      return false;
    }
    if (!Array.isArray(mcoContract))
    {
      if(mcoContract.value !== undefined && mcoContract.value !== null && mcoContract.value !== '') {
        return true;
      }
    }
    if (customerUserMcoContractData.length > 0) {
      if (customerUserMcoContractData[0].value !== null && customerUserMcoContractData[0].value !== undefined && customerUserMcoContractData[0].value !== '') {
        return true;        
      }
    }
    return false;
  }

  const isValidName = (str) => {
    const regex = /^$|^[a-zA-Z ]+$/;
    return regex.test(str);
  }

  const resetStates = () => {
    setSubmit(false);
    setInputErrors('');
    setUserFirstName('');
    setUserLastName('');
    setUserMiddleInitial('');
    setUserDepartment([]);
    setUserRole([]);
    setUserUnitManager([]);
    setMcoContract([]);
    setIsUserActive(false);
    setIsUserLocketOut(false);
    setSelectedRole([]);
    setIsUnitMangerSelected(false);
    setIsDepartmentMCOSelected(false);
  }

  const handleCancel = () => {
    resetStates();
    closeDetailPane();
  }

  const tabs = [
    {
      domID: 'id-1',
      label: 'Details',
      tabContent: (
        <Container>
          {(referralRole.roleTypeId === roleType.EXTERNAL) &&
            <Row>
              <Col>
                <Input
                  domID="userCustomer"
                  disabled
                  initialValue={customer.name}
                  name="userCustomer"
                  label="Customer"
                />
              </Col>
            </Row>
          }
          <Row>
            <Col>
              <Input
                domID="userEmail"
                disabled
                required
                initialValue={userName}
                name="email"
                label="Email"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                domID="userFistName"
                maxLength={30}
                regex={/^[a-zA-Z ]+$/}
                disabled={!isOpenEditOptions}
                // eslint-disable-next-line
                hasError={(clickSubmit && !userFirstName) || (clickSubmit && inputErrors === 'A user with this name already exists')}
                // eslint-disable-next-line
                errorMessage={clickSubmit && !userFirstName ? 'Please enter the First Name' : (clickSubmit && inputErrors === 'A user with this name already exists') ? 'A user with this name already exists' : 'Invalid first name'}
                initialValue={userFirstName}
                name="firstName"
                label="First Name"
                onChange={(e) => { setUserFirstName(e.target.value); setInputErrors(''); }}
              />
            </Col>
            <Col>
              <Input
                domID="userMiddleInitial"
                maxLength={1}
                regex={/^[a-zA-Z ]+$/}
                disabled={!isOpenEditOptions}
                hasError={(clickSubmit && inputErrors === 'A user with this name already exists')}
                errorMessage={(clickSubmit && inputErrors === 'A user with this name already exists') ? 'A user with this name already exists' : 'Invalid middle name'}
                initialValue={userMiddleInitial}
                label="Middle Initial"
                onChange={(e) => { setUserMiddleInitial(e.target.value); setInputErrors(''); }}
              />
            </Col>
            <Col>
              <Input
                domID="userLastName"
                maxLength={30}
                regex={/^[a-zA-Z ]+$/}
                disabled={!isOpenEditOptions}
                // eslint-disable-next-line
                hasError={(clickSubmit && !userLastName) || (clickSubmit && inputErrors === 'A user with this name already exists')}
                // eslint-disable-next-line
                errorMessage={clickSubmit && !userLastName ? 'Please enter the Last Name' : (clickSubmit && inputErrors === 'A user with this name already exists') ? 'A user with this name already exists' : 'Invalid last name'}
                initialValue={userLastName}
                name="lastName"
                label="Last Name"
                onChange={(e) => { setUserLastName(e.target.value); setInputErrors(''); }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {
                isOpenEditOptions
                  ?
                  <SelectDropdown
                    size="medium"
                    domID="userRoleDropDown"
                    name="roleDropDown"
                    label="Role"
                    isClearable={false}
                    required
                    hasError={clickSubmit && selectedRole.length === 0}
                    errorMessage={(clickSubmit && selectedRole.length === 0) && 'Please select a Role'}
                    options={rolesByTypeData}
                    initialValue={userRole}
                    onChange={handleRoleChange}
                    placeholderText="Select the Role"
                  />
                  :
                  <Input
                    domID="userRole"
                    disabled
                    initialValue={referralRole.roleName}
                    name="role"
                    label="Role"
                  />
              }
            </Col>
          </Row>
          {isUnitMangerSelected &&
            <Row>
              <Col>
                {
                  isOpenEditOptions
                    ?
                    <SelectDropdown
                      size="medium"
                      domID="userUnitManagerDropDown"
                      name="unitManager"
                      label="Unit Manager"
                      isClearable={false}
                      required={selectedRole.roleTypeId === 1 && isUnitMangerSelected}
                      hasError={clickSubmit && isUnitMangerSelected && Array.isArray(userUnitManager)}
                      errorMessage={(clickSubmit && isUnitMangerSelected && Array.isArray(userUnitManager)) ? 'Please select an Unit Manager' : ''}
                      options={unitManagerData}
                      initialValue={userUnitManager}
                      onChange={(e) => { setUserUnitManager(e ?? []) }}
                      placeholderText="Select Unit Manager"
                    />
                    :
                    <Input
                      domID="userUnitManager"
                      disabled
                      initialValue={unitManagerName}
                      name="unitManager"
                      label="Unit Manager"
                    />
                }
              </Col>
            </Row>
          }
          {isDepartmentMCOSelected &&
            <Row>
              <Col>
                {
                  isOpenEditOptions
                    ?
                    <SelectDropdown
                      size="medium"
                      domID="userReferralDepartment"
                      dataTestId="test-userReferralDepartmentDropdown"
                      name="userReferralDepartment"
                      isClearable={false}
                      label="Department"
                      required={selectedRole.roleTypeId === 1 && isDepartmentMCOSelected}
                      hasError={clickSubmit && isDepartmentMCOSelected && Array.isArray(userDepartment)}
                      errorMessage={(clickSubmit && isDepartmentMCOSelected && Array.isArray(userDepartment)) ? 'Please select a Department' : ''}
                      options={departmentsData}
                      initialValue={userDepartment}
                      onChange={(e) => { setUserDepartment(e ?? []) }}
                      placeholderText="Select the Department"
                    />
                    :
                    <Input
                      domID="userDepartment"
                      disabled
                      initialValue={referralDepartment.name}
                      name="department"
                      label="Department"
                    />
                }
              </Col>
            </Row>
          }
          {isDepartmentMCOSelected &&
            <Row>
              <Col>
                {
                  isOpenEditOptions
                    ?
                    <SelectDropdown
                      size="medium"
                      domID="userMcoContract"
                      name="userMcoContract"
                      isClearable={false}
                      label="Mco Contract"
                      required={selectedRole.roleTypeId === 1 && isDepartmentMCOSelected}
                      hasError={clickSubmit && isDepartmentMCOSelected && !mcoContractHasValue()}
                      errorMessage={(clickSubmit && isDepartmentMCOSelected && !mcoContractHasValue()) ? 'Please select a Contract' : ''}
                      options={mcoContractsData}
                      initialValue={customerUserMcoContractData}
                      onChange={(e) => { setMcoContract(e ?? []) }}
                      placeholderText="Select the Mco Contract"
                    />
                    :
                    <Input
                      domID="userMcoContract"
                      disabled
                      initialValue={customerUserMcoContractData.length > 0 ? customerUserMcoContractData[0].label : ''}
                      name="mcoContract"
                      label="Mco Contract"
                    />
                }
              </Col>
            </Row>
          }
          <Row>
            <div style={{minWidth:'11vw'}}>
              <Col>
                <Input
                  domID="userPhone"
                  mask='(999) 999-9999'
                  disabled={!isOpenEditOptions}
                  initialValue={userPhoneNumber}
                  onChange={(e) => { setUserPhoneNumber(e.target.value); setInputErrors('') }}
                  name="phone"
                  label="Phone"
                  hasError={(clickSubmit && inputErrors === 'Invalid phone number')}
                  errorMessage={(clickSubmit && inputErrors === 'Invalid phone number') ? 'Invalid phone number' : 'Invalid phone number'}
                />
              </Col>
            </div>
            <div style={{marginTop:'25px'}}>
              <Col>
                <Checkbox
                  domID='isActive'
                  ariaLabelledBy=""
                  label="Is Active?"
                  disabled
                  checked={isActive}
                  onChange={() => setIsUserActive(!isUserActive)}
                  size="medium"
                />
              </Col>
            </div>
            {/* <Col>
              <Checkbox
                domID='isLockedOut'
                ariaLabelledBy=""
                label="Is Locked out?"
                disabled={!isOpenEditOptions}
                checked={isLockedOut}
                onChange={() => setIsUserLocketOut(!isUserLocketOut)}
                size="medium"
              />
            </Col> */}
          </Row>

        </Container>
      ),
    },
    {
      domID: 'id-2',
      label: 'Activity',
      tabContent: (
        <GridWrapper className="detail-pane-grid">
          <Grid
            {...userActivityGridConfig}
            records={userActivities || []}
          >
          </Grid>

          <Pagination
            totalRecords={userActivitiesTotalRecords}
            pageNumber={filterUserActivities.pageNumber}
            pageSize={filterUserActivities.pageSize}
            pages={userActivitiesPages}
            totalPages={userActivitiesTotalPages}
            activePage={activePage}
            initPage={initPage}
            endPage={endPage}
            onGridPag={onGridPag}
            movePagesForward={movePagesForward}
            movePagesBackward={movePagesBackward} />
        </GridWrapper>
      ),
    },
  ];

  const tabsProp = {
    tabs: [
      { label: 'Details', domID: 'id-1' },
      { label: 'Activities', domID: 'id-2' },
    ],
    initialTab: activeTabIndex,
  }

  return (
    <StickyLayout
      footerContent={
        <FormFooter>
          {
            isOpenEditOptions
              ?
              <>
                <Button
                  dataTestId="cancel-button"
                  buttonType="diminished"
                  name="Back to User Search"
                  size="medium"
                  onClick={handleCancel}
                />
                <Button
                  dataTestId="update-button"
                  name='Save'
                  onClick={handleSubmit}
                />
              </>
              :
              <>
                <Button
                  dataTestId="cancel-button"
                  buttonType="diminished"
                  name="Back to User Search"
                  size="medium"
                  onClick={handleCancel}
                />
              </>
          }
        </FormFooter>
      }
    >

      <DetailPaneHeader
        title={userFullName}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig,
        ]}
      />
      <UserFormWrapper>
        { (isLoadingActivities || updateUserIsLoading ) && <LoadingOverlay /> } 
        <Tabs
          {...tabsProp}
          onTabSelect={(e, state) => {
            setActiveTabIndex(state.selectedTabIndex);
          }}
        >
        </Tabs>
        {activeTabIndex === 0 && tabs[activeTabIndex].tabContent}
        {activeTabIndex === 1 && tabs[activeTabIndex].tabContent}
      </UserFormWrapper>
      
    </StickyLayout>
  )
}

export default UserReadOnlyForm;