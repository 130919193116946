import { fetchWithToken } from "../../../../commonResources/helpers/fetch"

export const getRoles = async () => (
  fetchWithToken(`referralroles`).then(res => res.json())
)
export const getRolesByRoleType = async (roleTypeId) => (
  fetchWithToken(`referralroles/getRolesByRoleType/${roleTypeId}`).then(res => res.json())
)
export const getMcoContractsByCustomer = async (customerId) => (
  fetchWithToken(`McoContracts/customer/${customerId}/items`).then(res => res.json()).then(json => json)
)
export const getDepartmentsByCustomer = async (customerId) => (
  fetchWithToken(`referraldepartments/customer/${customerId}/items`).then(res => res.json()).then(json => json)
)
export const getUnitManagersByCustomer = async (customerId) => (
  fetchWithToken(`referralusers/search?RoleId=129&CustomerId=${customerId}&PageSize=10000000`).then(res => res.json()).then(json => json)
)
export const postUser = async (data) => (
  fetchWithToken(`ReferralUsers/createUser`, data, 'POST').then(res => res.json()).then(json => json)
)
export const getMcoContractByUser = async (customerUserId) => (
  fetchWithToken(`referralusers/mcoContractByUser/${customerUserId}`).then(res => res.json()).then(json => json)
)