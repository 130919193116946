

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@uicl/ui-core';
import { Col, Container, Row } from '@uicl/ui-core/dist/ContentGrid';

const paginationStyle = {
  display: "flex",
  justifyContent : "flex-end"
}

const Pagination = ({totalRecords,totalPages,pageSize,pageNumber,initPage,endPage,activePage,pages,movePagesBackward,movePagesForward,onGridPag}) => (
  <Container>
    <Row align="end">
      <Col>{ totalRecords > 0 && <p>Showing { 1 + ((pageSize * pageNumber) - pageSize) } to {(pageSize * pageNumber) < totalRecords ? pageSize * pageNumber : totalRecords} of {totalRecords} entries</p>}</Col>
      <Col>
        <Row style={paginationStyle}>
          { (initPage > 0)? (<Button buttonType = 'diminished' size = 'medium'  name = '<<' onClick = {() => movePagesBackward()}/>): (<></>)}
          {pages.slice(initPage,endPage).map( x => <Button index = {x} buttonType = { activePage === x ? 'deEmphasized': 'diminished'}   size = 'medium'  name = {x.toString()} onClick = {() => onGridPag(x)}/>)}
          { (totalPages > endPage)? (<Button buttonType = 'diminished' size = 'medium'  name = '>>' onClick = {() => movePagesForward()}/>): (<></>)}
        </Row>
      </Col>
    </Row>
  </Container>
);

export default Pagination;

Pagination.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  totalRecords: PropTypes.number,
  totalPages: PropTypes.number,
  pageSize: PropTypes.number,
  pageNumber: PropTypes.number,
  initPage: PropTypes.number,
  endPage: PropTypes.number,
  activePage: PropTypes.number,
  pages: PropTypes.array,
  movePagesBackward: PropTypes.func,
  movePagesForward: PropTypes.func,
  onGridPag: PropTypes.func,
};
