import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useLayout from '@uicl/ui-core/dist/useLayout';
import DetailPaneHeader from '@uicl/ui-core/dist/DetailPaneHeader';
import { Checkbox, Col, Container, DataItem, Grid, Row } from '@uicl/ui-core';
import Button from '@uicl/ui-core/dist/Button';
import RulesGridConfig from './RulesGridConfig';
import { loadRules, updateRole } from '../modules/actions';
import '../styles/styles.css'
import Notification from '../../../../components/notifications/Notification';
import LoadingPage from '../../../../components/LoadingPage';

const RolesDetail = ({ dataTestId }) => {

  const {
    detailProps = {},
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    closeDetailPane,
  } = useLayout();
  const dispatch = useDispatch();

  const { rules, rolesIsLoading, roleUpdateIsLoading } = useSelector(state => state.roles);
  const { userInformation } = useSelector(state => state.app);
  const [selectedRules, setSelectedRules] = useState([]);
  const [initialSelectedRules, setInitialSelectedRules] = useState([]);
  const [initialValuesGrid, setInitialValuesGrid] = useState(new Set([]));
  const [isDeletedValue, setIsDeletedValue] = useState(null);
  const [isDeletedInitialValue, setIsDeletedInitialValue] = useState(null);
  const [isValidationError, setIsValidationError] = useState(false);
  const [isSameValues, setIsSameValues] = useState(false);

  useEffect(() => {
    if (detailProps !== null) {
      if (Object.keys(detailProps).length !== 0) {
        if (rules.length === 0) {
          dispatch(loadRules());
        }
        const initValues = new Set(detailProps.roleRules.map(({ id }) => id))
        setInitialValuesGrid(initValues);
        setIsDeletedValue(detailProps.isDeleted);
        setIsDeletedInitialValue(detailProps.isDeleted);
        handleSelectRules(initValues);
        handleInitialSelectRules(initValues);
      }
    }
  }, [detailProps])

  const handleUpdate = () => {
    if (isDeletedInitialValue === isDeletedValue && isSameRules(initialSelectedRules, selectedRules)) {
      setIsSameValues(true);
      return
    }
    if (selectedRules.length >= 1) {
      const data = {
        id: detailProps.id,
        userName: userInformation.userName,
        isDeleted: isDeletedValue,
        rules: isSameRules(initialSelectedRules, selectedRules) ? [] : selectedRules,
      }
      dispatch(updateRole(data));

      return
    }
    setIsValidationError(true);
  }

  useEffect(() => {
    if (rolesIsLoading) {
      closeDetailPane();
    }
  }, [rolesIsLoading])


  const handleSelectRules = (items) => {
    const selectedItems = rules.filter(item => items.has(item.id));
    const rulesToSubmit = selectedItems.map(({ ruleName }) => ({ ruleName }));
    setSelectedRules(rulesToSubmit);
  }

  const handleInitialSelectRules = (items) => {
    const selectedItems = rules.filter(item => items.has(item.id));
    const rulesToSubmit = selectedItems.map(({ ruleName }) => ({ ruleName }));
    setSelectedRules(rulesToSubmit);
    setInitialSelectedRules(rulesToSubmit);
  }

  const isSameRules = (arr1, arr2) => {
    if (JSON.stringify(arr1) === JSON.stringify(arr2)) {
      return true
    }
    return false
  }

  return (detailProps ?
    <>
      <DetailPaneHeader
        title='Roles Detail'
        dataTestId={`${dataTestId}-header`}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig,
        ]}
      />
      {isValidationError && (
        <Notification
          title="Error"
          text="You must select at least 1 Rule"
          type="error"
          onDismiss={() => setIsValidationError(false)}
        />
      )}
      {isSameValues && (
        <Notification
          title="Info"
          text="Nothing to update"
          type="info"
          onDismiss={() => setIsSameValues(false)}
        />
      )}
      {
        roleUpdateIsLoading
          ?
          <LoadingPage />
          :
          <Container>
            <Row>
              <DataItem
                label='Role'
                content={detailProps.roleName}
              />
            </Row>
            <Row>
              <DataItem
                label='Role Type'
                content={detailProps.roleTypeName}
              />
            </Row>
            {
              detailProps.roleAssociationName &&
              <Row>
                <DataItem
                  label='Role Association'
                  content={detailProps.roleAssociationName}
                />
              </Row>
            }
            <Grid
              {...RulesGridConfig}
              records={rules}
              className='data-grid-details'
              supportSelection
              initialSelectedItemIds={initialValuesGrid}
              dataTestId="test-formGrid"
              onRowSelect={(e, gridState) => {
                // const selectedItems = rules.filter(item => gridState.selectedItemIds.has(item.id));
                // const rulesToSubmit = selectedItems.map(({ ruleName }) => ({ ruleName }));
                // setSelectedRules(rulesToSubmit);
                handleSelectRules(gridState.selectedItemIds);
              }}
              onSelectAll={(e, gridState) => {
                // const selectedItems = rules.filter(item => gridState.selectedItemIds.has(item.id));
                // const rulesToSubmit = selectedItems.map(({ ruleName }) => ({ ruleName }));
                // setSelectedRules(rulesToSubmit);
                handleSelectRules(gridState.selectedItemIds);
              }}
            />
            <Row>
              <Checkbox label='Is Deleted' checked={detailProps.isDeleted} onChange={() => setIsDeletedValue(!isDeletedValue)} />
            </Row>
            <Row>
              <Col>
                <DataItem
                  label='Created By'
                  content={detailProps.createdBy}
                />
              </Col>
              <Col>
                <DataItem
                  label='Created Date'
                  content={detailProps.createdDate}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <DataItem
                  label='Last Update By'
                  content={detailProps.lastUpdateBy}
                />
              </Col>
              <Col>
                <DataItem
                  label='Last Update Date'
                  content={detailProps.lastUpdateDate}
                />
              </Col>
            </Row>
            <br />
            <Button onClick={handleUpdate}>
              Save
            </Button>
            <Button buttonType="diminished" onClick={closeDetailPane}>
              Back to Roles Search
            </Button>

          </Container>
      }
    </> : null
  )
}

export default RolesDetail

RolesDetail.propTypes = {
  dataTestId: PropTypes.string,
};
