import React from 'react'
import NavigationPane from '../../NavigationPane/containers/NavigationPaneContainer';
import PageWrapper from '../../PageWrapper';
import CreateReferral from './components/CreateReferral';

/*eslint-disable */


const Referral = () => {
  return (
    <PageWrapper
      navigationPaneContent={<NavigationPane componentName = "Referral"/>}
      listPaneContent={<CreateReferral />}
    />
  );
};

export default Referral;