import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NotificationBanner } from '@uicl/ui-core';
import Notification from '../../../components/notifications/Notification';
import NavigationPane from '../../NavigationPane/containers/NavigationPaneContainer';
import PageWrapper from '../../PageWrapper';
import SearchUser from './components/SearchUser';
import UserForm from './components/UserForm';
import { setUpdateUserNotificationsToFalse } from './modules/actions';

const Users = () => {
  const {
    updateUserIsSuccess,
    updateUserIsFail
  } = useSelector(state => state.searchUsers);
  const dispatch = useDispatch();

  return (
    <>
      {updateUserIsSuccess && (
        <Notification
          title="Success"
          text="User Successfully Updated"
          type="success"
          timer={3500}
          onDismiss={() => dispatch(setUpdateUserNotificationsToFalse())}
        />
      )}
      {updateUserIsFail && (
        <NotificationBanner
          autoDismiss
          onDismiss={() => dispatch(setUpdateUserNotificationsToFalse())}
          text='Something went wrong while fetching data. Update User'
          type="negative"
        />
      )}
      <PageWrapper
        navigationPaneContent={<NavigationPane componentName="Users" />}
        listPaneContent={<SearchUser />}
        detailPaneContent={<UserForm />}
      />
    </>
  );
};

export default Users;