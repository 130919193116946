import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Button, DatePicker } from '@uicl/ui-core';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SelectDropdown from '@uicl/ui-core/dist/SelectDropdown';
import { loadCustomers, loadProdcuts, loadDepartments, clearDepartments } from '../modules/actions';
import { pageSizeOptions, defaultOptions, validationKeys, validationDefault, validationErrorMessages } from '../modules/constants';
import { isValidDate } from '../../../../commonResources/helpers/date';

const ReferralByProductFilters = (props) => {

  const { onSubmit } = props;

  const dispatch = useDispatch();
  const [products, customers] = useSelector((state) => [
    state.referralByProduct.products,
    state.referralByProduct.customers,
  ]);
  
  const departments = useSelector((state) => state.referralByProduct.departments);
  const userAttachedCustomerId = useSelector((state) => state.app.userInformation.customerId);
  const [totalRecords, loadingErrors, tryToAttachedCustomer] = useSelector((state) => [
    state.referralByProduct.totalRecords,
    state.referralByProduct.errors,
    state.referralByProduct.tryToAttachedCustomer,
  ]);

  const [validationValues, setValidationValues] = useState(validationDefault);
  const [validationErrors, setvalidationErrors] = useState({});
  const [attachedCustomer, setAttachedCustomer] = useState({});

  useEffect(() => {
    dispatch(loadProdcuts());
    dispatch(loadCustomers());
  }, []);

  useEffect(() => {
    const validCustomer = customers.find(customer => (customer.id === userAttachedCustomerId));
    if (validCustomer === undefined) {
      setAttachedCustomer(defaultOptions.attachedCustomer);
      return;
    }

    const attached = { isAttached: true, name: validationKeys.customer, value: validCustomer.id, label: validCustomer.name };
    setAttachedCustomer(attached); 
    setValidationValues({...validationValues, [validationKeys.customer]: { name: validationKeys.customer, value: validCustomer.id , label: validCustomer.name }});
    dispatch(loadDepartments(validCustomer.id));
  }, [tryToAttachedCustomer]);
  
  const onCustomerSelected = (customer) => {
    const { name, value, label } = customer;
    setValidationValues({...validationValues, [name]: { name, value, label }, 
      [validationKeys.department]: defaultOptions.departments });
   
    if (value === 0) {
      dispatch(clearDepartments());
    }
    else {
      dispatch(loadDepartments(value));
    }
  }

  const handleChange = (event) => {
    const { name, value } = event;
    setValidationValues({...validationValues, [name]: value });
  }

  const handleChangeSelect = (event) => {
    const { name, value, label } = event;
    setValidationValues({...validationValues, [name]: { name, value, label } });
  }

  const onSearchClicked = (exportToExcel) => {
    const errors = validate(validationValues);
    setvalidationErrors(errors);
  
    if (Object.keys(errors).length > 0) {
      return;
    }
   
    const searchFilers = {...validationValues, 
      [validationKeys.pageSize]: validationValues.pageSize.value,
      [validationKeys.product]: validationValues.product.value,
      [validationKeys.customer]: validationValues.customer.value,
      [validationKeys.department]: validationValues.department.value, 
      [validationKeys.fromDate]: validationValues.fromDate,
      [validationKeys.toDate]: validationValues.toDate,
      [validationKeys.exportToExcel]: exportToExcel };

    onSubmit(searchFilers);
  }

  const validate = (valuesToValidate) => {
    const errors = {};

    if (valuesToValidate.product.value === 0) {
      errors.productHasErrors = true;
      errors.productErrorMessage = validationErrorMessages.productRequired;
    }

    if (valuesToValidate.customer.value === 0) {
      errors.customerHasErrors = true;
      errors.customerErrorMessage = validationErrorMessages.customerRequired;
    }

    if (valuesToValidate.fromDate === "") {
      errors.fromDateHasErrors = true;
      errors.fromDateErrorMessage = validationErrorMessages.fromDateRequired;
    }

    if (!errors.fromDateHasErrors && !isValidDate(valuesToValidate.fromDate)) {
      errors.fromDateHasErrors = true;
      errors.fromDateErrorMessage = validationErrorMessages.dateFormat;
    }

    const fromDate = new Date(valuesToValidate.fromDate);
    const currentDate = new Date(Date.now());

    if (valuesToValidate.toDate !== "") {
      if (!isValidDate(valuesToValidate.toDate)) 
      {
        errors.toDateHasErrors = true;
        errors.toDateErrorMessage = validationErrorMessages.dateFormat;
      }

      const toDate = new Date(valuesToValidate.toDate);
  
      if (isValidDate(valuesToValidate.toDate) && fromDate >= toDate) {
        errors.toDateHasErrors = true;
        errors.toDateErrorMessage = validationErrorMessages.dateRangeTo;
      }
    }

    if (fromDate > currentDate) {
      errors.fromDateHasErrors = true;
      errors.fromDateErrorMessage = validationErrorMessages.dateRangeToday;
    }

    return errors;
  }

  const renderErrors = loadingErrors.map(error => error.message.length > 0 && <div key={error.key}>{error.message}</div>);

  return (
    <Container>
      <Row>
        <Col>
          <SelectDropdown
            size = "medium"
            domID="productDropdownID"
            dataTestId="test-refProducts"
            label="Products"
            placeholderText="Select a Product"
            options={products.map(({id, alternateName}) => ( { name: validationKeys.product, value: id, label: alternateName }))}
            onChange={(e) => handleChangeSelect(e ?? defaultOptions.products )}
            hasError={validationErrors.productHasErrors}
            errorMessage={validationErrors.productErrorMessage}
          />
        </Col>
        <Col>
          <DatePicker
            label="Date From"
            domID="dateFromID"
            hasError={validationErrors.fromDateHasErrors}
            errorMessage={validationErrors.fromDateHasErrors && validationErrors.fromDateErrorMessage || validationErrorMessages.dateFormat}
            onInputChange={(e) => handleChange({name: validationKeys.fromDate, value: e})}
          />    
        </Col>
      </Row>
      <Row> 
        <Col>
          {attachedCustomer.isAttached ? 
            <SelectDropdown
              size = "medium"
              domID="customerDropdownID"
              dataTestId="test-Customers"
              label="Customer"
              placeholderText="Select the customer"
              options={customers.map(({id, name}) => ({ name: validationKeys.customer, value: id, label: name }))}
              value={{name: validationKeys.customer, value: attachedCustomer.value, label: attachedCustomer.label} }
              hasError={validationErrors.customerHasErrors}
              errorMessage={validationErrors.customerErrorMessage}
              disabled
            />
            : 
            <SelectDropdown
              size = "medium"
              domID="customerDropdownID"
              dataTestId="test-Customers"
              label="Customer"
              placeholderText="Select the customer"
              options={customers.map(({id, name}) => ({ name: validationKeys.customer, value: id, label: name }))}
              onChange={(e) => onCustomerSelected(e ?? defaultOptions.customers )}
              hasError={validationErrors.customerHasErrors}
              errorMessage={validationErrors.customerErrorMessage}
            /> } 
        </Col>
        <Col>
          <DatePicker
            label="Date Through"
            domID="dateThroughID"
            hasError={validationErrors.toDateHasErrors}
            errorMessage={validationErrors.toDateHasErrors && validationErrors.toDateErrorMessage || validationErrorMessages.dateFormat}
            onInputChange={(e) => handleChange({name: validationKeys.toDate, value: e})}
          />   
        </Col>
      </Row> 
      <Row>
        <Col>
          <SelectDropdown
            size="medium"
            domID="departmentDropdownID"
            dataTestId="test-refDepartments"
            label="Department"
            placeholderText="Select a department"
            options={departments.map(({id, name}) => ({ name: validationKeys.department, label: name, value: id }))}
            value={validationValues.department}
            onChange={(e) => handleChangeSelect(e ?? defaultOptions.departments )}
          />
        </Col>
        <Col>
          <div>
            <SelectDropdown
              size = "medium"
              domID="pageSizeDropdownID"
              dataTestId="test-pageSize"
              label="Show"
              options = {pageSizeOptions}
              value={validationValues.pageSize}
              onChange={(e) => handleChangeSelect(e ?? defaultOptions.pageSize )}
            /> 
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
        </Col>
        <Col>
          <div className='column-button'>
            <Button className='search-button'
              size="medium"
              onClick={ () => onSearchClicked(false) }
              name="Search"
              dataTestId="test-formButton"
            />
            {totalRecords > 0 &&
            <Button className='referralFiltersExportExcelButton'
              onClick={ () => onSearchClicked(true) }
            >Export Result to Excel</Button>}
          </div> 
        </Col>
      </Row>
      <div>
        {renderErrors}
      </div>
    </Container>
  );
}

export default ReferralByProductFilters;

ReferralByProductFilters.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  onSubmit: PropTypes.func,
}