import moment from 'moment';

const SORT_ASCENDING = 'SORT_ASCENDING';

// advance search stub data
export const advanceSearchRecords = [
  {
    jobId: '1613371403',
    serviceDate: '04/20/2020',
    patientName: 'Chip Kemster',
    provider: 'Eichmann, Hoeger and Champlin',
    location: 'Duping',
  },
  {
    jobId: '9184235128',
    serviceDate: '07/12/2020',
    patientName: 'Wallache Jakovijevic',
    provider: 'Stehr Group',
    location: 'Baiqiao',
  },
  {
    jobId: '7335818540',
    serviceDate: '04/02/2020',
    patientName: 'Karie Erasmus',
    provider: 'Pollich LLC',
    location: 'Ilo',
  },
  {
    jobId: '3638375072',
    serviceDate: '07/01/2020',
    patientName: 'Rochell Tineman',
    provider: 'West Group',
    location: 'Mangaldan',
  },
  {
    jobId: '1754907431',
    serviceDate: '05/29/2020',
    patientName: 'Crystal Kilgallen',
    provider: 'Kunze-Huels',
    location: 'Huajie',
  },
  {
    jobId: '1722902523',
    serviceDate: '07/23/2020',
    patientName: 'Margery Lokier',
    provider: 'Abernathy, Johnston and Carter',
    location: 'Lesogorsk',
  },
  {
    jobId: '9097796253',
    serviceDate: '07/12/2020',
    patientName: 'Emyle Averall',
    provider: 'Kutch and Sons',
    location: 'Yangcun',
  },
  {
    jobId: '7649147150',
    serviceDate: '04/19/2020',
    patientName: 'Johna Torrent',
    provider: 'MacGyver, Hermiston and Herzog',
    location: 'Pamarayan',
  },
  {
    jobId: '4793578306',
    serviceDate: '06/13/2020',
    patientName: 'Dulce Duffy',
    provider: 'Stoltenberg and Sons',
    location: 'Raas',
  },
  {
    jobId: '4043425945',
    serviceDate: '05/10/2020',
    patientName: 'Alan McKie',
    provider: 'Raynor LLC',
    location: 'Las Heras',
  },
  {
    jobId: '5367665918',
    serviceDate: '05/28/2020',
    patientName: 'Em Harly',
    provider: 'Bartoletti, Sanford and Hamill',
    location: 'Serrana',
  },
  {
    jobId: '5764354641',
    serviceDate: '05/18/2020',
    patientName: 'Loralie Cheer',
    provider: 'Rippin Group',
    location: 'Bautista',
  },
  {
    jobId: '7352288759',
    serviceDate: '04/16/2020',
    patientName: 'Christiane Twiname',
    provider: 'Harber, Champlin and Maggio',
    location: 'Kihancha',
  },
  {
    jobId: '8739517187',
    serviceDate: '04/28/2020',
    patientName: 'Harcourt Kitson',
    provider: 'Mann-Romaguera',
    location: 'Ribeirão',
  },
  {
    jobId: '5934216864',
    serviceDate: '05/30/2020',
    patientName: 'Emmott Brimelow',
    provider: 'Prohaska LLC',
    location: 'Le Chambon-Feugerolles',
  },
  {
    jobId: '8406752416',
    serviceDate: '04/04/2020',
    patientName: 'Atalanta Pride',
    provider: 'Larson, Paucek and Bednar',
    location: 'Město',
  },
  {
    jobId: '9867498283',
    serviceDate: '07/05/2020',
    patientName: 'Thorn Bevir',
    provider: 'Reinger Inc',
    location: 'Abangay',
  },
  {
    jobId: '5294396807',
    serviceDate: '05/14/2020',
    patientName: 'Johnny Battison',
    provider: 'Torphy and Sons',
    location: 'Mosteiros',
  },
  {
    jobId: '5394062196',
    serviceDate: '04/02/2020',
    patientName: 'Kristofor Robrose',
    provider: 'Gibson, Smitham and Keebler',
    location: 'Huangtan',
  },
  {
    jobId: '9365858925',
    serviceDate: '04/08/2020',
    patientName: 'Martino Durrett',
    provider: 'Pouros LLC',
    location: 'Anlu',
  },
  {
    jobId: '9096590619',
    serviceDate: '04/07/2020',
    patientName: 'Hendrick Grafhom',
    provider: 'Reichert-Cole',
    location: 'Ila Orangun',
  },
  {
    jobId: '8529908813',
    serviceDate: '08/11/2020',
    patientName: 'Brew Riccetti',
    provider: 'Durgan-Christiansen',
    location: 'Longwood',
  },
  {
    jobId: '4878208910',
    serviceDate: '07/11/2020',
    patientName: 'Barry Fayter',
    provider: 'Brakus, Frami and Kunde',
    location: 'Saint-Gaudens',
  },
  {
    jobId: '5210808734',
    serviceDate: '06/01/2020',
    patientName: 'Darb Baudry',
    provider: 'Connelly, Dooley and Leuschke',
    location: 'Kuala Terengganu',
  },
  {
    jobId: '1517974038',
    serviceDate: '05/08/2020',
    patientName: 'Ilene Mandre',
    provider: 'Koepp Group',
    location: 'Lusacan',
  },
  {
    jobId: '1569718539',
    serviceDate: '07/10/2020',
    patientName: 'Rubi Porkiss',
    provider: 'Roberts-Ritchie',
    location: 'Kugesi',
  },
  {
    jobId: '9753505957',
    serviceDate: '04/10/2020',
    patientName: 'Kevin Spaven',
    provider: 'Breitenberg-DuBuque',
    location: 'Capas',
  },
  {
    jobId: '1118982894',
    serviceDate: '04/07/2020',
    patientName: 'Maryellen Roderigo',
    provider: 'Reichel-Kuphal',
    location: 'Talisay',
  },
  {
    jobId: '4207882030',
    serviceDate: '05/26/2020',
    patientName: 'Burt Yeatman',
    provider: 'Dibbert Group',
    location: 'Xinzhai',
  },
  {
    jobId: '0641273266',
    serviceDate: '04/25/2020',
    patientName: 'Jaquenette Morehall',
    provider: 'Cormier Group',
    location: 'Xinxu',
  },
  {
    jobId: '8707654677',
    serviceDate: '07/10/2020',
    patientName: 'Isahella Wharf',
    provider: 'Strosin, Schmitt and Bartell',
    location: 'Den Chai',
  },
  {
    jobId: '1014601398',
    serviceDate: '04/23/2020',
    patientName: 'Jody Mines',
    provider: 'Kovacek-Predovic',
    location: 'Tarīm',
  },
  {
    jobId: '7226263246',
    serviceDate: '07/12/2020',
    patientName: 'Eryn Gell',
    provider: 'Will, Turcotte and Bruen',
    location: 'Bęczarka',
  },
  {
    jobId: '0490117139',
    serviceDate: '05/07/2020',
    patientName: 'Sabra Croft',
    provider: 'Kessler, Luettgen and Boehm',
    location: 'El Ksour',
  },
  {
    jobId: '4891774002',
    serviceDate: '07/22/2020',
    patientName: 'Nico Gooder',
    provider: 'Denesik, Bernhard and Luettgen',
    location: 'Terpsithéa',
  },
  {
    jobId: '4467327347',
    serviceDate: '05/12/2020',
    patientName: 'Neron Rowesby',
    provider: 'Bartell and Sons',
    location: 'London',
  },
  {
    jobId: '4551998036',
    serviceDate: '07/17/2020',
    patientName: 'Tiffani Pynner',
    provider: 'Kessler-Hane',
    location: 'Pulupandan',
  },
  {
    jobId: '7349395264',
    serviceDate: '06/11/2020',
    patientName: 'Evelin Thayre',
    provider: 'Durgan, Hagenes and Hahn',
    location: 'La Cumbre',
  },
  {
    jobId: '4528254158',
    serviceDate: '05/31/2020',
    patientName: 'Edmon Boaler',
    provider: 'Rutherford, Schmeler and Mitchell',
    location: 'Greater Napanee',
  },
  {
    jobId: '2364539455',
    serviceDate: '05/06/2020',
    patientName: 'Moria Hazeldine',
    provider: 'Ziemann LLC',
    location: 'Zhoujiaba',
  },
  {
    jobId: '5200346002',
    serviceDate: '07/07/2020',
    patientName: 'Frederigo Shelmerdine',
    provider: 'Durgan-Corkery',
    location: 'Zdiby',
  },
  {
    jobId: '9807021480',
    serviceDate: '06/03/2020',
    patientName: 'Brenden Ethelston',
    provider: 'Bogisich-Gorczany',
    location: 'Xingang',
  },
  {
    jobId: '9745367133',
    serviceDate: '07/20/2020',
    patientName: 'Stephannie Dodworth',
    provider: 'Kovacek, Bergnaum and Heaney',
    location: 'Mokopane',
  },
  {
    jobId: '3350246834',
    serviceDate: '04/27/2020',
    patientName: 'Keary Linny',
    provider: 'Lesch-Medhurst',
    location: 'Qaryūt',
  },
  {
    jobId: '3952485411',
    serviceDate: '06/11/2020',
    patientName: 'Amby Ruberry',
    provider: 'Bins, Mertz and Rosenbaum',
    location: 'Laranjal Paulista',
  },
  {
    jobId: '3234832714',
    serviceDate: '05/03/2020',
    patientName: 'Ignatius Willstrop',
    provider: 'Considine-Brown',
    location: 'Raheny',
  },
  {
    jobId: '9307406456',
    serviceDate: '05/31/2020',
    patientName: 'Tera Rioch',
    provider: 'Mitchell Group',
    location: 'Avignon',
  },
  {
    jobId: '2917629886',
    serviceDate: '07/24/2020',
    patientName: 'Carrissa Heeran',
    provider: 'Steuber, Christiansen and Spencer',
    location: 'Severnoye',
  },
  {
    jobId: '8953782570',
    serviceDate: '05/12/2020',
    patientName: 'Velma Behrens',
    provider: 'Gislason LLC',
    location: 'Heshan',
  },
  {
    jobId: '8332214211',
    serviceDate: '04/20/2020',
    patientName: 'Timmy Hannaway',
    provider: 'Considine-Collier',
    location: 'Bundoran',
  },
  {
    jobId: '6034254574',
    serviceDate: '06/06/2020',
    patientName: 'Gabby Boutellier',
    provider: 'Cummerata Inc',
    location: 'Maibu',
  },
  {
    jobId: '2181225591',
    serviceDate: '07/17/2020',
    patientName: 'Alissa Chidler',
    provider: 'Mraz, Lemke and Gottlieb',
    location: 'Tam Bình',
  },
  {
    jobId: '5153374866',
    serviceDate: '07/17/2020',
    patientName: 'Teodoor Mackiewicz',
    provider: 'Satterfield-Daniel',
    location: 'Kediri',
  },
  {
    jobId: '7816642121',
    serviceDate: '06/20/2020',
    patientName: 'L;urette Jozsef',
    provider: 'Effertz Inc',
    location: 'Klerksdorp',
  },
  {
    jobId: '5636870396',
    serviceDate: '06/17/2020',
    patientName: 'Althea Corkill',
    provider: 'Huel and Sons',
    location: 'Colonia Catuete',
  },
  {
    jobId: '1766597793',
    serviceDate: '08/01/2020',
    patientName: 'Lazaro Chene',
    provider: 'Kertzmann-Schultz',
    location: 'Huangsha',
  },
  {
    jobId: '2074915373',
    serviceDate: '08/03/2020',
    patientName: "Perla O'Hartnedy",
    provider: 'Grimes Group',
    location: 'Cavadas',
  },
  {
    jobId: '2570498157',
    serviceDate: '04/28/2020',
    patientName: 'Blondy Lomas',
    provider: 'Heathcote-Herzog',
    location: 'Potštát',
  },
  {
    jobId: '4033304193',
    serviceDate: '08/12/2020',
    patientName: 'Thatcher Tuftin',
    provider: 'Berge-Rowe',
    location: 'Huili',
  },
  {
    jobId: '3274661619',
    serviceDate: '07/04/2020',
    patientName: 'Waly Hartfield',
    provider: 'Strosin, Brakus and Runolfsdottir',
    location: 'Ust’-Dzheguta',
  },
  {
    jobId: '8537835560',
    serviceDate: '07/02/2020',
    patientName: 'Wilden Lorman',
    provider: 'Cummings, Kutch and McGlynn',
    location: 'Ganhe',
  },
  {
    jobId: '0287191818',
    serviceDate: '04/02/2020',
    patientName: 'Lula Crippin',
    provider: 'Pollich Inc',
    location: 'Tegalheras',
  },
  {
    jobId: '2627112104',
    serviceDate: '04/23/2020',
    patientName: 'Aime Colbran',
    provider: 'Heller Group',
    location: 'Mamponteng',
  },
  {
    jobId: '5918780882',
    serviceDate: '06/20/2020',
    patientName: 'Lucian Laviste',
    provider: 'Langosh-Mayer',
    location: 'Carrigaline',
  },
  {
    jobId: '3883165468',
    serviceDate: '08/16/2020',
    patientName: 'Adan Chitson',
    provider: 'Thompson LLC',
    location: 'Vnorovy',
  },
  {
    jobId: '3882611472',
    serviceDate: '04/07/2020',
    patientName: 'Vlad Fley',
    provider: 'Denesik LLC',
    location: 'Linjiang',
  },
  {
    jobId: '8382442226',
    serviceDate: '04/03/2020',
    patientName: 'Audi Dilawey',
    provider: 'Murray, Okuneva and Ratke',
    location: 'Jhang Sadr',
  },
  {
    jobId: '3926555033',
    serviceDate: '06/01/2020',
    patientName: 'Ardys Cordeix',
    provider: 'Wisoky-Green',
    location: 'Andres Bonifacio',
  },
  {
    jobId: '9224171680',
    serviceDate: '06/01/2020',
    patientName: 'Vittoria Biaggelli',
    provider: 'Barton, Wisoky and Hoppe',
    location: 'Herrera',
  },
  {
    jobId: '2780459654',
    serviceDate: '04/17/2020',
    patientName: 'Daffy Licciardo',
    provider: 'Hagenes, Bernier and Veum',
    location: 'Pau',
  },
  {
    jobId: '0395358892',
    serviceDate: '05/12/2020',
    patientName: 'Mitch Ounsworth',
    provider: 'Skiles LLC',
    location: 'Zebrzydowice',
  },
  {
    jobId: '3587205159',
    serviceDate: '08/17/2020',
    patientName: 'Leanna Janota',
    provider: 'Kshlerin, Hintz and Wisoky',
    location: 'Pimentel',
  },
  {
    jobId: '7418403496',
    serviceDate: '08/06/2020',
    patientName: 'Randa Blampey',
    provider: 'Lesch-Adams',
    location: 'Beitan',
  },
  {
    jobId: '4030593542',
    serviceDate: '05/30/2020',
    patientName: 'Tersina Castri',
    provider: 'Fahey LLC',
    location: 'Kuala Bintang',
  },
  {
    jobId: '3056884225',
    serviceDate: '08/17/2020',
    patientName: 'Ardisj Graffin',
    provider: 'Monahan-Toy',
    location: 'Ho',
  },
  {
    jobId: '7068274153',
    serviceDate: '07/21/2020',
    patientName: 'Fanchon Gerrelt',
    provider: 'Langworth LLC',
    location: 'Avaré',
  },
  {
    jobId: '1105118258',
    serviceDate: '05/21/2020',
    patientName: 'Ky Zorzi',
    provider: 'Dibbert, Prosacco and Hyatt',
    location: 'Lago da Pedra',
  },
  {
    jobId: '9408116887',
    serviceDate: '04/07/2020',
    patientName: 'Abel Leal',
    provider: 'Zieme Inc',
    location: 'Bama',
  },
  {
    jobId: '2877340368',
    serviceDate: '04/07/2020',
    patientName: 'Stella McGloin',
    provider: 'Casper, Collier and Leuschke',
    location: 'Norrköping',
  },
  {
    jobId: '2040141030',
    serviceDate: '08/15/2020',
    patientName: 'Myrtie Culbard',
    provider: 'Ferry-Schowalter',
    location: 'Semikarakorsk',
  },
  {
    jobId: '8732339216',
    serviceDate: '08/11/2020',
    patientName: 'Cornie Gertz',
    provider: 'Hirthe-Mills',
    location: 'Himi',
  },
  {
    jobId: '1264135149',
    serviceDate: '06/10/2020',
    patientName: 'Aubrette Yoodall',
    provider: 'Tillman-Kuvalis',
    location: 'Budapest',
  },
  {
    jobId: '6781725440',
    serviceDate: '04/03/2020',
    patientName: 'Tammie Dignon',
    provider: 'Hane-Lowe',
    location: 'Telheira',
  },
  {
    jobId: '2274313458',
    serviceDate: '08/09/2020',
    patientName: 'Marthe Ottery',
    provider: 'Mosciski, Cruickshank and Jast',
    location: 'Romilly-sur-Seine',
  },
  {
    jobId: '5015735545',
    serviceDate: '07/25/2020',
    patientName: 'Valina Hamly',
    provider: 'Lueilwitz-Hoeger',
    location: 'Sidomulyo',
  },
  {
    jobId: '3629080189',
    serviceDate: '06/21/2020',
    patientName: 'Erroll Bunt',
    provider: 'Crona-Murray',
    location: 'Rettikhovka',
  },
  {
    jobId: '6113439747',
    serviceDate: '06/18/2020',
    patientName: 'Nettie Ruddock',
    provider: 'Boyle LLC',
    location: 'Phichit',
  },
  {
    jobId: '9013502024',
    serviceDate: '06/06/2020',
    patientName: 'Arlen Feldmark',
    provider: 'Bernier Group',
    location: 'Letsheng',
  },
  {
    jobId: '9178355133',
    serviceDate: '06/08/2020',
    patientName: 'Marshall Domonkos',
    provider: 'Lueilwitz Group',
    location: 'Malitubog',
  },
  {
    jobId: '0203163745',
    serviceDate: '04/20/2020',
    patientName: 'Rikki Rhead',
    provider: 'Lindgren-Smith',
    location: 'Zengjia',
  },
  {
    jobId: '2487791756',
    serviceDate: '06/16/2020',
    patientName: 'Luise Pretor',
    provider: 'Wilkinson, Beier and Ondricka',
    location: 'San Marcelino',
  },
  {
    jobId: '4429719411',
    serviceDate: '05/19/2020',
    patientName: 'Yorke Pechan',
    provider: 'Mitchell, Powlowski and Blick',
    location: 'Mosquera',
  },
  {
    jobId: '3705638653',
    serviceDate: '05/09/2020',
    patientName: 'Blondell Borkett',
    provider: 'Toy Group',
    location: 'Kertayuga',
  },
  {
    jobId: '3713132267',
    serviceDate: '04/21/2020',
    patientName: 'Wenonah Bozworth',
    provider: 'Graham, Hilll and Greenfelder',
    location: 'Mando',
  },
  {
    jobId: '7852222242',
    serviceDate: '04/05/2020',
    patientName: 'Roanna Tomkys',
    provider: 'Dooley Inc',
    location: 'Yong’an',
  },
  {
    jobId: '7005457773',
    serviceDate: '06/05/2020',
    patientName: 'Orsa Varfolomeev',
    provider: 'Baumbach-Kessler',
    location: 'Laguna',
  },
  {
    jobId: '3152133162',
    serviceDate: '04/05/2020',
    patientName: 'Karlotta Bockings',
    provider: 'Cummings Inc',
    location: 'Gąbin',
  },
  {
    jobId: '2821183682',
    serviceDate: '06/05/2020',
    patientName: 'Lesya Sarrell',
    provider: 'Schulist Group',
    location: 'Zhuangtou',
  },
  {
    jobId: '0436699877',
    serviceDate: '06/11/2020',
    patientName: 'Parke Lally',
    provider: 'Torphy-Satterfield',
    location: 'Huangcun',
  },
  {
    jobId: '2726674666',
    serviceDate: '04/20/2020',
    patientName: 'Denise Assaf',
    provider: 'Collier Group',
    location: 'Belyy Gorodok',
  },
];

export const gridProps = {
  records: advanceSearchRecords,
  columns: new Set([
    {
      dataName: 'jobId',
      text: 'Job',
      sortable: true,
      isSorted: 0,
    },
    {
      dataName: 'serviceDate',
      text: 'Service Date',
      sortable: true,
      isSorted: 0,
    },
    {
      dataName: 'patientName',
      text: 'Patient Name',
      sortable: true,
      isSorted: 1,
    },
    {
      dataName: 'provider',
      text: 'Provider',
      sortable: true,
      isSorted: 0,
    },
    {
      dataName: 'location',
      text: 'location',
      sortable: true,
      isSorted: 0,
    },
  ]),
  selectedItemIds: new Set([]),
  selectionKey: 'jobId',
  domId: 'grid',
  sortingComparator: (sortDataName, sortDirectionString, records) => {
    const makeComparator = (direction) => {
      const sortDirection = direction === SORT_ASCENDING ? 1 : -1;

      return (a, b) => a > b ? sortDirection : sortDirection * -1;
    };

    if (!sortDataName || !sortDirectionString || !records) return records;

    const comparator = makeComparator(sortDirectionString);
    switch (sortDataName) {
      case 'serviceDate':
        return records.sort((a, b) =>
          comparator(
            moment(a.serviceDate, 'MM-DD-YYYY'),
            moment(b.serviceDate, 'MM-DD-YYYY'),
          ),
        );

      default:
        return records.sort((a, b) =>
          comparator(a[sortDataName], b[sortDataName]),
        );
    }
  },
  onRowSelect: () => {},
  onSelectAll: () => {},
  onLinkClick: () => {},
  onSettingsCancel: () => {},
  supportSelection: false,
  isConfigurable: true,
};

export default {
  gridProps,
};
