import React, { useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CircleCheck from '@uicl/ui-core/dist/CircleCheck';
import ListPaneHeader from '@uicl/ui-core/dist/ListPaneHeader';
import useLayout from '@uicl/ui-core/dist/useLayout';
import { clearPageLeave,  getReferralActivity, exportReferralActivityReport } from '../modules/actions';
import ReferralByProductFilters from "./ReferralByProductFilters";
import { useIsMount } from '../../../../commonResources/hooks/useIsMount';
import { searchFilterReducer, initialSearchFilterState, paginationReducer, initialPaginationState } from '../modules/stateReducer';
import SearchResults from './SearchResults';
import { SearchFilterTypes, PaginationTypes } from '../modules/types';
import SearchResultsGridConfig from './SearchResultsGridConfig';
import { validationKeys } from '../modules/constants';

/*eslint-disable */

const ReferralByProduct = () => {

  const pageIndexes = 5;
  const [searchFilterState, dispatchSearchFilter] = useReducer(searchFilterReducer, initialSearchFilterState);
  const [paginationState, dispatchPagination] = useReducer(paginationReducer, initialPaginationState);
  const isMount = useIsMount();
  const { expandCollapseListPaneButtonConfig, } = useLayout();

  const dispatch = useDispatch();
  const totalRecords = useSelector((state) => state.referralByProduct.totalRecords);

  useEffect(() => {
    
    return () => {
      dispatch(clearPageLeave());
    }
  }, []);
  
  const onFilterSubmit = (filters) => { 

    if (filters.exportToExcel) {
      
      // use filters from the search results the user
      // can change the criteria while the results are being displayed
      // but for export want the filters related to results so
      // keep the filters intact from the last SEARCH

      const exportFilters = { 
        [validationKeys.pageSize]: totalRecords, 
        [validationKeys.pageNumber]: 1, 
        [validationKeys.product]: searchFilterState.product, 
        [validationKeys.customer]: searchFilterState.customer, 
        [validationKeys.department]: searchFilterState.department, 
        [validationKeys.fromDate]: searchFilterState.fromDate, 
        [validationKeys.toDate]: searchFilterState.toDate };
    
      dispatch(exportReferralActivityReport(exportFilters))
    }
    else {
      dispatchPagination({ type: PaginationTypes.PAGINATION_RESET, payload: initialPaginationState } );
      dispatchSearchFilter({ type: SearchFilterTypes.SEARCH_FILTER_SET, payload: filters });
    }
    
  };

  const onGridPageClicked = (pageNumber) => {

    dispatchPagination({type: PaginationTypes.PAGINATION_PAGE_NUMBER, payload: pageNumber });
    dispatchSearchFilter({type: SearchFilterTypes.SEARCH_FILTER_PAGE_NUMBER, payload: pageNumber });

  };

  const movePagesForward = () => {

    const intitialPage = paginationState.initPage + pageIndexes;
    const endingPage = paginationState.endPage + pageIndexes;
    dispatchPagination({ type: PaginationTypes.PAGINATION_MOVE, payload: { initPage: intitialPage, endPage: endingPage }});

  };

  const movePagesBackward = () => {

    const intitialPage = paginationState.initPage - pageIndexes;
    const endingPage = paginationState.endPage - pageIndexes;
    dispatchPagination({ type: PaginationTypes.PAGINATION_MOVE, payload: { initPage: intitialPage, endPage: endingPage }});

  };

  useEffect(() => {

    if (isMount === false) {
      return;
    }

    dispatch(getReferralActivity(searchFilterState));

  }, [searchFilterState]);

  const listPaneProps = {

    title: 'Referral Activity Report',
    icon: CircleCheck,
    description: 'Referral Activity Report',
    layoutControls: [expandCollapseListPaneButtonConfig],
    children: (<ReferralByProductFilters onSubmit = {(searchFilters) => onFilterSubmit(searchFilters)} />),

  };  

  return (
    <>
      <ListPaneHeader domID="ListPaneHeaderID" {...listPaneProps} />
      <SearchResults searchFilterState={searchFilterState} 
        paginationState={paginationState} onGridPageClicked={onGridPageClicked}  
        movePagesForward={movePagesForward} movePagesBackward={movePagesBackward} 
        SearchResultsGridConfig={SearchResultsGridConfig} />
   </>
  )
}

export default ReferralByProduct