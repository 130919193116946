
// verified

export const headingExcelReferralBulkReport = [
  [        
    'MembershipID',
    'Customer Name',
    'McoContract Name',
    'Department Name',
    'Bulk Loader First Name',
    'Bulk Loader Middle Initial',
    'Bul Loader Last Name',
    'Bulk Loader HICN',
    'Bulk Loader MBI',
    'Helath Plan ID',
    'Bulk Loader Comments',
    'Matched First Name',
    'Matched Middle Initial',
    'Matched Last Name',
    'Matched HICN',
    'Matched MBI',
    'Matched Comments',
    'Bulk Loader Status',
    'Created On Behalf Of User Name',
    'Created Date',
  ],
];

export const headersExcelReferralBulkReport = [
  'membershipID',
  'customerName',
  'mcoContractName',
  'departmentName',
  'bulkLoaderFirstName',
  'bulkLoaderMiddleInitial',
  'bulkLoaderLastName',
  'bulkLoaderHICN',
  'bulkLoaderMBI',
  'customerMemberId',
  'bulkLoaderComments',
  'matchedFirstName',
  'matchedMiddleInitial',
  'matchedLastName',
  'matchedHICN',
  'matchedMBI',
  'matchedComments',
  'bulkLoaderStatus',
  'createdOnBehalfOfUserName',
  'createdDate',
];
      
export const excelNameReferralBulkReport = 'Referral Bulk Report';
