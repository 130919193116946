import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
/*eslint-disable */
// import { updateTokenValues, getUser } from 'auth-management';
import  { updateTokenValues, getUser } from '../../../../commonResources/services/auth-management';
import PingError from '../../PingError';

// Parses URL from Ping
// Does not check if user all ready logged in
// Creates user object and sends user to the app
// Or renders ping error
export const PingAuth = (props) => {
  const { setUser, setUserInformation, push, store } = props;
  const [error, setError] = useState(false);

  useEffect(() => {
    // extract previously intended target location
    const targetLocation = store && store.getValue('targetLocation');
    if (targetLocation) {
      // done, delete it
      store.remove('targetLocation');
    }

    // check that we have a hash and are not in an iframe
    if (window.location.hash && window.top === window.self) {
      // pull out url hash props from ping and store them
      updateTokenValues(
        window.location.hash,
        (errorCode, errorDescription) => {
          setError({
            errorCode,
            errorDescription,
          });
        },
        // redirect to previously intended target location or home
        () => {
          const user = getUser();
          setUser(user);
          setUserInformation(user.sub);
          push(targetLocation || '/');
        },
        () => {
          push('/login');
        },
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <div>{error && <PingError {...error} {...props} />}</div>;
};

PingAuth.propTypes = {
  setUser: PropTypes.func,
  push: PropTypes.func,
  store: PropTypes.object,
};

export default PingAuth;
