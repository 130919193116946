import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TimeOutModal from '@uicl/ui-core/dist/TimeOutModal';
import { push } from 'connected-react-router';

import { isAuthenticated, updateTokenSilently, getUser, timeouts, } from '../../../commonResources/services/auth-management';

import {
  setUser,
  toggleAuthModalOpen,
} from '../../PageWrapper/modules/actions';
import { selectAuthModalOpen } from '../../PageWrapper/modules/selectors';

const SilentRefreshModal = ({
  authModalOpen,
  dispatchToggleAuthModalOpen,
  dispatchSetUser,
  dispatchPush,
}) => {
  
  const timer = 15;
  const logoutTimer = 20;
  const [isRefreshing, setIsRefreshing] = useState(false);

  const startTimer = useCallback(() => {
    dispatchToggleAuthModalOpen(false);
    timeouts.setAuthTimeouts(
      () => {
        dispatchToggleAuthModalOpen(true);
      },
      () => dispatchPush('/logout'),
      timer,
      logoutTimer,
    );
  }, [dispatchToggleAuthModalOpen, dispatchPush, timer,logoutTimer]);
  useEffect(() => {
    if (isAuthenticated()) startTimer();
    return timeouts.clearAuthTimeouts;
  }, [startTimer]);

  useEffect(() => {
    const authModal = document.getElementById("auth-refresh-modal");
    if(authModal){
      if(!authModalOpen){
        authModal.setAttribute('style', 'z-index:-1');
      }else{
        authModal.setAttribute('style', 'z-index:900');
      }
    }
  }, [authModalOpen]);

  const refresh = () => {
    setIsRefreshing(true);
    updateTokenSilently(
      () => {
        setIsRefreshing(false);
      },
      () => {
        setIsRefreshing(false);
        startTimer();
        dispatchSetUser(getUser());
      },
      () => {
        setIsRefreshing(false);
        dispatchPush('/login');
      },
    );
  };

  return (
    <TimeOutModal
      domID="auth-refresh-modal"
      isOpen={authModalOpen}
      onSignOut={() => dispatchPush('/logout')}
      onStaySignedIn={refresh}
      minutesUntilSignOut={timer}
      disableSignOutButton={isRefreshing}
      disableStaySignedInButton={isRefreshing}
    />
  );
};

SilentRefreshModal.propTypes = {
  authModalOpen: PropTypes.bool.isRequired,
  dispatchToggleAuthModalOpen: PropTypes.func,
  dispatchSetUser: PropTypes.func,
  dispatchPush: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  dispatchToggleAuthModalOpen: (open) => dispatch(toggleAuthModalOpen(open)),
  dispatchSetUser: (user) => dispatch(setUser(user)),
  dispatchPush: (location) => dispatch(push(location)),
});

const mapStateToProps = (state) => ({
  authModalOpen: selectAuthModalOpen(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(SilentRefreshModal);
