import { fetchWithToken } from "../../../commonResources/helpers/fetch";

export const getCustomers = async () => (
  fetchWithToken('customers/items').then(res => res.json()).then(json => json.data)
)

export const getStates = async () => (
  fetchWithToken('states/items').then(res => res.json()).then(json => json.data)
)

export const getUserInformation = async (param) => (
  fetchWithToken(`referralusers/searchbyuser?${new URLSearchParams(param)}`).then(res => res.json()).then(json => json)
)