/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */
import { getUserInformation } from './api';
import {
  ON_PRODUCT_MENU_SELECT,
  ON_AVATAR_MENU_SELECT,
  ON_MULTI_GROUP_OPEN,
  ON_MULTI_GROUP_CHECK,
  ON_SINGLE_GROUP_OPEN,
  ON_SINGLE_GROUP_SELECT,
  USER_SET,
  TOGGLE_AUTH_MODAL_OPEN,
  ON_INITIAL_NOTIFICATIONS,
  ON_UPDATE_NOTIFICATIONS,
  ON_UNPROCESSED_NOTIFICATIONS,
  MARK_NOTIFICATIONS_READ,
  LOAD_USER_INFORMATION_SUCCESS,
  LOAD_MENU_ITEMS,
  LOAD_USER_INFORMATION_FAIL,
  LOAD_USER_INFORMATION_IS_LOADING,
  LOAD_USER_INFORMATION_FINISH_LOADING,
} from './constants';

export const onProductMenuSelect = state => ({
  type: ON_PRODUCT_MENU_SELECT,
  state,
});

export const onAvatarMenuSelect = state => ({
  type: ON_AVATAR_MENU_SELECT,
  state,
});

export const onSingleGroupOpen = state => ({
  type: ON_SINGLE_GROUP_OPEN,
  state,
});

export const onSingleGroupSelect = state => ({
  type: ON_SINGLE_GROUP_SELECT,
  state,
});

export const onMultiGroupOpen = state => ({
  type: ON_MULTI_GROUP_OPEN,
  state,
});

export const onMultiGroupCheck = state => ({
  type: ON_MULTI_GROUP_CHECK,
  state,
});

export const setUser = state => ({
  type: USER_SET,
  state,
});

export const toggleAuthModalOpen = state => ({
  type: TOGGLE_AUTH_MODAL_OPEN,
  state,
});

export const setUserInformation = (userName) => async dispatch => {
  dispatch({ type: LOAD_USER_INFORMATION_IS_LOADING })
  getUserInformation({userName}).then(userInformation => {
    dispatch({
      type: LOAD_USER_INFORMATION_SUCCESS,
      payload: userInformation.data
    })

    dispatch({
      type: LOAD_MENU_ITEMS,
      payload: userInformation.data.menu
    })
  }).catch(() => {
    dispatch({
      type: LOAD_USER_INFORMATION_FAIL,
      payload: "Something went wrong while creating request data. User Information."
    })
  }).finally(() => {
    dispatch({ type: LOAD_USER_INFORMATION_FINISH_LOADING })
  });
}

// NOTIFICATIONS
// ––––––––––––––––––––––––––––––––––––––––––––––––––––––
export const onInitialNotifications = state => ({
  type: ON_INITIAL_NOTIFICATIONS,
  state,
});

export const onUpdateNotifications = state => ({
  type: ON_UPDATE_NOTIFICATIONS,
  state,
});

export const onUnprocessedNotifications = state => ({
  type: ON_UNPROCESSED_NOTIFICATIONS,
  state,
});

export const onMarkNotificationsRead = state => ({
  type: MARK_NOTIFICATIONS_READ,
  state,
});
