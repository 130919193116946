export const filterTypes = {
  ALL_ROLES : 'ALL_ROLES',
  BY_ROLE : 'BY_ROLE',
  BY_ROLE_CUSTOMER : 'BY_ROLE_CUSTOMER',
  BY_ROLE_CUSTOMER_UNIT_MANAGER : 'BY_ROLE_CUSTOMER_UNIT_MANAGER',
  BY_ROLE_CUSTOMER_DEPARTMENT : 'BY_ROLE_CUSTOMER_DEPARTMENT',
};

 
export const rolesId ={
  SYSTEM_ADMIN : '126', 
  ACCOUNT_MANAGER : '127',
  CORPORATE_MANAGER : '128',
  UNIT_MANAGER : '129',
  UNIT_MANAGER_ASSISTANT : '130',
  REFERRAL_USER : '131',
  SYSTEM_REFERRAL_ENTRY : '132',
  CORPORATE_REFERRAL_ENTRY : '133',
};

export const roleType = {
  EXTERNAL : 1,
  INTERNAL : 2,
};

export const roleAssociation = {
  DEPARTMENT_MCO : 1,
  UNIT_MANAGER : 2,
  NO_ASSOCIATION : 3
}