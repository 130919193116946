/**
 * Asynchronously loads the component for HomePage
 */
import React from 'react';
import loadable from '@loadable/component';

import LoadingPage from '../../../components/LoadingPage';

const LoadableWorkflow = loadable(() => import('.'), {
  fallback: <LoadingPage />,
});

export default LoadableWorkflow;
