import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NotificationBanner } from '@uicl/ui-core';
import NavigationPane from '../../NavigationPane/containers/NavigationPaneContainer';
import PageWrapper from '../../PageWrapper';
import ReferralDetails from './components/ReferralDetails';
import SearchReferral from './components/SearchReferral';
import { updateReferralClearNotification } from './modules/actions';
import Notification from '../../../components/notifications/Notification';

const Referral = () => {

  const { updateReferralErrorMsg, isUpdateReferralSuccess, isUpdateReferralFail } = useSelector(
    (state) => state.searchReferral,
  );

  const dispatch = useDispatch();

  return (
    <>
      {isUpdateReferralFail && (
        <NotificationBanner
          autoDismiss
          onDismiss={() => dispatch(updateReferralClearNotification())}
          text={updateReferralErrorMsg}
          type="negative"
        />
      )}
      {isUpdateReferralSuccess && (
        <Notification
          id={Math.floor((Math.random()) * 0x10000).toString(16)}
          autoDismiss
          onDismiss={() => dispatch(updateReferralClearNotification())}
          title="Success"
          text="Referral Updated Successfully"
          type="success"
        />
      )}
      <PageWrapper
        navigationPaneContent={<NavigationPane componentName="Referral" />}
        listPaneContent={<SearchReferral />}
        detailPaneContent={<ReferralDetails dataTestId='detail-pain' />}
      />
    </>
  )
};

export default Referral;