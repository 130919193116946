const initialState = {
  records: [],
};

function AppReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
export default AppReducer;