import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import NavigationPaneHeader from '@uicl/ui-core/dist/NavigationPaneHeader';
import SingleSelectItem from '@uicl/ui-core/dist/SingleSelectItem';
// import aetnalogo from '../../../assets/aetna-logo.svg';

const Navigation = ({ openComponent = () => { }, componentName, pathname, route, customerLogo }) => (
  <>
    <NavigationPaneHeader title={`${componentName}`} />
    {
      route.map((x) => (
        <SingleSelectItem text={x.text} isSelected={pathname === x.path} onClick={() => openComponent(x.path)} />
      ))
    }
    {
      customerLogo &&
      <div style={{ margin: '15px', width: '10vw' }}>
        <img style={{ maxWidth: '100%', maxHeight: '100%', textAlign: 'center' }} src={`data:image/png;base64,${customerLogo}`} alt='customerLogo' />
      </div>
    }
  </>
)

const NavigationPane = (props) => {
  const { pathname } = useLocation();
  const { navMenuItems, userInformation } = useSelector(state => state.app);
  const customerLogo = userInformation ? userInformation.customerLogo : null;
  // console.log(userInformation)
  const route = navMenuItems !== [] ? navMenuItems[0].filter((x) => x.label === props.componentName)[0].options.filter((x) =>
    !x.path.includes('index')
  ) : [];
  let component = <></>;
  component = Navigation({ ...props, pathname, route, customerLogo });
  
  return component
};

export default NavigationPane;

Navigation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  openComponent: PropTypes.func,
  componentName: PropTypes.string,
  pathname: PropTypes.string,
  route: PropTypes.string,
  customerLogo: PropTypes.string,
};