export const types = {
  LOAD_CUSTOMERS_SUCCESS: '[CUSTOMER] load data for customer dropdown',
  LOAD_CUSTOMERS_FAIL: '[CUSTOMER] Failed to load customer data for dropdown',
  LOAD_CUSTOMERS_IS_LOADING: '[CUSTOMER] customer data for dropdown is loading',
  LOAD_CUSTOMERS_FINISH_LOADING: '[CUSTOMER] customer data for dropdown finish loading',
  
  LOAD_STATES_SUCCESS: '[STATES] load data for state dropdown',   
  LOAD_STATES_FAIL: '[STATES] states data for dropdown is loading',   
  LOAD_STATES_IS_LOADING: '[STATES] states data for dropdown is loading',   
  LOAD_STATES_FINISH_LOADING: '[STATES] states data for dropdown finish loading',  

}
