export default [
  {
    label: 'Administrator',
    value: 'admin',
    description: 'Users with this role can have all the access',
  },
  {
    label: 'Developer',
    value: 'developer',
    description: 'Users responsible for development of the app',
  },
  {
    label: 'XYZ Role',
    value: 'xyz',
    description: 'Sample description',
  },
];
