import { types } from './types';
import { getAllCustomerImages, getCustomerImage, getCustomers, getMcoContractsByCustomer, putCustomerLogo } from "./api";

export const loadCustomers = (filterRequest) => async dispatch => {
  if (!filterRequest.loadGrid)
    return;

  dispatch({ type: types.LOAD_CUSTOMERS });
  const { pageNumber, pageSize, sortingName, sortingOrder } = filterRequest;
  (
    getCustomers(pageNumber, pageSize, sortingName, sortingOrder).then(paginatedData => {
      dispatch({ type: types.LOAD_CUSTOMERS_SUCCESS, paginatedData })
    }).catch(() => {
      dispatch({ type: types.LOAD_CUSTOMERS_FAIL, errorMessage: 'Something went wrong while fetching data. Load Customers' })
    })
  )
}

export const loadMcoContracts = (params) => async dispatch => {
  dispatch({ type: types.LOAD_MCOCONTRACTS_IS_LOADING });
  getMcoContractsByCustomer(params).then(paginatedDataMcoContracts => {
    dispatch({ type: types.LOAD_MCOCONTRACTS_SUCCESS, paginatedDataMcoContracts })
  }).catch(() => {
    dispatch({ type: types.LOAD_MCOCONTRACTS_FAIL, payload: 'Something went wrong while fetching data. Load McoContracts' })
  }).finally(() => {
    dispatch({ type: types.LOAD_MCOCONTRACTS_FINISH_LOADING })
  })
}

export const loadCustomerImage = (customerId) => async dispatch => {
  dispatch({ type: types.CLEAR_CUSTOMER_IMAGE })
  dispatch({ type: types.LOAD_CUSTOMER_IMAGES_IS_LOADING })
  getCustomerImage(customerId).then(customerImage => {
    if (customerImage.statusCode === 200) {
      dispatch({ type: types.LOAD_CUSTOMER_IMAGE_SUCCESS, payload: customerImage.data })
    }
  }).catch(() => {

  }).finally(() => {
    dispatch({ type: types.LOAD_CUSTOMER_IMAGES_FINISH_LOADING })
  })
}

export const loadAllCustomerImages = (customerId) => async dispatch => {
  dispatch({ type: types.CLEAR_CUSTOMER_IMAGES_FOR_GRID })
  getAllCustomerImages(customerId).then(customerImage => {
    if (customerImage.statusCode === 200) {
      dispatch({ type: types.LOAD_CUSTOMER_IMAGES_FOR_GRID_SUCCESS, payload: customerImage.data })
    }
  }).catch(() => {

  }).finally(() => {
  })
}

export const updateCustomerLogo = (data) => async dispatch => {
  dispatch({ type: types.UPDATE_CUSTOMER_IMAGE_IS_LOADING })
  putCustomerLogo(data).then(resp => {    
    if (resp.statusCode !== 200) {
      dispatch({ type: types.UPDATE_CUSTOMER_IMAGE_FAIL, payload: 'Something went wrong while fetching data. Update customer logo' })
      return
    }
    dispatch({
      type: types.UPDATE_CUSTOMER_IMAGE_SUCCESS,
      payload: resp
    })
    dispatch(loadCustomerImage(data.customerId));
    dispatch(loadAllCustomerImages(data.customerId));
  }).catch(() => {
    dispatch({ type: types.UPDATE_CUSTOMER_IMAGE_FAIL, payload: 'Something went wrong while fetching data. Update customer logo' })
  }).finally(() => {
    dispatch({ type: types.UPDATE_CUSTOMER_IMAGE_FINISH_LOADING })
  });
};

export const setNotificationsToFalse = () => dispatch => {
  dispatch({ type: types.CLEAR_CUSTOMER_NOTIFICATIONS })
}