export default {
  columns: new Set([
    {
      dataName: 'ruleName',
      text: 'Rule',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'rules-grid',
  onSortTableColumn: () => { },
  onSelectAll: () => { },
  onLinkClick: () => { },
  onSettingsCancel: () => { },
  isConfigurable: false,
  isFixedHeader: true,
};
