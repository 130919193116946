import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from '@uicl/ui-core';
import SelectDropdown from '@uicl/ui-core/dist/SelectDropdown';
import { Container, Row, Col } from '@uicl/ui-core/dist/ContentGrid';
import { loadCustomers } from '../../../../commonResources/commonModules/actions';
import { setSelectedCustomer } from '../modules/actions';
import '../styles/styles.css'

// eslint-disable-next-line
const FilterDepartments = ({ onSubmit = () => { }, onCreateDepartment = () => { } }) => {

  const dispatch = useDispatch();

  const { customers } = useSelector(state => state.common);
  const { selectedCustomer } = useSelector(state => state.departments);

  useEffect(() => {
    if (customers.length === 0) {
      dispatch(loadCustomers())
    }
    
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <SelectDropdown
            size="medium"
            domID="customerDropdown"
            dataTestId="test-roles"
            label="Customer"
            options={customers}
            value={selectedCustomer}
            onChange={(e) => dispatch(setSelectedCustomer(e ?? { label: 'All Customers', value: 0 }))}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className='button'>
            <ButtonGroup>
              <Button
                name="Search"
                size="medium"
                onClick={() => onSubmit(selectedCustomer.value)}
              />
              <Button
                name="Create"
                size="medium"
                onClick={() => onCreateDepartment(selectedCustomer)}
              />

            </ButtonGroup>
          </div>
        </Col>
      </Row>
    </Container>
  );

}

export default FilterDepartments;

FilterDepartments.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  onSubmit: PropTypes.func
};