export const types = {
  LOAD_DEPARTMENTS_IS_LOADING: '[LOAD_DEPARTMENTS] In Progress load data for departments grid',
  LOAD_DEPARTMENTS_FINISH_LOADING: '[LOAD_DEPARTMENTS] Finish load data for departments grid',
  LOAD_DEPARTMENTS_SUCCESS: '[LOAD_DEPARTMENTS] Load data for departments grid',
  LOAD_DEPARTMENTS_FAIL: '[LOAD_DEPARTMENTS] Failed load data for departments grid',

  CREATE_DEPARTMENT_SAVE_DEPARTMENT_SUCCESS: '[CREATE_DEPARTMENT] Save department data',
  CREATE_DEPARTMENT_SAVE_DEPARTMENT_FAIL: '[CREATE_DEPARTMENT] Failed saving department data',
  CREATE_DEPARTMENT_SAVE_DEPARTMENT_IS_SAVING: '[CREATE_DEPARTMENT] In progress saving department data',
  CREATE_DEPARTMENT_SAVE_DEPARTMENT_FINISH_SAVING: '[CREATE_DEPARTMENT] Finish saving department data',

  SEARCH_DEPARTMENT_SELECTED_CUSTOMER: '[SEARCH_DEPARTMENT] Set selected customer',

  EDIT_DEPARTMENT_UPDATE_DEPARTMENT_SUCCESS: '[EDIT_DEPARTMENT] Save department data',
  EDIT_DEPARTMENT_UPDATE_DEPARTMENT_FAIL: '[EDIT_DEPARTMENT] Failed saving department data',
  EDIT_DEPARTMENT_UPDATE_DEPARTMENT_IS_SAVING: '[EDIT_DEPARTMENT] In progress saving department data',
  EDIT_DEPARTMENT_UPDATE_DEPARTMENT_FINISH_SAVING: '[EDIT_DEPARTMENT] Finish saving department data',
};