/**
 * AccessDeniedPage
 *
 * This is the page we show when the user visits a url that he/she does not have access to
 */

import React from 'react';
import ErrorPage from '@uicl/ui-core/dist/ErrorPage';

export const AccessDeniedPage = () => (
  <ErrorPage
    message="Unfortunately, you do not have proper access for this page. Please contact application admins to request the access."
    title="Access Denied"
  />
);

export default AccessDeniedPage;
