import React from 'react'
import NavigationPane from '../../NavigationPane/containers/NavigationPaneContainer';
import PageWrapper from '../../PageWrapper';
import Rules from './components/Rules';

/*eslint-disable */


const Administration = () => {
  return (
    <PageWrapper
      navigationPaneContent={<NavigationPane componentName = "Administration"/>}
      listPaneContent={<Rules />}
    />
  );
};

export default Administration;