

export default {
  columns: new Set([
    {
      dataName: 'activityType',
      text: 'Activity',
      sortable: false,
      isSorted: 0,
    },
    {
      dataName: 'activityDate',
      text: 'Date',
      sortable: false,
      isSorted: 0,
    },
  ]),
  selectedItemIds: new Set([]),
  selectionKey: 'id',
  domId: 'detail-pane',
  onSortTableColumn: () => {},
  onSelectAll: () => {},
  onLinkClick: () => {},
  onSettingsCancel: () => {},
  isConfigurable: true,
};