

export const convertToMMSDateFormat = (date) => {
  return date !== null ? new Date(date).toLocaleString() : date;
};

export const convertMmDdYyyyToYyyyMmDd = date =>
  date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2")

export const isValidDate = (dateString) => {
  const regEx = /^\d{1,2}\/\d{1,2}\/\d{4}$/;

  if (!dateString.match(regEx)) {
    return false;
  }

  const dateArray = dateString.split("/");
  // eslint-disable-next-line 
  const month = parseInt(dateArray[0]);
  // eslint-disable-next-line 
  const day = parseInt(dateArray[1]);
  // eslint-disable-next-line 
  const year = parseInt(dateArray[2]);

  if (month < 1 || month > 12) {
    return false;
  }

  const d = new Date(year, month - 1, 1);
  const lastDayOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
  if (day < 1 || day > lastDayOfMonth) {
    return false;
  }

  if (year <= 0) {
    return false;
  }
  return true;
}


