/* eslint-disable  */
import { Redirect, Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import AsyncHomePage from './containers/HomePage/Loadable';
import AsyncDataGridPage from './containers/ExampleDataGridPage/Loadable';
import AsyncBlankPage from './containers/ExampleBlankPage/Loadable';
import AsyncWorkflow from './containers/Paradigms/Workflow/Loadable';
import AsyncDiscovery from './containers/Paradigms/Discovery/Loadable';
import AsyncSaveSearch from './containers/Paradigms/SaveSearch/Loadable';
import AsyncFormFlow from './containers/Paradigms/FormFlow/Loadable';
import integrations from './integrations';
import NotAuthorized from './containers/Security/NotAuthorized';
import PingAuth from './containers/Security/PingAuth';
import Logout from './containers/Security/Logout';
import PrivateRoute from './containers/Security/PrivateRoute';
import NotFound from './containers/NotFoundPage';
import { AccessDeniedPage } from './containers/AccessDeniedPage';
/* Account */
import AsyncAccountPage from './containers/Account/Loadable';
import ChangePassword from './containers/Account/ChangePassword';
/* Referral */
import AsyncReferralPage from './containers/Referral/Loadable';
import CreateReferral from './containers/Referral/CreateReferral';
import SearchReferral from './containers/Referral/SearchReferral';
/* Users */
import AsyncUserPage from './containers/Users/Loadable';
import CreateUser from './containers/Users/CreateUser';
import SearchUser from './containers/Users/SearchUser';
/* Reports */
import AsyncReportsPage from './containers/Reports/Loadable';
import ReferralByProduct from './containers/Reports/ReferralByProduct';
import ReferralBulk from './containers/Reports/ReferralBulk';
import ReferralCase from './containers/Reports/ReferralCase';
import ReferralException from './containers/Reports/ReferralException';
import ReferralOutreach from './containers/Reports/ReferralOutreach';
/* Administration */
import AsyncAdministrationPage from './containers/Administration/Loadable';
import Customers from './containers/Administration/Customers';
import Departments from './containers/Administration/Departments';
import Roles from './containers/Administration/Roles';
import Rules from './containers/Administration/Rules';
import RolesRules from './containers/Administration/RolesRules';
import { useDispatch, useSelector } from 'react-redux';
import { assignComponent } from './commonResources/helpers/assignComponent';
import { isAuthenticated } from './commonResources/services/auth-management';
import jwtdecode from "jwt-decode";
import { setUserInformation } from './containers/PageWrapper/modules/actions';
import LoadingPage from './components/LoadingPage';

export const history = createBrowserHistory();

/**
 * This component can be used to show different components when user
 * visits to different paths. We use connected react router which stores the current path
 * and other route parameters to the redux store.
 *
 */
const components = [
  {
    path: '/',
    component: AsyncHomePage,
    exact: true,
  },
  /* Account */
  {
    path: '/account/index',
    component: AsyncAccountPage
  },
  {
    path: '/account/change-password',
    component: ChangePassword,
  },
  /* Referral */
  {
    path: '/referral/index',
    component: AsyncReferralPage
  },
  {
    path: '/referral/create-referral',
    component: CreateReferral,
  },
  {
    path: '/referral/search-referral',
    component: SearchReferral,
  },
  /* Users */
  {
    path: '/users/index',
    component: AsyncUserPage
  },
  {
    path: '/users/create-user',
    component: CreateUser,
  },
  {
    path: '/users/search-user',
    component: SearchUser,
  },
  /* Reports */
  {
    path: '/reports/index',
    component: AsyncReportsPage
  },
  {
    path: '/reports/referral-by-product',
    component: ReferralByProduct,
  },
  {
    path: '/reports/referral-bulk',
    component: ReferralBulk,
  },
  {
    path: '/reports/referral-case',
    component: ReferralCase,
  },
  {
    path: '/reports/referral-exception',
    component: ReferralException,
  },
  {
    path: '/reports/referral-outreach',
    component: ReferralOutreach,
  },
  /* Administration */
  {
    path: '/administration/index',
    component: AsyncAdministrationPage
  },
  {
    path: '/administration/customers',
    component: Customers,
  },
  {
    path: '/administration/departments',
    component: Departments,
  },
  {
    path: '/administration/roles',
    component: Roles,
  },
  {
    path: '/administration/rules',
    component: Rules,
  },
  {
    path: '/administration/rolesrules',
    component: RolesRules,
  },
  {
    path: '/example-grid',
    component: AsyncDataGridPage,
  },
  {
    path: '/paradigms/workflow',
    component: AsyncWorkflow,
  },
  {
    path: '/paradigms/discovery',
    component: AsyncDiscovery,
  },
  {
    path: '/paradigms/save-search',
    component: AsyncSaveSearch,
  },
  {
    path: '/example-blank',
    component: AsyncBlankPage,
  },
  {
    path: '/paradigms/form-flow',
    component: AsyncFormFlow,
  },
];


const ApplicationRoutes = () => {
  const dispatch = useDispatch();
  const { userInformation, navMenuItems, userInformationIsLoading } = useSelector(state => state.app);

  useEffect(() => {
    if ((userInformation && userInformation.length === 0) && isAuthenticated()) {
      const accessToken = window.sessionStorage.getItem('ACCESS_TOKEN');
      if (accessToken !== null && accessToken !== undefined && accessToken !== '') {
        const user = jwtdecode(accessToken);
        dispatch(setUserInformation(user.sub))
      }
    }
  }, [userInformation])

  let routes = [{
    path: '/',
    component: AsyncHomePage,
    exact: true,
  }];

  if (isAuthenticated() && navMenuItems) {
    if (navMenuItems.length !== 0 && navMenuItems !== undefined) {
      navMenuItems[0].forEach(element => {
        element.options.forEach(x => {
          routes = [...routes, x]
        })
      });
      routes = routes.map(x => {
        return {
          path: x.path,
          component: assignComponent(components, x.path),
          exact: true,
        }
      })
      
      components.forEach(component => {

        let found = false;
        navMenuItems[0].forEach(element => {
          element.options.forEach(option => {
            if(option.path === component.path)
              found = true;
          })
        });

        if(!found){
          routes.push({
            path: component.path,
            component:AccessDeniedPage,
            exact: true,
          });
        }

      });
    }
  }
  else if (!isAuthenticated()){
    // Redirect to all 
    components.forEach(component => {
      routes.push({
        path: component.path,
        component:assignComponent(components, component.path),
        exact: true,
      });
    });
  }

  if (userInformationIsLoading) {
    return <LoadingPage />
  }

  return (
    <>
      <ConnectedRouter history={history}>
        <Switch>
          {
            // eslint-disable-next-line react/no-array-index-key
            routes.map((x) => (
              <PrivateRouteIfAuth key={x.path} {...x} />
            ))
          }
          <CIAMRoute path="/login" exact component={NotAuthorized} />
          <CIAMRoute path="/auth" exact component={PingAuth} />
          <CIAMRoute path="/logout" exact component={Logout} />
          <Route path="/unauthorized" component={AccessDeniedPage} />
          <Route
            path="*"
            render={(props) => props.location.pathname !== '/sso' && <NotFound />}
          />
        </Switch>
      </ConnectedRouter>
    </>
  )
};

// Make some routes private if using auth-management
const PrivateRouteIfAuth = integrations.ciam ? PrivateRoute : Route;

const CIAMRoute = ({ component: Component, ...props }) => (
  <Route
    {...props}
    render={(innerProps) =>
      integrations.ciam ? <Component {...innerProps} /> : <Redirect to="/404" />
    }
  />
);

export default ApplicationRoutes;
