/*eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { push } from 'connected-react-router';
import { compose } from 'redux';
import PageLayout from '@uicl/ui-core/dist/PageLayout';

import integrations from '../../integrations';
import injectReducer from '../../utils/injectReducer';
import reducer from './modules/reducer';
import { APP_ID } from './modules/constants';
import {
  makeSelectMasthead,
  makeSelectNotifications,
  makeSelectUser,
} from './modules/selectors';

import {
  onAvatarMenuSelect,
  onInitialNotifications,
  onMarkNotificationsRead,
  onUnprocessedNotifications,
  onUpdateNotifications,
} from './modules/actions';

const PageWrapper = ({
  navigationPaneContent = null,
  listPaneContent = null,
  detailPaneContent = null,
  user = {},
  mastheadConfig,
  mastheadActions,

  // group under mastheadProps?
  // dispatchOnInitialNotifications,
  // dispatchOnUpdateNotifications,
  // dispatchOnUnprocessedNotifications,
  // markNotificationsRead,
  // notifications,
}) => (
  <PageLayout
    mastheadProps={{
      ...mastheadConfig,
      ...mastheadActions,
      onNavMenuSelect: (event, { activeItem }) => {
        mastheadActions.onNavMenuSelect(event, activeItem);
      },
      onProductMenuSelect: (event, { activeItem }) => {
        mastheadActions.onProductMenuSelect(event, activeItem);
      },
      onAvatarMenuSelect: (event, { activeItem }) => {
        mastheadActions.onAvatarMenuSelect(event, activeItem);
      },
      // showNotifications: integrations.notifications && isAuthenticated(),
      // notificationAlerts: notifications,
      // onDismissCard: (notification) => markReadRef.current([notification]),
      // notificationsCallbacks: {
      //   markAllAsRead: () => markReadRef.current(notifications),
      // },
      userFirstName: user.given_name,
      userLastName: user.family_name,
      userEmail: user.roles,
      // integrations,
      appId: APP_ID,
    }}
    navigationPaneContent={navigationPaneContent}
    listPaneContent={listPaneContent}
    detailPaneContent={detailPaneContent}
  />
);

PageWrapper.propTypes = {
  navigationPaneContent: PropTypes.any,
  listPaneContent: PropTypes.any,
  detailPaneContent: PropTypes.any,
  user: PropTypes.object,
  mastheadConfig: PropTypes.object,
  mastheadActions: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  mastheadActions: {
    onLogOutSelect: () => dispatch(push('/logout')),
    notificationsCallbacks: {
      markAllAsRead: () => false,
      onPrevious: () => false,
      onNext: () => false,
    },
    onNavMenuOpenClose: () => false,
    onProductMenuOpenClose: () => false,
    onSupportMenuOpenClose: () => false,
    supportMenuSearch: () => false,
    onAvatarMenuOpenClose: () => false,
    onNavMenuSelect: (event, item) => {
      dispatch(push(item.path));
    },
    onProductMenuSelect: (event, item) => {
      // put any state cleanup or analytics here
      window.location.href = item.path;
    },
    onAvatarMenuSelect: (event, item, state) => {
      dispatch(onAvatarMenuSelect(state));
      if (item.path) {
        dispatch(push(item.path));
      }
    },
  },
  dispatchOnInitialNotifications: (notifications) =>
    dispatch(onInitialNotifications(notifications)),
  dispatchOnUpdateNotifications: (notifications) =>
    dispatch(onUpdateNotifications(notifications)),
  dispatchOnUnprocessedNotifications: (notifications) =>
    dispatch(onUnprocessedNotifications(notifications)),
  markNotificationsRead: (notifications) =>
    dispatch(onMarkNotificationsRead(notifications)),
});

const mapStateToProps = (state) => {
  
  const mastheadConfig = makeSelectMasthead()(state);
  const user = makeSelectUser()(state);
  const notifications = makeSelectNotifications()(state);

  return {
    ...state,
    mastheadConfig,
    user,
    notifications,
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'app', reducer });
export default compose(withReducer, withConnect)(PageWrapper);
