export const defaultOptions = {
  pageSize: { name: 'pageSize', label: '5 records per page', value: 5 },
  products: { name: 'product', label: 'Select a Product', value: 0 },
  customers: { name: 'customer', value: 0, label: 'Select a Customer' },
  departments: { name: 'department', label: 'All Departments', value: 0 },
  attachedCustomer: { isAttached: false, name: 'customer', value: 0, label: '' },
};

export const pageSizeOptions = [
  { name: 'pageSize', label: '5 records per page', value: 5 }, 
  { name: 'pageSize', label: '10 records per page', value: 10 }, 
  { name: 'pageSize', label: '15 records per page', value: 15 }, 
  { name: 'pageSize', label: '30 records per page', value: 30 }, 
  { name: 'pageSize', label: '50 records per page', value: 50 }, 
  { name: 'pageSize', label: '75 records per page', value: 75 }, 
  { name: 'pageSize', label: '100 records per page', value: 100 }
];

export const validationKeys = {
  product: "product",
  customer: "customer",
  department: "department",
  fromDate: "fromDate",
  toDate: "toDate",
  pageSize: "pageSize",
  activePage: "activePage",
  initPage: "initPage",
  endPage: "endPage",
  pageNumber: "pageNumber",
  exportToExcel: "exportToExcel",
};

export const validationDefault = {
  pageSize: { name: 'pageSize', label: '5 records per page', value: 5 },
  product: { name: 'product', label: 'Select a Product', value: 0 },
  customer: { name: 'customer', label: 'All Customers', value: 0 },
  department: { name: 'department', label: 'All Departments', value: 0 },
  fromDate: '', 
  toDate: '',
};

export const validationErrorMessages = {
  productRequired: "Product is required", 
  customerRequired: "Customer is required", 
  fromDateRequired: "From Date is required", 
  dateRangeTo: "Date Through must not be less than From Date",
  dateRangeToday: "From Date must be less than or equal to Today",
  dateFormat: "Invalid Date",
};

export const errorKeys = {
  product: "product",
  customer: "customer",
  department: "department",
  export: "export",
  search: "search",
};

export const loadingKeys = {
  product: "product",
  customer: "customer",
  department: "department",
  export: "export",
  search: "search",
};
