import { fetchWithToken } from '../../../../commonResources/helpers/fetch'

export const getCustomersCatalog = async () => (
  fetchWithToken('customers/items').then(res => res.json()).then(json => json.data)
)


export const getDepartmentsCatalog = async (customerId) => (
  fetchWithToken(`referraldepartments/customer/${customerId}/items`).then(res => res.json()).then(json => json.data)
)

export const getMcoContractsCatalog = async (customerId) => (
  fetchWithToken(`mcocontracts/customer/${customerId}/items`).then(res => res.json()).then(json => json.data)
)


export const getReferralCaseReportByCustomer = async ( pageSize,pageNumber, customerId, departmentId, mcoContractId, email, dateFrom, dateThrough) => (
  fetchWithToken(`referralreport/case?pageSize=${pageSize}&pageNumber=${pageNumber}&userName=${email}&customerId=${customerId}&departmentId=${departmentId}&mcoContract=${mcoContractId}&dateFrom=${dateFrom}&dateThrough=${dateThrough}`).then(res => res.json())
)

export const getReferralCaseReportByMember = async (pageSize, pageNumber, mbi, healthPlanId, dateFrom, dateThrough) => (
  fetchWithToken(`referralreport/case?pageSize=${pageSize}&pageNumber=${pageNumber}&mbi=${mbi}&healthPlanId=${healthPlanId}&dateFrom=${dateFrom}&dateThrough=${dateThrough}`).then(res => res.json())
)

export const getReferralCaseReportByCustomerForExcel = async ( pageSize,pageNumber, customerId, departmentId, mcoContractId, email, dateFrom, dateThrough) => (
  fetchWithToken(`referralreport/case?pageSize=${pageSize}&pageNumber=${pageNumber}&userName=${email}&customerId=${customerId}&departmentId=${departmentId}&mcoContract=${mcoContractId}&dateFrom=${dateFrom}&dateThrough=${dateThrough}`).then(res => res.json()).then(json => json.data)
)

export const getReferralCaseReportByMemberForExcel = async (pageSize, pageNumber, mbi, healthPlanId, dateFrom, dateThrough) => (
  fetchWithToken(`referralreport/case?pageSize=${pageSize}&pageNumber=${pageNumber}&mbi=${mbi}&healthPlanId=${healthPlanId}&dateFrom=${dateFrom}&dateThrough=${dateThrough}`).then(res => res.json()).then(json => json.data)
)