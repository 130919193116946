import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Col, Row, RadioGroup, SelectDropdown, Button, DatePicker, Input } from '@uicl/ui-core';
import { filterTypes, defaultOptions, pageSizeOptions, validationKeys, validationDefault, validationErrorMessages } from "../modules/constants";
import { loadCustomers, clearDepartments, loadDepartments  } from '../modules/actions';
import { isValidDate } from "../../../../commonResources/helpers/date";

const ReferralExceptionFilters = (props) => {

  const { onSubmit } = props;

  const dispatch = useDispatch();
  const customers = useSelector((state) => state.referralException.customers);
  const departments = useSelector((state) => state.referralException.departments);
  const userAttachedCustomerId = useSelector((state) => state.app.userInformation.customerId);
  const [totalRecords, loadingErrors, tryToAttachedCustomer] = useSelector((state) => [
    state.referralException.totalRecords,
    state.referralException.errors,
    state.referralException.tryToAttachedCustomer,
  ]);

  const [attachedCustomer, setAttachedCustomer] = useState({});
  const [validationValues, setValidationValues] = useState(validationDefault);
  const [filterBy, setFilterBy] = useState(filterTypes.byCustomer);
  const [validationErrors, setvalidationErrors] = useState({});

  useEffect(() => {
    dispatch(loadCustomers());
  }, []);

  useEffect(() => {
    const validCustomer = customers.find(customer => (customer.id === userAttachedCustomerId));
    if (validCustomer === undefined) {
      setAttachedCustomer(defaultOptions.attachedCustomer);
      return;
    }

    const attached = { isAttached: true, name: validationKeys.customer, value: validCustomer.id, label: validCustomer.name };
    setAttachedCustomer(attached); 
    setValidationValues({...validationValues, [validationKeys.customer]: { name: validationKeys.customer, value: validCustomer.id , label: validCustomer.name }});
    dispatch(loadDepartments(validCustomer.id));
  }, [tryToAttachedCustomer]);

  const onCustomerSelected = (customer) => {
    const { name, value, label } = customer;
    setValidationValues({...validationValues, [name]: { name, value, label }, 
      [validationKeys.department]: defaultOptions.departments });
   
    if (value === 0) {
      dispatch(clearDepartments());
    }
    else {
      dispatch(loadDepartments(value));
    }
  }
  
  const handleChangeSelect = (event) => {
    const { name, value, label } = event;
    setValidationValues({...validationValues, [name]: { name, value, label } });
  }

  const handleChange = (event) => {
    const { name, value } = event;
    setValidationValues({...validationValues, [name]: value });
  }

  useEffect(() => {
    if (filterBy === filterTypes.byCustomer) { 
      setValidationValues({...validationValues, [validationKeys.mbi]: '', 
        [validationKeys.healthPlanId]: '' });
    }
    else if (filterBy === filterTypes.byMember) {
      setValidationValues({...validationValues, [validationKeys.customer]: defaultOptions.customers, 
        [validationKeys.department]: defaultOptions.departments });
    }
  }, [filterBy]);

  const searchClicked = (exportToExcel) => {
    const dataInputErrors = validate(validationValues);
    setvalidationErrors(dataInputErrors);
  
    if (Object.keys(dataInputErrors).length > 0) {
      return;
    }

    const searchFilers = {[validationKeys.pageSize]: validationValues.pageSize.value,
      [validationKeys.customer]: validationValues.customer.value,
      [validationKeys.department]: validationValues.department.value, 
      [validationKeys.mbi]: validationValues.mbi,
      [validationKeys.healthPlanId]: validationValues.healthPlanId,
      [validationKeys.fromDate]: validationValues.fromDate,
      [validationKeys.toDate]: validationValues.toDate,
      [validationKeys.exportToExcel]: exportToExcel,
      [validationKeys.filterBy]: filterBy};

    onSubmit(searchFilers);
  }

  const validate = (valuesToValidate) => {
    const dataInputErrors = {};

    if (valuesToValidate.fromDate === "") {
      dataInputErrors.fromDateHasErrors = true;
      dataInputErrors.fromDateErrorMessage = validationErrorMessages.fromDateRequired;
    }
    
    if (!dataInputErrors.fromDateHasErrors && !isValidDate(valuesToValidate.fromDate)) {
      dataInputErrors.fromDateHasErrors = true;
      dataInputErrors.fromDateErrorMessage = validationErrorMessages.dateFormat;
    }

    const fromDate = new Date(valuesToValidate.fromDate);
    const currentDate = new Date(Date.now());

    if (valuesToValidate.toDate !== "") {
      if (!isValidDate(valuesToValidate.toDate)) 
      {
        dataInputErrors.toDateHasErrors = true;
        dataInputErrors.toDateErrorMessage = validationErrorMessages.dateFormat;
      }

      const toDate = new Date(valuesToValidate.toDate);
  
      if (isValidDate(valuesToValidate.toDate) && fromDate >= toDate) {
        dataInputErrors.toDateHasErrors = true;
        dataInputErrors.toDateErrorMessage = validationErrorMessages.dateRangeTo;
      }
    }

    if (fromDate > currentDate) {
      dataInputErrors.fromDateHasErrors = true;
      dataInputErrors.fromDateErrorMessage = validationErrorMessages.dateRangeToday;
    }

    return dataInputErrors;
  }

  const renderErrors = loadingErrors.map(error => error.message.length > 0 && <div key={error.key}>{error.message}</div>);

  return (
    <Container>
      <Row>
        <Col>
          <Row>
            <Col>
              Report Options
            </Col>
            <Col>
              <RadioGroup
                size="small"
                onChange={ (e) => {setFilterBy(e.id)}}
                checkedItem={{ id: filterBy }}
                items={[
                  { id: filterTypes.byCustomer, label: 'By Customer' },
                  { id: filterTypes.byMember, label: 'By Member' }
                ]}
              >
              </RadioGroup>
            </Col>
            <Col>
            </Col>
            <Col>
              <SelectDropdown
                size = "medium"
                domID="pageSizeDropdownID"
                dataTestId="test-pageSize"
                label="Show"
                options = {pageSizeOptions}
                value={validationValues.pageSize}
                onChange={(e) => handleChangeSelect(e ?? defaultOptions.pageSize )}
              /> 
            </Col>
          </Row>
        </Col>
      </Row> 
      { filterBy === filterTypes.byCustomer ?
        <Row>
          <Col>
            { attachedCustomer.isAttached ? 
              <SelectDropdown
                size = "medium"
                domID="customerDropdownID"
                dataTestId="test-Customers"
                label="Customer"
                placeholderText="Select the customer"
                options={customers.map(({id, name}) => ({ name: validationKeys.customer, value: id, label: name }))}
                value={ {name: validationKeys.customer, value: attachedCustomer.value, label: attachedCustomer.label} }
                disabled
              />
              : 
              <SelectDropdown
                size = "medium"
                domID="customerDropdownID"
                dataTestId="test-Customers"
                label="Customer"
                placeholderText="Select the customer"
                options={customers.map(({id, name}) => ({ name: validationKeys.customer, value: id, label: name }))}
                onChange={(e) => onCustomerSelected(e ?? defaultOptions.customers )}
              /> } 
          </Col>
          <Col>
            <SelectDropdown
              size="medium"
              domID="departmentDropdownID"
              dataTestId="test-refDepartments"
              label="Department"
              placeholderText="Select a department"
              options={departments.map(({id, name}) => ({ name: validationKeys.department, label: name, value: id }))}
              value={validationValues.department}
              onChange={(e) => handleChangeSelect(e ?? defaultOptions.departments )}
            />
          </Col>
        </Row> :
        <Row>
          <Col>
            <Input
              domID="mbi"
              size = "medium"
              dataTestId="test-mbi"
              label="MBI"
              name={validationKeys.mbi}
              value={validationValues.mbi}
              onChange={(e) => handleChange(e.target)}
            />
          </Col>
          <Col>
            <Input
              domID="healthPlan"
              size = "medium"
              dataTestId="test-healthPlan"
              label="Health Plan ID"
              name={validationKeys.healthPlanId}
              value={validationValues.healthPlanId}
              onChange={(e) => handleChange(e.target)}
            />
          </Col>
        </Row> }
      <Row>
        <Col>
          <DatePicker
            label="Date From"
            domID="customerDateFromID"
            hasError={validationErrors.fromDateHasErrors}
            errorMessage={validationErrors.fromDateHasErrors && validationErrors.fromDateErrorMessage || validationErrorMessages.dateFormat}
            onInputChange={(e) => handleChange({name: validationKeys.fromDate, value: e})}
          />    
        </Col>
        <Col>
          <DatePicker
            label="Date Through"
            domID="customerDateThroughID"
            hasError={validationErrors.toDateHasErrors}
            errorMessage={validationErrors.toDateHasErrors && validationErrors.toDateErrorMessage || validationErrorMessages.dateFormat}
            onInputChange={(e) => handleChange({name: validationKeys.toDate, value: e})}
          />   
        </Col>
      </Row>
      <Row>
        <Col>
        </Col>
        <Col>
          <div className='column-button'>
            <Button className='search-button'
              size="medium"
              onClick={ () => searchClicked(false) }
              name="Search"
              dataTestId="test-formButton"
            />
            {totalRecords > 0 &&
            <Button className='referralFiltersExportExcelButton'
              onClick={ () => searchClicked(true) }
            >Export Result to Excel</Button>}
          </div> 
        </Col>
      </Row>
      <div>
        {renderErrors}
      </div>
    </Container>
  );
}

export default ReferralExceptionFilters;

ReferralExceptionFilters.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  onSubmit: PropTypes.func,
}