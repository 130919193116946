import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useLayout from '@uicl/ui-core/dist/useLayout';
import DetailPaneHeader from '@uicl/ui-core/dist/DetailPaneHeader';
import { Button, Checkbox, Col, CollapsePanel, Container, Input, Row, SelectDropdown, TextArea } from '@uicl/ui-core';
import { clearZipcodeAltData, loadLanguages, loadOutreachChannels, loadRelationships, loadZipcodeByStateAlt, updateReferral } from '../modules/actions';
import { useForm } from '../../../../commonResources/hooks/useForm';
import LoadingPage from '../../../../components/LoadingPage';
import { isInRole } from '../../../../commonResources/helpers/ruleValidation';
import { ruleConstants } from '../../../../commonResources/constants/ruleConstants';
import Notification from '../../../../components/notifications/Notification';


const ReferralDetails = ({ dataTestId }) => {

  const {
    detailProps = {},
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    closeDetailPane
  } = useLayout();

  const {
    preferredLanguageData,
    otherLanguageData,
    relationshipData,
    outreachChannelData,
    updateReferalIsLoading,
    isUpdateReferralSuccess,
    zipcodeDataAlt,
    updateReferralErrorMsgForInput,
    isUpdateReferralFail
  } = useSelector(state => state.searchReferral);

  const { states } = useSelector(state => state.common);
  const { userInformation } = useSelector(state => state.app);

  const dispatch = useDispatch();

  // UI Flags
  const [openViewReferral, setOpenViewReferral] = useState(true);
  const [openMemberInformation, setOpenMemberInformation] = useState(true);
  const [openAlternativeContact, setOpenAlternativeContact] = useState(true);
  const [openMemberPreferences, setOpenMemberPreferences] = useState(true);
  const [isSameValues, setIsSameValues] = useState(false);
  // eslint-disable-next-line
  const [isOpenEditOptions, setIsOpenEditOptions] = useState(false);
  const [isOpenAlternativeContact, setIsOpenAlternativeContact] = useState(false);
  
  // Initial dropdown values
  const [initialPreferredLanguage, setInitialPreferredLanguage] = useState();
  const [initialOtherPreferredLanguage, setInitialOtherPreferredLanguage] = useState();
  const [initialPreferredOutreach, setInitialPreferredOutreach] = useState();
  const [initialRelationship, setInitialRelationship] = useState();
  const [initialState, setInitialState] = useState();

  // Dropdowns
  const [preferredLanguage, setPreferredLanguage] = useState(null);
  const [otherPreferredLanguage, setOtherPreferredLanguage] = useState(null);
  const [preferredOutreach, setPreferredOutreach] = useState(null);
  const [relationship, setRelationship] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  // Text areas
  const [comments, setComments] = useState('');

  // Check boxes
  const [isPreferredContact, setIsPreferredContact] = useState(null);
  const [isPreferredAlternateContact, setIsPreferredAlternateContact] = useState(null)
  const [hasAlternativeContact, setHasAlternativeContact] = useState(null);

  // Validate states
  const [homePhoneError, setHomePhoneError] = useState(false);
  const [alternatePhoneError, setAlternatePhoneError] = useState(false);
  const [alternativeContactPhoneError, setAlternativeContactPhoneError] = useState(false);
  const [memeberEmailError, setMemeberEmailError] = useState(false);
  const [alternativeContactEmailError, setAlternativeContactEmailError] = useState(false);

  // Inputs
  const [catsKey, setCatsKey] = useState(0);
  const [clickSubmit, setSubmit] = useState(false);
  const [selectedZipcodeAlt, setSelectedZipcodeAlt] = useState('');
  const [formValues, handleInputChange, reset] = useForm({
    homePhone: detailProps ? detailProps.homePhone : null,
    alternatePhone: detailProps ? detailProps.alternatePhone : null,
    email: detailProps ? detailProps.email : null,
    alternateFirstName: detailProps ? detailProps.altFirstName : null,
    alternateMiddleName: detailProps ? detailProps.altMiddleInitial : null,
    alternateLastName: detailProps ? detailProps.altLastName : null,
    alternateAddress1: detailProps ? detailProps.altAddress1 : null,
    alternateAddress2: detailProps ? detailProps.altAddress2 : null,
    alternateCity: detailProps ? detailProps.altCity : null,
    // alternateZip: detailProps ? detailProps.altZip : null,
    altPhone: detailProps ? detailProps.altPhone : null,
    alternateEmai: detailProps ? detailProps.altEmail : null,
  });

  const {
    homePhone,
    alternatePhone,
    email,
    alternateFirstName,
    alternateMiddleName,
    alternateLastName,
    alternateAddress1,
    alternateAddress2,
    alternateCity,
    // alternateZip,
    altPhone,
    alternateEmai,
  } = formValues

  // Triggers
  useEffect(() => {
    setIsOpenAlternativeContact(false);
    // setIsOpenEditOptions(false);
    // console.log(detailProps)
    if (detailProps !== null) {
      if (Object.keys(detailProps).length !== 0) {
        setCatsKey(key => key + 1)
        enableFields();
        setSubmit(false);
        setHomePhoneError(false);
        setAlternatePhoneError(false);
        setAlternativeContactPhoneError(false);
        setMemeberEmailError(false);
        setAlternativeContactEmailError(false);
        reset();
        setInitialValues();
      }
    }
  }, [detailProps])

  useEffect(() => {
    if (preferredLanguageData.length === 0) {
      dispatch(loadLanguages());
    }
    if (outreachChannelData.length === 0) {
      dispatch(loadOutreachChannels());
    }
    if (relationshipData.length === 0) {
      dispatch(loadRelationships());
    }
  }, [])

  useEffect(() => {
    if (isUpdateReferralSuccess) {
      setSubmit(false);
      closeDetailPane();
    }
  }, [isUpdateReferralSuccess])

  useEffect(() => {
    if (isUpdateReferralFail) {
      if (updateReferralErrorMsgForInput.indexOf("Invalid Home Phone Number") > 1) {
        setHomePhoneError(true);
      }
      if (updateReferralErrorMsgForInput.indexOf("Invalid Alternate Phone Number") > 1) {
        setAlternatePhoneError(true);
      }
      if (updateReferralErrorMsgForInput.indexOf("Alternative Contact Phone Number") > 1) {
        setAlternativeContactPhoneError(true);
      }
      if (updateReferralErrorMsgForInput.indexOf("Invalid Member's email") > 1) {
        setMemeberEmailError(true);
      }
      if (updateReferralErrorMsgForInput.indexOf("Invalid Alternative contact's email") > 1) {
        setAlternativeContactEmailError(true);
      }
    }
  }, [isUpdateReferralFail])


  // Helper methods
  const setInitialValues = () => {
    // console.log(detailProps)
    dispatch(clearZipcodeAltData());
    dispatch(loadZipcodeByStateAlt(detailProps.altState ? states.filter(s => s.value === detailProps.altState)[0].value : ''));
    setInitialPreferredLanguage(preferredLanguageData.filter(p => p.label === detailProps.preferredLanguage));
    setInitialOtherPreferredLanguage(otherLanguageData.filter(o => o.label === detailProps.otherPreferredLanguage));
    setInitialPreferredOutreach(outreachChannelData.filter(p => p.label === detailProps.outreachPreferredMethod));
    setInitialRelationship(relationshipData.filter(s => s.label === detailProps.altRelationship));
    setInitialState(states.filter(s => s.value === detailProps.altState));
    setPreferredLanguage(preferredLanguageData.find(p => p.label === detailProps.preferredLanguage));
    setOtherPreferredLanguage(otherLanguageData.find(o => o.label === detailProps.otherPreferredLanguage));
    setPreferredOutreach(outreachChannelData.find(p => p.label === detailProps.outreachPreferredMethod));
    setRelationship(relationshipData.find(s => s.label === detailProps.altRelationship));
    setSelectedState(states.find(s => s.value === detailProps.altState));
    setComments(detailProps.comments === null ? '' : detailProps.setComments);
    setSelectedZipcodeAlt(detailProps ? detailProps.altZip : null);
    // eslint-disable-next-line
    setIsPreferredContact(detailProps.preferredContact === 'Yes' ? true : false);
    // eslint-disable-next-line
    setIsPreferredAlternateContact(detailProps.altPreferredContact === 'Yes' ? true : false);
    // eslint-disable-next-line
    setHasAlternativeContact(detailProps.altFirstName ? true : false)
  }

  const enableFields = () => {
    // eslint-disable-next-line
    if ((isInRole(ruleConstants.EditReferralOwn, userInformation) && (detailProps.customerID == userInformation.customerId))) {
      setIsOpenEditOptions(true);
      return;
    }
    // eslint-disable-next-line
    if ((isInRole(ruleConstants.EditReferralOthers, userInformation) && (userInformation.customerId == 0))) {
      setIsOpenEditOptions(true);
      return;
    }
    setIsOpenEditOptions(false);
  }

  const getPhoneNumber = (phone) => {
    if (!phone) {
      return true
    }
    return phone.replace(/[^\w]/gi, '');
  }

  const isValidPhoneNumber = (phone) => {
    if (!phone) {
      return true
    }
    const phLenght = getPhoneNumber(phone).length;
    return phLenght === 10 || phLenght === 0;
  }

  const IsValidZipCode = (zipCode, zipcodes) => {
    if (zipCode && zipCode.length === 5) {
      // eslint-disable-next-line
      return zipcodes.find(zip => { return zip.value === zipCode }) ? true : false;
    }
    // eslint-disable-next-line
    else {
      return !zipCode || zipCode.length === 0;
    }
  }

  const validateEmail = (e) => {
    const emailForValidate = e === null || e === undefined ? '' : e;
    if (emailForValidate.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      return true;
    }
    return false;
  };

  const selectPreferredLanguage = () => {
    return preferredLanguage.value === 0 ? otherPreferredLanguage.value : preferredLanguage.value;
  }

  const handleMemberPreferredContactChange = () => {
    // un-checking the check box
    // if (isPreferredContact && !hasAlternativeContact) {
    //   setHasAlternativeContact(true);
    // }
    setIsPreferredContact(!isPreferredContact);
    setIsPreferredAlternateContact(isPreferredContact);
  }

  const handleAltPreferredContactChange = () => {
    setIsPreferredAlternateContact(!isPreferredAlternateContact);
    setIsPreferredContact(isPreferredAlternateContact);
  }

  // Dropdown events
  const handlePreferredLanguageChange = (e) => {
    if ((e && e !== preferredLanguage) || !e) {
      setOtherPreferredLanguage('');
    }
    // eslint-disable-next-line
    setPreferredLanguage(e ? e : '');
  }

  const handleOtherPreferredLanguageChange = (e) => {
    // eslint-disable-next-line
    setOtherPreferredLanguage(e ? e : '');
  }

  const handleOutreachMethod = (e) => {
    // eslint-disable-next-line
    setPreferredOutreach(e ? e : '');
  }

  const handleRelationship = (e) => {
    // eslint-disable-next-line
    setRelationship(e ? e : '');
  }

  const handleState = (e) => {
    if (e && e !== selectedZipcodeAlt) {
      dispatch(clearZipcodeAltData());
      dispatch(loadZipcodeByStateAlt(e.value));
      setSelectedZipcodeAlt('');
    }
    if (!e) {
      dispatch(clearZipcodeAltData());
      setSelectedZipcodeAlt('');
    }
    // eslint-disable-next-line
    setSelectedState(e ? e : '');
  }

  // Button events
  const onClose = () => {
    closeDetailPane();
    setSubmit(false);
    setHomePhoneError(false);
    setAlternatePhoneError(false);
    setAlternativeContactPhoneError(false);
    setMemeberEmailError(false);
    setAlternativeContactEmailError(false);
    reset();
    setIsOpenAlternativeContact(false);
    // setIsOpenEditOptions(false);
  }

  const isValidReferralInputData = () => {
    // eslint-disable-next-line
    const isValid = (
      (
        (preferredLanguage && preferredLanguage.value !== 0) ||
        (preferredLanguage.value === 0 && otherPreferredLanguage)
      ) &&
      (getPhoneNumber(homePhone) !== getPhoneNumber(alternatePhone)) &&
      (
        !preferredOutreach ||
        preferredOutreach.value !== 4 ||
        // eslint-disable-next-line
        (preferredOutreach.value === 4 && email && (validateEmail(email ? email : '')))
      ) &&
      (
        !hasAlternativeContact ||
        hasAlternativeContact &&
        (
          relationship &&
          alternateFirstName &&
          alternateAddress1 &&
          alternateCity &&
          selectedState &&
          selectedZipcodeAlt &&
          altPhone && getPhoneNumber(altPhone).length === 10 &&
          (
            // eslint-disable-next-line
            !preferredOutreach ||
            preferredOutreach.value !== 4 ||
            // eslint-disable-next-line
            (preferredOutreach.value === 4 && (validateEmail(alternateEmai ? alternateEmai : '')))
          )
        )
      )
    ) ? true : false;

    return isValid;
  }

  const referralDataChanged = (referralData) => {
    // eslint-disable-next-line
    return referralData.homePhone !== detailProps.homePhone || 
    referralData.alternatePhone !== detailProps.alternatePhone ||
    referralData.email !== detailProps.email ||
    referralData.altFirstName !== detailProps.altFirstName ||
    referralData.altAddress1 !== detailProps.altAddress1 ||
    referralData.altAddress2 !== detailProps.altAddress2 ||
    referralData.altCity !== detailProps.altCity ||
    referralData.altZip !== detailProps.altZip ||
    referralData.altPhone !== detailProps.altPhone ||
    referralData.altEmail !== detailProps.altEmail ||
    referralData.primaryLanguageId !== detailProps.primaryLanguageId ||
    referralData.outreachChannelTypeId !== detailProps.outreachChannelTypeId ||
    referralData.relationshipId !== detailProps.relationshipId ||
    referralData.altState !== detailProps.altState ||
    referralData.comments !== detailProps.comments ||
    // eslint-disable-next-line
    referralData.isMemberPreferredContact !== (detailProps.preferredContact === 'Yes' ? true : false)
  }

  const handleSubmit = () => {
    setSubmit(true);
    if (isValidReferralInputData()) {
      const data = {
        id: detailProps.referralID,
        homePhone: homePhone ? getPhoneNumber(homePhone) : null,
        alternatePhone: alternatePhone ? getPhoneNumber(alternatePhone) : null,
        email,
        firstName: detailProps.firstName,
        middleInitial: detailProps.middleInitial,
        lastName: detailProps.lastName,
        altFirstName: alternateFirstName,
        altMiddleInitial: alternateMiddleName,
        altLastName: alternateLastName,
        altAddress1: alternateAddress1,
        altAddress2: alternateAddress2,
        altCity: alternateCity,
        altZip: selectedZipcodeAlt,
        altPhone: altPhone ? getPhoneNumber(altPhone) : null,
        altEmail: alternateEmai,
        primaryLanguageId: preferredLanguage ? selectPreferredLanguage() : null,
        outreachChannelTypeId: preferredOutreach ? preferredOutreach.value : null,
        relationshipId: relationship ? relationship.value : null,
        altState: selectedState ? selectedState.value : null,
        comments,
        isMemberPreferredContact: isPreferredContact,
        personId: detailProps.personId,
        alternativeAddressId: detailProps.alternativeAddressId,
        homePhoneId: detailProps.homePhoneId,
        alternatePhoneId: detailProps.alternatePhoneId,
        alternativeContactPhoneId: detailProps.alternativeContactPhoneId,
        personEmailId: detailProps.personEmailId,
        alternativeEmailId: detailProps.alternativeEmailId,
        userName: userInformation.userName,
      }
      if (!referralDataChanged(data)) {
        setIsSameValues(true);
        return
      }
      // console.log(data)
      dispatch(updateReferral(data));
    }

  }

  return (detailProps ?
    <div style={{ width: "50vw" }} key={catsKey}>
      {isSameValues && (
        <Notification
          title="Info"
          text="Nothing to update"
          type="info"
          onDismiss={() => setIsSameValues(false)}
        />
      )}
      <DetailPaneHeader
        title='Referral Details'
        dataTestId={`${dataTestId}-header`}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig,
        ]}
      />
      {
        updateReferalIsLoading
          ?
          <LoadingPage />
          :
          <>
            {/* View Referral */}
            <CollapsePanel
              domID="viewReferral"
              title="View Referral"
              expanded={openViewReferral}
              onChange={() => setOpenViewReferral(!openViewReferral)}
              defaultExpanded={false}
            >
              <Container style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Row style={{ marginBottom: 8 }}>
                  <Col>
                    <Input
                      domID='txtCustomer'
                      disabled
                      label='Customer'
                      value={detailProps.customerName}
                    />
                  </Col>
                  <Col>
                    <Input
                      domID='txtMcoContract'
                      disabled
                      label='Mco Contract'
                      value={detailProps.mcoContractName}
                    />
                  </Col>
                  <Col>
                    <Input
                      domID='txtDepartment'
                      disabled
                      label='Department'
                      value={detailProps.departmentName}
                    />
                  </Col>
                </Row>

                <Row style={{ marginBottom: 8 }}>
                  <Col>
                    <Input
                      domID='txtReferredBy'
                      disabled
                      label='Referred By'
                      value={detailProps.referredBy}
                    />
                  </Col>
                  <Col>
                    <Input
                      domID='txtHealthPlanId'
                      disabled
                      label='Health Plan ID'
                      value={detailProps.healthPlanId}
                    />
                  </Col>
                  <Col>
                    <Input
                      domID='txtMbi'
                      disabled
                      label='MBI'
                      value={detailProps.mbi}
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: 8 }}>
                  <Col>
                    <Input
                      domID='txtReferredDate'
                      disabled
                      label='Referred Date'
                      value={detailProps.lastUpdateDate}
                    />
                  </Col>
                  <Col>
                    <Input
                      domID='txtProspect'
                      disabled
                      label='Prospect'
                      value={detailProps.prospect ? 'True' : 'False'}
                    />
                  </Col>
                  <Col>
                  </Col>
                </Row>

              </Container>
            </CollapsePanel>

            {/* Member Information */}
            <CollapsePanel
              domID="memberInformation"
              title="Member Information"
              expanded={openMemberInformation}
              onChange={() => setOpenMemberInformation(!openMemberInformation)}
              defaultExpanded={false}
            >
              <Container style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Row style={{ marginBottom: 8 }}>
                  <Col>
                    <Input
                      domID='txtFirstName'
                      disabled
                      label='First Name'
                      value={detailProps.firstName}
                    />
                  </Col>
                  <Col>
                    <Input
                      domID='txtMiddleInitial'
                      disabled
                      label='Middle Initial'
                      value={detailProps.middleInitial}
                    />
                  </Col>
                  <Col>
                    <Input
                      domID='txtLastName'
                      disabled
                      label='Last Name'
                      value={detailProps.lastName}
                    />
                  </Col>
                </Row>

                <Row style={{ marginBottom: 8 }}>
                  <Col>
                    <Input
                      domID='txtDOB'
                      disabled
                      label='Date Of Birth'
                      value={detailProps.dob}
                    />
                  </Col>
                  <Col>
                    <Input
                      domID='txtGender'
                      disabled
                      label='Gender'
                      value={detailProps.gender}
                    />
                  </Col>
                  <Col>
                  </Col>
                </Row>

                <Row style={{ marginBottom: 8 }}>
                  <Col>
                    <Input
                      domID='txtAddress1'
                      disabled
                      label='Address 1'
                      value={detailProps.address1}
                    />
                  </Col>
                  <Col>
                    <Input
                      domID='txtAddress2'
                      disabled
                      label='Address 2'
                      value={detailProps.address2}
                    />
                  </Col>
                </Row>

                <Row style={{ marginBottom: 8 }}>
                  <Col>
                    <Input
                      domID='txtCity'
                      disabled
                      label='City'
                      value={detailProps.city}
                    />
                  </Col>
                  <Col>
                    <Input
                      domID='txtState'
                      disabled
                      label='State'
                      value={detailProps.state}
                    />
                  </Col>
                  <Col>
                    <Input
                      domID='txtZip'
                      disabled
                      label='ZIP'
                      value={`${detailProps.zip === null ? '' : detailProps.zip}${detailProps.zipExtension === null ? '' : ` - ${detailProps.zipExtension}`}`}
                    />
                  </Col>
                </Row>

                <Row style={{ marginBottom: 8 }}>
                  <Col>
                    <Input
                      domID='txtHomePhone'
                      disabled={!isOpenEditOptions}
                      mask='(999) 999-9999'
                      label='Home Phone'
                      required
                      hasError={(clickSubmit && (!homePhone || getPhoneNumber(homePhone).length !== 10)) || !isValidPhoneNumber(homePhone) || homePhoneError}
                      // eslint-disable-next-line
                      errorMessage={clickSubmit && !homePhone ? 'Please enter the Phone Number' : !isValidPhoneNumber(homePhone) ? 'Invalid Phone Number' : homePhoneError ? 'Invalid Phone Number' : ''}
                      autoComplete='off'
                      onKeyPress={() => setHomePhoneError(false)}
                      name='homePhone'
                      value={homePhone}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col>
                    <Input
                      domID='txtAlternatePhone'
                      disabled={!isOpenEditOptions}
                      label='Alternate Phone'
                      mask='(999) 999-9999'
                      hasError={!isValidPhoneNumber(alternatePhone) || (clickSubmit && (getPhoneNumber(homePhone) === getPhoneNumber(alternatePhone))) || alternatePhoneError}
                      // eslint-disable-next-line
                      errorMessage={!isValidPhoneNumber(alternatePhone) ? 'Invalid Phone Number' : (getPhoneNumber(homePhone) === getPhoneNumber(alternatePhone)) ? 'The Alternate Phone cannot be the same as the Home Phone.' : alternatePhoneError ? 'Invalid Phone Number' : ''}
                      autoComplete='off'
                      onKeyPress={() => setAlternatePhoneError(false)}
                      name='alternatePhone'
                      // initialValue= {detailProps.alternatePhone}
                      value={alternatePhone}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col>
                    <Input
                      domID='txtEmail'
                      disabled={!isOpenEditOptions}
                      label='Email'
                      name='email'
                      // eslint-disable-next-line
                      required={(preferredOutreach ? preferredOutreach.value : 0) === 4}
                      // eslint-disable-next-line
                      hasError={(clickSubmit && ((preferredOutreach ? preferredOutreach.value : 0) === 4 && !email)) || (clickSubmit && !validateEmail(email ? email : '')) || memeberEmailError}
                      // eslint-disable-next-line
                      errorMessage={clickSubmit && ((preferredOutreach ? preferredOutreach.value : 0) === 4 && !email) ? 'Please enter the email' : !validateEmail(email ? email : '') ? 'Invalid Email' : memeberEmailError ? 'Invalid Email' : ''}
                      autoComplete='off'
                      onKeyPress={() => setMemeberEmailError(false)}
                      value={email}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>

              </Container>
            </CollapsePanel>

            {/* Member Preferences */}
            <CollapsePanel
              domID="memberPreferences"
              title="Member Preferences"
              expanded={openMemberPreferences}
              onChange={() => setOpenMemberPreferences(!openMemberPreferences)}
              defaultExpanded={false}
            >
              <Container style={{ paddingTop: 0, paddingBottom: 0 }}>

                <Row style={{ marginBottom: 8 }}>
                  <Col>
                    {
                      !isOpenEditOptions
                        ?
                        <Input
                          domID='txtPreferredLanguage'
                          disabled={!isOpenEditOptions}
                          label='Preferred Language'
                          value={detailProps.preferredLanguage}
                        />
                        :
                        <SelectDropdown
                          placeholderText='[Select Preferred Language]'
                          domID='ddlPreferredLanguage'
                          name='preferredLanguage'
                          // value={detailProps.preferredLanguage}
                          isClearable={false}
                          initialValue={initialPreferredLanguage}
                          // required
                          // hasError={clickSubmit && !preferredLanguage}
                          // errorMessage={clickSubmit && !preferredLanguage ? 'Please select the Preferred Language' : ''}
                          label='Preferred Language'
                          onChange={handlePreferredLanguageChange}
                          options={preferredLanguageData}
                        />
                    }
                  </Col>
                  <Col>
                    {
                      !isOpenEditOptions
                        ?
                        <Input
                          domID='txtOtherLanguage'
                          disabled={!isOpenEditOptions}
                          label='Other Preferred Language'
                          value={detailProps.otherPreferredLanguage}
                        />
                        :
                        <>
                          {
                            (((preferredLanguage ? preferredLanguage.value : null) === 0)) &&
                            <SelectDropdown
                              placeholderText='[Select Other Language]'
                              domID='ddlOtherLanguage'
                              name='otherLanguage'
                              isClearable={false}
                              initialValue={initialOtherPreferredLanguage}
                              value={otherPreferredLanguage}
                              required={(preferredLanguage ? preferredLanguage.value : null) === 0}
                              hasError={clickSubmit && !otherPreferredLanguage}
                              errorMessage={clickSubmit && (preferredLanguage ? preferredLanguage.value : null) === 0 && !otherPreferredLanguage ? 'Please select the Preferred Language' : ''}
                              label='Other Preferred Language'
                              onChange={handleOtherPreferredLanguageChange}
                              options={otherLanguageData}
                            />
                          }
                        </>
                    }
                  </Col>
                </Row>

                <Row style={{ marginBottom: 8 }}>
                  <Col>
                    {
                      !isOpenEditOptions
                        ?
                        <Input
                          domID='txtOutreachMethod'
                          disabled={!isOpenEditOptions}
                          label='Outreach Preferred Method'
                          value={detailProps.outreachPreferredMethod}
                        />
                        :
                        <SelectDropdown
                          placeholderText='[Select Outreach Method]'
                          domID='outreachChannel'
                          name='outreachChannel'
                          label='Preferred Outreach Method'
                          initialValue={initialPreferredOutreach}
                          value={preferredOutreach}
                          onChange={handleOutreachMethod}
                          options={outreachChannelData}
                        />
                    }
                  </Col>
                  <Col>
                    <Input
                      domID='txtPreferredContact'
                      disabled={!isOpenEditOptions}
                      label='Preferred Contact'
                      value={detailProps.preferredContact}
                    />
                    <Checkbox
                      domID='chbPrederredContact'
                      ariaLabelledBy=""
                      dataTestId="checkboxPreferredContact"
                      label="Preferred Contact"
                      disabled={!isOpenEditOptions}
                      checked={isPreferredContact}
                      onChange={handleMemberPreferredContactChange}
                      size="medium"
                    />
                  </Col>
                </Row>

                <Row style={{ marginBottom: 8 }}>
                  <Col>
                    <TextArea
                      domID='txtComments'
                      disabled={!isOpenEditOptions}
                      label='Comments'
                      initialValue={comments}
                      // value={comments}
                      onChange={(e) => setComments(e ? e.target.value : '')}
                    />
                  </Col>
                </Row>
                {
                  !isOpenAlternativeContact &&
                  <Row style={{ marginBottom: 8 }}>
                    <Col>
                      <Checkbox
                        domID='chbHasAlternativeContact'
                        ariaLabelledBy=""
                        dataTestId="checkboxAlternativeContact"
                        label="Has Alternative Contact"
                        // disabled={!isOpenEditOptions}
                        disabled
                        checked={detailProps.altFirstName && detailProps.altLastName}
                        // onChange={function noRefCheck() { }}
                        size="medium"
                      />
                    </Col>
                  </Row>
                }
              </Container>
            </CollapsePanel>

            <Container>
              {
                ((!detailProps.altFirstName && !detailProps.altLastName) && isOpenEditOptions)
                &&
                <>
                  <Button onClick={() => { setIsOpenAlternativeContact(!isOpenAlternativeContact); setHasAlternativeContact(!isOpenAlternativeContact); setIsPreferredContact(true) }}>
                    {
                      isOpenAlternativeContact
                        ?
                        'Cancel'
                        :
                        'Add Alternative Contact'
                    }
                  </Button>
                </>
              }
            </Container>


            {/* Alternative Contact */}
            {
              (detailProps.altFirstName || isOpenAlternativeContact)
              &&
              <CollapsePanel
                domID="alternativeContact"
                title="Alternative Contact"
                expanded={openAlternativeContact}
                onChange={() => setOpenAlternativeContact(!openAlternativeContact)}
                defaultExpanded={false}
              >
                <Container style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Row style={{ marginBottom: 8 }}>
                    <Col>
                      <Input
                        domID='txtAlternateFirstName'
                        disabled={!isOpenEditOptions}
                        label='Full Name'
                        name='alternateFirstName'
                        required={hasAlternativeContact}
                        hasError={hasAlternativeContact && clickSubmit && !alternateFirstName}
                        errorMessage={clickSubmit && !alternateFirstName ? 'Please enter the Full Name' : 'Invalid Name'}
                        regex="\b([A-Za-z]+[A-Za-z. ']+[ ]*)+$"
                        autoComplete='off'
                        maxLength={50}
                        value={alternateFirstName}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col></Col>
                    {/* <Col>
                      <Input
                        domID='txtAlternateMiddleInitial'
                        disabled={!isOpenEditOptions}
                        label='Middle Initial'
                        name='alternateMiddleName'
                        value={alternateMiddleName}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col>
                      <Input
                        domID='txtAlternateLastName'
                        disabled={!isOpenEditOptions}
                        label='Last Name'
                        name='alternateLastName'
                        value={alternateLastName}
                        onChange={handleInputChange}
                      />
                    </Col> */}
                  </Row>

                  <Row style={{ marginBottom: 8 }}>
                    <Col>
                      {
                        !isOpenEditOptions
                          ?
                          <Input
                            domID='txtRelationship'
                            disabled={!isOpenEditOptions}
                            label='Relationship'
                            value={detailProps.altRelationship}
                          />
                          :
                          <SelectDropdown
                            placeholderText='[Select Relationship]'
                            name='relationship'
                            domID='ddlRelationship'
                            label='Relationship'
                            isClearable={false}
                            initialValue={initialRelationship}
                            // value={relationship}
                            required={hasAlternativeContact}
                            hasError={hasAlternativeContact && clickSubmit && !relationship}
                            errorMessage={clickSubmit && !relationship ? 'Please select a Relationship' : ''}
                            onChange={handleRelationship}
                            options={relationshipData}
                          />
                      }
                    </Col>
                    <Col>
                      <Input
                        domID='txtAlternateAddress1'
                        disabled={!isOpenEditOptions}
                        label='Address 1'
                        name='alternateAddress1'
                        required={hasAlternativeContact}
                        maxLength={50}
                        hasError={hasAlternativeContact && clickSubmit && !alternateAddress1}
                        errorMessage={clickSubmit && !alternateAddress1 ? 'Please enter the Address' : ''}
                        value={alternateAddress1}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col>
                      <Input
                        domID='txtAlternateAddress2'
                        disabled={!isOpenEditOptions}
                        maxLength={50}
                        label='Address 2'
                        name='alternateAddress2'
                        value={alternateAddress2}
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: 8 }}>
                    <Col>
                      <Input
                        domID='txtAlternateCity'
                        disabled={!isOpenEditOptions}
                        label='City'
                        name='alternateCity'
                        autoComplete='off'
                        maxLength={50}
                        required={hasAlternativeContact}
                        hasError={hasAlternativeContact && clickSubmit && !alternateCity}
                        errorMessage={clickSubmit && !alternateCity ? 'Please enter the City' : ''}
                        value={alternateCity}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col>
                      {
                        !isOpenEditOptions
                          ?
                          <Input
                            domID='txtAlternateState'
                            disabled={!isOpenEditOptions}
                            label='State'
                            value={detailProps.altState}
                          />
                          :
                          <SelectDropdown
                            placeholderText='[Select State]'
                            domID='ddlState'
                            required={hasAlternativeContact}
                            hasError={hasAlternativeContact && clickSubmit && !selectedState}
                            errorMessage={clickSubmit && !selectedState ? 'Please select a State' : ''}
                            name='state'
                            // value={state}
                            label='State'
                            isClearable={false}
                            initialValue={initialState}
                            onChange={handleState}
                            options={states}
                          />
                      }
                    </Col>
                    <Col>
                      <Input
                        domID='txtAlternateZip'
                        disabled={!isOpenEditOptions}
                        label='ZIP'
                        name='selectedZipcodeAlt'
                        required={hasAlternativeContact && !isOpenEditOptions}
                        hasError={(clickSubmit && !selectedZipcodeAlt) || (!IsValidZipCode(selectedZipcodeAlt, zipcodeDataAlt) && isOpenEditOptions)}
                        // eslint-disable-next-line
                        errorMessage={clickSubmit && !selectedZipcodeAlt ? 'Please enter the Zip Code' : (!IsValidZipCode(selectedZipcodeAlt, zipcodeDataAlt) && isOpenEditOptions) ? 'Invalid zip code' : ''}
                        regex='\b([0-9]){5}$'
                        autoComplete='off'
                        maxLength={5}
                        value={selectedZipcodeAlt}
                        onChange={(e) => setSelectedZipcodeAlt(e ? e.target.value : '')}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: 8 }}>
                    <Col>
                      <Input
                        domID='txtAlternatePhone'
                        disabled={!isOpenEditOptions}
                        label='Phone'
                        name='altPhone'
                        required={hasAlternativeContact}
                        // eslint-disable-next-line
                        hasError={(hasAlternativeContact && clickSubmit && (!altPhone || getPhoneNumber(altPhone).length !== 10)) || !isValidPhoneNumber(altPhone) || alternativeContactPhoneError}
                        // eslint-disable-next-line
                        errorMessage={clickSubmit && !altPhone ? 'Please enter the Phone Number' : !isValidPhoneNumber(altPhone) ? 'Invalid Phone Number' : alternativeContactPhoneError ? 'Invalid Phone Number' : ''}
                        autoComplete='off'
                        onKeyPress={() => setAlternativeContactPhoneError(false)}
                        value={altPhone}
                        onChange={handleInputChange}
                        mask='(999) 999-9999'
                      />
                    </Col>
                    <Col>
                      <Input
                        domID='txtAlternateEmail'
                        disabled={!isOpenEditOptions}
                        label='Email'
                        name='alternateEmai'
                        // eslint-disable-next-line
                        required={hasAlternativeContact && (preferredOutreach ? preferredOutreach.value : 0) === 4}
                        // eslint-disable-next-line
                        hasError={(hasAlternativeContact && clickSubmit && ((preferredOutreach ? preferredOutreach.value : 0) === 4 && !alternateEmai) || (hasAlternativeContact && clickSubmit && ((preferredOutreach ? preferredOutreach.value : 0) === 4) && (!validateEmail(alternateEmai ? alternateEmai : '')))) || alternativeContactEmailError}
                        // eslint-disable-next-line
                        errorMessage={clickSubmit && ((preferredOutreach ? preferredOutreach.value : 0) === 4 && !alternateEmai) ? 'Please enter the email' : !validateEmail(alternateEmai ? alternateEmai : '') ? 'Invalid Email' : alternativeContactEmailError ? 'Invalid Email' : ''}
                        autoComplete='off'
                        onKeyPress={() => setAlternativeContactEmailError(false)}
                        value={alternateEmai}
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: 8 }}>
                    <Col>
                      {/* <Input
                    domID='txtAlternatePrederredContact'
                    disabled={!isOpenEditOptions}
                    label='Preferred Contact'
                    value={detailProps.altPreferredContact}
                  /> */}
                      <Checkbox
                        domID='chbAlternatePrederredContact'
                        ariaLabelledBy=""
                        dataTestId="checkboxAlternativePreferredContact"
                        label="Preferred Contact"
                        disabled={!isOpenEditOptions}
                        checked={isPreferredAlternateContact}
                        onChange={handleAltPreferredContactChange}
                        size="medium"
                      />
                    </Col>
                  </Row>

                </Container>
              </CollapsePanel>
            }

            {/* Buttons */}
            <Container>
              {
                isOpenEditOptions
                  ?
                  <>
                    <Button dataTestId="update-button" onClick={handleSubmit}>
                      Save
                    </Button>
                    {/* <Button buttonType="diminished" onClick={() => { setIsOpenEditOptions(!isOpenEditOptions); setIsOpenAlternativeContact(false); reset(); }}>
                      Back to View Referral
                    </Button> */}
                    <Button buttonType="diminished" onClick={onClose}>
                      Back to Referral Search
                    </Button>
                  </>
                  :
                  <>
                    {/* <Button onClick={() => setIsOpenEditOptions(!isOpenEditOptions)}>
                      Edit
                    </Button> */}
                    <Button buttonType="diminished" onClick={onClose}>
                      Back to Referral Search
                    </Button>
                  </>
              }
            </Container>
          </>
      }


    </div>
    : null
  )
}

export default ReferralDetails

ReferralDetails.propTypes = {
  dataTestId: PropTypes.string,
};