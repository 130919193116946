import { fetchWithToken } from '../../../../commonResources/helpers/fetch'

export const getCustomersCatalog = async () => (
  fetchWithToken('customers/items').then(res => res.json()).then(json => json.data)
)

export const getRolesCatalog = async () => (
  fetchWithToken('referralroles').then(res => res.json()).then(json => json.data)
)

export const getDepartmentsCatalog = async (customerId) => (
  fetchWithToken(`referraldepartments/customer/${customerId}/items`).then(res => res.json()).then(json => json.data)
)

export const getUnitManagersCatalog = async (customerId) => (
  fetchWithToken(`referralusers/search?RoleId=129&CustomerId=${customerId}&PageSize=10000000`).then(res => res.json()).then(json => json.data)
)

export const getUsers = async(roleId, customerId, departmentId, unitManagerId, email, pageNumber, pageSize) =>(
  fetchWithToken(`referralusers/search?PageNumber=${pageNumber}&PageSize=${pageSize}&RoleId=${roleId}&CustomerId=${customerId}&UnitManagerId=${unitManagerId}&DepartmentId=${departmentId}&Email=${email}`).then(res => res.json())
)


export const getUserActivity = async (userId, pageNumber, pageSize) => (
  fetchWithToken(`referraluseractivity/search?pageNumber=${pageNumber}&pageSize=${pageSize}&UserId=${userId}`).then(res => res.json())
)

export const updateUserStatus = async (data) => (
  fetchWithToken(`ReferralUsers/UpdateUserStatus`, data, 'PUT').then(res => res.json()).then(json => json)
)

export const putUser = async (data) => (
  fetchWithToken(`ReferralUsers/updateUser`, data, 'PUT').then(res => res.json()).then(json => json)
)