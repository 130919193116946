import React from 'react'
import NavigationPane from '../../NavigationPane/containers/NavigationPaneContainer';
import PageWrapper from '../../PageWrapper';
import RolesRules from './components/RolesRules';

/*eslint-disable */


const Administration = () => {
  return (
    <PageWrapper
      navigationPaneContent={<NavigationPane componentName = "Administration"/>}
      listPaneContent={<RolesRules />}
    />
  );
};

export default Administration;