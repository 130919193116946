import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import useLayout from '@uicl/ui-core/dist/useLayout';
import { Container, Row, Input, DetailPaneHeader, Checkbox, DataItem, Button } from '@uicl/ui-core';
import { updateExistingDepartment } from '../modules/actions';
import Notification from '../../../../components/notifications/Notification';
import { useIsMount } from '../../../../commonResources/hooks/useIsMount';

/*eslint-disable */
const DepartmentDetail = () => {

  const dispatch = useDispatch();
  const isMount = useIsMount();

  const {
    detailProps = {},
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    closeDetailPane,
  } = useLayout();

  const headerProps = {
    title: 'View Department',
    layoutControls: [expandCollapseDetailPaneButtonConfig, closeDetailPaneButtonConfig]
  }

  const {
    departmentEditResponseIsSuccessStatusCode,
    departmentEditError,
    triggerPut
  } = useSelector(state => state.departments);

  const [name, setDepartmentName] = useState('');
  const [description, setDepartmentDescription] = useState('');
  const [isActive, setIsActive] = useState(false);

  const { userInformation } = useSelector(state => state.app);


  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState();
  const [isApiError, setIsApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState();
  const [isSameValues, setIsSameValues] = useState(false);
  const [catsKey, setCatsKey] = useState(0);

  useEffect(() => {
    if (detailProps && Object.keys(detailProps).length !== 0) {
      setDepartmentName(detailProps.name);
      setDepartmentDescription(detailProps.description);
      setIsActive(!detailProps.isDeleted);
    }
  }, [detailProps])

  useEffect(() => {
    if (isMount) {
      if (!departmentEditResponseIsSuccessStatusCode) {
        setApiErrorMessage(departmentEditError);
        setIsApiError(true);
        return
      }
      setCatsKey(key => key + 1)
      setIsSaveSuccess(true);
    }
  }, [triggerPut])

  const [clickSubmit, setSubmit] = useState(false);

  const isValidTextData = (text) => {
    const regex = /^([A-Za-z0-9]+[A-Za-z0-9 -.]+[ ]*)+$/;
    return regex.test(text);
  }

  
  const isValidDepartmentInputData = () => {
    if (!name || !description) {
      setValidationErrorMessage("Please complete the required fields (*)");
      return false;
    }
    if (!isValidTextData(name) || !isValidTextData(description)) {
      setValidationErrorMessage("Please review invalid input data");
      return false;
    }

    setValidationErrorMessage();
    return true;
  };

  const departmentDataChanged = (department) => {
    return department.Name != detailProps.name || department.Description != detailProps.description || department.isDeleted != detailProps.isDeleted;
  }
  const handleSubmit = async () => {

    setSubmit(true);

    if (isValidDepartmentInputData()) {

      const department = {
        Id: detailProps.id,
        CustomerId: detailProps.customerId,
        Name: name,
        Description: description,
        LastUpdateBy: userInformation.userName,
        LastUpdateDate: new Date(),
        isDeleted: !isActive
      };      

      if (!departmentDataChanged(department)) {
        setIsSameValues(true);
        return;
      }
      
      dispatch(updateExistingDepartment(department));
      return;
    }
    setIsValidationError(true);
  }

  return (detailProps ?
    <>

      <DetailPaneHeader {...headerProps} />

      {isSaveSuccess && (
        <Notification
          title="Success"
          text='Department was updated successfully'
          type="success"
          onDismiss={() => {
            setIsSaveSuccess(false);
            closeDetailPane();
          }}
        />
      )}
      {isValidationError && (
        <Notification
          title="Error"
          text={validationErrorMessage}
          type="error"
          onDismiss={() => setIsValidationError(false)}
        />
      )}
      {isApiError && (
        <Notification
          title="Error"
          text={apiErrorMessage}
          type="error"
          timer={3500}
          onDismiss={() => setIsApiError(false)}
        />
      )}

      {isSameValues && (
        <Notification
          title="Info"
          text="Nothing to update"
          type="info"
          onDismiss={() => setIsSameValues(false)}
        />
      )}

      <Container >
        <Row>
          <div className='column-dropdowns form-items-margin'>
            <DataItem
              label='Customer'
              content={detailProps.customerName}
            />
          </div>
        </Row>
        <Row className='details'>
          <div className='column-dropdowns form-items-margin'>
            <Input
              label='Department Name'
              domID='name'
              name='name'
              required
              regex={/^([A-Za-z0-9]+[A-Za-z0-9 -.]+[ ]*)+$/}
              autoComplete='off'
              maxLength={100}
              hasError={clickSubmit && !name}
              errorMessage={clickSubmit && !name ? 'Please enter Department Name' : 'Invalid Department Name'}
              value={name}
              onChange={(e) => setDepartmentName(e ? e.target.value : '')}
            />
          </div>
          <div className='column-dropdowns form-items-margin'>
            <Input
              label='Description'
              domID='description'
              name='description'
              required
              regex={/^([A-Za-z0-9]+[A-Za-z0-9 -.]+[ ]*)+$/}
              autoComplete='off'
              maxLength={100}
              hasError={clickSubmit && !description}
              errorMessage={clickSubmit && !description ? 'Please enter Department Description' : 'Invalid Department Description'}
              value={description}
              onChange={(e) => setDepartmentDescription(e ? e.target.value : '')}
            />
          </div>
        </Row>

        <Row>
          <div className='column-dropdowns form-items-margin'>
            <DataItem
              label='Created By'
              content={detailProps.createdBy}
            />
          </div>
          <div className='column-dropdowns form-items-margin'>
            <DataItem
              label='Created Date'
              content={detailProps.createdDate}
            />
          </div>
        </Row>

        <Row>
          <div className='column-dropdowns form-items-margin'>
            <DataItem
              label='Last Update By'
              content={detailProps.lastUpdateBy}
            />
          </div>
          <div className='column-dropdowns form-items-margin'>
            <DataItem
              label='Last Update Date'
              content={detailProps.lastUpdateDate}
            />
          </div>
        </Row>
        <Row >
          <div className='column-dropdowns'>
            <Checkbox
              label='Is Active?'
              dataTestId='isActive'
              checked={!detailProps.isDeleted}
              size='medium'
              value={isActive}
              onChange={(e) => setIsActive(!isActive)}
            />
          </div>
        </Row>

        <Button onClick={handleSubmit}>
          Save
        </Button>
        <Button buttonType="diminished" onClick={closeDetailPane}>
          Back to Departments Search
        </Button>

      </Container>
    </> : null
  )
}


export default DepartmentDetail;