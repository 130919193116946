export const DEFAULT_OPTIONS = {
  storage: localStorage,
  namespace: "store"
};

export const INVALID_STORAGE =
  "Please pass a reference to a supported storage type: localStorage or sessionStorage";

export const INVALID_NAMESPACE = "You must pass a valid namespace";

export const UTILITY_OBJECT_ASSIGN_TYPE_ERROR =
  "Cannot convert undefined or null to object";
