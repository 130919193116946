export const ruleConstants = {
  AdminSystem: 'AdminSystem',
  CreateUser: 'CreateUser',
  RequiresCorp: 'RequiresCorp',
  RequiresCorpMulti: 'RequiresCorpMulti',
  RequiresDept: 'RequiresDept',
  RequiresPlan: 'RequiresPlan',
  RequiresUnitMgr: 'RequiresUnitMgr',
  EditUser: 'EditUser',
  CreateReferralOthers: 'CreateReferralOthers',
  CreateReferralOwn: 'CreateReferralOwn',
  ManagedByAccountMgr: 'ManagedByAccountMgr',
  ManagedByCorpMgr: 'ManagedByCorpMgr',
  ManagedByUnitMgr: 'ManagedByUnitMgr',
  ManagedByMgrAssistant: 'ManagedByMgrAssistant',
  ManageCorpAccounts: 'ManageCorpAccounts',
  ManageCorpEntity: 'ManageCorpEntity',
  ManageRefUsers: 'ManageRefUsers',
  ManagerAssistant: 'ManagerAssistant',
  EditReferralOwn: 'EditReferralOwn',
  EditReferralOthers: 'EditReferralOthers',
  ViewAllReferrals: 'ViewAllReferrals',
  ViewCorporateReferrals: 'ViewCorporateReferrals',
  ViewOwnReferrals: 'ViewOwnReferrals',
  ViewOthersReferrals: 'ViewOthersReferrals',  
}