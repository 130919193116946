import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Grid, ListPaneHeader } from '@uicl/ui-core';
import useLayout from '@uicl/ui-core/dist/useLayout';
import CircleCheck from '@uicl/ui-core/dist/CircleCheck';
import RolesGridConfig from './RolesGridConfig';
import Pagination from '../../../../components/Pagination';
import { clearRolesReducer, loadRoles, loadRules } from '../modules/actions';
import CreateRole from './CreateRole';
import '../styles/styles.css';
import { useIsMount } from '../../../../commonResources/hooks/useIsMount';
import Notification from '../../../../components/notifications/Notification';
import LoadingPage from '../../../../components/LoadingPage';

const Roles = () => {
  const {
    openDetailPane,
    detailProps: selectedRecord,
    expandCollapseListPaneButtonConfig,
  } = useLayout();

  const dispatch = useDispatch();
  const [createRole, setCreateRole] = useState(false);
  const isMount = useIsMount();

  const { rules,roles, rolesPaginationData, triggerPut, statusCodeUpdate, rolesIsLoading } = useSelector(state => state.roles);

  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const [initPage, setInitPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [pages, setPages] = useState([]);

  const { pageNumber, pageSize, totalPages, totalRecords } = rolesPaginationData;

  const movePagesForward = () => {
    setInitPage(initPage + 10);
    setEndPage(endPage + 10);
  };
  const movePagesBackward = () => {
    setInitPage(initPage - 10);
    setEndPage(endPage - 10);
  };

  const onGridPag = (page) => {
    const params = {
      pageNumber: page,
      pageSize: 10
    }
    dispatch(loadRoles(params))
    setActivePage(page);
  };

  useEffect(() => {
    if (rules.length === 0) {
      dispatch(loadRules());
    }
  }, [])

  useEffect(() => {
    if (roles.length === 0) {
      const params = {
        pageNumber: 1,
        pageSize: 10
      }
      dispatch(loadRoles(params));
    }
    return () => {
      dispatch(clearRolesReducer())
    }
  }, [])

  useEffect(() => {
    const pags = [];
    for (let i = 1; i <= totalPages; i += 1) {
      pags.push(i);
    }
    setPages(pags);
  }, [rolesPaginationData]);

  useEffect(() => {
    if (isMount) {
      if (statusCodeUpdate === 200) {
        const params = {
          pageNumber,
          pageSize: 10
        }
        dispatch(loadRoles(params));
        setIsSaveSuccess(true);
      }
    }
  }, [triggerPut])


  const headerProps = {
    title: createRole ? 'Create Role' : 'Roles',
    icon: CircleCheck,
    description: 'This is short description',
    layoutControls: [expandCollapseListPaneButtonConfig]
  };

  return (
    <>
      <ListPaneHeader {...headerProps} />
      {isSaveSuccess && (
        <Notification
          title="Success"
          text="Role Saved Successfully"
          type="success"
          timer={3500}
          onDismiss={() => setIsSaveSuccess(false)}
        />
      )}
      <Container>
        {
          createRole
            ?
            <Container>
              <CreateRole setCreateRole={setCreateRole} />
            </Container>
            :
            <>
              {
                roles &&
                <>
                  {
                    rolesIsLoading
                      ?
                      <LoadingPage />
                      :
                      <>
                        <div className='column-button'>
                          <Button className='createRoleButton' onClick={() => setCreateRole(true)}>Create Role</Button>
                        </div>
                        <Grid
                          {...RolesGridConfig}
                          records={roles}
                          className='dataGrid'
                          dataTestId="test-formGrid"
                          onClickThrough={(e, record) => {
                            openDetailPane(record, e);
                          }}
                          focusedItem={selectedRecord}
                        />
                        <Pagination
                          totalRecords={totalRecords}
                          pageNumber={pageNumber}
                          pageSize={pageSize}
                          pages={pages}
                          totalPages={totalPages}
                          activePage={activePage}
                          initPage={initPage}
                          endPage={endPage}
                          onGridPag={onGridPag}
                          movePagesForward={movePagesForward}
                          movePagesBackward={movePagesBackward} />
                      </>
                  }
                </>
              }
            </>
        }
      </Container>
    </>
  )
}

export default Roles