/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../../commonResources/services/auth-management';
import SilentRefreshModal from './SilentRefreshModal';

export const PrivateRoute = ({ component: Component, ...rest }) => {

  if(isAuthenticated()){

    return(
      <>
        <Route
          {...rest}
          render={(props) =>
            <Component {...props} />
          }
        />
        <SilentRefreshModal />
      </>
    );
  }

  return (<>
    <Route
      {...rest}
      render={(props) =>
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      }
    />
    <SilentRefreshModal />
  </>);
};

export default PrivateRoute;
