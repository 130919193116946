import { types } from "./types";


const initialState = {
  searchReferralGridData: [],
  searchReferralPaginationData: {},
  searchReferralErrorFlag: false,
  searchGridIsLoading: false,
  searchReferralError: '',
  searchReferralExcelData: [],
  searchExcelDataIsLoading: false,
  searchReferralExcelError: '',

  preferredLanguageData: [],
  otherLanguageData: [],
  languageError: '',
  languageIsLoading: false,

  relationshipData: [],
  relationshipError: '',
  relationshipIsLoading: false,

  outreachChannelData: [],
  outreachChannelError: '',
  outreachChannelIsLoading: false,

  updateReferalResponse: [],
  updateReferralErrorMsg: '',
  updateReferalIsLoading: false,
  isUpdateReferralSuccess: false,
  isUpdateReferralFail: false,
  updateReferralErrorMsgForInput: '',

  zipcodeDataAlt: [],
  zipcodeErrorAlt: '',
}

export const searchReferralReducer = (state = initialState, action) => {

  switch (action.type) {

    case types.LOAD_REFERRAL_DATA_GRID_SUCCESS:
      return {
        ...state,
        searchReferralGridData: action.payload,
      }

    case types.LOAD_REFERRAL_DATA_GRID_FAIL:
      return {
        ...state,
        searchReferralError: action.payload,
        searchReferralErrorFlag: true
      }

    case types.LOAD_REFERRAL_GRID_IS_LOADING:
      return {
        ...state,
        searchGridIsLoading: true
      }

    case types.LOAD_REFERRAL_GRID_FINISH_LOADING:
      return {
        ...state,
        searchGridIsLoading: false
      }

    case types.LOAD_REFERRAL_PAGINATION_DATA_SUCCESS:
      return {
        ...state,
        searchReferralPaginationData: action.payload
      }

    case types.LOAD_REFERRAL_DATA_FOR_EXCEL_SUCCESS:
      return {
        ...state,
        searchReferralExcelData: action.payload,
      }

    case types.LOAD_REFERRAL_DATA_FOR_EXCEL_FAIL:
      return {
        ...state,
        searchReferralExcelError: action.payload
      }

    case types.LOAD_REFERRAL_EXCEL_IS_LOADING:
      return {
        ...state,
        searchExcelDataIsLoading: true
      }

    case types.LOAD_REFERRAL_EXCEL_FINISH_LOADING:
      return {
        ...state,
        searchExcelDataIsLoading: false
      }

    case types.CLEAR_REFERRAL_DATA_GRID:
      return {
        ...state,
        searchReferralGridData: [],
        searchReferralPaginationData: {}
      }

    case types.CLEAR_REFERRAL_EXCEL_DATA:
      return {
        ...state,
        searchReferralExcelData: []
      }

    case types.CLEAR_REFERRAL_NOTIFICATIONS:
      return {
        ...state,
        searchReferralErrorFlag: false
      }

    case types.LOAD_LANGUAGES_SUCCESS:
      return {
        ...state,
        preferredLanguageData: action.preferredLanguages,
        otherLanguageData: action.otherLanguages
      }

    case types.LOAD_LANGUAGES_FAIL:
      return {
        ...state,
        languageError: action.payload
      }

    case types.LOAD_RELATIONSHIPS_SUCCESS:
      return {
        ...state,
        relationshipData: action.payload
      }

    case types.LOAD_RELATIONSHIPS_FAIL:
      return {
        ...state,
        relationshipError: action.payload
      }

    case types.LOAD_OUTREACHCHANNEL_SUCCESS:
      return {
        ...state,
        outreachChannelData: action.payload
      }

    case types.LOAD_OUTREACHCHANNEL_FAIL:
      return {
        ...state,
        outreachChannelError: action.payload
      }

    case types.UPDATE_REFERRAL_SUCCESS:
      return {
        ...state,
        updateReferalResponse: action.payload,
        isUpdateReferralSuccess: true
      }

    case types.UPDATE_REFERRAL_FAIL:
      return {
        ...state,
        updateReferralErrorMsg: action.payload,
        updateReferralErrorMsgForInput: action.updateErrorMsg,
        isUpdateReferralFail: true
      }

    case types.UPDATE_REFERRAL_IS_LOADING:
      return {
        ...state,
        updateReferalIsLoading: true
      }

    case types.UPDATE_REFERRAL_FINISH_LOADING:
      return {
        ...state,
        updateReferalIsLoading: false
      }

    case types.UPDATE_REFERRAL_CLEAR_NOTIFICATIONS:
      return {
        ...state,
        isUpdateReferralSuccess: false,
        isUpdateReferralFail: false
      }

    case types.UPDATE_REFERRAL_LOAD_ZIPCODE_ALT_SUCCESS:
      return {
        ...state,
        zipcodeDataAlt: action.payload
      }

    case types.UPDATE_REFERRAL_LOAD_ZIPCODE_ALT_FAIL:
      return {
        ...state,
        zipcodeErrorAlt: action.payload
      }

    case types.UPDATE_REFERRAL_CLEAR_ZIPCODE_ALT_DATA:
      return {
        ...state,
        zipcodeDataAlt: []
      }

    default:
      return state;
  }

}