// import * as XLSX from 'xlsx/xlsx.mjs';
import * as XLSX from '../xls/xlsx.mjs';

export const excelExport = (heading, headers, data, fileName) => {

  // Had to create a new workbook and then add the header
  // A workbook is the name given to an Excel file
  const wb = XLSX.utils.book_new(); // make Workbook of Excel
  XLSX.utils.sheet_add_aoa(wb, heading);

  // Starting in the second row to avoid overriding and skipping headers
  const excelContent = XLSX.utils.sheet_add_json(wb, data, {
    origin: "A2",
    header: headers,
    skipHeader: true,
  });

  // add Worksheet to Workbook
  // Workbook contains one or more worksheets
  XLSX.utils.book_append_sheet(wb, excelContent); // sheetAName is name of Worksheet

  // export Excel file
  XLSX.writeFile(wb, `${fileName}.xlsx`); // name of the file is 'book.xlsx'
}

