/**
 * Advance Search Constants
 */
export const ON_ADVANCED_SEARCH_SUBMIT = 'ON_ADVANCED_SEARCH_SUBMIT';
export const ON_ADVANCED_SEARCH_CLEAR = 'ON_ADVANCED_SEARCH_CLEAR';
export const SET_ADVANCED_SEARCH_TITLE = 'SET_ADVANCED_SEARCH_TITLE';
// export const ON_ADVANCED_SEARCH_CANCEL = 'ON_ADVANCE_SEARCH_CANCEL';
export const ON_ADVANCE_SEARCH_TOGGLE_FROM_EDIT =
  'ON_ADVANCE_SEARCH_TOGGLE_FROM_EDIT';

/**
 * Save Search Constants
 */
export const ON_SAVE_SEARCH = 'ON_SAVE_SEARCH';
export const ON_MODIFY_SAVED_SEARCH = 'ON_MODIFY_SAVED_SEARCH';
export const ON_DUPLICATE_SAVED_SEARCH = 'ON_DUPLICATE_SAVED_SEARCH';
export const ON_RENAME_SAVED_SEARCH = 'ON_RENAME_SAVED_SEARCH';
export const ON_DELETE_SAVED_SEARCH = 'ON_DELETE_SAVED_SEARCH';
export const ON_APPLY_SAVED_SEARCH = 'ON_APPLY_SAVED_SEARCH';

/**
 * UI Update Constants
 */

export const SET_IS_FLYOUT_OPEN = 'SET_IS_FLYOUT_OPEN';
export const SET_MOST_RECENT_SEARCH_TYPE = 'SET_MOST_RECENT_SEARCH_TYPE';
export const SET_RESULTS_TITLE = 'SET_RESULTS_TITLE';
export const SET_SAVED_SEARCH_ACTIVE_INDEX = 'SET_SAVED_SEARCH_ACTIVE_INDEX';
export const SET_ADVANCED_SEARCH_FORM_DATA_STATE =
  'SET_ADVANCED_SEARCH_FORM_DATA_STATE';
export const SET_ADVANCED_SEARCH_EDIT_MODE = 'SET_ADVANCED_SEARCH_EDIT_MODE';
export const SET_SAVED_SEARCH_OPEN_INDICES = 'SET_SAVED_SEARCH_OPEN_INDICES';
export const SET_SAVED_SEARCHES = 'SET_SAVED_SEARCHES';
export const SET_SHOW_SAVE_DROPDOWN = 'SET_SHOW_SAVE_DROPDOWN';
export const SET_SHOW_DELETE_MODAL = 'SET_SHOW_DELETE_MODAL';
export const SET_SAVED_SEARCH_SINGLE_SELECT_KEY =
  'SET_SAVED_SEARCH_SINGLE_SELECT_KEY';

/**
 * Edit mode
 */
export const ON_TOGGLE_EDIT_MODE = 'ON_TOGGLE_EDIT_MODE';
export const ON_NAVIGATIONPANE_ITEMS_MOVE = 'ON_NAVIGATIONPANE_ITEMS_MOVE';
export const ON_SET_HOME = 'ON_SET_HOME';
export const SET_SELECTED_SINGLE_SELECT_ITEM =
  'SET_SELECTED_SINGLE_SELECT_ITEM';
export const SET_OPEN_GROUP_INDICES = 'SET_OPEN_GROUP_INDICES';
export const SET_DRAGGING_INDEX = 'SET_DRAGGING_INDEX';

export const ON_RESTORE_TO_DEFAULT = 'ON_RESTORE_TO_DEFAULT';

export const navigationPaneGroupTypes = {
  SINGLE_GROUP: 'SINGLE_GROUP',
  MULTIPLE_GROUP: 'MULTIPLE_GROUP',
  SAVED_SEARCH: 'SAVED_SEARCH',
};

const navigationPaneItemsMultiSelect = {
  id: 'one',
  text: 'one',
  items: [
    {
      id: 'two',
      text: 'Multi Select Item I',
    },
    {
      id: 'three',
      text: 'Multi Select Item II',
    },
    {
      id: 'four',
      text: 'Multi Select Item III',
    },
  ],
};

export const defaultNavigationPaneItems = [
  {
    id: 'some-group-1',
    type: navigationPaneGroupTypes.SINGLE_GROUP,
    text: 'Title One',
    items: [
      'Single-Select Group Item 1',
      `A very long text. This will ellipsis when it runs out of the screen and the tooltip will be displayed on hover`,
    ],
    extraProps: {},
  },
  {
    id: 'some-group-2',
    type: navigationPaneGroupTypes.SINGLE_GROUP,
    text: 'Title Two',
    items: ['item one', 'item two', 'item three', 'item four', 'item five'],
    extraProps: {},
  },
  {
    id: 'single-item-in-group-example',
    type: navigationPaneGroupTypes.SINGLE_GROUP,
    text: 'Only one item group',
    items: ['I am the only item in this group'],
    extraProps: {},
  },
  {
    id: 'some-group-3',
    type: navigationPaneGroupTypes.MULTIPLE_GROUP,
    ...navigationPaneItemsMultiSelect,
    extraProps: {
      dataTestId: 'test-multiComplex',
      domID: 'multi-select-group',
      onItemClick: () => false,
    },
  },
];

export const saveSearchSubMenuOptions = [
  {
    id: 'edit',
    label: 'Edit',
  },
  {
    id: 'rename',
    label: 'Rename',
  },
  {
    id: 'duplicate',
    label: 'Duplicate',
  },
  {
    id: 'delete',
    label: 'Delete',
  },
];
