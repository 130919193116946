export const types = {
  // LOAD_CUSTOMERS : '[LOAD_CUSTOMERS] Load data for departments customer dropdown',
  // LOAD_CUSTOMERS_SUCCESS : '[LOAD_CUSTOMERS_SUCCESS] Load data for departments customer dropdown',
  // LOAD_CUSTOMERS_FAIL : '[LOAD_CUSTOMERS_FAIL] Load data for departments customer dropdown',

  LOAD_CUSTOMERS : '[LOAD_CUSTOMERS] Load data for CUSTOMERS grid',
  LOAD_CUSTOMERS_SUCCESS : '[LOAD_CUSTOMERS_SUCCESS] Load data for CUSTOMERS grid',
  LOAD_CUSTOMERS_FAIL : '[LOAD_CUSTOMERS_FAIL] Load data for CUSTOMERS grid',

  LOAD_MCOCONTRACTS_SUCCESS: '[LOAD_MCOCONTRACTS] load data for mco contracts detail grid',
  LOAD_MCOCONTRACTS_FAIL: '[LOAD_MCOCONTRACTS] load data for mco contracts detail grid fail',
  LOAD_MCOCONTRACTS_IS_LOADING: '[LOAD_MCOCONTRACTS] load data for mco contracts detail grid is loading',
  LOAD_MCOCONTRACTS_FINISH_LOADING: '[LOAD_MCOCONTRACTS] load data for mco contracts detail grid finish loading',

  LOAD_CUSTOMER_IMAGE_SUCCESS: '[LOAD_CUSTOMER_IMAGE] load customer image for view details',
  CLEAR_CUSTOMER_IMAGE: '[LOAD_CUSTOMER_IMAGE] clear customer image for view details',
  LOAD_CUSTOMER_IMAGES_IS_LOADING: '[LOAD_CUSTOMER_IMAGE] load customer image is loading',
  LOAD_CUSTOMER_IMAGES_FINISH_LOADING: '[LOAD_CUSTOMER_IMAGE] load customer image finish loading',

  LOAD_CUSTOMER_IMAGES_FOR_GRID_SUCCESS: '[LOAD_CUSTOMER_IMAGE] load customer image for grid',
  LOAD_CUSTOMER_IMAGES_FOR_GRID_FAIL: '[LOAD_CUSTOMER_IMAGE] load customer image for grid fail',  
  CLEAR_CUSTOMER_IMAGES_FOR_GRID: '[LOAD_CUSTOMER_IMAGE] clear data customer images grid',

  UPDATE_CUSTOMER_IMAGE_SUCCESS: '[UPDATE_CUSTOMER_IMAGE] update customer image success',
  UPDATE_CUSTOMER_IMAGE_FAIL: '[UPDATE_CUSTOMER_IMAGE] update customer image fail',
  UPDATE_CUSTOMER_IMAGE_IS_LOADING: '[UPDATE_CUSTOMER_IMAGE] update customer image is loading',
  UPDATE_CUSTOMER_IMAGE_FINISH_LOADING: '[UPDATE_CUSTOMER_IMAGE] update customer image finish loading',

  CLEAR_CUSTOMER_NOTIFICATIONS: '[CUSTOMER_NOTIFICATIONS] clear customer notifications',
};